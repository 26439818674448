
<mat-grid-list cols="18" rowHeight="36" gutterSize="10">
  <mat-grid-tile colspan="18" rowspan="1" style="text-align: center;">
    <div style="display: flex; align-items: flex-end; height: 100%; width: 100%;">
      <p style="width: 100%; margin: 0; text-align: center; font-size: 13px; font-weight: bold; color: #616161;">Imposta fascia oraria monitoraggio unità</p>
    </div>
  </mat-grid-tile>
  <mat-grid-tile colspan="18" rowspan="1">    
    <ion-toolbar mode="ios" style="width: 100%; min-height: 40px;">
      <ion-segment value="all" [value]="deviceSettings.notificationMode" (ionChange)="deviceSettings.notificationMode = $any($event.detail.value)">
        <ion-segment-button value="scheduled">
          <ion-label>Temporizzato</ion-label>
        </ion-segment-button>
        <ion-segment-button value="always_active">
          <ion-label>Sempre attivo</ion-label>
        </ion-segment-button>
        <ion-segment-button value="disabled">
          <ion-label>Disattivato</ion-label>
        </ion-segment-button>
      </ion-segment>
    </ion-toolbar>
  </mat-grid-tile>

  <!-- CIRCULAR RANGE SELECTOR -->
  <!-- <mat-grid-tile colspan="9" rowspan="6">
    <div style="margin: auto;">
      <time-range-selector [diameter]="250" (handleTouched)="handleTouched($event)"></time-range-selector>
    </div>
  </mat-grid-tile> -->
  <!-- RAW RANGE SELECTOR -->
   @switch (deviceSettings.notificationMode) {
    @case ('always_active') {
      <mat-grid-tile colspan="18" rowspan="1" style="overflow: visible;">
        <p style="width: 100%; text-align: center; color: #616161; font-size: 13px;">Tutti i dispositivi della stanza sono sempre attivi</p>
      </mat-grid-tile>
    } @case ('disabled') {
      <mat-grid-tile colspan="18" rowspan="1" style="overflow: visible;">
        <p style="width: 100%; text-align: center; color: #616161; font-size: 13px;">Tutti i dispositivi della stanza sono disattivati</p>
      </mat-grid-tile>
    } @default {
      <mat-grid-tile colspan="9" rowspan="1" style="overflow: visible;">
        <mat-form-field style="width: 100%; height: 100%;" appearance="outline">
          <mat-label>Orario inizio</mat-label>
          <mat-select [(ngModel)]="deviceSettings.scheduleStartTime">
              <mat-option [value]="hour" *ngFor="let hour of hoursList">{{hour}}</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="9" rowspan="1" style="overflow: visible;">
        <mat-form-field style="width: 100%; height: 100%;" appearance="outline">
          <mat-label>Orario fine</mat-label>
          <mat-select [(ngModel)]="deviceSettings.scheduleEndTime">
            <mat-option [value]="hour" *ngFor="let hour of hoursList">{{hour}}</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
    }
   }

  <mat-grid-tile colspan="18" rowspan="1" style="overflow: visible;"></mat-grid-tile>

  <mat-grid-tile colspan="18" rowspan="1" style="text-align: center; overflow: visible;">
    <div style="display: flex; align-items: flex-end; height: 100%; width: 100%;">
      <p style="width: 100%; margin: 0; text-align: center; font-size: 13px; font-weight: bold; color: #616161;">Imposta ritardo allerta uscita letto</p>
    </div>
  </mat-grid-tile>
  <mat-grid-tile colspan="6" rowspan="1" *ngIf="!isMobile"></mat-grid-tile>
  <mat-grid-tile [colspan]="isMobile ? 18 : 6" rowspan="1" style="overflow: visible;">
    <mat-form-field style="width: 100%; height: 100%;" appearance="outline">
      <mat-label>Minuti di ritardo</mat-label>
      <mat-select [(ngModel)]="deviceSettings.bedExitDelay">
          <mat-option [value]="0">Allerta immediata</mat-option>
          <mat-option [value]="index+1" *ngFor="let _ of [].constructor(30); index as index">{{index+1}}</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-grid-tile>
  <mat-grid-tile colspan="18" rowspan="1" style="overflow: visible;"></mat-grid-tile>
  <mat-grid-tile [colspan]="isMobile ? 2 : 4" rowspan="1" style="overflow: visible;"></mat-grid-tile>
  <mat-grid-tile [colspan]="isMobile ? 14 : 10" rowspan="1" style="overflow: visible;">
    <ion-button color="primary" mode="md" style="width: 100%; height: 100%;" (click)="submitData()">
      Salva e chiudi
    </ion-button>
  </mat-grid-tile>
  <mat-grid-tile colspan="18" rowspan="1" style="overflow: visible;"></mat-grid-tile>
</mat-grid-list>