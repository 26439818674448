import { Injectable } from "@angular/core";
import { ITableConfigParamsFilter } from "./table.component";
import { map } from "rxjs";
import { RequestsService } from "../../../core/services/utils/requests.service";
import { FacilitiesService } from "../../../core/services/facilities.service";
import { RoleToTranslation } from "../../enums/userRole.enum";
import { TranslateService } from "@ngx-translate/core";
import { SensorService } from "../../../core/services/sensors.service";

@Injectable({
    providedIn: 'any',
})
export class FiltersList {
    constructor(
        private requestsService: RequestsService,
        private facilitiesService: FacilitiesService,
        private translateService: TranslateService,
        private sensorService: SensorService
    ) {

    }

    getFilterById(filterId: string): ITableConfigParamsFilter | null {
        return this.filters.find((filter) => filter.id === filterId) || null;
    }

    public filters: ITableConfigParamsFilter[] = [
      {
        type: 'select',
        label: 'PATIENT_ADMISSION',
        id: 'patientStatus',
        tableParamName: 'patientStatus',
        options: [{
            id: 'active',
            name: 'ACTIVE',
            translateName: true,
        },{
            id: 'discharged',
            name: 'DISCHARGED',
            translateName: true,
        }]
      },
      { 
        type: 'multiSelect',
        label: 'STATUS',
        id: 'status',
        allCheckedByDefault: true,
        tableParamName: 'status',
        selectedValue: ['online', 'offline', 'disabled'],
        options: [{
          id: 'online',
          name: 'ONLINE',
          translateName: true,
        },{
          id: 'offline',
          name: 'OFFLINE',
          translateName: true,
        },{
          id: 'disabled',
          name: 'DISABLED',
          translateName: true,
        }]
      },{
        type: 'select',
        label: 'ASSOCIATED',
        id: 'facilities',
        tableParamName: 'facilities',
        checkExistance: true,
        options: [{
          id: 'notLinked',
          name: 'NOT_LINKED',
          value: false,
          translateName: true,
        },{
          id: 'linked',
          name: 'LINKED',
          value: true,
          translateName: true,
        }]
      },
      {
        label: 'ORGANIZATION',
        id: 'organization',
        tableParamName: 'organization',
        dontResetOnClear: true,
        type: 'select' as 'select',
        options: [],
        dynamicCustomerAndOrganization: !this.requestsService.hasLinkedOrg()
      },
      {
        label: 'BUILDING',
        id: 'building',
        tableParamName: 'building',
        type: 'select' as 'select',
        options: [],
        loader: (customerId?: string, orgId?: string) => this.facilitiesService.getBuildings$(customerId, orgId, false).pipe(
          map((data) => data.map((building) => ({id: building.id!, name: building.name!})))
        ),
        ...(!this.requestsService.hasLinkedOrg() ? {dynamicLoadOn: 'organization'} : {}),
        dynamicCustomerAndOrganization: !this.requestsService.hasLinkedOrg()
      },{
        label: 'UNIT',
        id: 'unit',
        tableParamName: 'unit',
        type: 'select' as 'select',
        options: [],
        loader: (buildingId?: string, customerId?: string, orgId?: string) => this.facilitiesService.getUnits$(buildingId!, customerId, orgId).pipe(
          map((data) => data.map((unit) => ({id: unit.id!, name: unit.name!})))
        ),
        dynamicLoadOn: 'building',
        dynamicCustomerAndOrganization: !this.requestsService.hasLinkedOrg()
      },{
        label: 'ROOM',
        id: 'room',
        tableParamName: 'room',
        type: 'select' as 'select',
        options: [],
        loader: (unitId?: string, customerId?: string, orgId?: string) => this.facilitiesService.getRooms$(unitId!, customerId, orgId).pipe(
          map((data) => data.map((room) => ({id: room.id!, name: room.name!})))
        ),
        dynamicLoadOn: 'unit',
        dynamicCustomerAndOrganization: !this.requestsService.hasLinkedOrg()
      },{
        label: 'ROLE',
        id: 'role',
        tableParamName: 'role',
        type: 'select',
        options: [
            {
            id: 'superUser',
            name: this.translateService.instant('PAGES.USERS.ROLES.'+RoleToTranslation.superuser),
            },{
            id: 'localAdmin',
            name: this.translateService.instant('PAGES.USERS.ROLES.'+RoleToTranslation.localadmin),
            },{
            id: 'doctor',
            name: this.translateService.instant('PAGES.USERS.ROLES.'+RoleToTranslation.doctor),
            },{
            id: 'nurse',
            name: this.translateService.instant('PAGES.USERS.ROLES.'+RoleToTranslation.nurse),
            },{
            id: 'oss',
            name: this.translateService.instant('PAGES.USERS.ROLES.'+RoleToTranslation.oss),
            },{
            id: 'secretary',
            name: this.translateService.instant('PAGES.USERS.ROLES.'+RoleToTranslation.secretary),
            }
        ]
      },{
        type: 'multiSelect',
        label: 'ALERT_TYPE',
        id: 'severity',
        tableParamName: 'severity',
        allCheckedByDefault: true,
        selectedValue: ['01','02','03','04','05','06'],
        options: []
      },{
        type: 'select',
        label: 'FILTER_BY_TIME',
        id: 'timestamp',
        tableParamName: 'timestamp',
        selectedValue: 'last24h',
        defaultValue: 'last24h',
        reloadTableData: true,
        options: [{
          id: 'last6h',
          name: "Ultime 6 ore"
        },{
          id: 'last24h',
          name: "Ultime 24 ore"
        },{
          id: 'lastWeek',
          name: "Ultima settimana"
        },{
          id: 'lastMonth',
          name: "Ultimo mese"
        }]
      }
    ]

}