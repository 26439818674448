import { Directive, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, SimpleChanges, OnChanges } from '@angular/core';
import { fromEvent, interval, merge, Subscription } from 'rxjs';
import { filter, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Point } from '../models/point.dto';

const MOUSE_PRIMARY = 0;

@Directive({
  selector: '[circularSliderHandle]',
  standalone: true
})
export class CircularSliderHandleDirective implements OnInit {

    private element: HTMLElement;
    private elementStartSubscription?: Subscription;
    private elementMoveSubscription?: Subscription;
    private _angle: number = 0;
    private layerLatestDelta?: Point;
    private circleRadius: number = 0;

    @Output() refreshArc = new EventEmitter<number>();
    @Input() handleType?: 'start' | 'end';
    @Input() circlePosition?: {x:number; y:number};
    /**
     * @description Diametro del canvas
     */
    @Input() diameter: number = 300;
    /**
     * @description Diametro dei cerchietti "handle"
     */
    @Input() handleDiameter: number = 1;
    /**
     * @description Passo dello slider, in MINUTI
     */
    @Input() step: number = 30;
    @Input()
    get angle() {
        return this._angle;
    }
    set angle(deg: number) {
        this._angle = deg || 0;
        this.setTransform(this.calcHandlePosition(this._angle));
        this.angleChange.emit(this._angle);
    }

    @Output()
    angleChange = new EventEmitter<number>();

    constructor(
        private ngZone: NgZone,
        private elementRef: ElementRef,
    ) {
        this.element = this.elementRef.nativeElement;
    }

    ngOnInit() {
        this.positionHandle();
        this.registerStart(this.element);
    }

    private positionHandle() {
        this.circleRadius = this.diameter / 2;
        this.element.style.width = `${this.handleDiameter}px`;
        this.element.style.height = `${this.handleDiameter}px`;
        this.element.style.top = '0px';
        this.element.style.left = '0px';
        this.setTransform(this.calcHandlePosition(this.angle));
    }

    private registerStart(element: HTMLElement) {
        this.unregisterStart();
        this.ngZone.runOutsideAngular(() => {
            const mousedown$ = fromEvent<MouseEvent>(element, 'mousedown').pipe(
                filter(event => event.button === MOUSE_PRIMARY),
                tap(event => {
                // avoid interference by "native" dragging of <img> tags
                if (event.target && (event.target as HTMLElement).draggable) {
                    event.preventDefault();
                }
                // avoid triggering other draggable parents
                event.stopPropagation();
                }),
                map(event => this.parseMouseEvent(event)),
            );
            const touchstart$ = fromEvent<TouchEvent>(element, 'touchstart').pipe(
                // delay touch
                switchMap(event => interval(500).pipe(
                    first(),
                    takeUntil(fromEvent(document, 'touchend')),
                    map(() => event),
                )),
                tap(event => {
                    // avoid triggering other draggable parents
                    event.stopPropagation();
                }),
                map(event => this.parseTouchEvent(event)),
            );
            this.elementStartSubscription = merge(mousedown$, touchstart$).subscribe(windowPoint => {
                this.registerMove();
                //this.dragStart({x: windowPoint.x, y: windowPoint.y});
            });
        });
    }

    private unregisterStart() {
        if (this.elementStartSubscription) {
        this.elementStartSubscription.unsubscribe();
        this.elementStartSubscription = undefined;
        }
    }

    private registerMove() {
        this.unregisterMove();
        this.elementMoveSubscription = new Subscription();

        const mousemove$ = fromEvent<MouseEvent>(document, 'mousemove').pipe(
            tap(event => {
                event.preventDefault();
            }),
            map(event => this.parseMouseEvent(event)),
        );
        const touchmove$ = fromEvent<TouchEvent>(document, 'touchmove').pipe(
            tap(event => {
                event.preventDefault();
            }),
            map(event => this.parseTouchEvent(event)),
        );
        this.elementMoveSubscription.add(
        merge(mousemove$, touchmove$).subscribe(windowPoint => {
            this.dragMove(windowPoint);
        }),
        );

        const mouseup$ = fromEvent<void>(document, 'mouseup');
        const touchend$ = fromEvent<void>(document, 'touchend');
        this.elementMoveSubscription.add(
        merge(mouseup$, touchend$).subscribe(() => {
            this.dragStop();
        }),
        );
    }

    private unregisterMove() {
        if (this.elementMoveSubscription) {
            this.elementMoveSubscription.unsubscribe();
            this.elementMoveSubscription = undefined;
        }
    }

    private dragMove(windowPoint: Point) {
        /* Calcolo l'angolo della posizione attuale dell'handle, ESPRESSI IN GRADI! */
        const angle = Number(this.calcAngle(windowPoint).toFixed(1));
        /* Converto lo step dei minuti indicati in GRADI */
        const minStepToAngle = Number((360/(1440/this.step)).toFixed(1))
        /* Se l'angolo rispecchia lo step indicato allora posso procedere ad aggiornare la posizione dell'handle */
        if (angle % minStepToAngle === 0) {
            this.refreshArc.emit(angle)
            this.layerLatestDelta = this.calcHandlePosition(angle);
            this.setTransform(this.layerLatestDelta);
            this.ngZone.run(() => {
                this.angle = angle;
            });            
        }
    }

    private calcAngle(windowPoint: Point) {
        let angle = 0;
        if (this.diameter) {
            const mousePos: Point = {
                x: windowPoint.x,
                y: windowPoint.y,
            };
            const aTan = Math.atan2(mousePos.x - this.circleRadius, mousePos.y - this.circleRadius);
            angle = -aTan / (Math.PI / 180) + 180;
        }
        return angle;
    }

    /**
     * @description Position of the Handle, X and Y
     * @param angle 
     * @returns 
     */
    public calcHandlePosition(angle: number): Point {
        const offset = this.circleRadius - this.handleDiameter / 2;
        const newX = offset * Math.sin(angle * Math.PI / 180) + offset - this.handleDiameter;
        const newY = offset *  -Math.cos(angle * Math.PI / 180) + offset - this.handleDiameter;
        return {
            x: newX,
            y: newY,
        }
    }

    private dragStop() {
        this.unregisterMove();
        if (!this.layerLatestDelta) {
        return;
        }

        this.ngZone.run(() => {
        this.layerLatestDelta = undefined;
        });
    }

    public setTransform(point: Point) {
        this.element.style.transform = `translate(${point.x}px, ${point.y}px)`;
    }

    parseMouseEvent(event: MouseEvent): Point {
        return {
            x: event.pageX - (this.circlePosition?.x || 0),
            y: event.pageY - (this.circlePosition?.y || 0)
        };
    }

    parseTouchEvent(event: TouchEvent): Point {
        const touch = event.touches[0];
        return {
            x: touch.pageX - (this.circlePosition?.x || 0),
            y: touch.pageY - (this.circlePosition?.y || 0)
        };
    }

}