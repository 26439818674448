import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable, catchError, map, take } from "rxjs";
import { RequestsService } from "./utils/requests.service";
import { LoggerService } from "./utils/logger.service";
import { INotificationData } from "../../shared/models/be/notification";

@Injectable({
    providedIn: 'any'
  })
export class AlertsService {
  
    constructor(
      private _http: HttpClient,
      private requestsService: RequestsService,
      private loggerService: LoggerService
    ) {
    }

    public manageAlert$(notificationId: string) {
        return this._http.put<INotificationData[]>(
            `${environment.apiUrl('notifications')}/notifications/${notificationId}/manage`, {}
        ).pipe(
            take(1),
            catchError(err => {
                this.loggerService.error("ERRORE manageAlert");
                throw err;
            })
        );
    }

    public getActiveAlertsList$(): Observable<INotificationData[]> {
        const date = new Date((new Date().getTime()) - 8 * 60 * 60 * 1000).toISOString();
        const buildingId = this.requestsService.getCurrentUser()?.building?.id
        const codeToSeverity = {
            "01": "high",
            "02": "medium",
            "03": "medium",
            "04": "medium",
            "05": "medium",
            "06": "low",
            "07": "low",
            "08": "low"
        };
        return this._http.get<INotificationData[]>(
            `${environment.apiUrl('notifications')}/notifications?status=active&buildingId=${buildingId}&from=${date}`
        ).pipe(
            take(1),
            map((alerts) => {
                alerts.map((alert) => {
                    alert.severity = (codeToSeverity[alert.eventType] as "low" | "medium" | "high")
                    alert.timestamp = new Date(alert.timestamp+"")
                    return alert;
                });
                return alerts;
            }),
            catchError(err => {
                this.loggerService.error("ERRORE getActiveAlertsList");
                throw `error in source. Details:  + ${err}`;
            })
        );
    }

    getAnalyticsAlerts$(filterDate: 'last24h' | 'lastWeek' | 'lastMonth' | 'last6h' = 'last24h'): Observable<INotificationData[]> {
        let startDate: string;
        switch (filterDate) {
            case 'last6h':
                startDate = new Date(new Date().getTime() - (6 * 60 * 60 * 1000)).toISOString();
                break;
            case 'last24h':
                startDate = new Date(new Date().getTime() - (24 * 60 * 60 * 1000)).toISOString();
                break;
            case 'lastWeek':
                startDate = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)).toISOString();
                break;
            case 'lastMonth':
                startDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate()).toISOString();
                break;
        }
        const endDate = new Date(new Date().getTime()).toISOString();
        const buildingId = this.requestsService.getCurrentUser()?.building?.id;
        return this._http.get<INotificationData[]>(
            `${environment.apiUrl('notifications')}/notifications?buildingId=${buildingId}&from=${startDate}&to=${endDate}`
        ).pipe(
            take(1),
            /* map((data) => {
                return data.reduce((acc, curr) => {
                    if (acc.find((room) => room.id === curr.facilities.room.id)) {
                        acc.find((room) => room.id === curr.facilities.room.id)!.alerts.push(curr); 
                    } else {
                        acc.push({...curr.facilities.room, alerts: []})
                    }
                    return acc;
                }, [] as {id: string; name: string; alerts: INotificationData[]}[])
            }), */
            map((data) => {
                return data.sort((a, b) => {
                    const dateA = new Date(a.timestamp);
                    const dateB = new Date(b.timestamp);
                    return dateB.getTime() - dateA.getTime(); // Ordina dal più recente al meno recente
                })
            }),
            catchError(err => {
                this.loggerService.error("ERRORE getActiveAlertsList");
                throw `error in source. Details:  + ${err}`;
            })
        );
    }
}