import {inject} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { RequestsService } from '../services/utils/requests.service';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

export const organizationGuard = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const alertController = inject(AlertController);
  const translateService = inject(TranslateService);
  const requestsService = inject(RequestsService);
  const router = inject(Router);

  /**
   * Funzione che apre la modale che avvisa di settare una Organizzazione per poter procedere con la navigazione alla pagina desiderata
   */
  async function openSetOrgModal() {
    const organizationAlert = await alertController.create({
      mode: 'md',
      header: translateService.instant('GENERAL.MESSAGES.CAUTION'),
      message: translateService.instant('GENERAL.MESSAGES.ORGANIZATION_REQUIRED_MESSAGE'),
      buttons: [
        {
          text: translateService.instant('GENERAL.BUTTONS.CANCEL'),
          handler: () => {
            organizationAlert.dismiss();
          },
        },
        {
          text: translateService.instant('GENERAL.BUTTONS.SETTINGS'),
          handler: () => {
            organizationAlert.dismiss();
            requestsService.changeSelectedItem('settings')
            router.navigateByUrl('wita/settings')
          },
        },
      ],
    });
    await organizationAlert.present();
  };

  // Se è presente una organizzazione associata allora procedo con la navigazione
  if (requestsService.hasLinkedOrg()) {
    requestsService.changeSelectedItem(state.url.split("/wita/")[1]);
    return true;
  };
  // Altrimento apro la modale
  openSetOrgModal();
  // E torno false
  return false;
};


