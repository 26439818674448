import { Injectable } from '@angular/core';
import { Howl } from 'howler';
import { RequestsService } from './requests.service';

@Injectable({
  providedIn: 'root'
})
export class RingtoneService {

  constructor(
    private requestService: RequestsService
  ) { }

  private vibrationInterval?: any;

  public sound = new Howl({
    src: ['assets/sounds/ringtone.mp3'],
    html5: true
  }); 

  playRingtone() {
    if (!this.sound.playing()) {
      this.sound.play();
    }
    /* switch (this.requestService.getCurrentUser()?.notificationPreference) {
      case 'ring':
          this.isSoundPlaying = true;
          this.sound.play();
        break;
      case 'vibrate':
          this.vibrationInterval = setInterval(() => {
            navigator.vibrate(100); // Vibra per 100 millisecondi ogni volta
          }, 100);
        break;
      default:
        break;
    } */
  }

  stopRingtone() {
    if (this.sound.playing()) {
      this.sound.stop();
    }
    /* switch (this.requestService.getCurrentUser()?.notificationPreference) {
      case 'ring':
        this.isSoundPlaying = false;
        this.sound.stop();   
        break;
      case 'vibrate':
        clearInterval(this.vibrationInterval);
        break;
      default:
        break;
    } */
  }
}