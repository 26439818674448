export function getTimeLeft(date: Date | string) {
    const diffInMilliseconds = Math.abs((new Date()).getTime() - (new Date(date)).getTime());
    const diffInMin = Math.floor(diffInMilliseconds / (1000 * 60));
    return diffInMin > 60 ? "60+" : diffInMin
}

export function getTimeDifferenceInMs(date: Date | string) {
    const timestamp1 = (new Date()).getTime();
    const timestamp2 = (new Date(date)).getTime();
    return Math.abs(timestamp1 - timestamp2);
}