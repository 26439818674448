<div style="display: flex; justify-content: space-between; align-items: center; padding: 10px; background-color: #f4f5f8; border: 1px solid #e6e6e6; height: 40px; border-radius: 5px 5px 0 0; border-style: solid solid none solid;">
  <h2 style="margin: 0; font-size: 15px; color: #616161;">{{devices[currentSensorIndex].label}}</h2>
  <mat-icon>{{devices[currentSensorIndex].src ? 'wifi' : 'wifi_off'}}</mat-icon>
</div>
<div style="height: 100%;">
  <div [class.fullScreenContainer]="fullScreenMode" [class.imageContainer]="!fullScreenMode">
      @if (devices[currentSensorIndex].src) {
        <img [src]="devices[currentSensorIndex].src" class="fullscreen-image" [class.image]="!fullScreenMode" style="aspect-ratio: 16/9">
      } @else {
        <div style="background-color: #616161; color: aliceblue; width: 100%; border-radius: 0 0 5px 5px; border: 1px solid #e6e6e6; display: flex; justify-content: center; align-items: center; aspect-ratio: 16/9;">
          STREAMING NON DISPONIBILE
        </div>
      }
      <div class="fullscreen" (click)="fullScreenMode = !fullScreenMode" [ngClass]="fullScreenMode ? 'fullscreen_bigScreen' : 'fullscreen_smallScreen'">
        <mat-icon>fullscreen</mat-icon>
      </div>
      <div class="arrow" [ngClass]="fullScreenMode ? 'arrow_left_bigScreen' : 'arrow_left_smallScreen'" *ngIf="currentSensorIndex" (click)="currentSensorIndex = (currentSensorIndex-1)">
        <mat-icon>chevron_left</mat-icon>
      </div>
      <div class="arrow" [ngClass]="fullScreenMode ? 'arrow_right_bigScreen' : 'arrow_right_smallScreen'" *ngIf="(devices.length-1) !== currentSensorIndex" (click)="currentSensorIndex = (currentSensorIndex+1)">
        <mat-icon>chevron_right</mat-icon>
      </div>
    </div>
</div>
