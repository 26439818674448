import { Component, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './core/services/auth.service';
import { SwUpdate } from '@angular/service-worker';
import { RequestsService } from './core/services/utils/requests.service';
import { NotificationService } from './core/services/notification.service';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from '@angular/platform-browser';
import { webSocket } from 'rxjs/webSocket';
import { environment } from '../environments/environment';
import { SharedService } from './core/services/shared.service';
import { INotificationData } from './shared/models/be/notification';
import { ToastService } from './core/services/utils/toast.service';
import { IonicColors } from './shared/enums/ionicColors.enum';
import { MatDialog } from '@angular/material/dialog';
import { RoomDetailsDialogComponent } from './shared/components/roomDetailsDialog/room-details-dialog.component';
import { AlertsService } from './core/services/alerts.service';
import { SensorService } from './core/services/sensors.service';
import { IPatient } from './shared/models/be/patients';
import { IDevice } from './shared/models/be/device';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HttpClientModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AppComponent),
      multi: true,
    }
  ]
})
export class AppComponent {
  public isPwa: boolean = false;
  public isMobile: boolean = false;

  deviceOffline: boolean

  constructor(
    private translateService: TranslateService,
    private swUpdate: SwUpdate,
    private requestsService: RequestsService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private sharedService: SharedService,
    public dialog: MatDialog,
    private toastService: ToastService,
    private alertService: AlertsService,
    private sensorService: SensorService,
  ) {

    this.deviceOffline = false;
    this.loadCustomMatIcons();
    this.initializeLanguage(navigator.language);
    sharedService.startWss(toastService);
    requestsService.checkNetworkStatus();
     
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
          this.sharedService.startWss(toastService);
      } else if (document.visibilityState === 'hidden') {
          this.sharedService.stopWss();
      }
    });

    navigator.serviceWorker.addEventListener('message', (event) => {
      /* if (event.data && event.data.messageType === 'notification-clicked') {
        let patients: IPatient[] = [];
        let devices: IDevice[] = [];
        this.sharedService.unitsTree.forEach((unit) => {
          (event.data.data?.facilities.unit.id === unit.id) && unit.rooms?.forEach((room) => {
            if ((event.data.data?.facilities.room.id === room.id) && room.devices?.some((device) => device.label === event.data.data?.deviceId)) {
              patients = room.patients || [];
              devices = room.devices?.filter((device) => device.label === event.data.data?.deviceId);
            }
          })
        });
        this.dialog.open(RoomDetailsDialogComponent, {
          data: {
            title: `${event.data.data.facilities.room.name} (${event.data.data.facilities.unit.name})`,
            roomId: event.data.data.facilities.room.id,
            patients,
            devices,
            alert: event.data.data,
            alertService: this.alertService,
            sensorService: this.sensorService
          },
          width: '95vw',
          maxWidth: '95vw'
        });
      }; */
      console.log(event)
    });

    /* Network.addListener('networkStatusChange', status => {
      console.log('Network status changed', status);
      if (status.connected === false) {
        this.deviceOffline = true
      } else {
        this.deviceOffline = false
      }
    }); */
    this.swUpdate.checkForUpdate().then((foundNewVersion) => {
      if (foundNewVersion) {
        confirm(this.translateService.instant('GENERAL.PWA.NEW_VERSION_AVAILABLE')) && window.location.reload();
      }
    });
    // app.js
    navigator.serviceWorker.ready.then(reg => {
      reg.getNotifications().then(notifications => {
        for (let i = 0; i < notifications.length; i += 1) {
          notifications[i].close();
        }
      });
    });
  }

  loadCustomMatIcons() {
    this.matIconRegistry.addSvgIcon(
      `fall`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/files/customSvg/fall.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "room_in",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/files/customSvg/room_in.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "room_out",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/files/customSvg/room_out.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "bed_in",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/files/customSvg/bed_in.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "bed_out",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/files/customSvg/bed_out.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "device_offline",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/files/customSvg/device_offline.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "device_online",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/files/customSvg/device_online.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "mentorage",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/files/customSvg/mentorage.svg")
    );
  }

  /**
   * @description Imposto le traduzioni sulla base della lingua dello user o, se non esistente, del browser, di default metto 'it'
   * @param browserLanguage 
   */
  initializeLanguage(browserLanguage: string) {
    if (['it', 'en', 'fr'].includes(this.requestsService.getCurrentUser()?.language || "")) {
      this.translateService.use(this.requestsService.getCurrentUser()!.language);
    } else {
        if (browserLanguage.includes('en-')) {
          this.translateService.use('en');
        } else if (browserLanguage.includes('fr-')) {
          this.translateService.use('fr');
        } else {
          this.translateService.use('it');
        }
    }
  }

  
  ngOnDestroy(): void {
    this.requestsService.networkStatus$.unsubscribe();
  }
}
