import { Injectable } from "@angular/core";
import { ITableConfigParams, ITableConfigParamsFilter, ITableData, ITableDataData, ITableGeneralConfigParams } from "../table.component";
import { TranslateService } from "@ngx-translate/core";
import { from, mergeMap, of, tap } from "rxjs";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { TableService } from "../table.service";

@Injectable({
    providedIn: 'any'
  })
export class FilterInitializer {
  
    constructor(
        private translateService: TranslateService,
        private tableService: TableService
    ) {}


    /**
     * @description Inizializzo la logica di filtraggio dei dati della tabella
     * @param config Configurazione della tabella
     * @param dataSource "Datasource" della tabella, al fine di filtrarne i valori
     */
    initializeFiltersLoader(config: ITableConfigParams, dataSource: MatTableDataSource<ITableData, MatPaginator>) {
        let tablePreferences: ITableConfigParamsFilter[] = [];
        if (localStorage.getItem('TABLE_PREFERENCES')) {
            tablePreferences = JSON.parse(
                localStorage.getItem('TABLE_PREFERENCES')!
            )[config.id]?.filters || [];
        };
        from(config.filters || []).pipe(
            tap((filter) => {
                const currentFilter = tablePreferences.find((preference) => preference.id === filter.id);
                // ATTENZIONE, Per effetturare modifiche su filter è necessario cambiare ogni singolo parametro che si vuole cambiare e non tutto l'oggetto, NON SI PUO' FARE filter = customFilter, LA MODIFICA NON VERREBBE PRESA
                filter.selectedValue = currentFilter?.selectedValue || filter.selectedValue;
                filter.options = currentFilter?.options || filter.options;
            }),
            mergeMap((filter) => {
                if (!filter.options.length && !!filter.loader) {
                    if (filter.dynamicLoadOn) {
                        const upperLevelSelectedValueId = config.filters?.find((currFilter) => currFilter.id === filter.dynamicLoadOn)?.selectedValue as string || null
                        return upperLevelSelectedValueId ? filter.loader(upperLevelSelectedValueId).pipe(
                            tap((data) => {
                                filter.options = [{id: 'all', name: this.translateService.instant('TABLE.FILTER.LABEL.ALL')}, ...data as {id: string; name: string}[]];
                            })
                        ) : of(true); 
                    } else {
                        return filter.loader().pipe(
                            tap((data) => {
                                filter.options = [{id: 'all', name: this.translateService.instant('TABLE.FILTER.LABEL.ALL')}, ...data as {id: string; name: string}[]];
                            })
                        );                        
                    }
                } else if (filter.type === 'select' && !filter.options.some((opt) => opt.id === 'all')) {
                    // Altrimenti se il tipo di filtro è una select aggiungo un campo 'all' per permettere di "cancellare" il filtro
                    filter.options = [{id: 'all', name: this.translateService.instant('TABLE.FILTER.LABEL.ALL')}, ...filter.options]
                }
                return of(true)
            }),
            tap(() => {
                const savedFilters = config.filters?.filter((filter) => tablePreferences.some((pref) => pref.id === filter.id))
                if (savedFilters) {
                    this.tableService.updateTablePreferences(config.id, 'filters', savedFilters)
                    dataSource.filter = JSON.stringify({ filters: savedFilters || []});                    
                }
            })
        ).subscribe();

    }
    
    /**
     * @description Funzione che sovrascrive la logica del filtro di Material Datatable per far fronte alle mie esigenze
     */
    filterLogic() {
        let filterFunction = function (data: ITableData, filters: string): boolean {
            let generalFilterData: ITableGeneralConfigParams = JSON.parse(filters);
            let nameSearch = () => {
                let found: boolean[] = [];
                (generalFilterData.filters || []).forEach((filter) => {
                    if (filter.type === 'multiSelect') {
                        //Verifico l'esito del filtraggio della multiselect
                        // 1) Filtro le options per selectedValue (es: opt = [{id: 1, name: "uno"}] => selectedValue = [1])
                        // 2) Verifico se tra le options filtrate sono presenti valori il cui NAME(!) corrisponde a quello trovato in tabella
                        const researchOutcome: boolean = 
                            filter.options
                                .filter((opt) => (filter.selectedValue as string[])
                                ?.includes(opt.id))
                                .some((_filter) => _filter.name === (data.data as ITableDataData)[filter.tableParamName] as string);
                        found.push(researchOutcome);
                    } else if (filter.type === 'select') {
                        const selectedOption = filter.options.find((opt) => opt.id === filter.selectedValue);
                        if (selectedOption?.id === 'all') {
                            found.push(true);
                        } else if (filter.checkExistance) {
                            found.push(!!(data.data as ITableDataData)[filter.id] === selectedOption?.value);
                        } else {
                            found.push(((data.data as ITableDataData)[filter.id] as string)?.toLowerCase() === selectedOption?.name?.toLowerCase());
                        }
                    }
                });

                generalFilterData.searchParams && found.push(generalFilterData.searchParams.tableFields.some((fieldId) => ((data.data as ITableDataData)[fieldId] as string)?.toLowerCase()?.includes(generalFilterData.searchParams!.value.toLowerCase())));

                if (!generalFilterData.filters?.length && !generalFilterData.searchParams) {
                    return true
                } else {
                    return !!found.length && found.every((value) => value === true);
                }
            };
            return nameSearch();
        };
        return filterFunction;
    }

}