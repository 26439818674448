<div class="circle" [ngStyle]="{ 'width.px': diameter, 'height.px': diameter, 'border': handleDiameter+'px, solid #999999' }" #circle>
    <canvas [width]="diameter" [height]="diameter" [ngStyle]="{'position': 'absolute', 'top': -handleDiameter+'px', 'left': -handleDiameter+'px'}" #arc></canvas>
    <div style="padding: 30px; position: absolute; display: flex; flex-direction: column; justify-content: space-evenly; width: 100%; height: 100%; align-items: center;">
        <mat-icon style="color: #8F7ACC;">
            bedtime
        </mat-icon>
        <p style="margin: 0; display: flex; align-items: center;">
            {{startingHourMinutes.getHours().toString().padStart(2, '0')}}:{{startingHourMinutes.getMinutes().toString().padStart(2, '0')}}
            <mat-icon>
                arrow_forward
            </mat-icon>
            {{endingHourMinutes.getHours().toString().padStart(2, '0')}}:{{endingHourMinutes.getMinutes().toString().padStart(2, '0')}}
        </p>
        <mat-icon style="color: #FFA800;">
            sunny
        </mat-icon>
    </div>
    <div class="clock">
        <!-- Numeretti -->
        <label *ngFor="let _ of [].constructor(12); index as index" [ngStyle]="{'--i': index, 'color': [0,3,6,9].includes(index) ? 'black' : 'gray'}" style="font-size: 12px;"><span>{{index*2}}</span></label>
        <!-- Linette -->
        <span *ngFor="let _ of [].constructor(24); index as index" [ngStyle]="{'--i': index+1}" style="font-size: xx-small;">|</span>
    </div>
    <div class="circle-handle circle-handle_start"
        circularSliderHandle
        [circlePosition]="circlePosition"
        handleType="start"
        [diameter]="diameter"
        [handleDiameter]="handleDiameter"
        [(angle)]="angle1"
        [step]="30"
        (refreshArc)="refreshArc('start', $event)"
        (touchstart)="onHandleTouch(true)"
        (touchend)="onHandleTouch(false)"
        >
        <mat-icon style="transform: scale(0.9);">
            {{(startingHourMinutes.getHours() >= 18 || startingHourMinutes.getHours() < 6) ? 'bedtime' : 'sunny'}}
        </mat-icon>
    </div>
    <div class="circle-handle circle-handle_end"
        circularSliderHandle
        [circlePosition]="circlePosition"
        handleType="end"
        [diameter]="diameter"
        [handleDiameter]="handleDiameter"
        [(angle)]="angle2"
        [step]="30"
        (refreshArc)="refreshArc('end', $event)"
        (touchstart)="onHandleTouch(true)"
        (touchend)="onHandleTouch(false)"
        >
        <mat-icon style="transform: scale(0.9);">
            {{(endingHourMinutes.getHours() >= 18 || endingHourMinutes.getHours() < 6) ? 'bedtime' : 'sunny'}}
        </mat-icon>
    </div>
</div>