import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { Routes } from '@angular/router';
import { RootRoutes } from './pages/core/root/root.routes';
import { provideState } from '@ngrx/store';
import * as fromUserReducer from './state/reducers/user.reducer';
import { LoginPage } from './pages/auth/login/login.page';
import { antiAuthGuard, authGuard } from './core/guards/auth.guard';
import { RootPage } from './pages/core/root/root.page';
import { authInterceptor } from './shared/interceptors/auth.interceptor';
import { deviceGuard } from './core/guards/device.guard';
import { PwaInstallationPage } from './pages/core/pwaInstallation/pwaInstallation.component';
import { MobileRootRoutes } from './pages/core/mobile/mobileRoot/root.mobile.routes';
import { RootMobilePage } from './pages/core/mobile/mobileRoot/root.mobile.page';

export const routes: Routes = [
    {
      path: 'auth',
      providers: [
        provideHttpClient(withInterceptors([authInterceptor])),
        provideState(fromUserReducer.USER_FEATURE_KEY, fromUserReducer.userReducer),
      ],
      canActivate: [antiAuthGuard, deviceGuard('pwaMobileOrDesktop', '/pwaInstallation')],
      children: [
        {
          path: 'login',
          component: LoginPage
        },
        {
          path: 'reset-password',
          component: LoginPage
        },
        {
          path: '',
          redirectTo: 'login',
          pathMatch: 'full',
        },
      ],
    },
    {
      path: "wita",
      component: RootPage,
      children: RootRoutes,
      canActivate: [authGuard, deviceGuard('desktop', '/witaMobile')],
      providers: [
        provideHttpClient(withInterceptors([authInterceptor])),
        provideState(fromUserReducer.USER_FEATURE_KEY, fromUserReducer.userReducer)
      ]
    },
    {
      path: "witaMobile",
      component: RootMobilePage,
      children: MobileRootRoutes,
      canActivate: [authGuard, deviceGuard('mobilePwa', '/pwaInstallation')],
      providers: [
        provideHttpClient(withInterceptors([authInterceptor])),
      ]
    },
    {
      path: "pwaInstallation",
      component: PwaInstallationPage,
      canActivate: [deviceGuard('mobile', '/')],
      providers: [
        provideHttpClient(withInterceptors([authInterceptor]))
      ]
    },
    {
      path: '',
      redirectTo: 'wita',
      pathMatch: 'full',
    }
];
