import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, viewChild, ViewChild, ViewChildren } from '@angular/core';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { CircularSliderHandleDirective } from './directives/circular-slider-handle.directive';

@Component({
  selector: 'time-range-selector',
  templateUrl: './time-range-selector.component.html',
  styleUrls: ['./time-range-selector.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    CommonModule,
    CircularSliderHandleDirective,
    MatIcon
  ]
})
export class TimeRangeSelector implements OnInit {
  @ViewChild('circle') circle?: ElementRef;
  @ViewChild('arc') arc?: ElementRef;
  @ViewChildren(CircularSliderHandleDirective) directives!: QueryList<CircularSliderHandleDirective>;

  @Input() diameter: number = 300;
  @Output() handleTouched = new EventEmitter(false);

  public startingHourMinutes: Date = new Date();
  public endingHourMinutes: Date = new Date();

  constructor() {
    this.startingHourMinutes.setHours(this.startingHourMinutes.getHours()-2);
    this.endingHourMinutes.setHours(this.endingHourMinutes.getHours()+2);
    this._angle1 = this.startingHourMinutes.getHours()*15;
    this._angle2 = this.endingHourMinutes.getHours()*15;
    this.startHandleRadiant = (this.angle1*(Math.PI/180))-(Math.PI/2);
    this.endingHandleRadiant = (this.angle2*(Math.PI/180))-(Math.PI/2);
  }

  onHandleTouch(isHandleTouched: boolean) {
    this.handleTouched.emit(isHandleTouched)
  }

  private _angle1: number;
  private _angle2: number;
  

  public circlePosition?: {x:number; y:number};
  public handleDiameter: number = (this.diameter*0.21)/2;

  get angle1() {
    return this._angle1;
  }
  set angle1(deg: number) {
    this._angle1 = deg;
  }

  get angle2() {
    return this._angle2;
  }
  set angle2(deg: number) {
    this._angle2 = deg;
  }

  public startHandleRadiant: number;
  public endingHandleRadiant: number;

  ngOnInit() {

  }

  
  changeTimedPriority(value: "always_active" | "timed" | "disabled") {
    switch (value) {
      case "always_active":
        this.refreshArc('start', 1)
        this.directives.first.setTransform(this.directives.first.calcHandlePosition(1));
        this.refreshArc('end', 0);
        this.directives.last.setTransform(this.directives.last.calcHandlePosition(0));
        break;
      case "disabled":
        this.refreshArc('start', 0)
        this.directives.first.setTransform(this.directives.first.calcHandlePosition(0));
        this.refreshArc('end', 0);
        this.directives.last.setTransform(this.directives.last.calcHandlePosition(0));        
        break;
      default:
        this.refreshArc('start', 90);
        this.directives.first.setTransform(this.directives.first.calcHandlePosition(90));
        this.refreshArc('end', 180);
        this.directives.last.setTransform(this.directives.last.calcHandlePosition(180));
        break;
    }
  }

  refreshCirclePosition() {
      const rect = this.circle?.nativeElement.getBoundingClientRect();
      this.circlePosition = {
        x: rect.x,
        y: rect.y
      }
  }
  
  ngAfterViewInit() {
    setTimeout(() => {
      this.refreshCirclePosition()
    }, 500);
    if (this.arc) {
      const ctx = this.arc.nativeElement.getContext("2d");
      const x = this.arc.nativeElement.width/2;
      const y = this.arc.nativeElement.height/2;
      const radius = (this.diameter-this.handleDiameter)/2;
      ctx.beginPath();
      ctx.save();
      ctx.lineWidth = this.handleDiameter*0.3;
      ctx.arc(x, y, radius, 0, 1.5*Math.PI);
      ctx.stroke();
      this.refreshArc('start', this.angle1);
      this.refreshArc('end', this.angle2);
    }
  }

  /**
   * @description Ridisegna l'arco nel canvas
   * @param pointType 'start' o 'end' indica a quale handle stiamo facendo riferimento, se quello di inizio o quello di fine
   * @param degrees Numero di gradi
   */
  refreshArc(pointType: 'start' | 'end', degrees: number) {
    const ctx = this.arc!.nativeElement.getContext("2d");
    const canvWidth = this.arc!.nativeElement.width;
    const canvHeight = this.arc!.nativeElement.height;
    if (pointType === 'start') {
      this.startHandleRadiant = degrees*(Math.PI/180)-(Math.PI/2);
      this.startingHourMinutes.setHours(degrees/15);
      this.startingHourMinutes.setMinutes((degrees % 15) * 4)
    } else {
      this.endingHandleRadiant = degrees*(Math.PI/180)-(Math.PI/2);
      this.endingHourMinutes.setHours(degrees/15);
      this.endingHourMinutes.setMinutes((degrees % 15) * 4)
    }
    ctx.clearRect(0, 0, canvWidth, canvHeight);
    ctx.beginPath();
    ctx.arc(canvWidth/2, canvHeight/2, (this.diameter-this.handleDiameter)/2, this.startHandleRadiant, this.endingHandleRadiant);
    const gradient = ctx.createRadialGradient(110, 90, 30, 100, 100, 70);
    gradient.addColorStop(0, '#F9D905');
    gradient.addColorStop(1, '#F9A605');
    ctx.strokeStyle = gradient;

    ctx.stroke();
  }
}
