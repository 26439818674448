import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { AlertController, IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { Store, select } from '@ngrx/store';
import { switchMap, of, Subject, takeUntil, take, tap, combineLatest, mergeAll, map, mergeMap, toArray, catchError } from 'rxjs';
import { Router } from '@angular/router';
import { ICtaOutput, IOptionOutput, ITableColumns, ITableConfigParams, ITableConfigParamsFilter, ITableData, ITableDataOptions, TableComponent } from '../../../shared/components/table/table.component';
import { IFormChangeEmitter } from '../../../shared/components/form/form.component';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { PrivilegesTableComponent } from './privileges-table/privileges-table.component';
import { UserService } from '../../../core/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { FacilitiesService } from '../../../core/services/facilities.service';
import { CustomerService } from '../../../core/services/customer.service';
import { RoleToTranslation } from '../../../shared/enums/userRole.enum';
import { ToastService } from '../../../core/services/utils/toast.service';
import { IonicColors } from '../../../shared/enums/ionicColors.enum';
import { RequestsService } from '../../../core/services/utils/requests.service';
import { FiltersList } from '../../../shared/components/table/filtersList';


@Component({
  selector: 'app-users',
  templateUrl: 'users.page.html',
  styleUrls: ['users.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TableComponent, MatCardModule],
})
export class UsersPage {
  public tableColumns: ITableColumns[] = [
    /* { label: 'ID', id: 'id', showCol: true }, */
    { label: 'CUSTOMER', id: 'customer', showCol: true },
    { label: 'ORGANIZATION', id: 'organization', showCol: true },
    { label: 'ROLE', id: 'role', showCol: true },
    { label: 'USER', id: 'user', showCol: true, cannotHide: true },
    { label: 'EMAIL', id: 'email', showCol: true },
    { label: 'BIRTHDATE', id: 'birthdate', showCol: false },
    { label: 'BIRTHPLACE', id: 'birthplace', showCol: false },
    { label: 'BIRTHPROVINCE', id: 'birthprovince', showCol: false },
    { label: 'TAX_CODE', id: 'taxCode', showCol: false },
    { label: 'GENDER', id: 'gender', showCol: true },
    { label: 'LANGUAGE', id: 'language', showCol: true },
  ];

  public tableData: ITableData[] = [];

  private translateService = inject(TranslateService);
  public tableConfigParams: ITableConfigParams = {
    id: 'users',
    referenceLabel: 'USERS',
    contactInfo: {
      title: 'user',
      subtitles: [{value: 'creation_date'}],
    },
    filters: [
      this.filtersList.getFilterById('role')
    ].filter((filter) => Boolean(filter)) as ITableConfigParamsFilter[],
    searchBy: ['user']
  };

  //private monitoringService: MonitoringService = inject(MonitoringService)

  private store = inject(Store)
  private router = inject(Router)
  //elements: MonitoringTile[]
  public isDataLoading: boolean = false;
  loadingElements: boolean
  hasError: boolean

  constructor(
    public dialog: MatDialog,
    private userService: UserService,
    private alertController: AlertController,
    private facilitiesService: FacilitiesService,
    private customerService: CustomerService,
    private toastService: ToastService,    
    private requestsService: RequestsService,
    private cdRef:ChangeDetectorRef,
    private filtersList: FiltersList
  ) {
    //this.elements = []
    this.loadingElements = false
    this.hasError = false
  }

  ionViewDidEnter() {
    this.loadElements()
  }

  private destroyed$ = new Subject();

  ngOnInit() {
    if  (!['admin', 'superuser'].includes(this.requestsService.getCurrentUser()?.role || "")) {
      this.tableColumns = this.tableColumns.filter((column) => column.id !== 'id')
    }
  }

  ngAfterViewInit() {
    this.loadUsers();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  loadUsers(showToast: boolean = false) {
    this.isDataLoading = true;
    combineLatest([
      this.userService.loadUsers$(),
      this.customerService.loadCustomers$().pipe(
        take(1),
        mergeAll(),
        mergeMap((customer) => 
          this.facilitiesService.getOrganization$(customer.id+"").pipe(
            map((organizations) => ({
              id: customer.id,
              name: customer.businessName,
              orgs: organizations.map((organization) => ({
                id: organization.id,
                name: organization.name
              }))
            })),
            catchError((err) => {
              return of(null);
            })
          ))
        ,
        toArray()
      )
    ]).pipe(
      take(1),
      tap(([users, customersAndOrgs]) => {
        this.tableData = users.users.map((item) => {
          return {
            data: {
              ...item,
              user: `${item.name} ${item.lastname}`,
              role: this.translateService.instant('PAGES.USERS.ROLES.'+RoleToTranslation[item.role]),
              gender: this.translateService.instant('GENERAL.GENDER.'+item.gender.toUpperCase()),
              language: this.translateService.instant('GENERAL.LANGUAGES.'+item.language.toUpperCase()),
              customer: customersAndOrgs.find((customer) => customer && (customer.id === item.customerId))?.name || "-",
              organization: (customersAndOrgs.find((customer) => customer && (customer.id === item.customerId))?.orgs || []).find((org) => org.id === item.organizationId)?.name || "-",
            },            
            options: [
              {
                action: 'privileges',
                icon: 'note_alt',
                label: 'MODIFY_PRIVILEGES',
              },
              {
                action: 'edit',
                icon: 'edit',
                label: 'EDIT',
              },
              {
                action: 'delete',
                icon: 'delete',
                label: 'DELETE',
              },
            ].filter((x) => x),
          } as ITableData;
        });
        showToast && this.toastService.showToast(this.translateService.instant('GENERAL.SUCCESS.DATA_REFRESHED'), IonicColors.GREEN);
        this.isDataLoading = false;
      }),
      catchError(err => {
        this.isDataLoading = false;
        showToast && this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.LOADING_DATA'), IonicColors.RED);
        throw err;
      })
    ).subscribe();
  }
  
  /**
   * @description Funzione invocata al click di un button (Aggiungi risorsa, refresh dati, ecc...)
   * @param event Viene passato un oggetto contenente il tipo di action invocata
   */
  onCtaAction(event: ICtaOutput) {
    switch (event.action) {
      case 'add':
        this.router.navigate(['/wita/users/new']);
        break;
      case 'refresh':
        this.loadUsers(true);
        break;
      default:
        break;
    }
  }
  
  /**
   * @description Funzione invocata al click di una opzione della table
   * @param event Viene passato un oggetto contenente l'id delle risorsa in oggetto e l'action invocata
   */
  async onOptionAction(event: IOptionOutput) {
    switch (event.action) {
      case 'privileges':
        this.dialog.open(PrivilegesTableComponent, {
          width: '1000px',
          data: {
            tableData: [{
              id: 'anagrafica',
              functionality: 'Anagrafica',
              canView: true,
              canEdit: false,
              disabled: false
            },{
              id: 'ammissione',
              functionality: 'Ammissione',
              canView: false,
              canEdit: true,
              disabled: true
            },{
              id: 'anamnesi',
              functionality: 'Anamnesi',
              canView: true,
              canEdit: true,
              disabled: true
            }]
          }
        });
        break;
      case 'edit':
        this.router.navigate([`/wita/users/edit/${event.resource.id}`]);
        break;
      case 'delete':
        const customerAlert = await this.alertController.create({
          mode: 'md',
          header: this.translateService.instant('GENERAL.MESSAGES.CAUTION'),
          message: 'Se veramente sicuro?',//this.translateService.instant('GENERAL.MESSAGES.DELETE_CUSTOMER_MESSAGE'),
          buttons: [
            {
              text: this.translateService.instant('GENERAL.BUTTONS.OK'),
              handler: () => {
                customerAlert.dismiss();
                this.userService.deleteUser$(event.resource.id).subscribe(() => {
                  this.loadUsers();
                });
              },
            },
            {
              text: this.translateService.instant('GENERAL.BUTTONS.CANCEL'),
              handler: () => {
                customerAlert.dismiss();
              },
            },
          ],
        });
        await customerAlert.present();
        break;
      default:
        this.router.navigate(['/wita/users']);
        break;
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(1)
    this.destroyed$.complete()
  }

  loadElements() {
    //this.monitoringService.loadMonitoringElements()
  }

  handlePressElement(event: any) {
    //let element = this.elements.find(element => element.id === event);
    //this.router.navigate(['/wita/appMonitoring/monitoringRoot/sensors/bed', { id: element?.id }])
  }

  handleSelectElement(event: any) {
    //let element = this.elements.find(element => element.id === event);
    //console.log(element)
    console.log("Element selected")
  }

}