<ion-content [fullscreen]="true" class="page-content">
  <div class="container">
    <mat-grid-list cols="9" rowHeight="36" gutterSize="10" class="container_modal">
      <mat-grid-tile colspan="9" rowspan="1"></mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="4"></mat-grid-tile>
      <mat-grid-tile colspan="7" rowspan="1">
        <p class="container_modal_title">Attenzione!</p>
      </mat-grid-tile>
      <mat-grid-tile colspan="7" rowspan="1">
        <p class="container_modal_subtitle">Installare l'applicazione per procedere con l'utilizzo di Wita</p>
      </mat-grid-tile>
      <mat-grid-tile colspan="7" rowspan="1">
        <ion-button expand="block" class="container_modal_button" (click)="showInstallPwaPrompt()">Installa</ion-button>
      </mat-grid-tile>
      <mat-grid-tile colspan="7" rowspan="1">
        <a class="container_modal_link">Problemi nell'installazione?</a>
      </mat-grid-tile>
      <mat-grid-tile colspan="7" rowspan="1"></mat-grid-tile>
    </mat-grid-list>
  </div>
</ion-content>