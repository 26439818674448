import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { ModalContainerComponent } from '../../../shared/components/modal-container/modal-container.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IBreadcrumbs } from '../../../shared/components/breadcrumbs/breadcrumbs.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerService } from '../../../core/services/customer.service';
import { FacilitiesService } from '../../../core/services/facilities.service';
import { catchError, combineLatest, concatMap, filter, finalize, from, map, of, Subject, tap } from 'rxjs';
import { IFormChangeEmitter } from '../../../shared/components/form/form.component';
import { RequestsService } from '../../../core/services/utils/requests.service';
import { UserService } from '../../../core/services/user.service';
import { SensorService } from '../../../core/services/sensors.service';
import { PatientService } from '../../../core/services/patient.service';
import { AuthService } from '../../../core/services/auth.service';
import { ToastService } from '../../../core/services/utils/toast.service';
import { IonicColors } from '../../../shared/enums/ionicColors.enum';
import { NotificationService } from '../../../core/services/notification.service';
import { IBuilding } from '../../../shared/models/be/building';
import { IonButton, IonContent, IonToolbar, IonSegment, IonLabel, IonSegmentButton, IonSegmentContent, IonSegmentView, IonSpinner } from "@ionic/angular/standalone";
import { eventTypeToData, INotificationData } from '../../../shared/models/be/notification';
import { IUser } from '../../../shared/models/be/user';
import { MatBadgeModule } from '@angular/material/badge';
import { SharedService } from '../../../core/services/shared.service';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { RoomDetailsDialogComponent } from '../../../shared/components/roomDetailsDialog/room-details-dialog.component';
import { IDevice } from '../../../shared/models/be/device';
import { IPatient } from '../../../shared/models/be/patients';
import { IRoom } from '../../../shared/models/be/room';
import { IUnit } from '../../../shared/models/be/unit';
import { getTimeLeft } from '../../../shared/utils/transformation.util';
import { AlertsService } from '../../../core/services/alerts.service';
import { RingtoneService } from '../../../core/services/utils/ringtone.service';
import { MatDialog } from '@angular/material/dialog';
import { isTimeWithinRange } from '../../../shared/utils/check.utils';

@Component({
  selector: 'app-settings',
  templateUrl: './mentorage.page.html',
  styleUrls: ['./mentorage.page.scss'],
  standalone: true,
  imports: [
    IonSpinner,
    IonSegment,
    IonToolbar,
    IonContent, 
    CommonModule,
    ModalContainerComponent,
    MatGridListModule,
    MatFormFieldModule,
    TranslateModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    IonButton,
    MatIconModule,
    RouterModule,
    MatToolbarModule,
    MatTabsModule,
    MatExpansionModule,
    MatBadgeModule,
    IonLabel,
    IonSegmentButton,
    IonSegmentView,
    IonSegmentContent
  ]
})
export class MentoragePage implements OnInit {

  public breadcrumbsList: IBreadcrumbs[] = [{
    icon: 'mentorage',
    routerLink: '',
    isSvgIcon: true,
    label: 'MENTORAGE'
  }]

  public tableRowSpan: number = 14
  public currentSegment: 'structure' | 'allerts' = 'structure';
  public eventTypeToData = eventTypeToData;

  constructor (
    private facilitiesService: FacilitiesService,
    private requestsService: RequestsService,
    private sensorService: SensorService,
    private alertController: AlertController,
    private alertService: AlertsService,
    public sharedSrv: SharedService,
    public dialog: MatDialog,
    private ringtoneService: RingtoneService
  ) { }

  private destroyed$ = new Subject();
  public installPrompt?: any;
  public devicesList: IDevice[] = [];
  public manageAllRunning: boolean = false;
  getTimeLeft(data: Date | string) {
    return getTimeLeft(data)
  }

  ngOnInit() {
    combineLatest([
      this.requestsService.currentUser$,
      this.sensorService.getDevices$(),
      this.alertService.getActiveAlertsList$()
    ]).pipe(
      tap(([user, devices, notificationsList]) => {
        this.sharedSrv.alertsList = this.sharedSrv.sortBySeverity(notificationsList);
        this.devicesList = devices;
        user?.building?.id && this.facilitiesService.getBuilding$(user?.building?.id, true).pipe(
          tap((building) => {
            this.sharedSrv.unitsTree = (building.units || []).reduce((acc, curr) => {
              let currentUnitDevices = devices.filter((device) => device.facilities?.unit?.id === curr.id);
              currentUnitDevices.length && acc.push({
                ...curr,
                isOpen: false,
                rooms: curr.rooms?.filter((room) => currentUnitDevices.map((device) => device.facilities?.room?.id).includes(room.id)).map((room) => {
                  let currRoomDevices = devices.filter((device) => device.facilities?.room?.id === room.id);
                  const currAllerts = this.sharedSrv.sortBySeverity(this.sharedSrv.alertsList.filter((alert) => currRoomDevices.some((roomDevice) => roomDevice.label === alert.deviceId)));
                  return {
                    ...room,
                    devices: currRoomDevices,
                    offlineMode: currRoomDevices.some((device) => device.status === 'offline') ? (currRoomDevices.every((device) => device.status === 'offline') ? 'full' : 'partial') : undefined,
                    alert: currAllerts[0] || undefined
                  }
                })
              })
              return acc;
            }, [] as (IUnit & {isOpen: boolean})[]);
            this.sharedSrv.unitsTree.length === 1 && (this.sharedSrv.unitsTree[0].isOpen = true)
          }),
          map((building) => {
            this.sharedSrv.unitsTree.forEach((unit) => {
              unit.rooms?.forEach((room) => {                
                this.sensorService.getDevicesSettings$('roomId', room.id).pipe(
                  tap((data) => {
                    room.isNotificationSilenced = data.notificationMode === 'disabled' || (data.notificationMode === 'scheduled' && !isTimeWithinRange(data.scheduleStartTime!, data.scheduleEndTime!))
                  })
                ).subscribe();
              })
            })
          })
        ).subscribe();
      })
    ).subscribe();

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.alertService.getActiveAlertsList$().pipe(
          tap((alerts) => {
            this.sharedSrv.alertsList = this.sharedSrv.sortBySeverity(alerts);
            this.sharedSrv.unitsTree.forEach((unit) => {
              unit.rooms?.forEach((room) => {
                const currRoomAlerts = this.sharedSrv.alertsList.filter((alert) => room.devices?.some((device) => device.label === alert.deviceId))
                currRoomAlerts.length && (room.alert = this.sharedSrv.sortBySeverity(currRoomAlerts)[0]);
              })
            })
          })
        ).subscribe();
      }
    });
  }

  getUnitAlerts(unit: IUnit) {
    return unit.rooms?.reduce((acc, curr) => {
      if (curr.alert && !acc.some((item) => item.severity === curr.alert?.severity)) {
        curr.alert.severity !== 'low' && acc.push({
          icon: eventTypeToData[curr.alert.eventType].statusIcon,
          color: eventTypeToData[curr.alert.eventType].exaColor,
          severity: curr.alert.severity
        })
      }
      return acc;
    }, [] as {icon: string; color: string; severity: 'medium' | 'high'}[])
  }

  openModal(room : IRoom, unit: IUnit) {
    this.reorderDevicesOnModal(room.devices || [], room.alert);
    this.dialog.open(RoomDetailsDialogComponent, {
      data: {
        title: `${room.name}`,
        roomId: room.id,
        patients: room.patients,
        devices: room.devices,
        alertService: this.alertService,
        sensorService: this.sensorService
      },
      width: '65vw'
    });
    //this.onModalClosed(dialogRef);
  }

  openModalFromAlert(alert: INotificationData) {
    let patients: IPatient[] = [];
    let devices: IDevice[] = [];
    this.sharedSrv.unitsTree.forEach((unit) => {
      (alert?.facilities.unit.id === unit.id) && unit.rooms?.forEach((room) => {
        if ((alert?.facilities.room.id === room.id) && room.devices?.some((device) => device.label === alert?.deviceId)) {
          patients = room.patients || [];
          devices = room.devices?.filter((device) => device.label === alert?.deviceId);
        }
      })
    });
    this.reorderDevicesOnModal(devices, alert);
    this.dialog.open(RoomDetailsDialogComponent, {
      data: {
        title: `${alert.facilities.room.name}`,
        roomId: alert.facilities.room.id,
        patients,
        devices,
        alert,
        alertService: this.alertService,
        sensorService: this.sensorService
      },
      width: '65vw'
    });
    //this.onModalClosed(dialogRef);
  }

  reorderDevicesOnModal(devices: IDevice[], alert?: INotificationData) {
    if (alert) {
      devices = devices.sort((a, b) => {
        if (a.label === alert.deviceId) {
          return -1; // a viene prima
        } else if (b.label === alert.deviceId) {
          return 1; // b viene prima
        } else {
          return 0; // Nessun cambiamento di ordine
        }
      });
    } else if (devices.some((device) => device.status === "offline") && devices.some((device) => device.status === "online")) {
      devices = devices.sort((a, b) => {
        if (a.status === 'online') {
          return -1; // a viene prima
        } else if (b.status === 'online') {
          return 1; // b viene prima
        } else {
          return 0; // Nessun cambiamento di ordine
        }
      });
    }
  }

  async manageAll() {
    const alert = await this.alertController.create({
      header: 'ATTENZIONE',
      message: 'Gestire tutte le allerte?',
      mode: 'ios',
      buttons: [{
        text: 'Chiudi',
        role: 'close',
        handler: () => {},
      },
      {
        text: 'Gestisci tutte',
        role: 'manage',
        handler: () => {
          this.manageAllRunning = true;
          this.ringtoneService.stopRingtone();
          from(this.sharedSrv.alertsList).pipe(
            concatMap((alertEl) =>
              this.alertService.manageAlert$(alertEl.id).pipe(
                tap(() => {
                  this.sharedSrv.removeAlert(alertEl);
                }),
                catchError((err) => {
                  (err.error.error === "15") && this.sharedSrv.removeAlert(alertEl);
                  return of(null);
                })
              )
            ),
            finalize(() => {
              this.manageAllRunning = false;
            })
          ).subscribe();
        },
      }],
    });
    await alert.present();
  }

  ngOnDestroy() {
    this.destroyed$.next(1);
    this.destroyed$.complete();
  }

}
