<ion-content [fullscreen]="true" class="page-content" color="light">
  <modal-container
      [modalHeightInRows]="tableRowSpan"
      [breadcrumbsList]="breadcrumbsList"
      [formStatus]="formStatus"
      (onSubmit)="submitData()"
    >
    <mat-grid-list cols="8" rowHeight="36px" gutterSize="20">
      <ng-container *ngFor="let section of settingsData">
        <!-- Titolo della macrosezione -->
        <mat-grid-tile colspan="8" rowspan="1">
          <b>{{'FORM.LABELS.'+section.label | translate}}</b>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1" [rowspan]="1"></mat-grid-tile>
        <mat-grid-tile *ngFor="let input of section.inputs" [colspan]="input.type === 'status' ? 7 : 2" [rowspan]="input.type === 'status' ? 6 : 1" style="overflow: visible;">
          <ng-container [ngSwitch]="input.type">
            <!-- Status -->
            <div *ngSwitchCase="'status'" style="width: 100%; display: flex; flex-direction: column;">
              <div *ngFor="let item of input.values" style="width: 100%; display: flex;">
                <p style="margin-right: auto;">{{'PAGES.SETTINGS.SERVICES.'+item.label | translate}}</p>
                <p>{{item.status ? '&#128994;' : '&#128308;'}}</p>
              </div>
            </div>
            <!-- Button -->
            <ion-button *ngSwitchCase="'button'" style="width: 100%; height: 100%;" mode="md" expand="block" (click)="input?.onSelect('')">{{input.label}}</ion-button>
            <!-- Select -->
            <mat-form-field appearance="outline" style="width: 100%; height: 100%;" *ngSwitchDefault>
              <mat-label>{{'FORM.LABELS.'+input.label | translate}}</mat-label>
              <!-- Forzato tipo any a malincuore a causa di errori di compilazione per via del fatto che values è presente solo in ISelectInput  -->
              <mat-select [(ngModel)]="input.selectedValue" (selectionChange)="input.onSelect && input.onSelect($event.value)" [disabled]="!$any(input).values.length">
                @for (item of $any(input).values; track item) {
                  <mat-option [value]="item.id">{{input.disableTranslation ? item.label : ('FORM.LABELS.'+item.label | translate)}}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </ng-container>
        </mat-grid-tile>
      </ng-container>
    </mat-grid-list>
  </modal-container>
</ion-content>