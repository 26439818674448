import { Validators } from "@angular/forms";
import { CustomRegExp } from "../../enums/regex.enum";
import { IFormData, IFormStructure } from "./form.component";
import { Injectable, inject } from "@angular/core";
import { CustomerService } from "../../../core/services/customer.service";
import { ICustomer } from "../../models/be/customers";
import { PatientService } from "../../../core/services/patient.service";
import { IPatient } from "../../models/be/patients";
import { FacilitiesService } from "../../../core/services/facilities.service";
import { Observable, map, of, tap } from "rxjs";
import { IUnit } from "../../models/be/unit";
import { IBuilding } from "../../models/be/building";
import { IRoom } from "../../models/be/room";
import { IBed } from "../../models/be/bed";
import { AdmissionService } from "../../../core/services/admission.service";
import { IAdmission } from "../../models/be/encounter";
import { EvaluationService } from "../../../core/services/evaluation.service";
import { IEvaluation } from "../../models/be/evaluation";
import { IOrganization } from "../../models/be/organization";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: 'root',
})
export class ResourcesStructure {
    private custumerService = inject(CustomerService);
    private patientService = inject(PatientService);
    private facilitiesService = inject(FacilitiesService);
    private admissionService = inject(AdmissionService);
    private evaluationService = inject(EvaluationService);
    private customerService = inject(CustomerService);
    private httpClient = inject(HttpClient);
    constructor() {

    }

    public formResourcesStructure: IFormResourceStructure = {
        customer: {
            formStructure: {
                formType: 'simple',
                structure: [{
                    id: 'legal_entity',
                    title: 'LEGAL_ENTITY',
                    children: [
                    {
                        id: 'tax_data',
                        title: 'TAX_DATA',
                        requiredToSaveDraft: 'businessName',
                        inputs: [
                        {
                            id: 'businessName',
                            type: 'input' as 'input',
                            length: 6,
                            label: 'BUSINESS_NAME',
                            validators: [
                            Validators.required,
                            Validators.pattern(CustomRegExp.BUSINESS_NAME),
                            ],
                            placeholder: 'Azienda S.R.L.',
                        },
                        {
                            id: 'vatNumber',
                            type: 'input' as 'input',
                            length: 3,
                            label: 'VAT',
                            validators: [
                            Validators.required,
                            Validators.pattern(CustomRegExp.VAT),
                            ],
                            placeholder: '02539330221',
                            inputRule: 'number'
                        },
                        {
                            id: 'taxCode',
                            type: 'input' as 'input',
                            length: 3,
                            label: 'TAX_CODE',
                            validators: [Validators.pattern(CustomRegExp.VAT)],
                            placeholder: '02539330221',
                            inputRule: 'number'
                        },
                        {
                            id: 'pec',
                            type: 'input' as 'input',
                            length: 3,
                            label: 'PEC',
                            validators: [
                            Validators.required,
                            Validators.pattern(CustomRegExp.PEC),
                            ],
                            placeholder: 'azienda@pec.it',
                        },
                        {
                            id: 'recipientCode',
                            type: 'input' as 'input',
                            length: 3,
                            label: 'RECIPIENT_CODE',
                            validators: [
                            Validators.required,
                            Validators.pattern(CustomRegExp.RECIPIENT_CODE),
                            ],
                            placeholder: 'A1B2C3D',
                            inputRule: 'upperCase'
                        },
                        ],
                    },
                    {
                        title: 'REGISTERED_OFFICE',
                        id: 'registered_office',
                        inputs: [
                        {
                            id: 'street',
                            type: 'input' as 'input',
                            length: 4,
                            label: 'STREET',
                            validators: [Validators.required],
                            placeholder: 'Via dei Mille',
                        },
                        {
                            id: 'civic',
                            type: 'input' as 'input',
                            length: 2,
                            label: 'CIVIC',
                            validators: [
                            Validators.required,
                            Validators.pattern(CustomRegExp.CIVIC),
                            ],
                            placeholder: '',
                        },
                        {
                            id: 'city',
                            type: 'input' as 'input',
                            length: 2,
                            label: 'MUNICIPALITY',
                            validators: [
                            Validators.required,
                            ],
                            placeholder: '',
                            inputRule: 'capitalize'
                        },
                        {
                            id: 'postalCode',
                            type: 'input' as 'input',
                            length: 2,
                            label: 'POSTAL_CODE',
                            validators: [
                            Validators.required,
                            Validators.pattern(CustomRegExp.CAP),
                            ],
                            placeholder: '',
                            inputRule: 'number'
                        },
                        {
                            id: 'province',
                            type: 'input' as 'input',
                            length: 2,
                            label: 'PROVINCE',
                            validators: [
                            Validators.required,
                            Validators.pattern(CustomRegExp.PROVINCE),
                            ],
                            placeholder: '',
                            inputRule: 'capitalize'
                        },
                        {
                            id: 'country',
                            type: 'input' as 'input',
                            length: 2,
                            label: 'COUNTRY',
                            validators: [
                            Validators.required,
                            Validators.pattern(CustomRegExp.COUNTRY),
                            ],
                            placeholder: '',
                            inputRule: 'capitalize'
                        },
                        ],
                    },
                    {
                        title: 'CONTACTS',
                        id: 'contacts',
                        inputs: [
                        {
                            id: 'representative',
                            type: 'input' as 'input',
                            length: 6,
                            label: 'REFERENT',
                            validators: [],
                            placeholder: 'Mario Rossi',
                            inputRule: 'capitalize'
                        },
                        {
                            id: 'email',
                            type: 'input' as 'input',
                            length: 2,
                            label: 'EMAIL',
                            validators: [Validators.pattern(CustomRegExp.EMAIL)],
                            placeholder: 'email@mail.com',
                            inputRule: 'noSpaces'
                        },
                        {
                            id: 'phoneNumber',
                            type: 'input' as 'input',
                            length: 2,
                            label: 'MOBILE_NUMBER',
                            validators: [Validators.pattern(CustomRegExp.PHONE_NUMBER)],
                            placeholder: '',
                            inputRule: 'number'
                        },
                        {
                            id: 'telephone',
                            type: 'input' as 'input',
                            length: 2,
                            label: 'LANDLINE_PHONE',
                            validators: [Validators.pattern(CustomRegExp.PHONE_NUMBER)],
                            placeholder: '',
                            inputRule: 'number'
                        },
                        ]
                    },
                    ]  
                }],
            }
        },
        organization: {
            formStructure: {
                formType: 'simple',
                structure: [{
                    id: 'organization',
                    title: 'ORGANIZATION',
                    children: [
                        {
                        id: 'organization_name',
                        title: 'ORGANIZATION_NAME',
                        requiredToSaveDraft: 'business_name',
                        inputs: [
                            {
                            id: 'name',
                            type: 'input',
                            length: 6,
                            label: 'ORGANIZATION_NAME_ALIAS',
                            validators: [
                                Validators.required,
                                Validators.pattern(CustomRegExp.BUSINESS_NAME),
                            ],
                            placeholder: 'Nome organizzazione',
                            },
                        ],
                        },
                        {
                        id: 'modules_enabled',
                        title: 'MODULES_ENABLED',
                        inputs: [
                            {
                            id: 'mentorage',
                            type: 'checkbox',
                            label: 'MENTORAGE',
                            length: 3,
                            },
                            {
                            id: 'clinical_evaluation',
                            type: 'checkbox',
                            label: 'CLINICAL_EVALUATION',
                            length: 3,
                            }
                        ],
                        },
                        {
                        id: 'organization_address',
                        title: 'ORGANIZATION_ADDRESS',
                        inputs: [
                            {
                            id: 'org_to_business_same_address',
                            type: 'checkbox',
                            label: 'ORG_TO_BUSINESS_SAME_ADDRESS',
                            completeSection: true,
                            length: 6,
                            },
                            {
                            id: 'street',
                            type: 'input',
                            length: 4,
                            label: 'STREET',
                            validators: [Validators.required],
                            placeholder: 'Via dei Mille',
                            },
                            {
                            id: 'civic',
                            type: 'input',
                            length: 2,
                            label: 'CIVIC',
                            validators: [
                                Validators.required,
                                Validators.pattern(CustomRegExp.CIVIC),
                            ],
                            placeholder: '',
                            },
                            {
                            id: 'city',
                            type: 'input',
                            length: 2,
                            label: 'MUNICIPALITY',
                            validators: [
                                Validators.required,
                            ],
                            placeholder: '',
                            },
                            {
                            id: 'postalCode',
                            type: 'input',
                            length: 2,
                            label: 'POSTAL_CODE',
                            validators: [
                                Validators.required,
                                Validators.pattern(CustomRegExp.CAP),
                            ],
                            placeholder: '',
                            },
                            {
                            id: 'province',
                            type: 'input',
                            length: 2,
                            label: 'PROVINCE',
                            validators: [
                                Validators.required,
                                Validators.pattern(CustomRegExp.PROVINCE),
                            ],
                            placeholder: '',
                            },
                            {
                            id: 'country',
                            type: 'input',
                            length: 2,
                            label: 'COUNTRY',
                            validators: [
                                Validators.required,
                                Validators.pattern(CustomRegExp.COUNTRY),
                            ],
                            placeholder: '',
                            },
                        ],
                        },{
                        title: 'CONTACTS',
                        id: 'contacts',
                        inputs: [
                            {
                            id: 'contactToBusinessContact',
                            type: 'checkbox',
                            label: 'CONTACT_TO_BUSINESS_CONTACT',
                            completeSection: true,
                            length: 6,
                            },
                            {
                            id: 'representative',
                            type: 'input',
                            length: 3,
                            label: 'REFERENT',
                            validators: [],
                            placeholder: 'Mario Rossi',
                            },
                            {
                            id: 'email',
                            type: 'input',
                            length: 3,
                            label: 'EMAIL',
                            validators: [Validators.pattern(CustomRegExp.EMAIL)],
                            placeholder: 'email@mail.com',
                            inputRule: 'noSpaces'
                            },
                            {
                            id: 'phoneNumber',
                            type: 'input',
                            length: 3,
                            label: 'MOBILE_NUMBER',
                            validators: [Validators.pattern(CustomRegExp.PHONE_NUMBER)],
                            placeholder: '',
                            },
                            {
                            id: 'telephone',
                            type: 'input',
                            length: 3,
                            label: 'LANDLINE_PHONE',
                            validators: [Validators.pattern(CustomRegExp.PHONE_NUMBER)],
                            placeholder: '',
                            },
                        ],
                        }
                    ],
                }]
            }
        },
        structure: {
            formStructure: {
                formType: 'simple',
                structure: [{
                    id: 'ward',
                    title: 'WARD',
                    children: [
                        {
                            id: 'type',
                            title: 'TYPE',
                            inputs: [
                                {
                                    id: 'unitType',
                                    type: 'select',
                                    length: 6,
                                    label: 'TYPE',
                                    validators: [
                                    Validators.required,
                                    ],
                                    values: [{
                                    id: 'wa',
                                    label: 'WARD'
                                    },{
                                    id: 'ho',
                                    label: 'APARTMENT'
                                    }]
                                }
                            ],
                        },{
                            id: 'wardName',
                            title: 'WARD_NAME',
                            requiredToSaveDraft: 'wardName',
                            inputs: [
                                {
                                    id: 'name',
                                    type: 'input',
                                    length: 6,
                                    label: 'WARD_NAME',
                                    validators: [
                                        Validators.required
                                    ],
                                    placeholder: 'Nome Unità',
                                },
                            ],
                        }
                    ],
                },{
                    id: 'rooms',
                    title: 'ROOMS',
                    categoryLabel: "ROOM",
                    dynamicChildren: true,
                    children: [
                        {
                            id: 'room',
                            title: 'ROOM',
                            childrenType: 'accordion',
                            rowSpan: 1,
                            isAccordionOpen: true,
                            inputs: [
                                {
                                    id: 'name',
                                    type: 'input',
                                    length: 6,
                                    label: 'ROOM_NAME',
                                    validators: [
                                        Validators.required,
                                    ],
                                    placeholder: 'Nome/numero stanza',
                                },{
                                    id: 'beds',
                                    type: 'inputArray',
                                    buttonLabel: "Aggiungi Letto",
                                    inputs: [{
                                        id: 'name',
                                        type: 'input',
                                        length: 2,
                                        label: 'BED_NUMBER',
                                        validators: [
                                            Validators.required,
                                        ],
                                        placeholder: 'Numero letto',
                                    }]
                                }
                            ],
                        }
                    ]
                }]
            }
        },
        building: {
            formStructure: {
                formType: 'simple',
                structure: [{
                    id: 'building',
                    title: 'BUILDING',
                    categoryLabel: "BUILDING",
                    children: [
                    {
                        id: 'buildingName',
                        title: 'GENERIC_NAME',
                        inputs: [
                        {
                            id: 'name',
                            type: 'input',
                            length: 6,
                            label: 'BUILDING_NAME',
                            validators: [
                            Validators.required,
                            Validators.pattern(CustomRegExp.BUSINESS_NAME),
                            ],
                            placeholder: 'Nome organizzazione',
                        },
                        ],
                    },
                    {
                        id: 'building_address',
                        title: 'ADDRESS',
                        inputs: [
                        {
                            id: 'building_to_org_same_address',
                            type: 'checkbox',
                            label: 'BUILDING_TO_ORG_SAME_ADDRESS',
                            completeSection: true,
                            length: 6,
                        },
                        {
                            id: 'street',
                            type: 'input',
                            length: 4,
                            label: 'STREET',
                            validators: [Validators.required],
                            placeholder: 'Via dei Mille',
                        },
                        {
                            id: 'civic',
                            type: 'input',
                            length: 2,
                            label: 'CIVIC',
                            validators: [
                            Validators.required,
                            Validators.pattern(CustomRegExp.CIVIC),
                            ],
                            placeholder: '',
                        },
                        {
                            id: 'city',
                            type: 'input',
                            length: 2,
                            label: 'MUNICIPALITY',
                            validators: [
                            Validators.required,
                            ],
                            placeholder: '',
                        },
                        {
                            id: 'postalCode',
                            type: 'input',
                            length: 2,
                            label: 'POSTAL_CODE',
                            validators: [
                            Validators.required,
                            Validators.pattern(CustomRegExp.CAP),
                            ],
                            placeholder: '',
                        },
                        {
                            id: 'province',
                            type: 'input',
                            length: 2,
                            label: 'PROVINCE',
                            validators: [
                            Validators.required,
                            Validators.pattern(CustomRegExp.PROVINCE),
                            ],
                            placeholder: '',
                        },
                        {
                            id: 'country',
                            type: 'input',
                            length: 2,
                            label: 'COUNTRY',
                            validators: [
                            Validators.required,
                            Validators.pattern(CustomRegExp.COUNTRY),
                            ],
                            placeholder: '',
                        },
                        ],
                    },{
                        title: 'CONTACTS',
                        id: 'contacts',
                        inputs: [
                        {
                            id: 'representative',
                            type: 'input',
                            length: 3,
                            label: 'REFERENT',
                            validators: [],
                            placeholder: 'Mario Rossi',
                        },
                        {
                            id: 'email',
                            type: 'input',
                            length: 3,
                            label: 'EMAIL',
                            validators: [Validators.pattern(CustomRegExp.EMAIL)],
                            placeholder: 'email@mail.com',
                            inputRule: 'noSpaces'
                        },
                        {
                            id: 'phoneNumber',
                            type: 'input',
                            length: 3,
                            label: 'MOBILE_NUMBER',
                            validators: [Validators.pattern(CustomRegExp.PHONE_NUMBER)],
                            placeholder: '',
                        },
                        {
                            id: 'telephone',
                            type: 'input',
                            length: 3,
                            label: 'LANDLINE_PHONE',
                            validators: [Validators.pattern(CustomRegExp.PHONE_NUMBER)],
                            placeholder: '',
                        },
                        ]
                    }
                    ]
                }]
            }
        },
        patient: {
            formStructure: {
                formType: 'simple',
                structure: [{
                    id: 'patient',
                    title: 'PATIENT',
                    children: [
                    {
                        id: 'personal_data',
                        title: 'INPUT_GROUP_TITLE.PERSONAL_DATA',
                        requiredToSaveDraft: 'name',
                        inputs: [
                        {
                            id: 'name',
                            type: 'input',
                            length: 2,
                            label: 'NAME',
                            validators: [
                                Validators.required,
                            ],
                            inputRule: 'capitalize',
                            placeholder: 'Mario',
                        },{
                            id: 'surname',
                            type: 'input',
                            length: 2,
                            label: 'SURNAME',
                            validators: [
                                Validators.required,
                            ],
                            inputRule: 'capitalize',
                            placeholder: 'Rossi',
                        },{
                            id: 'gender',
                            type: 'select',
                            length: 2,
                            label: 'SEX',
                            validators: [
                            Validators.required,
                            ],
                            values: [{
                            id: 'male',
                            label: 'MALE'
                            },{
                            id: 'female',
                            label: 'FEMALE'
                            }]
                        },{
                            id: 'birthdate',
                            type: 'date',
                            length: 2,
                            label: 'BIRTHDATE',
                            validators: [
                            Validators.required,
                            ]
                        },{
                            id: 'birthplace',
                            type: 'input',
                            length: 2,
                            label: 'BIRTH_PLACE',
                            inputRule: 'capitalize',
                            generatedData: ['Pordenone', 'Roma', 'Milano', 'Napoli', 'Torino', 'Palermo', 'Genova', 'Messina', 'Perugia'],
                            validators: [
                            Validators.required,
                            ]
                        },{
                            id: 'birthprovince',
                            type: 'input',
                            length: 2,
                            label: 'BIRTH_PROVINCE',
                            inputRule: 'capitalize',
                            generatedData: ['Pordenone', 'Gorizia', 'Lucca', 'Milano', 'Macerata', 'Modena', 'Napoli', 'Roma'],
                            validators: [
                            Validators.required,
                            ]
                        },{
                            id: 'taxCode',
                            type: 'input',
                            length: 2,
                            label: 'TAX_CODE',
                            validators: [
                                Validators.required,
                                Validators.pattern(CustomRegExp.TAX_CODE),
                            ],
                            inputRule: 'upperCase'
                        }
                        ],
                    },
                    {
                        title: 'ADDRESS',
                        id: 'address',
                        inputs: [
                        {
                            id: 'street',
                            type: 'input',
                            length: 4,
                            label: 'STREET',
                            generatedData: ['Via dei Mille', 'Via dei flagelli', 'Via salino', 'Via castorino sotto', 'Via degli angeli', 'Via immacolata', 'Via dei popini'],
                            validators: [Validators.required],
                            placeholder: 'Via dei Mille',
                        },
                        {
                            id: 'civic',
                            type: 'input',
                            length: 2,
                            label: 'CIVIC',
                            generatedData: ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19', '20'],
                            validators: [
                            Validators.required,
                            Validators.pattern(CustomRegExp.CIVIC),
                            ],
                            placeholder: '',
                        },
                        {
                            id: 'city',
                            type: 'input',
                            length: 2,
                            label: 'MUNICIPALITY',
                            generatedData: ['Palermo', 'Milano', 'Trento', 'Venezia', 'Roma', 'San Gimigliano', 'Siracusa'],
                            validators: [
                            Validators.required,
                            ],
                            inputRule: 'capitalize',
                            placeholder: '',
                        },
                        {
                            id: 'postalCode',
                            type: 'input',
                            length: 2,
                            label: 'POSTAL_CODE',
                            generatedData: [33182,33170,33025,33749,33354,32145],
                            validators: [
                            Validators.required,
                            Validators.pattern(CustomRegExp.CAP),
                            ],
                            inputRule: 'number',
                            placeholder: '',
                        },
                        {
                            id: 'province',
                            type: 'input',
                            length: 2,
                            label: 'PROVINCE',
                            generatedData: ['Pordenone', 'Gorizia', 'Lucca', 'Milano', 'Macerata', 'Modena', 'Napoli', 'Roma'],
                            validators: [
                            Validators.required,
                            Validators.pattern(CustomRegExp.PROVINCE),
                            ],
                            inputRule: 'capitalize',
                            placeholder: '',
                        },
                        {
                            id: 'country',
                            type: 'input',
                            length: 2,
                            label: 'COUNTRY',
                            generatedData: ['Italia'],
                            validators: [
                            Validators.required,
                            Validators.pattern(CustomRegExp.COUNTRY),
                            ],
                            inputRule: 'capitalize',
                            placeholder: '',
                        },
                        ],
                    },
                    {
                        title: 'CONTACTS',
                        id: 'contacts',
                        inputs: [
                        {
                            id: 'email',
                            type: 'input',
                            length: 2,
                            label: 'EMAIL',
                            validators: [Validators.pattern(CustomRegExp.EMAIL)],
                            placeholder: 'email@mail.com',
                            inputRule: 'noSpaces'
                        },
                        {
                            id: 'mobile_number',
                            type: 'input',
                            length: 2,
                            label: 'MOBILE_NUMBER',
                            validators: [Validators.pattern(CustomRegExp.MOBILE_PHONE_NUMBER)],
                            placeholder: '',
                            inputRule: 'number',
                        },
                        {
                            id: 'landline_phone',
                            type: 'input',
                            length: 2,
                            label: 'LANDLINE_PHONE',
                            validators: [Validators.pattern(CustomRegExp.MOBILE_PHONE_NUMBER)],
                            placeholder: '',
                            inputRule: 'number',
                        },
                        ],
                    },
                    ],
                },{
                    id: 'doctor',
                    title: 'DOCTOR',
                    children: [{
                    id: 'personal_data',
                    title: 'INPUT_GROUP_TITLE.PERSONAL_DATA',
                    requiredToSaveDraft: 'name',
                    inputs: [
                        {
                        id: 'name',
                        type: 'input',
                        length: 3,
                        label: 'NAME',
                        generatedData: ['Mario', 'Marco', 'Matteo', 'Mattia', 'Filippo', 'Filomena', 'Andreoschi', 'Angelo', 'Stefano'],
                        validators: [
                            Validators.required,
                        ],
                        inputRule: 'capitalize',
                        placeholder: 'Mario',
                        },{
                        id: 'surname',
                        type: 'input',
                        length: 3,
                        label: 'SURNAME',
                        generatedData: ['Sacagnat', 'Sporin', 'Alato', 'Stefani', 'Marinoschi', 'De Marco', 'Franchino'],
                        validators: [
                            Validators.required,
                        ],
                        inputRule: 'capitalize',
                        placeholder: 'Rossi',
                        }
                    ],
                    },
                    {
                    title: 'ADDRESS',
                    id: 'address',
                    inputs: [
                        {
                        id: 'street',
                        type: 'input',
                        length: 4,
                        label: 'STREET',
                        validators: [
                        ],
                        placeholder: 'Via dei Mille',
                        },
                        {
                        id: 'civic',
                        type: 'input',
                        length: 2,
                        label: 'CIVIC',
                        validators: [
                            Validators.pattern(CustomRegExp.CIVIC),
                        ],
                        placeholder: '',
                        },
                        {
                        id: 'city',
                        type: 'input',
                        length: 2,
                        label: 'MUNICIPALITY',
                        validators: [
                        ],
                        placeholder: '',
                        inputRule: 'capitalize',
                        },
                        {
                        id: 'postalCode',
                        type: 'input',
                        length: 2,
                        label: 'POSTAL_CODE',
                        validators: [
                            Validators.pattern(CustomRegExp.CAP),
                        ],
                        placeholder: '',
                        inputRule: 'number',
                        },
                        {
                        id: 'province',
                        type: 'input',
                        length: 2,
                        label: 'PROVINCE',
                        validators: [
                            Validators.pattern(CustomRegExp.PROVINCE),
                        ],
                        placeholder: '',
                        inputRule: 'capitalize',
                        },
                        {
                        id: 'country',
                        type: 'input',
                        length: 2,
                        label: 'COUNTRY',
                        validators: [
                            Validators.pattern(CustomRegExp.COUNTRY),
                        ],
                        placeholder: '',
                        },
                    ],
                    },{
                    title: 'CONTACTS',
                    id: 'contacts',
                    inputs: [
                        {
                            id: 'email',
                            type: 'input',
                            length: 2,
                            label: 'EMAIL',
                            validators: [Validators.pattern(CustomRegExp.EMAIL)],
                            placeholder: 'email@mail.com',
                            inputRule: 'noSpaces'
                        },
                        {
                        id: 'phoneNumber',
                        type: 'input',
                        length: 2,
                        label: 'MOBILE_NUMBER',
                        validators: [Validators.pattern(CustomRegExp.PHONE_NUMBER)],
                        placeholder: '',
                        inputRule: 'number',
                        },
                        {
                        id: 'telephone',
                        type: 'input',
                        length: 2,
                        label: 'LANDLINE_PHONE',
                        validators: [Validators.pattern(CustomRegExp.PHONE_NUMBER)],
                        placeholder: '',
                        inputRule: 'number',
                        },
                    ],
                    }]
                }],
            },
            showGenerateDataButton: true
        },
        admission: {
            formStructure: {
                formType: 'simple',
                structure: [{
                    id: 'admission',
                    title: 'ADMISSION',
                    children: [
                        {
                            title: 'PATIENT_ADMISSION',
                            id: 'patientAdmission',
                            inputs: [
                                {
                                    id: 'buildingId',
                                    type: 'select',
                                    length: 6,
                                    label: 'BUILDING',
                                    disableTranslation: true,
                                    loader: ():Observable<{id: string, label: string}[]> => this.facilitiesService.getBuildings$().pipe(
                                        map((data) => data.map((item) => ({id: item.id!, label: item.name!})))
                                    ),
                                    values: []
                                },{
                                    id: 'unitId',
                                    type: 'select',
                                    length: 6,
                                    label: 'UNIT',
                                    disableTranslation: true,
                                    loader: (buildingId: string | number | undefined):Observable<{id: string, label: string}[]> => buildingId ? this.facilitiesService.getUnits$(buildingId as string).pipe(
                                        map((data) => data.map((item) => ({id: item.id!, label: item.name!})))
                                    ) : of([]),
                                    values: []
                                },{
                                    id: 'roomId',
                                    type: 'select',
                                    length: 6,
                                    label: 'ROOM',
                                    disableTranslation: true,
                                    loader: (unitId: string | number | undefined):Observable<{id: string, label: string, disabled: boolean}[]> => unitId ? this.facilitiesService.getRooms$(unitId as string).pipe(
                                        map((data) => data.map((item) => ({id: item.id!, label: item.name!, disabled: false/* , disabled: !item.beds?.length */})))
                                    ) : of([]),
                                    values: []
                                },{
                                    id: 'bedId',
                                    type: 'select',
                                    length: 6,
                                    label: 'BED',
                                    disableTranslation: true,
                                    allowClear: true,
                                    loader: (roomId: string | number | undefined):Observable<{id: string, label: string, disabled: boolean}[]> => roomId ? this.facilitiesService.getBeds$(roomId as string).pipe(
                                        map((data) => data.map((item) => ({id: item.id!, label: item.name!, disabled: item.status === 'O'})))
                                    ) : of([]),
                                    values: []
                                }
                            ],
                        }
                    ],
                }]
            }
        },
        medical_history: {
            formStructure: {
                formType: 'simple',
                structure: [{
                    id: 'medicalHistory',
                    title: 'MEDICAL_HISTORY',
                    children: [
                    {
                        id: 'personalData',
                        title: 'GENERIC_PERSONAL_DATA',
                        inputs: [
                        {
                            id: 'weight',
                            type: 'input',
                            length: 2,
                            label: 'WEIGHT',
                            validators: [
                            Validators.required,
                            ],
                            inputRule: 'number'
                        },{
                            id: 'height',
                            type: 'input',
                            length: 2,
                            label: 'HEIGHT',
                            validators: [
                            Validators.required,
                            ],
                            inputRule: 'number'
                        },{
                            id: 'disability',
                            type: 'select',
                            length: 2,
                            label: 'DISABILITY',
                            values: [{
                                id: 'LA31043-5',
                                label: 'NEUROLOGIC_DISABILITY'
                            },{
                                id: 'LA31044-3',
                                label: 'NEURODEVELOPMENT_DISABILITY'
                            },{
                                id: 'LA29576-8',
                                label: 'INTELLECTUAL_DISABILITY'
                            },{
                                id: 'LA31046-8',
                                label: 'PHYSICAL_DISABILITY'
                            },{
                                id: 'LA31047-6',
                                label: 'VISION_IMPAIRMENT'
                            },{
                                id: 'LA16989-8',
                                label: 'HEARING_IMPAIRMENT'
                            }]
                        }
                        ],
                    },{
                        id: 'pathologicalHistory',
                        title: 'PATHOLOGICAL_HISTORY',
                        inputs: [
                        {
                            id: 'remotePathologicalHistory',
                            type: 'input',
                            length: 3,
                            label: 'REMOTE_PATHOLOGICAL_HISTORY',
                            validators: [
                            Validators.required,
                            ]
                        },{
                            id: 'immediatePathologicalHistory',
                            type: 'input',
                            length: 3,
                            label: 'IMMEDIATE_PATHOLOGICAL_HISTORY',
                            validators: [
                            Validators.required,
                            ]
                        }
                        ],
                    },{
                        id: 'otherInfo',
                        title: 'OTHER_INFORMATION',
                        inputs: [
                        {
                            id: 'smoker',
                            type: 'select',
                            length: 3,
                            label: 'SMOKER',
                            values: [{
                                id: 'LA18976-3',
                                label: "EVERY_DAY_SMOKE"
                            },{
                                id: 'LA18977-1',
                                label: "SOME_DAY_SMOKE"
                            },
                            {
                                id: "LA15920-4",
                                label: "FORMER_SMOKER"
                            },{
                                id: "LA18978-9",
                                label: "NEVER_SMOKER"
                            },{
                                id: "LA18979-7",
                                label: "USE_TO_SMOKE"
                            },{
                                id: "LA18980-5",
                                label: "UNKNOWN_SMOKER"
                            },{
                                id: "LA18981-3",
                                label: "HEAVY_SMOKER"
                            },{
                                id: "LA18982-1",
                                label: "LIGHT_SMOKER"
                            }]
                        },{
                            id: 'alcohol',
                            type: 'select',
                            length: 3,
                            label: 'ALCOL',
                            values: [{
                            id: 'LA33-6',
                            label: 'YES'
                            },{
                            id: 'LA32-8',
                            label: 'NO'
                            }]
                        }
                        ],
                    },{
                        id: 'materialAllergies',
                        title: 'MATERIAL_ALLERGIES',
                        showCreateNewResource: true,
                        inputs: [
                        {
                            id: 'materials',
                            type: 'autocomplete',
                            length: 6,
                            label: 'MATERIALS',
                            validators: [],
                            suggestedItems: [{id: "prova", label: 'Prova'}, {id: "prova1", label: 'Prova1'}]
                        }
                        ],
                    },{
                        id: 'foodAllergies',
                        title: 'FOOD_ALLERGIES',
                        showCreateNewResource: true,
                        inputs: [
                        {
                            id: 'food',
                            type: 'autocomplete',
                            length: 6,
                            label: 'FOOD',
                            validators: [],
                            suggestedItems: [{id: "prova", label: 'Prova'}, {id: "prova1", label: 'Prova1'}]
                        }
                        ],
                    },{
                        id: 'medicationAllergies',
                        title: 'MEDICATION_ALLERGIES',
                        showCreateNewResource: true,
                        inputs: [
                        {
                            id: 'medications',
                            type: 'autocomplete',
                            length: 6,
                            label: 'MEDICATIONS',
                            validators: [],
                            suggestedItems: [{id: "prova", label: 'Prova'}, {id: "prova1", label: 'Prova1'}]
                        }
                        ],
                    }
                    ],
                }],
            }
        },
        /* clinical: {
            formStructure: {
                formType: 'simple',
                structure: [{
                    id: 'prevention',
                    title: 'PREVENTION',
                    children: [
                        {
                            id: 'braden',
                            title: 'BRADEN.TITLE',
                            score: {
                                current: 0,
                                max: 0,
                                grade: 'none'
                            },
                            hideSection: true,
                            inputs: [
                                {
                                id: 'sensoryPerception',
                                type: 'select',
                                length: 3,
                                label: 'BRADEN.SENSORY_PERCEPTION.LABEL',
                                validators: [Validators.required],
                                values: [{
                                    id: 'sensoryPerception1',
                                    label: 'BRADEN.SENSORY_PERCEPTION.SENSORYPERCEPTION1'
                                },{
                                    id: 'sensoryPerception2',
                                    label: 'BRADEN.SENSORY_PERCEPTION.SENSORYPERCEPTION2'
                                },{
                                    id: 'sensoryPerception3',
                                    label: 'BRADEN.SENSORY_PERCEPTION.SENSORYPERCEPTION3'
                                },{
                                    id: 'sensoryPerception4',
                                    label: 'BRADEN.SENSORY_PERCEPTION.SENSORYPERCEPTION4'
                                }]
                                },{
                                id: 'moisture',
                                type: 'select',
                                length: 3,
                                label: 'BRADEN.MOISTURE.LABEL',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'moisture1',
                                    label: 'BRADEN.MOISTURE.MOISTURE1'
                                },{
                                    id: 'moisture2',
                                    label: 'BRADEN.MOISTURE.MOISTURE2'
                                },{
                                    id: 'moisture3',
                                    label: 'BRADEN.MOISTURE.MOISTURE3'
                                },{
                                    id: 'moisture4',
                                    label: 'BRADEN.MOISTURE.MOISTURE4'
                                }]
                                },{
                                id: 'activity',
                                type: 'select',
                                length: 3,
                                label: 'BRADEN.ACTIVITY.LABEL',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'activity1',
                                    label: 'BRADEN.ACTIVITY.ACTIVITY1'
                                },{
                                    id: 'activity2',
                                    label: 'BRADEN.ACTIVITY.ACTIVITY2'
                                },{
                                    id: 'activity3',
                                    label: 'BRADEN.ACTIVITY.ACTIVITY3'
                                },{
                                    id: 'activity4',
                                    label: 'BRADEN.ACTIVITY.ACTIVITY4'
                                }]
                                },{
                                id: 'mobility',
                                type: 'select',
                                length: 3,
                                label: 'BRADEN.MOBILITY.LABEL',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'mobility1',
                                    label: 'BRADEN.MOBILITY.MOBILITY1'
                                },{
                                    id: 'mobility2',
                                    label: 'BRADEN.MOBILITY.MOBILITY2'
                                },{
                                    id: 'mobility3',
                                    label: 'BRADEN.MOBILITY.MOBILITY3'
                                },{
                                    id: 'mobility4',
                                    label: 'BRADEN.MOBILITY.MOBILITY4'
                                }]
                                },{
                                id: 'nutrition',
                                type: 'select',
                                length: 3,
                                label: 'BRADEN.NUTRITION.LABEL',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'nutrition1',
                                    label: 'BRADEN.NUTRITION.NUTRITION1'
                                },{
                                    id: 'nutrition2',
                                    label: 'BRADEN.NUTRITION.NUTRITION2'
                                },{
                                    id: 'nutrition3',
                                    label: 'BRADEN.NUTRITION.NUTRITION3'
                                },{
                                    id: 'nutrition4',
                                    label: 'BRADEN.NUTRITION.NUTRITION4'
                                }]
                                },{
                                id: 'friction',
                                type: 'select',
                                length: 3,
                                label: 'BRADEN.FRICTION.LABEL',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'friction1',
                                    label: 'BRADEN.FRICTION.FRICTION1'
                                },{
                                    id: 'friction2',
                                    label: 'BRADEN.FRICTION.FRICTION2'
                                },{
                                    id: 'friction3',
                                    label: 'BRADEN.FRICTION.FRICTION3'
                                }]
                                }
                            ],
                        },{
                            id: 'norton',
                            title: 'NORTON.TITLE',
                            score: {
                                current: 0,
                                max: 0,
                                grade: 'none'
                            },
                            hideSection: true,
                            inputs: [
                                {
                                id: 'physicalConditions',
                                type: 'select',
                                length: 3,
                                label: 'NORTON.PHYSICAL_CONDITIONS.LABEL',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'physicalConditions1',
                                    label: 'NORTON.PHYSICAL_CONDITIONS.PHYSICAL_CONDITIONS1'
                                },{
                                    id: 'physicalConditions2',
                                    label: 'NORTON.PHYSICAL_CONDITIONS.PHYSICAL_CONDITIONS2'
                                },{
                                    id: 'physicalConditions3',
                                    label: 'NORTON.PHYSICAL_CONDITIONS.PHYSICAL_CONDITIONS3'
                                },{
                                    id: 'physicalConditions4',
                                    label: 'NORTON.PHYSICAL_CONDITIONS.PHYSICAL_CONDITIONS4'
                                }]
                                },{
                                id: 'mentalState',
                                type: 'select',
                                length: 3,
                                label: 'NORTON.MENTAL_STATE.LABEL',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'mentalState1',
                                    label: 'NORTON.MENTAL_STATE.MENTAL_STATE1'
                                },{
                                    id: 'mentalState2',
                                    label: 'NORTON.MENTAL_STATE.MENTAL_STATE2'
                                },{
                                    id: 'mentalState3',
                                    label: 'NORTON.MENTAL_STATE.MENTAL_STATE3'
                                },{
                                    id: 'mentalState4',
                                    label: 'NORTON.MENTAL_STATE.MENTAL_STATE4'
                                }]
                                },{
                                id: 'activity',
                                type: 'select',
                                length: 3,
                                label: 'NORTON.ACTIVITY.LABEL',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'activity1',
                                    label: 'NORTON.ACTIVITY.ACTIVITY1'
                                },{
                                    id: 'activity2',
                                    label: 'NORTON.ACTIVITY.ACTIVITY2'
                                },{
                                    id: 'activity3',
                                    label: 'NORTON.ACTIVITY.ACTIVITY3'
                                },{
                                    id: 'activity4',
                                    label: 'NORTON.ACTIVITY.ACTIVITY4'
                                }]
                                },{
                                id: 'mobility',
                                type: 'select',
                                length: 3,
                                label: 'NORTON.MOBILITY.LABEL',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'mobility1',
                                    label: 'NORTON.MOBILITY.MOBILITY1'
                                },{
                                    id: 'mobility2',
                                    label: 'NORTON.MOBILITY.MOBILITY2'
                                },{
                                    id: 'mobility3',
                                    label: 'NORTON.MOBILITY.MOBILITY3'
                                },{
                                    id: 'mobility4',
                                    label: 'NORTON.MOBILITY.MOBILITY4'
                                }]
                                },{
                                id: 'incontinence',
                                type: 'select',
                                length: 3,
                                label: 'NORTON.INCONTINENCE.LABEL',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'incontinence1',
                                    label: 'NORTON.INCONTINENCE.INCONTINENCE1'
                                },{
                                    id: 'incontinence2',
                                    label: 'NORTON.INCONTINENCE.INCONTINENCE2'
                                },{
                                    id: 'incontinence3',
                                    label: 'NORTON.INCONTINENCE.INCONTINENCE3'
                                },{
                                    id: 'incontinence4',
                                    label: 'NORTON.INCONTINENCE.INCONTINENCE4'
                                }]
                                }
                            ],
                        },{
                            id: 'barthel',
                            title: 'BARTHEL.TITLE',
                            score: {
                                current: 0,
                                max: 0,
                                grade: 'none'
                            },
                            hideSection: true,
                            inputs: [
                                {
                                id: 'diet',
                                type: 'select',
                                length: 3,
                                label: 'BARTHEL.DIET.LABEL',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'diet1',
                                    label: 'BARTHEL.DIET.DIET1'
                                },{
                                    id: 'diet2',
                                    label: 'BARTHEL.DIET.DIET2'
                                },{
                                    id: 'diet3',
                                    label: 'BARTHEL.DIET.DIET3'
                                }]
                                },{
                                id: 'takeBath',
                                type: 'select',
                                length: 3,
                                label: 'BARTHEL.TAKE_BATH.LABEL',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'takeBath1',
                                    label: 'BARTHEL.TAKE_BATH.TAKE_BATH1'
                                },{
                                    id: 'takeBath2',
                                    label: 'BARTHEL.TAKE_BATH.TAKE_BATH2'
                                }]
                                },{
                                id: 'personalHygiene',
                                type: 'select',
                                length: 3,
                                label: 'BARTHEL.PERSONAL_HYGIENE.LABEL',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'personalHygiene1',
                                    label: 'BARTHEL.PERSONAL_HYGIENE.PERSONAL_HYGIENE1'
                                },{
                                    id: 'personalHygiene2',
                                    label: 'BARTHEL.PERSONAL_HYGIENE.PERSONAL_HYGIENE2'
                                }]
                                },{
                                id: 'dressing',
                                type: 'select',
                                length: 3,
                                label: 'BARTHEL.DRESSING.LABEL',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'dressing1',
                                    label: 'BARTHEL.DRESSING.DRESSING1'
                                },{
                                    id: 'dressing2',
                                    label: 'BARTHEL.DRESSING.DRESSING2'
                                },{
                                    id: 'dressing3',
                                    label: 'BARTHEL.DRESSING.DRESSING3'
                                }]
                                },{
                                id: 'rectum',
                                type: 'select',
                                length: 3,
                                label: 'BARTHEL.RECTUM.LABEL',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'rectum1',
                                    label: 'BARTHEL.RECTUM.RECTUM1'
                                },{
                                    id: 'rectum2',
                                    label: 'BARTHEL.RECTUM.RECTUM2'
                                },{
                                    id: 'rectum3',
                                    label: 'BARTHEL.RECTUM.RECTUM3'
                                }]
                                },{
                                id: 'bladder',
                                type: 'select',
                                length: 3,
                                label: 'BARTHEL.BLADDER.LABEL',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'bladder1',
                                    label: 'BARTHEL.BLADDER.BLADDER1'
                                },{
                                    id: 'bladder2',
                                    label: 'BARTHEL.BLADDER.BLADDER2'
                                },{
                                    id: 'bladder3',
                                    label: 'BARTHEL.BLADDER.BLADDER3'
                                }]
                                },{
                                id: 'bathroomTransfer',
                                type: 'select',
                                length: 3,
                                label: 'BARTHEL.BATHROOM_TRANSFER.LABEL',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'bathroomTransfer1',
                                    label: 'BARTHEL.BATHROOM_TRANSFER.BATHROOM_TRANSFER1'
                                },{
                                    id: 'bathroomTransfer2',
                                    label: 'BARTHEL.BATHROOM_TRANSFER.BATHROOM_TRANSFER2'
                                },{
                                    id: 'bathroomTransfer3',
                                    label: 'BARTHEL.BATHROOM_TRANSFER.BATHROOM_TRANSFER3'
                                }]
                                },{
                                id: 'chairBedTransfer',
                                type: 'select',
                                length: 3,
                                label: 'BARTHEL.CHAIR_BED_TRANSFER.LABEL',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'chairBedTransfer1',
                                    label: 'BARTHEL.CHAIR_BED_TRANSFER.CHAIR_BED_TRANSFER1'
                                },{
                                    id: 'chairBedTransfer2',
                                    label: 'BARTHEL.CHAIR_BED_TRANSFER.CHAIR_BED_TRANSFER2'
                                },{
                                    id: 'chairBedTransfer3',
                                    label: 'BARTHEL.CHAIR_BED_TRANSFER.CHAIR_BED_TRANSFER3'
                                },{
                                    id: 'chairBedTransfer4',
                                    label: 'BARTHEL.CHAIR_BED_TRANSFER.CHAIR_BED_TRANSFER4'
                                }]
                                },{
                                id: 'walking',
                                type: 'select',
                                length: 3,
                                label: 'BARTHEL.WALKING.LABEL',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'walking1',
                                    label: 'BARTHEL.WALKING.WALKING1'
                                },{
                                    id: 'walking2',
                                    label: 'BARTHEL.WALKING.WALKING2'
                                },{
                                    id: 'walking3',
                                    label: 'BARTHEL.WALKING.WALKING3'
                                },{
                                    id: 'walking4',
                                    label: 'BARTHEL.WALKING.WALKING4'
                                }]
                                }
                            ],
                        },{
                            id: 'albumin',
                            title: 'ALBUMIN.TITLE',
                            score: {
                                current: 0,
                                max: 0,
                                grade: 'none'
                            },
                            hideSection: true,
                            inputs: [
                                {
                                id: 'albumin',
                                type: 'select',
                                length: 6,
                                label: 'ALBUMIN.ALBUMIN.LABEL',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'albumin1',
                                    label: 'ALBUMIN.ALBUMIN.ALBUMIN1'
                                },{
                                    id: 'albumin2',
                                    label: 'ALBUMIN.ALBUMIN.ALBUMIN2'
                                },{
                                    id: 'albumin3',
                                    label: 'ALBUMIN.ALBUMIN.ALBUMIN3'
                                }]
                            }]
                        },{
                            id: 'conley',
                            title: 'CONLEY.TITLE',
                            score: {
                                current: 0,
                                max: 0,
                                grade: 'none'
                            },
                            hideSection: true,
                            inputs: [{
                                id: 'fallen',
                                type: 'select',
                                length: 3,
                                label: 'CONLEY.FALLEN',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'fallen1',
                                    label: 'CONLEY.NO'
                                },{
                                    id: 'fallen2',
                                    label: 'CONLEY.YES'
                                }]
                            },{
                                id: 'dizziness',
                                type: 'select',
                                length: 3,
                                label: 'CONLEY.DIZZINESS',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'dizziness1',
                                    label: 'CONLEY.NO'
                                },{
                                    id: 'dizziness2',
                                    label: 'CONLEY.YES'
                                }]
                            },{
                                id: 'incontinence',
                                type: 'select',
                                length: 3,
                                label: 'CONLEY.INCONTINENCE',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'incontinence1',
                                    label: 'CONLEY.NO'
                                },{
                                    id: 'incontinence2',
                                    label: 'CONLEY.YES'
                                }]
                            },{
                                id: 'unstableWalking',
                                type: 'select',
                                length: 3,
                                label: 'CONLEY.UNSTABLE_WALKING',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'unstableWalking1',
                                    label: 'CONLEY.NO'
                                },{
                                    id: 'unstableWalking2',
                                    label: 'CONLEY.YES'
                                }]
                            },{
                                id: 'agitated',
                                type: 'select',
                                length: 3,
                                label: 'CONLEY.AGITATED',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'agitated1',
                                    label: 'CONLEY.NO'
                                },{
                                    id: 'agitated2',
                                    label: 'CONLEY.YES'
                                }]
                            },{
                                id: 'impairedJudgment',
                                type: 'select',
                                length: 3,
                                label: 'CONLEY.IMPAIRED_JUDGMENT',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'impairedJudgment1',
                                    label: 'CONLEY.NO'
                                },{
                                    id: 'impairedJudgment2',
                                    label: 'CONLEY.YES'
                                }]
                            }]
                        },{
                            id: 'bedsores',
                            title: 'BEDSORES.TITLE',
                            score: {
                                current: 0,
                                max: 0,
                                grade: 'none'
                            },
                            hideSection: true,
                            inputs: [
                            {
                            id: 'bedsores',
                                type: 'select',
                                length: 6,
                                label: 'BEDSORES.BEDSORES.LABEL',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'bedsores1',
                                    label: 'BEDSORES.BEDSORES.BEDSORES1'
                                },{
                                    id: 'bedsores2',
                                    label: 'BEDSORES.BEDSORES.BEDSORES2'
                                },{
                                    id: 'bedsores3',
                                    label: 'BEDSORES.BEDSORES.BEDSORES3'
                                },{
                                    id: 'bedsores4',
                                    label: 'BEDSORES.BEDSORES.BEDSORES4'
                                },{
                                    id: 'bedsores5',
                                    label: 'BEDSORES.BEDSORES.BEDSORES5'
                                }]
                            }]
                        },{
                            id: 'iad',
                            title: 'IAD.TITLE',
                            score: {
                                current: 0,
                                max: 0,
                                grade: 'none'
                            },
                            hideSection: true,
                            inputs: [{
                                id: 'lucidity',
                                type: 'select',
                                length: 3,
                                label: 'IAD.LUCIDITY.LABEL',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'lucidity1',
                                    label: 'IAD.LUCIDITY.LUCIDITY1'
                                },{
                                    id: 'lucidity2',
                                    label: 'IAD.LUCIDITY.LUCIDITY2'
                                }]
                            },{
                                id: 'urinaryIncontinence',
                                type: 'select',
                                length: 3,
                                label: 'IAD.URINARY_INCONTINENCE.LABEL',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'urinaryIncontinence1',
                                    label: 'IAD.URINARY_INCONTINENCE.URINARY_INCONTINENCE1'
                                },{
                                    id: 'urinaryIncontinence2',
                                    label: 'IAD.URINARY_INCONTINENCE.URINARY_INCONTINENCE2'
                                },{
                                    id: 'urinaryIncontinence3',
                                    label: 'IAD.URINARY_INCONTINENCE.URINARY_INCONTINENCE3'
                                }]
                            },{
                                id: 'fecalIncontinence',
                                type: 'select',
                                length: 3,
                                label: 'IAD.FECAL_INCONTINENCE.LABEL',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'fecalIncontinence1',
                                    label: 'IAD.FECAL_INCONTINENCE.FECAL_INCONTINENCE1'
                                },{
                                    id: 'fecalIncontinence2',
                                    label: 'IAD.FECAL_INCONTINENCE.FECAL_INCONTINENCE2'
                                },{
                                    id: 'fecalIncontinence3',
                                    label: 'IAD.FECAL_INCONTINENCE.FECAL_INCONTINENCE3'
                                }]
                            },{
                                id: 'lesionPresence',
                                type: 'select',
                                length: 3,
                                label: 'IAD.LESION_PRESENCE.LABEL',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'lesionPresence1',
                                    label: 'IAD.LESION_PRESENCE.LESION_PRESENCE1'
                                },{
                                    id: 'lesionPresence2',
                                    label: 'IAD.LESION_PRESENCE.LESION_PRESENCE2'
                                },{
                                    id: 'lesionPresence3',
                                    label: 'IAD.LESION_PRESENCE.LESION_PRESENCE3'
                                },{
                                    id: 'lesionPresence4',
                                    label: 'IAD.LESION_PRESENCE.LESION_PRESENCE4'
                                }]
                            }]
                        },{
                            id: 'skinTears',
                            title: 'SKIN_TEARS.TITLE',
                            score: {
                                current: 0,
                                max: 0,
                                grade: 'none'
                            },
                            hideSection: true,
                            inputs: [{
                                id: 'skinTears',
                                type: 'select',
                                length: 3,
                                label: 'SKIN_TEARS.SKIN_TEARS.LABEL',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'skinTears1',
                                    label: 'SKIN_TEARS.SKIN_TEARS.SKIN_TEARS1'
                                },{
                                    id: 'skinTears2',
                                    label: 'SKIN_TEARS.SKIN_TEARS.SKIN_TEARS2'
                                }]
                            }]
                        }
                    ]
                },{
                    id: 'summary',
                    title: 'SUMMARY',
                    children: [{
                        id: 'evaluationSummary',
                        title: 'EVALUATION_SUMMARY',
                        inputs: [],
                        hideSection: false,
                        textLines: [{
                            type: 'textValue',
                            text: 'MOBILIZATION_PERCENTAGE',
                            value: '-50%'
                        },{
                            type: 'textValue',
                            text: 'MOBILIZATION_MINUTES',
                            value: '60 minuti'
                        },{
                            type: 'textValue',
                            text: 'SUMMARY_SCORE',
                            value: '3/7'
                        }]
                    },{
                        id: 'specs',
                        title: 'SPECS',
                        inputs: [{
                            id: 'nextEvaluation',
                            type: 'input',
                            length: 3,
                            label: 'NEXT_EVALUATION_DATE',
                            validators: [Validators.required],
                        },{
                            id: 'matress',
                            type: 'select',
                            length: 3,
                            label: 'MATTRESS',
                            validators: [Validators.required],
                            values: [{
                                id: 'mattress1',
                                label: 'MATTRESS_LIST.MATRESS1'
                            },{
                                id: 'mattress2',
                                label: 'MATTRESS_LIST.MATRESS2'
                            },{
                                id: 'mattress3',
                                label: 'MATTRESS_LIST.MATRESS3'
                            }]
                        }]
                    }]
                }]
            }
        }, */
        /* psychological: {
            formStructure: {
                formType: 'simple',
                structure: [{
                    id: 'prevention',
                    title: 'PREVENTION',
                    children: [
                        {
                            id: 'health',
                            title: 'HEALTH.TITLE',
                            score: {
                                current: 0,
                                max: 0,
                                grade: 'none'
                            },
                            hideSection: true,
                            inputs: [
                                {
                                    id: 'mobility',
                                    type: 'select',
                                    length: 3,
                                    label: 'HEALTH.MOBILITY.LABEL',
                                    validators: [Validators.required],
                                    values: [{
                                        id: 'mobility1',
                                        label: 'HEALTH.MOBILITY.MOBILITY1'
                                    },{
                                        id: 'mobility2',
                                        label: 'HEALTH.MOBILITY.MOBILITY2'
                                    },{
                                        id: 'mobility3',
                                        label: 'HEALTH.MOBILITY.MOBILITY3'
                                    }]
                                },{
                                    id: 'selfCare',
                                    type: 'select',
                                    length: 3,
                                    label: 'HEALTH.SELF_CARE.LABEL',
                                    validators: [Validators.required],
                                    values: [{
                                        id: 'selfCare1',
                                        label: 'HEALTH.SELF_CARE.SELF_CARE1'
                                    },{
                                        id: 'selfCare2',
                                        label: 'HEALTH.SELF_CARE.SELF_CARE2'
                                    },{
                                        id: 'selfCare3',
                                        label: 'HEALTH.SELF_CARE.SELF_CARE3'
                                    }]
                                },{
                                    id: 'activity',
                                    type: 'select',
                                    length: 3,
                                    label: 'HEALTH.ACTIVITY.LABEL',
                                    validators: [Validators.required],
                                    values: [{
                                        id: 'activity1',
                                        label: 'HEALTH.ACTIVITY.ACTIVITY1'
                                    },{
                                        id: 'activity2',
                                        label: 'HEALTH.ACTIVITY.ACTIVITY2'
                                    },{
                                        id: 'activity3',
                                        label: 'HEALTH.ACTIVITY.ACTIVITY3'
                                    }]
                                },{
                                    id: 'pain',
                                    type: 'select',
                                    length: 3,
                                    label: 'HEALTH.PAIN.LABEL',
                                    validators: [Validators.required],
                                    values: [{
                                        id: 'pain1',
                                        label: 'HEALTH.PAIN.PAIN1'
                                    },{
                                        id: 'pain2',
                                        label: 'HEALTH.PAIN.PAIN2'
                                    },{
                                        id: 'pain3',
                                        label: 'HEALTH.PAIN.PAIN3'
                                    }]
                                },{
                                    id: 'anxiety',
                                    type: 'select',
                                    length: 3,
                                    label: 'HEALTH.ANXIETY.LABEL',
                                    validators: [Validators.required],
                                    values: [{
                                        id: 'anxiety1',
                                        label: 'HEALTH.ANXIETY.ANXIETY1'
                                    },{
                                        id: 'anxiety2',
                                        label: 'HEALTH.ANXIETY.ANXIETY2'
                                    },{
                                        id: 'anxiety3',
                                        label: 'HEALTH.ANXIETY.ANXIETY3'
                                    }]
                                },{
                                    id: 'selfEvaluation',
                                    type: 'input',
                                    length: 3,
                                    label: 'HEALTH.SELF_EVALUATION',
                                    validators: [
                                        Validators.required,
                                    ],
                                }
                            ],
                        },{
                            id: 'moca',
                            title: 'MOCA.TITLE',
                            score: {
                                current: 0,
                                max: 0,
                                grade: 'none'
                            },
                            hideSection: true,
                            inputs: [{
                                id: 'visuospatialExecutive',
                                type: 'input',
                                length: 3,
                                label: 'MOCA.VISUOSPATIAL_EXECUTIVE',
                                validators: [
                                    Validators.required,
                                ],
                            },{
                                id: 'naming',
                                type: 'input',
                                length: 3,
                                label: 'MOCA.NAMING',
                                validators: [
                                    Validators.required,
                                ],
                            },{
                                id: 'memory',
                                type: 'input',
                                length: 3,
                                label: 'MOCA.MEMORY',
                                validators: [
                                    Validators.required,
                                ],
                            },{
                                id: 'attention',
                                type: 'input',
                                length: 3,
                                label: 'MOCA.ATTENTION',
                                validators: [
                                    Validators.required,
                                ],
                            },{
                                id: 'language',
                                type: 'input',
                                length: 3,
                                label: 'MOCA.LANGUAGE',
                                validators: [
                                    Validators.required,
                                ],
                            },{
                                id: 'abstraction',
                                type: 'input',
                                length: 3,
                                label: 'MOCA.ABSTRACTION',
                                validators: [
                                    Validators.required,
                                ],
                            },{
                                id: 'delayedCall',
                                type: 'input',
                                length: 3,
                                label: 'MOCA.DELAYED_RECALL',
                                validators: [
                                    Validators.required,
                                ],
                            },{
                                id: 'orientation',
                                type: 'input',
                                length: 3,
                                label: 'MOCA.ORIENTATION',
                                validators: [
                                    Validators.required,
                                ],
                            },{
                                id: 'less13YrsEdu',
                                type: 'select',
                                length: 3,
                                label: 'MOCA.LESS_13_YRS_EDU.LABEL',
                                    validators: [Validators.required],
                                values: [{
                                    id: 'yes',
                                    label: 'MOCA.LESS_13_YRS_EDU.YES'
                                },{
                                    id: 'no',
                                    label: 'MOCA.LESS_13_YRS_EDU.NO'
                                }]
                            }],
                        },{
                            id: 'phq9',
                            title: 'PHQ9.TITLE',
                            score: {
                                current: 0,
                                max: 0,
                                grade: 'none'
                            },
                            hideSection: true,
                            inputs: [{
                                id: 'simpleText',
                                type: 'simpleText',
                                length: 6,
                                label: 'PHQ9.TEXT'
                            }],
                            radio: {
                                headers: [
                                    'PHQ9.HEADERS.NEVER',
                                    'PHQ9.HEADERS.SOME_DAYS',
                                    'PHQ9.HEADERS.MORE_THAN_HALF_DAYS',
                                    'PHQ9.HEADERS.ALMOST_EVERY_DAY'
                                ],
                                values: [
                                    { label: "PHQ9.LABELS.INTEREST", id:"interest"},
                                    { label: "PHQ9.LABELS.DEPRESSED", id:"depressed"},
                                    { label: "PHQ9.LABELS.SLEEPING", id:"sleeping"},
                                    { label: "PHQ9.LABELS.TIRED", id:"tired"},
                                    { label: "PHQ9.LABELS.EATING", id:"eating"},
                                    { label: "PHQ9.LABELS.FEELING", id:"feeling"},
                                    { label: "PHQ9.LABELS.CONCENTRATION", id:"concentration"},
                                    { label: "PHQ9.LABELS.SPEED", id:"speed"},
                                    { label: "PHQ9.LABELS.THOUGHTS", id:"thoughts"}
                                ],
                                validators: [Validators.required]
                            }
                        },{
                            id: 'gad7',
                            title: 'GAD7.TITLE',
                            score: {
                                current: 0,
                                max: 0,
                                grade: 'none'
                            },
                            hideSection: true,
                            inputs: [{
                                id: 'simpleText',
                                type: 'simpleText',
                                length: 6,
                                label: 'GAD7.TEXT'
                            }],
                            radio: {
                                headers: [
                                    'GAD7.HEADERS.NEVER',
                                    'GAD7.HEADERS.SOME_DAYS',
                                    'GAD7.HEADERS.MORE_THAN_HALF_DAYS',
                                    'GAD7.HEADERS.ALMOST_EVERY_DAY'
                                ],
                                values: [
                                    { label: "GAD7.LABELS.NERVOUS", id:"nervous"},
                                    { label: "GAD7.LABELS.CONTROL_CERCERNS", id:"controlCercerns"},
                                    { label: "GAD7.LABELS.TOO_MUCH_CONCERNS", id:"tooMuchConcerns"},
                                    { label: "GAD7.LABELS.RELAX", id:"relax"},
                                    { label: "GAD7.LABELS.RESTLESS", id:"restless"},
                                    { label: "GAD7.LABELS.IRRITABLE", id:"irritable"},
                                    { label: "GAD7.LABELS.SCARED", id:"scared"},
                                ],
                                validators: [Validators.required]
                            }
                        },{
                            id: 'iadl',
                            title: 'IADL.TITLE',
                            score: {
                                current: 0,
                                max: 0,
                                grade: 'none'
                            },
                            hideSection: true,
                            inputs: [
                                {
                                    id: 'phone',
                                    type: 'select',
                                    length: 6,
                                    label: 'IADL.PHONE.LABEL',
                                    values: [{
                                        id: 'phone1',
                                        label: 'IADL.PHONE.PHONE1'
                                    },{
                                        id: 'phone2',
                                        label: 'IADL.PHONE.PHONE2'
                                    },{
                                        id: 'phone3',
                                        label: 'IADL.PHONE.PHONE3'
                                    },{
                                        id: 'phone4',
                                        label: 'IADL.PHONE.PHONE4'
                                    }],
                                    validators: [Validators.required]
                                },{
                                    id: 'shopping',
                                    type: 'select',
                                    length: 6,
                                    label: 'IADL.SHOPPING.LABEL',
                                    values: [{
                                        id: 'shopping1',
                                        label: 'IADL.SHOPPING.SHOPPING1'
                                    },{
                                        id: 'shopping2',
                                        label: 'IADL.SHOPPING.SHOPPING2'
                                    },{
                                        id: 'shopping3',
                                        label: 'IADL.SHOPPING.SHOPPING3'
                                    },{
                                        id: 'shopping4',
                                        label: 'IADL.SHOPPING.SHOPPING4'
                                    }],
                                    validators: [Validators.required]
                                },{
                                    id: 'cook',
                                    type: 'select',
                                    length: 6,
                                    label: 'IADL.COOK.LABEL',
                                    values: [{
                                        id: 'cook1',
                                        label: 'IADL.COOK.COOK1'
                                    },{
                                        id: 'cook2',
                                        label: 'IADL.COOK.COOK2'
                                    },{
                                        id: 'cook3',
                                        label: 'IADL.COOK.COOK3'
                                    },{
                                        id: 'cook4',
                                        label: 'IADL.COOK.COOK4'
                                    }],
                                    validators: [Validators.required]
                                },{
                                    id: 'houseKeeping',
                                    type: 'select',
                                    length: 6,
                                    label: 'IADL.HOUSE_KEEPING.LABEL',
                                    values: [{
                                        id: 'houseKeeping1',
                                        label: 'IADL.HOUSE_KEEPING.HOUSE_KEEPING1'
                                    },{
                                        id: 'houseKeeping2',
                                        label: 'IADL.HOUSE_KEEPING.HOUSE_KEEPING2'
                                    },{
                                        id: 'houseKeeping3',
                                        label: 'IADL.HOUSE_KEEPING.HOUSE_KEEPING3'
                                    },{
                                        id: 'houseKeeping4',
                                        label: 'IADL.HOUSE_KEEPING.HOUSE_KEEPING4'
                                    },{
                                        id: 'houseKeeping5',
                                        label: 'IADL.HOUSE_KEEPING.HOUSE_KEEPING5'
                                    }],
                                    validators: [Validators.required]
                                },{
                                    id: 'laundry',
                                    type: 'select',
                                    length: 6,
                                    label: 'IADL.LAUNDRY.LABEL',
                                    values: [{
                                        id: 'laundry1',
                                        label: 'IADL.LAUNDRY.LAUNDRY1'
                                    },{
                                        id: 'laundry2',
                                        label: 'IADL.LAUNDRY.LAUNDRY2'
                                    },{
                                        id: 'laundry3',
                                        label: 'IADL.LAUNDRY.LAUNDRY3'
                                    }],
                                    validators: [Validators.required]
                                },{
                                    id: 'transport',
                                    type: 'select',
                                    length: 6,
                                    label: 'IADL.TRANSPORT.LABEL',
                                    values: [{
                                        id: 'transport1',
                                        label: 'IADL.TRANSPORT.TRANSPORT1'
                                    },{
                                        id: 'transport2',
                                        label: 'IADL.TRANSPORT.TRANSPORT2'
                                    },{
                                        id: 'transport3',
                                        label: 'IADL.TRANSPORT.TRANSPORT3'
                                    },{
                                        id: 'transport4',
                                        label: 'IADL.TRANSPORT.TRANSPORT4'
                                    },{
                                        id: 'transport5',
                                        label: 'IADL.TRANSPORT.TRANSPORT5'
                                    }],
                                    validators: [Validators.required]
                                },{
                                    id: 'medications',
                                    type: 'select',
                                    length: 6,
                                    label: 'IADL.MEDICATIONS.LABEL',
                                    values: [{
                                        id: 'medications1',
                                        label: 'IADL.MEDICATIONS.MEDICATIONS1'
                                    },{
                                        id: 'medications2',
                                        label: 'IADL.MEDICATIONS.MEDICATIONS2'
                                    },{
                                        id: 'medications3',
                                        label: 'IADL.MEDICATIONS.MEDICATIONS3'
                                    }],
                                    validators: [Validators.required]
                                },{
                                    id: 'finances',
                                    type: 'select',
                                    length: 6,
                                    label: 'IADL.FINANCES.LABEL',
                                    values: [{
                                        id: 'finances1',
                                        label: 'IADL.FINANCES.FINANCES1'
                                    },{
                                        id: 'finances2',
                                        label: 'IADL.FINANCES.FINANCES2'
                                    },{
                                        id: 'finances3',
                                        label: 'IADL.FINANCES.FINANCES3'
                                    }],
                                    validators: [Validators.required]
                                }
                            ],
                        },{
                            id: 'ipaq',
                            title: 'IPAQ.TITLE',
                            score: {
                                current: 0,
                                max: 0,
                                grade: 'none'
                            },
                            inputs: [
                                {
                                    id: 'simpleText',
                                    type: 'simpleText',
                                    length: 6,
                                    label: 'IPAQ.VIGOROUS_ACTIVITIES.TEXT',
                                    infoText: 'IPAQ.VIGOROUS_ACTIVITIES.INFO'
                                },
                                {
                                    id: 'vigorousActivities',
                                    type: 'select',
                                    length: 3,
                                    label: 'IPAQ.VIGOROUS_ACTIVITIES.LABEL',
                                    values: [{
                                        id: 'vigorousActivities1',
                                        label: 'IPAQ.VIGOROUS_ACTIVITIES.VIGOROUS_ACTIVITIES1'
                                    },{
                                        id: 'vigorousActivities2',
                                        label: 'IPAQ.VIGOROUS_ACTIVITIES.VIGOROUS_ACTIVITIES2'
                                    },{
                                        id: 'vigorousActivities3',
                                        label: 'IPAQ.VIGOROUS_ACTIVITIES.VIGOROUS_ACTIVITIES3'
                                    },{
                                        id: 'vigorousActivities4',
                                        label: 'IPAQ.VIGOROUS_ACTIVITIES.VIGOROUS_ACTIVITIES4'
                                    },{
                                        id: 'vigorousActivities5',
                                        label: 'IPAQ.VIGOROUS_ACTIVITIES.VIGOROUS_ACTIVITIES5'
                                    },{
                                        id: 'vigorousActivities6',
                                        label: 'IPAQ.VIGOROUS_ACTIVITIES.VIGOROUS_ACTIVITIES6'
                                    },{
                                        id: 'vigorousActivities7',
                                        label: 'IPAQ.VIGOROUS_ACTIVITIES.VIGOROUS_ACTIVITIES7'
                                    },{
                                        id: 'vigorousActivities8',
                                        label: 'IPAQ.VIGOROUS_ACTIVITIES.VIGOROUS_ACTIVITIES8'
                                    }],
                                    validators: [Validators.required]
                                },{
                                    id: 'vigorousActivitiesHours',
                                    type: 'input',
                                    length: 3,
                                    label: 'IPAQ.VIGOROUS_ACTIVITIES.VIGOROUS_ACTIVITIES_HOURS',
                                    validators: [],
                                    disableOn: {
                                        field: "vigorousActivities",
                                        mustExist: true,
                                        cannotBe: ['vigorousActivities1']
                                    }
                                },{
                                    id: 'simpleText',
                                    type: 'simpleText',
                                    length: 6,
                                    label: 'IPAQ.MODERATE_ACTIVITIES.TEXT',
                                    infoText: 'IPAQ.MODERATE_ACTIVITIES.INFO'
                                },{
                                    id: 'moderateActivities',
                                    type: 'select',
                                    length: 3,
                                    label: 'IPAQ.MODERATE_ACTIVITIES.LABEL',
                                    values: [{
                                        id: 'moderateActivities1',
                                        label: 'IPAQ.MODERATE_ACTIVITIES.MODERATE_ACTIVITIES1'
                                    },{
                                        id: 'moderateActivities2',
                                        label: 'IPAQ.MODERATE_ACTIVITIES.MODERATE_ACTIVITIES2'
                                    },{
                                        id: 'moderateActivities3',
                                        label: 'IPAQ.MODERATE_ACTIVITIES.MODERATE_ACTIVITIES3'
                                    },{
                                        id: 'moderateActivities4',
                                        label: 'IPAQ.MODERATE_ACTIVITIES.MODERATE_ACTIVITIES4'
                                    },{
                                        id: 'moderateActivities5',
                                        label: 'IPAQ.MODERATE_ACTIVITIES.MODERATE_ACTIVITIES5'
                                    },{
                                        id: 'moderateActivities6',
                                        label: 'IPAQ.MODERATE_ACTIVITIES.MODERATE_ACTIVITIES6'
                                    },{
                                        id: 'moderateActivities7',
                                        label: 'IPAQ.MODERATE_ACTIVITIES.MODERATE_ACTIVITIES7'
                                    },{
                                        id: 'moderateActivities8',
                                        label: 'IPAQ.MODERATE_ACTIVITIES.MODERATE_ACTIVITIES8'
                                    }],
                                    validators: [Validators.required]
                                },{
                                    id: 'moderateActivitiesHours',
                                    type: 'input',
                                    length: 3,
                                    label: 'IPAQ.MODERATE_ACTIVITIES.MODERATE_ACTIVITIES_HOURS',
                                    validators: [],
                                    disableOn: {
                                        field: "moderateActivities",
                                        mustExist: true,
                                        cannotBe: ['moderateActivities1']
                                    }
                                },{
                                    id: 'simpleText',
                                    type: 'simpleText',
                                    length: 6,
                                    label: 'IPAQ.WALKING.TEXT',
                                    infoText: 'IPAQ.WALKING.INFO'
                                },{
                                    id: 'walkingActivities',
                                    type: 'select',
                                    length: 3,
                                    label: 'IPAQ.WALKING.LABEL',
                                    values: [{
                                        id: 'walking1',
                                        label: 'IPAQ.WALKING.WALKING1'
                                    },{
                                        id: 'walking2',
                                        label: 'IPAQ.WALKING.WALKING2'
                                    },{
                                        id: 'walking3',
                                        label: 'IPAQ.WALKING.WALKING3'
                                    },{
                                        id: 'walking4',
                                        label: 'IPAQ.WALKING.WALKING4'
                                    },{
                                        id: 'walking5',
                                        label: 'IPAQ.WALKING.WALKING5'
                                    },{
                                        id: 'walking6',
                                        label: 'IPAQ.WALKING.WALKING6'
                                    },{
                                        id: 'walking7',
                                        label: 'IPAQ.WALKING.WALKING7'
                                    },{
                                        id: 'walking8',
                                        label: 'IPAQ.WALKING.WALKING8'
                                    }],
                                    validators: [Validators.required]
                                },{
                                    id: 'walkingActivitiesHours',
                                    type: 'input',
                                    length: 3,
                                    label: 'IPAQ.WALKING.WALKING_HOURS',
                                    validators: [],
                                    disableOn: {
                                        field: "walkingActivities",
                                        mustExist: true,
                                        cannotBe: ['walkingActivities1']
                                    }
                                },{
                                    id: 'simpleText',
                                    type: 'simpleText',
                                    length: 6,
                                    label: 'IPAQ.SITTING.TEXT',
                                    infoText: 'IPAQ.SITTING.INFO'
                                },{
                                    id: 'sittingHours',
                                    type: 'input',
                                    length: 6,
                                    label: 'IPAQ.SITTING.SITTING_HOURS',
                                    validators: [],
                                }
                            ],
                        },{
                            id: 'ucla',
                            title: 'UCLA.TITLE',
                            score: {
                                current: 0,
                                max: 0,
                                grade: 'none'
                            },
                            hideSection: true,
                            inputs: [],
                            radio: {
                                headers: [
                                    'UCLA.HEADERS.NEVER',
                                    'UCLA.HEADERS.RARELY',
                                    'UCLA.HEADERS.SOMETIMES',
                                    'UCLA.HEADERS.OFTEN'
                                ],
                                values: [
                                    { label: "UCLA.LABELS.UCLA1", id:"ucla1"},
                                    { label: "UCLA.LABELS.UCLA2", id:"ucla2"},
                                    { label: "UCLA.LABELS.UCLA3", id:"ucla3"},
                                    { label: "UCLA.LABELS.UCLA4", id:"ucla4"},
                                    { label: "UCLA.LABELS.UCLA5", id:"ucla5"},
                                    { label: "UCLA.LABELS.UCLA6", id:"ucla6"},
                                    { label: "UCLA.LABELS.UCLA7", id:"ucla7"},
                                    { label: "UCLA.LABELS.UCLA8", id:"ucla8"},
                                    { label: "UCLA.LABELS.UCLA9", id:"ucla9"},
                                    { label: "UCLA.LABELS.UCLA10", id:"ucla10"},
                                    { label: "UCLA.LABELS.UCLA11", id:"ucla11"},
                                    { label: "UCLA.LABELS.UCLA12", id:"ucla12"},
                                    { label: "UCLA.LABELS.UCLA13", id:"ucla13"},
                                    { label: "UCLA.LABELS.UCLA14", id:"ucla14"},
                                    { label: "UCLA.LABELS.UCLA15", id:"ucla15"},
                                    { label: "UCLA.LABELS.UCLA16", id:"ucla16"},
                                    { label: "UCLA.LABELS.UCLA17", id:"ucla17"},
                                    { label: "UCLA.LABELS.UCLA18", id:"ucla18"},
                                    { label: "UCLA.LABELS.UCLA19", id:"ucla19"},
                                    { label: "UCLA.LABELS.UCLA20", id:"ucla20"},
                                ],
                                validators: [Validators.required]
                            }
                        },{
                            id: 'pss',
                            title: 'PSS.TITLE',
                            score: {
                                current: 0,
                                max: 0,
                                grade: 'none'
                            },
                            hideSection: true,
                            inputs: [],
                            radio: {
                                headers: [
                                    'PSS.HEADERS.NEVER',
                                    'PSS.HEADERS.ALMOST_NEVER',
                                    'PSS.HEADERS.SOMETIMES',
                                    'PSS.HEADERS.QUITE_OFTEN',
                                    'PSS.HEADERS.OFTEN'
                                ],
                                values: [
                                    { label: "PSS.LABELS.PSS1", id:"pss1"},
                                    { label: "PSS.LABELS.PSS2", id:"pss2"},
                                    { label: "PSS.LABELS.PSS3", id:"pss3"},
                                    { label: "PSS.LABELS.PSS4", id:"pss4"},
                                    { label: "PSS.LABELS.PSS5", id:"pss5"},
                                    { label: "PSS.LABELS.PSS6", id:"pss6"},
                                    { label: "PSS.LABELS.PSS7", id:"pss7"},
                                    { label: "PSS.LABELS.PSS8", id:"pss8"},
                                    { label: "PSS.LABELS.PSS9", id:"pss9"},
                                    { label: "PSS.LABELS.PSS10", id:"pss10"},
                                ],
                                validators: [Validators.required]
                            }
                        }
                    ]
                }]
            }
        }, */
        user: {
            formStructure: {
                formType: 'simple',
                structure: [{
                    id: 'user',
                    title: 'SECTION_TITLE.USER',
                    children: [
                    {
                        id: 'user_role',
                        title: 'INPUT_GROUP_TITLE.USER_ROLE',
                        inputs: [
                            {
                                id: 'role',
                                type: 'select',
                                length: 6,
                                label: 'ROLE',
                                validators: [Validators.required],
                                values: [
                                    {
                                        id: 'superuser',
                                        label: 'SUPER_USER'
                                    },{
                                        id: 'localadmin',
                                        label: 'LOCAL_ADMIN'
                                    },{
                                        id: 'doctor',
                                        label: 'DOCTOR'
                                    },{
                                        id: 'nurse',
                                        label: 'NURSE'
                                    },{
                                        id: 'oss',
                                        label: 'OSS'
                                    },{
                                        id: 'secretary',
                                        label: 'SECRETARY'
                                    }
                                ]
                            }
                        ],
                    },
                    {
                        id: 'personal_data',
                        title: 'INPUT_GROUP_TITLE.PERSONAL_DATA',
                        requiredToSaveDraft: 'name',
                        inputs: [
                        {
                            id: 'name',
                            type: 'input',
                            length: 2,
                            label: 'NAME',
                            validators: [
                            Validators.required,
                            ],
                            inputRule: 'capitalize',
                            placeholder: 'Mario',
                        },{
                            id: 'lastname',
                            type: 'input',
                            length: 2,
                            label: 'SURNAME',
                            validators: [
                            Validators.required,
                            ],
                            inputRule: 'capitalize',
                            placeholder: 'Rossi',
                        },{
                            id: 'gender',
                            type: 'select',
                            length: 2,
                            label: 'SEX',
                            validators: [
                            Validators.required,
                            ],
                            values: [{
                            id: 'm',
                            label: 'MALE'
                            },{
                            id: 'f',
                            label: 'FEMALE'
                            }]
                        },{
                            id: 'birthdate',
                            type: 'date',
                            length: 2,
                            label: 'BIRTHDATE',
                            validators: [],
                        },{
                            id: 'birthplace',
                            type: 'input',
                            length: 2,
                            label: 'BIRTH_PLACE',
                            inputRule: 'capitalize',
                            validators: []
                        },{
                            id: 'birthprovince',
                            type: 'input',
                            length: 2,
                            label: 'BIRTH_PROVINCE',
                            inputRule: 'capitalize',
                            validators: []
                        },{
                            id: 'taxCode',
                            type: 'input',
                            length: 2,
                            label: 'TAX_CODE',
                            inputRule: 'upperCase',
                            validators: [
                            Validators.pattern(CustomRegExp.TAX_CODE),
                            ]
                        },{
                            id: 'language',
                            type: 'select',
                            length: 2,
                            label: 'LANGUAGE',
                            validators: [
                            Validators.required,
                            ],
                            values: [{
                                id: 'it',
                                label: 'ITALIAN'
                                },{
                                id: 'en',
                                label: 'ENGLISH'
                            }]
                        }
                        ],
                    },
                    {
                        title: 'CONTACTS',
                        id: 'contacts',
                        inputs: [
                        {
                            id: 'email',
                            type: 'input',
                            disableOnEdit: true,
                            length: 2,
                            label: 'EMAIL',
                            validators: [Validators.pattern(CustomRegExp.EMAIL), Validators.required],
                            placeholder: 'email@mail.com',
                            inputRule: 'noSpaces'
                        },
                        {
                            id: 'mobilePhoneNumber',
                            type: 'input',
                            length: 2,
                            label: 'MOBILE_NUMBER',
                            validators: [Validators.pattern(CustomRegExp.MOBILE_PHONE_NUMBER)],
                            placeholder: '',
                            inputRule: 'number',
                        },
                        {
                            id: 'phoneNumber',
                            type: 'input',
                            length: 2,
                            label: 'LANDLINE_PHONE',
                            validators: [Validators.pattern(CustomRegExp.MOBILE_PHONE_NUMBER)],
                            inputRule: 'number',
                            placeholder: '',
                        },
                        ],
                    },
                    {
                        title: 'INPUT_GROUP_TITLE.ASSOCIATED_ORGANIZATION',
                        id: 'associated_organization',
                        disabledOn: {
                            childId: 'user_role',
                            inputId: 'role',
                            value: 'superuser'
                        },
                        inputs: [
                            {
                                id: 'customerId',
                                type: 'autocomplete',
                                length: 3,
                                label: 'CUSTOMER',
                                validators: [Validators.required],
                                suggestedItems: this.customerService.loadCustomers$().pipe(
                                    map((data) => (data || []).map((item: any) => ({id: item.id, label: item.businessName})))
                                )
                            },{
                                id: 'organizationId',
                                type: 'select',
                                length: 3,
                                label: 'ORGANIZATION',
                                values: [],
                                validators: [
                                Validators.required,
                                ],
                                disableTranslation: true,
                                loadOn: 'customerId',
                                loader: (customerId: number | string | undefined):Observable<{id: string, label: string}[]> => this.facilitiesService.getOrganization$(customerId as string).pipe(map((data) => data.map((item) => ({id: item.id, label: item.name}))))
                            }
                        ],
                    },
                    ],
                }],
            }
        },
        deviceLink: {
            formStructure: {
                formType: 'simple',
                structure: [{
                    id: 'deviceLink',
                    title: 'SECTION_TITLE.LINK_DEVICE',
                    children: [
                        {
                            title: 'INPUT_GROUP_TITLE.LINK_DEVICE',
                            id: 'deviceLink',
                            inputs: [
                            {
                                id: 'building',
                                type: 'select',
                                length: 6,
                                label: 'BUILDING',
                                disableTranslation: true,
                                loader: ():Observable<{id: string, label: string}[]> => this.facilitiesService.getBuildings$().pipe(
                                    map((data) => data.map((item) => ({id: item.id!, label: item.name!})))
                                ),
                                values: []
                            },{
                                id: 'unit',
                                type: 'select',
                                length: 6,
                                label: 'UNIT',
                                disableTranslation: true,
                                loader: (buildingId: string | number | undefined):Observable<{id: string, label: string}[]> => buildingId ? this.facilitiesService.getUnits$(buildingId as string).pipe(
                                    map((data) => data.map((item) => ({id: item.id!, label: item.name!})))
                                ) : of([]),
                                values: []
                            },{
                                id: 'room',
                                type: 'select',
                                length: 6,
                                label: 'ROOM',
                                disableTranslation: true,
                                loader: (unitId: string | number | undefined):Observable<{id: string, label: string}[]> => unitId ? this.facilitiesService.getRooms$(unitId as string).pipe(
                                    map((data) => data.map((item) => ({id: item.id!, label: item.name!})))
                                ) : of([]),
                                values: []
                            }
                            ],
                        }
                    ],
                }]
            }
        },
    }

}

interface IFormResourceStructure {
    [key: string]: {
        formStructure: IFormData;
        showGenerateDataButton?: boolean;
        dynamicSection?: IFormStructure
    };
}