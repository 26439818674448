import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError, from, switchMap, combineLatest, throwError, Observable, map, take, mergeMap, of, concatMap } from 'rxjs';
import { Store } from '@ngrx/store';

//import { loadUserSuccess, login, updateUserDevice, updateUserDeviceError, updateUserDeviceSuccess } from 'src/app/state/actions/user.actions';
//import { Device } from '@capacitor/device';
import { LoggerService } from './utils/logger.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from './utils/toast.service';
//import { IonicColors } from 'src/app/shared/enums/ionic-colors.enum';
import { MenuController } from '@ionic/angular';
//import { DeviceSpecs, LocalUserDevices, UserDevices } from 'src/app/shared/models/user-devices';
import { UserService } from './user.service';
import { UserCredentials } from '../../shared/models/user-credentials';
import { User } from '../../shared/models/user';
import { environment } from '../../../environments/environment';
import { loadUserSuccess, login } from '../../state/actions/user.actions';
import { LoginResponse } from '../../shared/models/login-response';
import { IonicColors } from '../../shared/enums/ionicColors.enum';
import { RequestsService } from './utils/requests.service';

@Injectable({
  providedIn: 'any'
})
export class AuthService {

  constructor(
    private loggerService: LoggerService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private router: Router,
    private store: Store,
    private menuController: MenuController,
    private _http: HttpClient,
    private userService: UserService,
    private requestsService: RequestsService
  ) {}

  public getServiceStatus$(): Observable<boolean> {
    return this._http.get<{message: string}>(`${environment.apiUrl('auth')}/auth/ping`).pipe(
      take(1),
      map((val: {message: string}) => !!val.message),
      catchError(err => {
        this.loggerService.error('Error on .../ping')
        return of(false);
      })
    );
  }

  /**
   * @description Funzione che si occupa di fare la chiamata di login
   * @param userCredentials 
   * @param callbackFn 
   */
  public login(userCredentials: UserCredentials, callbackFn: Function) {
    this.store.dispatch(login(userCredentials));
    this._http.post<LoginResponse>(
      `${environment.apiUrl('auth')}/auth`,
      userCredentials,
      { withCredentials: true }
    ).pipe(
        take(1),
        tap((response: LoginResponse) => {
          localStorage.setItem('ACCESS_TOKEN', response.access_token || "");
          this.initializeLanguage(navigator.language);
          this.toastService.showToast(this.translateService.instant('GENERAL.SUCCESS.LOGGED_SUCCESFULLY'), IonicColors.GREEN);
          this.loggerService.debug('Login OK 🟢');
          //this.store.dispatch(loadUserSuccess({response}));
        }),
        switchMap((loginResponse) => this.userService.getUser$(loginResponse.userId)),
        tap((user) => {
          localStorage.setItem('USER', JSON.stringify(user));
          this.translateService.use(user.language || "it");
          this.loggerService.debug('Get User OK 🟢');
          this.router.navigate([`/wita/${['admin', 'superuser'].includes(user.role) ? 'customers' : 'patients'}`]);
        }),
        catchError(err => {
          localStorage.clear();
          this.router.navigate(['/wita']);
          callbackFn(err)
          throw err;
        })
        ).subscribe()
  }

  /**
   * 
   * @description Aggiorna le info del device associato all'utente, inviando: deviceId, deviceInfo (Json stringified), token (firebase token), connect (indica quando viene eseguita una connessione effettiva da parte dell'utente, viene passato a false quando c'è soltanto un aggiornamento delle info del device o del token)
   */
  /* updateUserDevice$(connect: boolean = false){
    this.store.dispatch(updateUserDevice());
    return combineLatest([from(Device.getInfo()), from(Device.getId())])
    .pipe(
      switchMap(([deviceInfo, deviceId]) => this._http.post(`${environment.apiUrl}/users/device-token-update`, {
        deviceId: deviceId.identifier,
        deviceInfo: JSON.stringify(deviceInfo),
        connect,
        token: 'todoreplacewithfirebasetoken'
      })),
      tap(() => this.store.dispatch(updateUserDeviceSuccess())),
      catchError((err) => {
        this.store.dispatch(updateUserDeviceError());
        return throwError(() => err);
      })
    );
  } */

  /**
   * @description Chiamata per la disconnesione di TUTTI i devices collegati allo user
   */
  disconnectAllUserDevices$() {
    return this._http.post(`${environment.apiUrl('auth')}/auth/request-action`, { 'type': '30', 'mode': 'all' });
  }

  /**
   * @description Disconnette un singolo device
   * @returns 
   */
  disconnectDevice$(deviceId: string, token: string) {
    const body = {
      connect: true,
      deviceId,
      unbindExistingDevice: true,
      unbindDeviceId: deviceId,
      token
    };
    return this._http.post(`${environment.apiUrl('auth')}/users/device-token-update`, body);
  }

  /**
   * @description Chiamata per l'eliminazione dell'account
   */
  deleteAccount$() {
    return this._http.delete(`${environment.apiUrl('auth')}/user-info/account`);
  }

  /**
   * @description Notifica al server la disconnessione dell'utente dal dispositivo e poi esegue il logout
   */
  logout$(){
    return this._http.post<any>(
      `${environment.apiUrl('auth')}/auth/logout`, {}, { withCredentials: true }
    ).pipe(
        take(1),
        tap(() => {
          localStorage.clear();
          this.router.navigateByUrl('auth');
        })
    )
    /* return from(Device.getId()).pipe(
      // serve per inviare al server la disconnessione da un dispositivo, in modo anche da interrompere la ricezione delle notifiche
      switchMap((deviceId) => this._http.post(`${environment.apiUrl}/auth/logout`, { deviceId: deviceId.identifier })),
      tap(() => {
        this.toastService.showToast(this.translateService.instant('GENERAL.SUCCESS.LOGGED_OUT_SUCCESFULLY'), IonicColors.GREEN);
        this.translateService.use(this.translateService.defaultLang);
        this.appLogout();
      })
    ); */
  }

  public sendEmail$(email: {email: string, type: string}) {
    const headers = new HttpHeaders({
        'Content-Type': 'application/json'
    });
    const options = { headers: headers };
    const url = `${environment.apiUrl('auth')}/auth/request`;
    return this._http.post(url, email, options).pipe(
      take(1),
      catchError((err) => {
        this.loggerService.error(err);
        throw err;
      })
    );
  }

  public sendForgotPwMail(email: {email: string}) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const options = { headers: headers };
    const url = `${environment.apiUrl('users')}/users/forgot-password`;
    return this._http.post(url, email, options).pipe(
      take(1),
      catchError((err) => {
        this.loggerService.error(err);
        throw err;
      })
    );
  }

  public setPassword$(tokenAndPassword: {token: string, password: string}) {
    const headers = new HttpHeaders({
        'Content-Type': 'application/json'
    });
    const options = { headers: headers };
    const url = `${environment.apiUrl('auth')}/users/activate`;
    return this._http.post(url, tokenAndPassword, options).pipe(
      take(1),
      catchError((err) => {
        this.loggerService.error(err);
        throw err;
      })
    );
  }

  public resetPassword$(tokenAndPassword: {token: string, newPassword: string}) {
    const headers = new HttpHeaders({
        'Content-Type': 'application/json'
    });
    const options = { headers: headers };
    const url = `${environment.apiUrl('users')}/users/reset-password`;
    return this._http.post(url, tokenAndPassword, options).pipe(
      take(1),
      catchError((err) => {
        this.loggerService.error(err);
        throw err;
      })
    );
  }

  /**
   * @description Sulla base della presenza dell'ACCES_TOKEN sul localStorage torna true o false
   */
  public isLoggedIn(){
    return localStorage.getItem('ACCESS_TOKEN') !== null;
  }

  /**
   * @description effettua il logout dall'app senza notificare la disconnessione dal dispositivo al server (ad esempio nel caso di ricezione di un error unauthorized)
   */
  public appLogout(){
    localStorage.removeItem('ACCESS_TOKEN');
    this.menuController?.close();
    this.router.navigate(['/auth']);
  }

  /**
   * @description Imposto le traduzioni sulla base della lingua dello user o, se non esistente, del browser, di default metto 'it'
   * @param browserLanguage 
   */
  private initializeLanguage(browserLanguage: string) {
    if (['it', 'en', 'fr'].includes(this.requestsService.getCurrentUser()?.language || "")) {
      this.translateService.use(this.requestsService.getCurrentUser()!.language);
    } else {
        if (browserLanguage.includes('en-')) {
          this.translateService.use('en');
        } else if (browserLanguage.includes('fr-')) {
          this.translateService.use('fr');
        } else {
          this.translateService.use('it');
        }
    }
  }

}