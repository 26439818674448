import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { environment } from '../../../environments/environment';
import { Observable, catchError, of, take, tap } from "rxjs";
import { createPatientError, createPatientsuccess, loadingPatients, loadingPatientsError } from "../../state/actions/patients.actions";
import { IPatient } from "../../shared/models/be/patients";
import { IAdmission } from "../../shared/models/be/encounter";
import { RequestsService } from "./utils/requests.service";

@Injectable({
    providedIn: 'any'
  })
export class AdmissionService {
  
    constructor(
      private store: Store,
      private _http: HttpClient,
      private requestsService: RequestsService
    ) {}

    public getEncounter$(patientId: string) {
        const currentUser = this.requestsService.getCurrentUser();
        return this._http.get<IAdmission>(
            `${environment.apiUrl('ehr')}/ehr/admission/${patientId}?customerId=${currentUser?.customer?.id}`
            ).pipe(
            take(1),
            tap((encounter: IAdmission) => {
                //this.store.dispatch(loadCustomersSuccess({customers}))
            }),
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        )
    }

    public createEncounter$(encounter: IAdmission): Observable<any> {
        const currentUser = this.requestsService.getCurrentUser();
        encounter.organizationId = currentUser?.organization?.id || '';
        return this._http.post(
            `${environment.apiUrl('ehr')}/ehr/admission?customerId=${currentUser?.customer?.id}`,
            encounter
        ).pipe(
            take(1),
            tap(() => {
                //this.store.dispatch(createPatientsuccess({encounter}))
            }),
            catchError(err => {
                this.store.dispatch(createPatientError())
                throw `error in source. Details:  + ${err}`
            })
        );
    }

    public editEncounter$(encounter: IAdmission): Observable<any> {
        const currentUser = this.requestsService.getCurrentUser();
        encounter.organizationId = currentUser?.organization?.id || '';
        return this._http.put(
            `${environment.apiUrl('ehr')}/ehr/admission/${encounter.id}?customerId=${currentUser?.customer?.id}`,
            encounter
        ).pipe(
            take(1),
            tap(() => {
                //this.store.dispatch(createPatientsuccess({patient}))
            }),
            catchError(err => {
                this.store.dispatch(createPatientError())
                throw `error in source. Details:  + ${err}`
            })
        );
    }
}