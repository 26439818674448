import { Injectable } from "@angular/core";
import { ITableConfigParams, ITableConfigParamsFilter, ITableData, ITableDataData, ITableGeneralConfigParams } from "../table.component";
import { TranslateService } from "@ngx-translate/core";
import { from, mergeMap, of, tap } from "rxjs";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { TableService } from "../table.service";

@Injectable({
    providedIn: 'any'
  })
export class FilterInitializer {
  
    constructor(
        private translateService: TranslateService,
        private tableService: TableService
    ) {}

    initializeFiltersLoader(config: ITableConfigParams, dataSource: MatTableDataSource<ITableData, MatPaginator>) {
        let tablePreferences: ITableConfigParamsFilter[] = [];
        if (localStorage.getItem('TABLE_PREFERENCES')) {
            tablePreferences = JSON.parse(
                localStorage.getItem('TABLE_PREFERENCES')!
            )[config.id]?.filters || [];
        };

        from(config.filters || []).pipe(
            tap((filter) => {
                // ATTENZIONE, Per effetturare modifiche su filter è necessario cambiare ogni singolo parametro che si vuole cambiare e non tutto l'oggetto, NON SI PUO' FARE filter = customFilter, LA MODIFICA NON VERREBBE PRESA
                filter.selectedValue = tablePreferences.find((preference) => preference.id === filter.id)?.selectedValue || filter.selectedValue;
                filter.options = tablePreferences.find((preference) => preference.id === filter.id)?.options || filter.options;
            }),
            mergeMap((filter) => {
                if (!filter.options.length && !!filter.loader) {
                    if (filter.dynamicLoadOn) {
                        const upperLevelSelectedValueId = config.filters?.find((currFilter) => currFilter.id === filter.dynamicLoadOn)?.selectedValue as string || null
                        return upperLevelSelectedValueId ? filter.loader(upperLevelSelectedValueId).pipe(
                            tap((data) => {
                                filter.options = [{id: 'all', name: this.translateService.instant('TABLE.FILTER.LABEL.ALL')}, ...data as {id: string; name: string}[]];
                            })
                        ) : of(true); 
                    } else {
                        return filter.loader().pipe(
                            tap((data) => {
                                filter.options = [{id: 'all', name: this.translateService.instant('TABLE.FILTER.LABEL.ALL')}, ...data as {id: string; name: string}[]];
                            })
                        );                        
                    }
                } else if (filter.type === 'select' && !filter.options.some((opt) => opt.id === 'all')) {
                    // Altrimenti se il tipo di filtro è una select aggiungo un campo 'all' per permettere di "cancellare" il filtro
                    filter.options = [{id: 'all', name: this.translateService.instant('TABLE.FILTER.LABEL.ALL')}, ...filter.options]
                }
                return of(true)
            }),
            tap(() => {
                const savedFilters = config.filters?.filter((filter) => tablePreferences.some((pref) => pref.id === filter.id))
                if (savedFilters) {
                    this.tableService.updateTablePreferences(config.id, 'filters', savedFilters)
                    dataSource.filter = JSON.stringify({ filters: savedFilters || []});                    
                }
            })
        ).subscribe();

    }
    
    /**
     * @description Funzione che sovrascrive la logica del filtro di Material Datatable per far fronte alle mie esigenze
     */
    filterLogic() {
        let filterFunction = function (data: ITableData, filters: string): boolean {
            let generalFilterData: ITableGeneralConfigParams = JSON.parse(filters);
            let nameSearch = () => {
                let found: boolean[] = [];
                (generalFilterData.filters || []).forEach((filter) => {
                    if (filter.type === 'multiSelect') {
                        found.push(((filter.selectedValue as string[]) || []).includes((data.data as ITableDataData)[filter.id] as string))
                    } else if (filter.type === 'select') {
                        const selectedOption = filter.options.find((opt) => opt.id === filter.selectedValue);
                        if (selectedOption?.id === 'all') {
                            found.push(true);
                        } else if (filter.checkExistance) {
                            found.push(!!(data.data as ITableDataData)[filter.id] === selectedOption?.value);
                        } else {
                            found.push(((data.data as ITableDataData)[filter.id] as string)?.toLowerCase() === selectedOption?.name?.toLowerCase());
                        }
                    }
                });

                generalFilterData.searchParams && found.push(generalFilterData.searchParams.tableFields.some((fieldId) => ((data.data as ITableDataData)[fieldId] as string)?.toLowerCase()?.includes(generalFilterData.searchParams!.value.toLowerCase())));

                if (!generalFilterData.filters?.length && !generalFilterData.searchParams) {
                    return true
                } else {
                    return !!found.length && found.every((value) => value === true);
                }
            };
            return nameSearch();
        };
        return filterFunction;
    }

}