import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ModalContainerComponent } from '../../../shared/components/modal-container/modal-container.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IBreadcrumbs } from '../../../shared/components/breadcrumbs/breadcrumbs.component';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerService } from '../../../core/services/customer.service';
import { FacilitiesService } from '../../../core/services/facilities.service';
import { combineLatest, Observable, of, tap } from 'rxjs';
import { IOrganization } from '../../../shared/models/be/organization';
import { IFormChangeEmitter } from '../../../shared/components/form/form.component';
import { RequestsService } from '../../../core/services/utils/requests.service';
import { UserService } from '../../../core/services/user.service';
import { SensorService } from '../../../core/services/sensors.service';
import { PatientService } from '../../../core/services/patient.service';
import { AuthService } from '../../../core/services/auth.service';
import { ToastService } from '../../../core/services/utils/toast.service';
import { IonicColors } from '../../../shared/enums/ionicColors.enum';
import { IUser } from '../../../shared/models/be/user';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.page.html',
  styleUrls: ['./settings.page.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    ModalContainerComponent,
    MatGridListModule,
    MatFormFieldModule,
    TranslateModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class SettingsPage  implements OnInit {

  public currentLanguage: string = 'it';

  public formStatus: IFormChangeEmitter = {
    canSaveDraft: true,
    canSaveData: true,
  }

  public breadcrumbsList: IBreadcrumbs[] = [{
    icon: 'settings',
    routerLink: '',
    label: 'SETTINGS'
  }]

  public settingsData: ISettingsData[] = []

  constructor(
    private translate: TranslateService,
    private customerService: CustomerService,
    private facilitiesService: FacilitiesService,
    private requestsService: RequestsService,
    private userService: UserService,
    private sensorService: SensorService,
    private patientService: PatientService,
    private authService: AuthService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.currentLanguage = this.translate.currentLang;
    this.settingsData.push({
      id: 'language',
      label: 'LANGUAGE',
      rowSpan: 2,
      inputs: [{
        id: 'language',
        type: 'select',
        length: 2,
        label: 'LANGUAGE',
        selectedValue: this.translate.currentLang,
        values: [{
          id: 'it',
          label: 'ITALIAN'
        },{
          id: 'en',
          label: 'ENGLISH'
        }],
        onSelect: (resourceId: string) => this.changeLanguage(resourceId)
      }]
    })
    combineLatest([
      this.customerService.loadCustomers$(),
      this.requestsService.getCurrentUser()?.customer?.id ? this.facilitiesService.getOrganization$(this.requestsService.getCurrentUser()!.customer!.id+"") : of([])
    ]).pipe(
      tap(([customersData, organizations]) => {
        this.settingsData.push({
          id: 'organization',
          label: 'ORGANIZATION',
          rowSpan: 2,
          inputs: [{
            id: 'customer',
            type: 'select',
            length: 2,
            label: 'CUSTOMER',
            selectedValue: this.requestsService.getCurrentUser()?.customer?.id+"" || null,
            disableTranslation: true,
            values: customersData.map((customer) => ({
              id: customer.id+"",
              label: customer.businessName
            })),
            onSelect: (resourceId: string) => this.loadOrganizations(resourceId)
          },{
            id: 'organization',
            type: 'select',
            length: 2,
            selectedValue: this.requestsService.getCurrentUser()?.organization?.id || null,
            label: 'ORGANIZATION',
            disableTranslation: true,
            values: (organizations || []).map((org) => ({
              id: org.id,
              label: org.name
            }))
          }]
        })
      })
    ).subscribe()
  }

  reloadServicesStatus() {
    combineLatest([
      this.userService.getServiceStatus$(),
      this.facilitiesService.getServiceStatus$(),
      this.sensorService.getServiceStatus$(),
      this.patientService.getServiceStatus$(),
      this.authService.getServiceStatus$(),
      this.customerService.getServiceStatus$()
    ]).pipe(
      tap(([userStatus, facilitiesStatus, sensorStatus, ehrStatus, authService, customerStatus]) => {
        this.settingsData.push({
          id: 'developer',
          label: 'DEVELOPER',
          rowSpan: 7,
          inputs: [{
            id: 'servicesStatus',
            type: 'status',
            length: 6,
            label: 'SERVICES_STATUS',
            values: [{
              id: 'user',
              label: 'USER',
              status: userStatus
            },{
              id: 'facilities',
              label: 'FACILITIES',
              status: facilitiesStatus
            },{
              id: 'sensor',
              label: 'SENSOR',
              status: sensorStatus
            },{
              id: 'ehr',
              label: 'EHR',
              status: ehrStatus
            },{
              id: 'auth',
              label: 'AUTH',
              status: authService
            },{
              id: 'customer',
              label: 'CUSTOMER',
              status: customerStatus
            }]
          }]
        })
      })
    ).subscribe();
  }

  changeLanguage(lang: string) {
    this.translate.use(lang);
  }

  loadOrganizations(customerId: string) {
    this.facilitiesService.getOrganization$(customerId).pipe(
      tap((data) => {
        this.settingsData.find((setting) => setting.id === 'organization')!.inputs.find((input) => input.id === 'organization')!.values = data.map((org) => ({
          id: org.id,
          label: org.name
        }))
      })
    ).subscribe();
  }

  submitData() {
    this.settingsData.forEach((setting) => {
      switch (setting.id) {
        case 'organization':
          let orgObject: {[key: string]: {id: string | number, businessName: string}} = {};
          setting.inputs.forEach((input) => {
            orgObject[input.id] = {
              id: input.values.find((value) => value.id === input.selectedValue)?.id || "",
              businessName: input.values.find((value) => value.id === input.selectedValue)?.label || ""
            }
          })
          const currentUser = !!localStorage.getItem('USER') && JSON.parse(localStorage.getItem('USER')!)
          if (currentUser) {
            (currentUser as IUser).customer = orgObject['customer'] as { id: number; businessName: string; };
            (currentUser as IUser).organization = orgObject['organization'] as { id: string; businessName: string; };
            localStorage.setItem('USER', JSON.stringify(currentUser));
            this.requestsService.saveCurrentUser(currentUser)
          }
          break;
        default:
          break;
      }
    });
    this.toastService.showToast(this.translate.instant('GENERAL.SUCCESS.CHANGES_SUCCESSFULLY'), IonicColors.GREEN)
  }

  get tableRowSpan(): number {
    return this.settingsData.reduce((prev, curr) => prev + curr.rowSpan,0)+1
  }

}

interface ISettingsData {
  id: string,
  label: string,
  rowSpan: number,
  inputs: {
    id: string,
    type: 'select' | 'status',
    length: number,
    label: string,
    selectedValue?: string | null,
    disableTranslation?: boolean,
    values: {
      id: string,
      label: string,
      status?: boolean
    }[],
    onSelect?: (resourceId: string) => void;
  }[]
}
