import { Component, inject } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import {
  Subject,
  tap,
  take,
  catchError,
  map,
} from 'rxjs';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  IOptionOutput,
  ICtaOutput,
  ITableColumns,
  ITableConfigParams,
  ITableData,
  TableComponent,
  ITableConfigParamsFilter,
} from '../../../shared/components/table/table.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { PatientService } from '../../../core/services/patient.service';
import { ToastService } from '../../../core/services/utils/toast.service';
import { IonicColors } from '../../../shared/enums/ionicColors.enum';
import { FacilitiesService } from '../../../core/services/facilities.service';
import { FiltersList } from '../../../shared/components/table/filtersList';

@Component({
  selector: 'customers',
  templateUrl: 'patients.page.html',
  styleUrls: ['patients.page.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    MatGridListModule,
    MatIconModule,
    TableComponent,
    TranslateModule,
    RouterModule
  ]
})
export class PatientsPage {
  public tableColumns: ITableColumns[] = [
    /* { label: 'ID', id: 'id', showCol: true }, */
    { label: 'PATIENT', id: 'patient', showCol: true, cannotHide: true },
    { label: 'TAX_CODE', id: 'taxCode', showCol: false },
    { label: 'PATIENT_STATUS', id: 'patientStatus', showCol: true },
    { label: 'ADMISSION_DATE', id: 'admissionDate', showCol: true },
    { label: 'DISCHARGE_DATE', id: 'dischargeDate', showCol: true },
    { label: 'ORGANIZATION', id: 'organization', showCol: false },
    { label: 'BUILDING', id: 'building', showCol: true },
    { label: 'UNIT', id: 'unit', showCol: true },
    { label: 'ROOM', id: 'room', showCol: true },
    { label: 'BED', id: 'bed', showCol: true }
  ];

  public tableData: ITableData[] = [];

  public tableConfigParams: ITableConfigParams = {
    id: 'patients',
    referenceLabel: 'PATIENTS',
    contactInfo: {
      title: 'patient',
      subtitles: [{
        value: 'email'
      },{
        value: 'telephone',
        prefix: 'Tel:'
      },{
        value: 'phoneNumber',
        prefix: 'Mob:'
      },{
        value: 'doctorName',
        prefix: 'Medico curante:'
      }],
    },
    filters: [
      this.filtersList.getFilterById('patientStatus'),
      this.filtersList.getFilterById('building'),
      this.filtersList.getFilterById('unit'),
      this.filtersList.getFilterById('room'),
    ].filter((filter) => Boolean(filter)) as ITableConfigParamsFilter[],
    searchBy: ['patient'],
    defaultSort: 'patient'
  };
  
  /**
   * Definisce l'altezza della form in numero di righe, di default 1, viene poi valorizzato correttamente nel costruttore
   */
  public patientFormRows: number = 1;
  public admissionFormRows: number = 1;
  public medicalHistoryFormRows: number = 1;
  
  //private monitoringService: MonitoringService = inject(MonitoringService)

  private store = inject(Store);
  //elements: MonitoringTile[]
  loadingElements: boolean;
  hasError: boolean;
  public isDataLoading: boolean = false;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public patientService: PatientService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private facilitiesService: FacilitiesService,
    private filtersList: FiltersList
  ) {
    //this.elements = []
    this.loadingElements = false;
    this.hasError = false;
  }

  ionViewDidEnter() {
    this.loadElements();
  }

  private destroyed$ = new Subject();

  ngOnInit() {
    this.loadPatientsData();
  }

  /**
   * @description Funzione che va ad aggiornare i dati della table in pagina
   */
  loadPatientsData(refreshData: boolean = false) {
    if (this.isDataLoading) {
      return;
    }
    this.isDataLoading = true;
    this.patientService.getPatients$().pipe(
      take(1),
      tap((patientsData) => {
        this.tableData = patientsData?.map((patient) => {
          return {
            data: {
              ...patient,
              patient: `${patient.name} ${patient.surname}`,
              doctorName: `${patient.doctor.name} ${patient.doctor.surname}`,
              building: patient.facilities?.building?.name,
              unit: patient.facilities?.unit?.name,
              room: patient.facilities?.room?.name,
              bed: patient.facilities?.bed?.name,
              organization: patient.facilities?.organization?.name,
              patientStatus: patient.status //this.translateService.instant('GENERAL.STATUS.'+patient.status.toUpperCase())
            },
            options: [
              {
                action: patient.facilities?.room?.name ? 'edit_admission' : 'add_admission',
                icon: 'single_bed',
                label: patient.facilities?.room?.name ? 'EDIT_ADMISSION' : 'ADMIT',
              },
              patient.status === 'active' && {
                action: 'discharge',
                icon: 'close',
                label: 'DISCHARGE_PATIENT',
              },
              {
                action: 'edit',
                icon: 'edit',
                label: 'EDIT_PATIENT',
              },
              {
                action: 'delete',
                icon: 'delete',
                label: 'DELETE_PATIENT',
              },
            ].filter((x) => x),
          } as ITableData;
        });
        this.isDataLoading = false;
        refreshData && this.toastService.showToast(this.translateService.instant('GENERAL.SUCCESS.DATA_REFRESHED'), IonicColors.GREEN);
      }),
      catchError(err => {
        this.isDataLoading = false;
        refreshData && this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.LOADING_DATA'), IonicColors.RED);
        throw err
      })
    ).subscribe()
  }

  /**
   * @description Funzione invocata al click di una opzione della table
   * @param event Viene passato un oggetto contenente l'id delle risorsa in oggetto e l'action invocata
   */
  onOptionAction(event: IOptionOutput) {
    switch (event.action) {
      case 'edit_admission':
        this.router.navigate([`/wita/patients/admission/${event.resource.id}`], { state: { additionalData: event.resource, breadCrumbSubject: event.resource['patient']}});
        break;        
      case 'add_admission':
        this.router.navigate([`/wita/patients/admission`], { state: { additionalData: event.resource, breadCrumbSubject: event.resource['patient']}});
        break;
      case 'edit':
        this.router.navigate([`/wita/patients/edit/${event.resource.id}`], { state: { breadCrumbSubject: event.resource['patient']}});
        break;
      case 'edit_medical_history':
        this.router.navigate([`/wita/patients/medical_history/${event.resource.id}`], { state: { breadCrumbSubject: event.resource['patient']}});
        break;
      case 'medical_history':
        this.router.navigate(['/wita/patients/medical_history'], { state: { breadCrumbSubject: event.resource['patient']}});
        break;
      case 'discharge':
        this.patientService.dischargePatient$(event.resource.id, event.resource.facilities?.bed?.id || undefined).pipe(
          tap(() => {
            this.toastService.showToast(this.translateService.instant('GENERAL.SUCCESS.RESOURCE_UPDATED_SUCCESFULLY'), IonicColors.GREEN)
            this.loadPatientsData();
          }),
          catchError(err => {
            this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.RESOURCE_UPDATE'), IonicColors.RED)
            throw err;
          })
        ).subscribe();
        break;
      case 'delete':
        this.patientService.deletePatient$(event.resource.id).pipe(
          tap(() => {
            this.toastService.showToast(this.translateService.instant('GENERAL.SUCCESS.RESOURCE_DELETED_SUCCESFULLY'), IonicColors.GREEN)
            this.loadPatientsData();
          }),
          catchError(err => {
            this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.RESOURCE_DELETE'), IonicColors.RED)
            throw err;
          })
        ).subscribe();
        break;
      default:
        this.router.navigate(['/wita/patients'], { fragment: event.action });
        break;
    }
  }

  /**
   * @description Funzione invocata al click di un button (Aggiungi risorsa, refresh dati, ecc...)
   * @param event Viene passato un oggetto contenente il tipo di action invocata
   */
  onCtaAction(event: ICtaOutput) {
    switch (event.action) {
      case 'add':
        this.router.navigate(['wita/patients/new']);
        break;
      case 'refresh':
        this.loadPatientsData(true);
        break;
      default:
        break;
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(1);
    this.destroyed$.complete();
  }

  loadElements() {
    //this.monitoringService.loadMonitoringElements()
  }

  handlePressElement(event: any) {
    //let element = this.elements.find(element => element.id === event);
    //this.router.navigate(['/wita/appMonitoring/monitoringRoot/sensors/bed', { id: element?.id }])
  }

  handleSelectElement(event: any) {
    //let element = this.elements.find(element => element.id === event);
    //console.log(element)
    console.log('Element selected');
  }
}
