<ion-content [fullscreen]="true" class="page-content" color="light">
  <mat-grid-list cols="9" rowHeight="36px" gutterSize="15" style="margin: 10px;">
    <ng-container *ngFor="let section of settingsData">
      <!-- Titolo della macrosezione -->
      <mat-grid-tile colspan="9" rowspan="1">
        <b style="color: #616161;">{{'PAGES.MOBILE_SETTINGS.'+section.label | translate}}</b>
      </mat-grid-tile>
      <mat-grid-tile *ngFor="let input of section.inputs" [colspan]="9" [rowspan]="1" style="overflow: visible;">
        <mat-form-field appearance="outline" style="height: 100%; width: 100%;">
          <mat-label>{{'FORM.LABELS.'+input.label | translate}}</mat-label>
          <!-- Forzato tipo any a malincuore a causa di errori di compilazione per via del fatto che values è presente solo in ISelectInput  -->
          <mat-select [(ngModel)]="input.selectedValue" (selectionChange)="input.touched = true" [disabled]="!$any(input).values.length">
            @for (item of $any(input).values; track item) {
              <mat-option [value]="item.id">{{input.disableTranslation ? item.label : ('FORM.LABELS.'+item.label | translate)}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
    </ng-container>
    <mat-grid-tile [colspan]="9" [rowspan]="1"></mat-grid-tile>
    <mat-grid-tile [colspan]="2" [rowspan]="1"></mat-grid-tile>
    <mat-grid-tile [colspan]="5" [rowspan]="1" style="overflow: visible;">
      <ion-button (click)="saveData()" style="width: 100%; height: 100%;">
        {{'GENERAL.BUTTONS.SAVE_AND_CLOSE' | translate}}
      </ion-button>
    </mat-grid-tile>
  </mat-grid-list>
</ion-content>