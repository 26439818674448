import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    CommonModule
  ],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => AvatarComponent)
  }]
})
export class AvatarComponent implements OnInit, ControlValueAccessor {
  file: string = '';
  defaultAvatar: string = `assets/files/avatarsDefault/${Math.floor(Math.random()*100)}.svg`

  constructor() { }

  ngOnInit() {}

  onFileChange(event: any) {
    const files = event.target.files as FileList;

    if (files.length > 0) {
      const _file = URL.createObjectURL(files[0]);
      this.file = _file;
      this.onChange(this.file);
      this.resetInput();   
    }
  }

  resetInput(){
    const input = document.getElementById('avatar-input-file') as HTMLInputElement;
    if(input){
      input.value = "";
    }
  }


  // STEP 3
  onChange = (value: any) => {
    console.log(value);
  };
  // STEP 4
  registerOnChange(fn: (value: any) => void): void {
      console.log('entra');
      this.onChange = fn;
  }
  writeValue(file: any) {
    console.log(file);
    this.file = file;
  }
  registerOnTouched(){}

}
