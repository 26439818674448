import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IBreadcrumbs } from '../../../shared/components/breadcrumbs/breadcrumbs.component';
import { catchError, combineLatest, take, tap } from 'rxjs';
import { IFormResource } from '../../../shared/components/form/form.component';
import { IonContent } from "@ionic/angular/standalone";
import { ActivatedRoute, RouterModule } from '@angular/router';
import { AlertsService } from '../../../core/services/alerts.service';
import { ModalContainerComponent } from '../../../shared/components/modal-container/modal-container.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { EventTypeCodes, eventTypeToData, INotificationData } from '../../../shared/models/be/notification';
import 'chartjs-adapter-luxon';
import { MatGridListModule } from '@angular/material/grid-list';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ICtaOutput, IOptionOutput, ITableColumns, ITableConfigParams, ITableConfigParamsFilter, ITableData, TableComponent } from '../../../shared/components/table/table.component';
import moment from 'moment';
import { ToastService } from '../../../core/services/utils/toast.service';
import { IonicColors } from '../../../shared/enums/ionicColors.enum';
import { FiltersList } from '../../../shared/components/table/filtersList';
import { FacilitiesService } from '../../../core/services/facilities.service';
import { IUnit } from '../../../shared/models/be/unit';

@Component({
  selector: 'app-settings',
  templateUrl: './alertsHistory.page.html',
  styleUrls: ['./alertsHistory.page.scss'],
  standalone: true,
  imports: [
    IonContent, 
    CommonModule,
    TranslateModule,
    RouterModule,
    ModalContainerComponent,
    MatExpansionModule,
    MatGridListModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    TableComponent
  ]
})
export class AlertsHistoryPage implements OnInit {
  @ViewChildren('chartCanvas') canvasRefs!: QueryList<ElementRef<HTMLCanvasElement>>;
  public breadcrumbsList: IBreadcrumbs[] = [{
    icon: 'query_stats',
    label: 'ALERTS_HISTORY'
  }]

  public tableRowSpan: number = 11;
  public isDataLoading: boolean = false;
  public resource?: IFormResource & {unitId: string; buildingId: string};
  public data: {
    id: string;
    name: string;
    alerts: INotificationData[];
    chartData?: any;
    isLoaded?: boolean;
  }[] = [];

  public tableColumns: ITableColumns[] = [
    /* { label: 'ID', id: 'id', showCol: true }, */
    { label: 'ALERT_DATE', id: 'alertDate', showCol: true },
    { label: 'MANAGED_AT', id: 'managedAt', showCol: true },
    { label: 'SEVERITY', id: 'severity', showCol: true },
    { label: 'BUILDING', id: 'building', showCol: false },
    { label: 'UNIT', id: 'unit', showCol: true },
    { label: 'ROOM', id: 'room', showCol: true },
    { label: 'MANAGED_BY', id: 'managedBy', showCol: true },
    { label: 'DEVICE', id: 'device', showCol: false },
  ];

  public tableData: ITableData[] = [];

  public tableConfigParams: ITableConfigParams = {
    id: "alertsHistory",
    referenceLabel: 'ALERTS_HISTORY',
    searchBy: [],
    hideAddBtn: true,
    filters: [],
    hideSearchBar: true
  };

  constructor (
    private alertService: AlertsService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private toastService: ToastService,
    private filtersList: FiltersList,
    private facilitiesService: FacilitiesService
  ) {}

  ngOnInit() {
    this.loadFilters();
    this.loadAlerts();
  }

  ngOnDestroy() {
  }

  ngAfterViewInit() {
    this.resource = this.route.snapshot.data['data'];
  }

  /**
   * @description Funzione che va ad aggiornare i dati della table in pagina
   */
  loadAlerts(refreshData: boolean = false, reloadOnlyOnStatusChange: boolean = false, timeFilter: 'last24h' | 'lastWeek' | 'lastMonth' = 'last24h') {
    if (refreshData && this.isDataLoading) {
      return;
    }
    this.isDataLoading = !reloadOnlyOnStatusChange && true;
    combineLatest([
      this.alertService.getAnalyticsAlerts$(timeFilter),
      this.facilitiesService.getUnits$()
    ]).pipe(
      take(1),
      tap(([alerts, units]) => {
        this.tableData = alerts.map((alert) => {
          return {
            data: {
              timestamp: null,
              alertDate: moment(alert.timestamp).format("DD/MM - HH:mm"),
              managedAt: alert.managedAt ? moment(alert.managedAt).format("DD/MM - HH:mm") : undefined,
              building: alert.facilities.building.name,
              unit: alert.facilities.unit.name,
              room: alert.facilities.room.name,
              managedBy: alert.managedBy,
              severity: this.translateService.instant(eventTypeToData[alert.eventType].translation),
              device: alert.deviceId
            },
            options: [].filter((x) => x),
            rowStyle: {
              bgColor: this.eventTypeToBgColor(alert.eventType)
            },
          } as ITableData;
        });
        this.loadFiltersData(units)
        this.isDataLoading = false;
        refreshData && this.toastService.showToast(this.translateService.instant('GENERAL.SUCCESS.DATA_REFRESHED'), IonicColors.GREEN);
      }),
      catchError(err => {
        this.isDataLoading = false;
        refreshData && this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.LOADING_DATA'), IonicColors.RED);
        throw err
      })
    ).subscribe()
  }

  eventTypeToBgColor(eventType: EventTypeCodes) {
    switch (eventType) {
      case '01':
        return 'rgb(238 134 125 / 15%)'
      case '02':
        return 'rgb(231 156 83 / 10%)'
      case '03':
        return 'rgb(231 156 83 / 10%)'
      case '04':
        return 'rgb(231 218 83 / 5%)'
      case '05':
        return 'rgb(231 218 83 / 5%)'
      case '06':
        return '#8080801f'
      case '07':
        return '#00800021'
      default:
        return 'unset'
    }
  }

  loadFiltersData(units: IUnit[]) {
    const unitsFilter = this.tableConfigParams.filters?.find((filter) => filter.id === 'unit');
    const severityFilter = this.tableConfigParams.filters?.find((filter) => filter.id === 'severity');
    if (unitsFilter) {
      unitsFilter.dontResetOnClear = true;
      unitsFilter.selectedValue = units.map((unit) => unit.id!);
      unitsFilter.options = [
        {id: 'all', name: this.translateService.instant('TABLE.FILTER.LABEL.ALL')},
        ...units.map((unit) => ({id: unit.id!, name: unit.name!}))
      ];
    }
    if (severityFilter) {
      severityFilter.options = [{
        id: '01',
        name: this.translateService.instant('GENERAL.EVENTS_LIST.FALL'),
        translateName: false,
      },{
        id: '02',
        name: this.translateService.instant('GENERAL.EVENTS_LIST.ROOM_IN'),
        translateName: false,
      },{
        id: '03',
        name: this.translateService.instant('GENERAL.EVENTS_LIST.ROOM_OUT'),
        translateName: false,
      },{
        id: '04',
        name: this.translateService.instant('GENERAL.EVENTS_LIST.BED_IN'),
        translateName: false,
      },{
        id: '05',
        name: this.translateService.instant('GENERAL.EVENTS_LIST.BED_OUT'),
        translateName: false,
      },{
        id: '06',
        name: this.translateService.instant('GENERAL.EVENTS_LIST.DEVICE_OFFLINE'),
        translateName: false,
      }]
    }
  }

  loadFilters() {
    !this.tableConfigParams.filters?.length && (this.tableConfigParams.filters = [
      this.filtersList.getFilterById('severity'),
      this.filtersList.getFilterById('unit'),
      this.filtersList.getFilterById('room'),
      this.filtersList.getFilterById('timestamp')
    ].filter((filter) => Boolean(filter)) as ITableConfigParamsFilter[])
  };

  reloadBaseOnTimeFilter(value: 'last24h' | 'lastWeek' | 'lastMonth') {
    this.loadAlerts(undefined, undefined, value)
  }


  /**
   * @description Funzione invocata al click di un button (Aggiungi risorsa, refresh dati, ecc...)
   * @param event Viene passato un oggetto contenente il tipo di action invocata
   */
  onCtaAction(event: ICtaOutput) {
    switch (event.action) {
      case 'refresh':
        this.loadAlerts(true);
        break;
      default:
        break;
    }
  }

}
