import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { environment } from '../../../environments/environment';
import { Observable, catchError, map, of, take, tap } from "rxjs";
import { IPatient } from "../../shared/models/be/patients";
import { Wound } from "../../shared/models/be/omnidermal.model";
import { LoggerService } from "./utils/logger.service";
import { RequestsService } from "./utils/requests.service";
import { GenericBeResponse } from "../../shared/models/beGeneral";

@Injectable({
    providedIn: 'any',
  })
export class PatientService {
  
    constructor(
      private store: Store,
      private _http: HttpClient,
      private loggerService: LoggerService,
      private requestsService: RequestsService
    ) {}

    public getServiceStatus$(): Observable<boolean> {
        return this._http.get<{message: string}>(`${environment.apiUrl('ehr')}/ehr/ping`).pipe(
            take(1),
            map((val: {message: string}) => !!val.message),
            catchError(err => {
            this.loggerService.error('Error on .../ping')
            return of(false);
            })
        );
    }

    /**
     * @description Carica la lista dei pazienti nello store e li ritorna al subscribe
     */
    public getPatients$(): Observable<IPatient[]> {
        const currentUser = this.requestsService.getCurrentUser();
        return this._http.get<{patients: IPatient[]}>(
        `${environment.apiUrl('ehr')}/ehr/patients?customerId=${currentUser?.customer?.id}`
        ).pipe(
            take(1),
            map((patientsData) => patientsData.patients || []),
            catchError(err => {
                throw 'error in source. Details: ' + err;
            })
        )
    }


    public getPatient$(patientId: string) {
        const currentUser = this.requestsService.getCurrentUser();
        return this._http.get<IPatient>(
            `${environment.apiUrl('ehr')}/ehr/patients/${patientId}?customerId=${currentUser?.customer?.id}`
            ).pipe(
                take(1),
            tap((patient: IPatient) => {
                //this.store.dispatch(loadCustomersSuccess({customers}))
            }),
            catchError(err => {
                throw 'error in source. Details: ' + err;
            })
        )
    }

  public getPatientWounds$(patientId: string): Observable<Wound[]> {
    return this._http.get<Wound[]>(
        `${environment.apiUrl('ehr')}/ehr/omnidermal/wound/eaafbefd-87ef-42c7-b899-02463b844334`//${patientId}`
        ).pipe(
            take(1),
            catchError(err => {
                throw 'error in source. Details: ' + err;
            })
        )
  }

  public getPatientActivities$(patientId: string): Observable<Wound[]> {
    return this._http.get<Wound[]>(
        `${environment.apiUrl('ehr')}/ehr/omnidermal/wound/${patientId}`
        ).pipe(
            take(1),
            catchError(err => {
                throw 'error in source. Details: ' + err;
            })
        )
  }

  public createPatient$(patient: IPatient): Observable<any> {
    const currentUser = this.requestsService.getCurrentUser();
    return this._http.post(
        `${environment.apiUrl('ehr')}/ehr/patients?customerId=${currentUser?.customer?.id}`,
        {
            ...patient,
            organizationId: currentUser?.organization?.id
        }
    ).pipe(
        take(1),
        catchError(err => {
            throw `error in source. Details:  + ${err}`
        })
    );
  }

  public editPatient$(patient: IPatient): Observable<any> {
    const currentUser = this.requestsService.getCurrentUser();
    return this._http.put(
        `${environment.apiUrl('ehr')}/ehr/patients/${patient.id}?customerId=${currentUser?.customer?.id}`,
        {
            ...patient,
            organizationId: currentUser?.organization?.id
        }
    ).pipe(
        catchError(err => {
            throw `error in source. Details:  + ${err}`
        })
    );
  }
  
  public deletePatient$(patientId: string): Observable<GenericBeResponse> {
    const currentUser = this.requestsService.getCurrentUser();
    return this._http.delete<GenericBeResponse>(
        `${environment.apiUrl('ehr')}/ehr/patients/${patientId}?customerId=${currentUser?.customer?.id}`
    ).pipe(
        catchError(err => {
            //this.store.dispatch(createPatientError())
            throw `error in source. Details:  + ${err}`
        })
    );
  }

  public dischargePatient$(patientId: string, bedId?: string): Observable<GenericBeResponse> {
    const currentUser = this.requestsService.getCurrentUser();
    return this._http.patch<GenericBeResponse>(
        `${environment.apiUrl('ehr')}/ehr/patients/${patientId}/discharge?customerId=${currentUser?.customer?.id}&organizationId=${currentUser?.organization?.id}${bedId ? '&bedId='+bedId : ''}`, {}
    ).pipe(
        catchError(err => {
            //this.store.dispatch(createPatientError())
            throw `error in source. Details:  + ${err}`
        })
    );
  }
}