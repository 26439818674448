import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IBreadcrumbs } from '../../../shared/components/breadcrumbs/breadcrumbs.component';
import { tap } from 'rxjs';
import { IFormResource } from '../../../shared/components/form/form.component';
import { IonContent, IonButton } from "@ionic/angular/standalone";
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AlertsService } from '../../../core/services/alerts.service';
import { ModalContainerComponent } from '../../../shared/components/modal-container/modal-container.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { INotificationData } from '../../../shared/models/be/notification';
import Chart, { TooltipItem, TooltipModel } from 'chart.js/auto';
import 'chartjs-adapter-luxon';
import { MatGridListModule } from '@angular/material/grid-list';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ICtaOutput, IOptionOutput, ITableColumns, ITableConfigParams, ITableData, TableComponent } from '../../../shared/components/table/table.component';

@Component({
  selector: 'app-settings',
  templateUrl: './analytics.page.html',
  styleUrls: ['./analytics.page.scss'],
  standalone: true,
  imports: [ 
    IonContent, 
    CommonModule,
    TranslateModule,
    RouterModule,
    MatExpansionModule,
    MatGridListModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    TableComponent
  ]
})
export class AnalyticsPage implements OnInit {
  @ViewChildren('chartCanvas') canvasRefs!: QueryList<ElementRef<HTMLCanvasElement>>;
  public breadcrumbsList: IBreadcrumbs[] = [{
    icon: 'query_stats',
    label: 'ANALYTICS'
  }]

  public tableRowSpan: number = 11
  public isDataLoading: boolean = false;
  public severityToColor = {
    "low": "#E6E6E6",
    "medium": "#E7DA53",
    "high": "#EE867D"
  }
  public resource?: IFormResource & {unitId: string; buildingId: string};
  public data: {
    id: string;
    name: string;
    alerts: INotificationData[];
    chartData?: any;
    isLoaded?: boolean;
  }[] = [];

  private legendMargin = {
    id: 'legendMargin',
    afterInit(chart: any, args: any, plugins: any) {
      const originalFit = chart.legend.fit;
      chart.legend.fit = function fit() {
        if (originalFit) {
          originalFit.call(this);
        }
        return this.height += 50
      }
    }
  }

  public tableColumns: ITableColumns[] = [
    /* { label: 'ID', id: 'id', showCol: true }, */
    { label: 'STATUS', id: 'status', showCol: true, cannotHide: true },
    { label: 'REF', id: 'label', showCol: true },
    { label: 'LINK_STATUS', id: 'linked', showCol: true },
    { label: 'ID_RUST', id: 'macAddress', showCol: true },
    { label: 'CREATION_DATE', id: 'createdAt', showCol: false },
    { label: 'ORGANIZATION', id: 'organization', showCol: false },
    { label: 'BUILDING', id: 'building', showCol: false },
    { label: 'UNIT', id: 'unit', showCol: false },
    { label: 'ROOM', id: 'room', showCol: true },
  ];

  public tableData: ITableData[] = [];

  public tableConfigParams: ITableConfigParams = {
    id: "devices",
    referenceLabel: 'DEVICES',
    searchBy: ['label', 'macAddress'],
    hideAddBtn: true,
    filters: [],
    defaultSort: 'label'
  };
  
  public inputsList = [
    {
      id: 'room',
      label: 'Stanza',
      selectedValue: null,
      options: []
    },{
      id: 'chartType',
      label: 'Tipo di visulizzazione',
      selectedValue: 'chart',
      options: [{
        id: 'table',
        label: 'Tabella'
      },{
        id: 'chart',
        label: 'Grafico'
      }]
    },{
      id: 'filterby',
      label: 'Filtro temporale',
      selectedValue: 'last24h',
      options: [{
        id: 'last24h',
        label: 'Ultime 24 Ore'
      },{
        id: 'last7days',
        label: 'Ultimi 7 giorni'
      },{
        id: 'lastMonth',
        label: 'Ultimo mese'
      }]
    }
  ]

  private customTooltipPlugin = {
    id: 'customTooltip', // Identificatore univoco del plugin
    beforeTooltipDraw: (chart: Chart, args: { tooltip: TooltipModel<any> }, options: any) => {
      const tooltip = args.tooltip;      
      if (tooltip && tooltip.body && tooltip.body.length) {
        const item: TooltipItem<any> = tooltip.dataPoints[0];
        tooltip.title = [item.dataset.label];

        tooltip.body.forEach((body, i) => {
          if (item) {
            // Modifica il contenuto del tooltip qui
            body.lines[0] = this.getTimeFromChartjsFormattedValue(body.lines[0]) || '';
          }
        });
      }
    }
  };

  constructor (
    private alertService: AlertsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  ngAfterViewInit() {
    this.resource = this.route.snapshot.data['data'];
    /* this.resource?.buildingId && this.alertService.getAnalyticsAlerts$(this.resource.buildingId).pipe(
      tap((data) => {
        this.data = data;
        const roomInput = this.inputsList.find((input) => input.id === 'room')!
        roomInput.options = data.map((item) => ({id: item.id, label: item.name}));
        roomInput.selectedValue = roomInput.options[0].id || null;
      })
    ).subscribe() */
  }

  ngAfterViewChecked() {
    /* if (this.data.length > 0 && this.canvasRefs.length === this.data.length && !this.data[0].chartData) {
      this.createCharts();
    } */
  }

  createCharts() {
    this.canvasRefs.forEach((canvasRef, index) => {
      const room = this.data[index];
      if (room && !room.isLoaded) {
        room.isLoaded = true;
        room.chartData = new Chart(canvasRef.nativeElement, {
          type: 'scatter',
          data: {
            //labels: this.generaOrari(),
            datasets: [{
              label: 'CADUTA',
              data: room.alerts.filter((alert) => alert.eventType === '01').map((alert) => ({x: new Date(alert.timestamp), y: 'CADUTA'})),
              borderColor: '#EE867D',
              backgroundColor: 'rgb(238 134 125 / 71%)',
              pointRadius: 6,
              pointHoverRadius: 8
            },
            {
              label: 'ENTRATA STANZA',
              data: room.alerts.filter((alert) => alert.eventType === '02').map((alert) => ({x: new Date(alert.timestamp), y: 'ENTRATA STANZA'})),
              borderColor: '#E7DA53',
              backgroundColor: '#e7da53ad',
              pointRadius: 6,
              pointHoverRadius: 8
            },
            {
              label: 'USCITA STANZA',
              data: room.alerts.filter((alert) => alert.eventType === '03').map((alert) => ({x: new Date(alert.timestamp), y: 'USCITA STANZA'})),
              borderColor: '#E7DA53',
              backgroundColor: '#e7da53ad',
              pointRadius: 6,
              pointHoverRadius: 8
            },
            {
              label: 'ENTRATA LETTO',
              data: room.alerts.filter((alert) => alert.eventType === '04').map((alert) => ({x: new Date(alert.timestamp), y: 'ENTRATA LETTO'})),
              borderColor: '#E7DA53',
              backgroundColor: '#e7da53ad',
              pointRadius: 6,
              pointHoverRadius: 8
            },
            {
              label: 'USCITA LETTO',
              data: room.alerts.filter((alert) => alert.eventType === '05').map((alert) => ({x: new Date(alert.timestamp), y: 'USCITA LETTO'})),
              borderColor: '#E7DA53',
              backgroundColor: '#e7da53ad',
              pointRadius: 6,
              pointHoverRadius: 8
            }]
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: 'top',
              }
            },
            scales: {
              x: {
                type: 'time',
                time: {
                  unit: 'hour',
                  displayFormats: {
                    hour: 'HH:mm'
                  },
                  tooltipFormat: 'HH:mm'
                },
                ticks: {
                  display: true,
                  autoSkip: false
                }
              },
              y: {
                type: 'category',
                labels: ['CADUTA', 'ENTRATA STANZA', 'USCITA STANZA', 'ENTRATA LETTO', 'USCITA LETTO'],
                ticks: {
                  autoSkip: false,
                  font: {
                    size: 12
                  }
                }
              }
            }
          },
          plugins: [this.legendMargin, this.customTooltipPlugin]
        });
      }
    });
  }

  getTimeFromChartjsFormattedValue(stringa: string): string | null {
    const regex = /\((\d{2}:\d{2}),/;
    const risultato = stringa.match(regex);

    if (risultato && risultato[1]) {
      return risultato[1];
    } else {
      return stringa;
    }
  }

  generaOrari(): string[] {
    const orari: string[] = [];
    for (let ora = 0; ora < 24; ora++) {
      for (let minuti = 0; minuti < 60; minuti += 1) {
        const oraStringa = ora.toString().padStart(2, '0');
        const minutiStringa = minuti.toString().padStart(2, '0');
        orari.push(`${oraStringa}:${minutiStringa}`);
      }
    }
    return orari;
  }

  applyValue(input: any, value: any) {
    console.log(value)
  }

    /**
     * @description Funzione invocata al click di una opzione della table
     * @param event Viene passato un oggetto contenente l'id delle risorsa in oggetto e l'action invocata
     */
    async onOptionAction(event: IOptionOutput) {
      switch (event.action) {
        default:
          //this.router.navigate(['/wita/devices']);
          break;
      }
    }
  
    /**
     * @description Funzione invocata al click di un button (Aggiungi risorsa, refresh dati, ecc...)
     * @param event Viene passato un oggetto contenente il tipo di action invocata
     */
    onCtaAction(event: ICtaOutput) {
      switch (event.action) {
        case 'add':
          //this.router.navigate(['/wita/devices/new']);
          break;
        default:
          break;
      }
    }


}
