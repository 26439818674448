import { Component, ElementRef, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { ISelectInput, ISimpleInput } from '../form/form.component';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SanitizeInputDirective } from '../../directives/inputSanitize.directive';
import { MatInputModule } from '@angular/material/input';
import { IonButton } from '@ionic/angular/standalone';
import { tap } from 'rxjs';
import { SensorService } from '../../../core/services/sensors.service';
import { webSocket } from 'rxjs/webSocket';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  standalone: true,
  imports: [ 
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatGridListModule,
    MatFormFieldModule,
    MatSelectModule,
    TranslateModule,
    ReactiveFormsModule,
    SanitizeInputDirective,
    MatInputModule,
    IonButton
  ]
})
export class DialogComponent implements OnInit {
  
  /**
   * @description FormGroup che regola tutti i campi della form nel component 
   */
  public formGroup!: FormGroup;
  public formArray!: FormArray;
  public hasError: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IDialogData,
    private formBuilder: FormBuilder,
    private sensorService: SensorService
  ) { }

  ngOnInit() {
    switch (this.data.mode) {
      case 'streaming':
        this.sensorService.getDeviceSessionId$(this.data.additionalData.name).pipe(
          tap((data) => {
            this.data.additionalData.sessionId = data.sessionId
            const ws = webSocket(environment.wsUrl+'video/signal/'+this.data.additionalData.name);
            ws.subscribe({
              next: (msg: any) => {
                switch (msg.type) {
                  case 'welcome':
                    ws.next({type:"register_viewer",deviceId: this.data.additionalData.name, role:"viewer"});
                    break;
                  case 'registration_successful':
                    ws.next({type: 'start_stream', deviceId: this.data.additionalData.name, sessionId: this.data.additionalData.sessionId});
                    break;   
                  case 'video_frame':
                    this.data.additionalData.src = "data:image/jpg;base64,"+JSON.parse(msg.data).data
                    break;
                  default:
                    break;
                }
                msg.type !== 'video_frame' && console.log(msg);
              },
              error: (err: any) => console.log(err),
              complete: () => console.log('complete')
            });
          })
        ).subscribe();
        break;
      case 'editDeviceName':
        this.formArray = this.formBuilder.array(
          this.data.formData.map((input) => (new FormControl(input.inputValue, input.validators)))
        )        
        break;
      case 'form':
        this.formGroup = this.formBuilder.group(
          this.data.formData.reduce((_accumulator, currentInput) => {
            _accumulator[currentInput.id] = [currentInput.inputValue || null, (currentInput as ISimpleInput).validators || []]
            return _accumulator
          }, {} as any)
        )        
        break;
      default:
        break;
    }  
  }

  @ViewChildren('inputEl') inputEls!: QueryList<ElementRef<HTMLInputElement>>;
  
  handleKeyPress(e: KeyboardEvent, idx: number) {
    const isDigit = /\d/.test(e.key);  
    if (isDigit && idx + 1 < 4) {
      this.inputEls.get(idx+1)?.nativeElement.focus()
    }  
    if (isDigit && this.formArray.controls[idx].value) {
      // If user deselects an input which already has a value
      // we want to clear it so that it doesn't have more than 1 digit
      this.formArray.controls[idx].setValue('');
    }  
    return isDigit;
  }

  handleInput(idx: number) {
    if (this.inputEls.get(idx)?.nativeElement.value) {
      this.inputEls.get(idx+1)?.nativeElement.focus()
    }
    if (this.formArray.valid) {
      const currentValue = this.inputEls.map((input) => input.nativeElement.value).join().replaceAll(',', '')
      this.hasError = this.data.additionalData.valuesToCheck.some((value) => value === currentValue);
    } else {
      this.hasError = false;
    }
  }
  
  handleFocus(e: FocusEvent) {
    // Select previously entered value to replace with a new input
    (e.target as HTMLInputElement).select();
  }

  handleKeyDown(e: KeyboardEvent, idx: number) {
    if (e.key === 'ArrowLeft') {
      if (idx > 0) {
        this.inputEls.get(idx-1)?.nativeElement.focus()
      }
    } else if (e.key === 'ArrowRight') {
      if (idx < 4) {
        this.inputEls.get(idx+1)?.nativeElement.focus()
      }
    }
  }

  get saveButtonDisabledStatus(): boolean {
    return (this.formGroup && !this.formGroup.valid) || (this.formArray && (!this.formArray.valid || this.hasError))
  }
}

export interface IDialogData {
  formData: (ISimpleInput | ISelectInput)[], 
  title: string, 
  additionalData: {
    valuesToCheck: string[],
    id: string,
    name: string,
    sessionId?: string,
    src?: string
  },
  mode: 'form' | 'streaming' | 'editDeviceName'
}
