import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { InjectionToken, enableProdMode, importProvidersFrom, isDevMode } from '@angular/core';
import { environment } from './environments/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import { LogLevel } from './app/core/services/utils/logger.service';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { routes } from './app/app.routes';
import { provideStore } from '@ngrx/store';
import { storeWita } from './app/state/wita.store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { witaStoreDevToolsConfig } from './app/state/wita.store-dev-tools-config';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideServiceWorker } from '@angular/service-worker';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { provideIonicAngular } from '@ionic/angular/standalone';

if (environment.production) {
  enableProdMode();
}

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const MIN_LOG_LEVEL = new InjectionToken<LogLevel>("Minimum log level");
bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: MIN_LOG_LEVEL,
      useValue: LogLevel.DEBUG//isDevMode() ? LogLevel.INFO : LogLevel.NEVER,
    },
    { 
      provide: RouteReuseStrategy, 
      useClass: IonicRouteStrategy 
    },
    provideRouter(routes),
    // Setup NGRX:
    provideStore(storeWita),
    provideStoreDevtools(witaStoreDevToolsConfig),
    provideHttpClient(),
    // Import Providers
    importProvidersFrom(
      /* IonicModule.forRoot({
        mode: "ios",
        innerHTMLTemplatesEnabled: true
      }), */
      // Ngx Translate
      TranslateModule.forRoot({
        defaultLanguage: 'it',
        loader: {
            provide: TranslateLoader,
            useFactory: createTranslateLoader,
            deps: [HttpClient],
        },
      })
    ),
    provideIonicAngular({
      innerHTMLTemplatesEnabled: true // Add here
    }),
    provideAnimations(),
    // Setup Service Worker
    provideServiceWorker('ngsw-worker.js', {
        //enabled: !isDevMode(),
        enabled: true,
        registrationStrategy: 'registerWhenStable:30000'
    }),
    provideServiceWorker('firebase-messaging-sw.js', {
      enabled: true
    }),
    //provideFirebaseApp(() => initializeApp(environment.firebase)), 
    provideAnalytics(() => getAnalytics()), 
    /*ScreenTrackingService, provideAppCheck(() => {
      // TODO get a reCAPTCHA Enterprise here https://console.cloud.google.com/security/recaptcha?project=_
      const provider = new ReCaptchaEnterpriseProvider(reCAPTCHA Enterprise site key);
      return initializeAppCheck(undefined, { provider, isTokenAutoRefreshEnabled: true });
    }), */
    /* provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()), */
    provideMessaging(() => getMessaging()),
    /* providePerformance(() => getPerformance()),
    provideStorage(() => getStorage()),
    provideRemoteConfig(() => getRemoteConfig()) */
  ],
})
  .catch((err) => console.error(err));
