import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import {
  BehaviorSubject,
  Observable,
  catchError,
  filter,
  finalize,
  map,
  of,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import { AuthService } from '../../core/services/auth.service';
import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../core/services/utils/toast.service';
import { environment } from '../../../environments/environment';
import { LoggerService } from '../../core/services/utils/logger.service';

export const authInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  let AUTH_HEADER = 'Authorization';
  let token: string | boolean = localStorage.getItem('ACCESS_TOKEN') || false;
  let refreshTokenInProgress = false;
  let refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  const refreshTokenEnabled: boolean = false;
  const authService: AuthService = inject(AuthService);
  const toastService = inject(ToastService);
  const translateService = inject(TranslateService);
  const loggerService = inject(LoggerService);
  const _http = inject(HttpClient);

  if (!req.headers.has('Content-Type')) {
    req = req.clone({
      headers: req.headers.set('Content-Type', 'application/json'),
    });
  }

  // Se la chiamata in oggetto è quella di auth allora la mando skippando tutta la logica di aggiunta token
  if (req.url.includes('/auth') && !req.url.includes('/auth/')) {
    return next(req).pipe(
      catchError((error: HttpErrorResponse) => {
        loggerService.error('Login KO 🔴');
        if (error && error.status === 401) {
          // 401 unauthorized, invalid credentials
          return throwError(() => 'Username o password errati');
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  req = addAuthenticationToken(req, token, AUTH_HEADER);
  return next(req).pipe(
    tap(() => {}),
    catchError((error: HttpErrorResponse) => {
      loggerService.error('Request KO 🔴');
      switch (error.error?.code) {
        case 16:
          authService.appLogout();
          return throwError(() => error);
        case 7:
          if (refreshTokenInProgress) {
            /**
             * refreshTokenInProgress, wait until refreshTokenSubject has a non-null value
             * (when not null the new token is ready and we can retry the request again)
             */
            return refreshTokenSubject.pipe(
              filter((result) => result !== null),
              take(1),
              switchMap(() =>
                next(addAuthenticationToken(req, token, AUTH_HEADER))
              )
            );
          } else {
            refreshTokenInProgress = true;
            /**
             * refreshTokenSubject setted to null in order to lock all subsequent API calls
             * until the new token has been retrieved
             */
            refreshTokenSubject.next(null);
            return refreshAccessToken(_http).pipe(
              switchMap((success: boolean) => {
                refreshTokenSubject.next(success);
                return next(addAuthenticationToken(req, token, AUTH_HEADER));
              }),
              finalize(() => (refreshTokenInProgress = false))
            );
          }
        default:
          return throwError(() => error);
      }
    })
  );
};

function addAuthenticationToken(
  request: HttpRequest<any>,
  token: string | boolean,
  AUTH_HEADER: string
): HttpRequest<any> {
  //Avoid to add not valid token in the request header
  if (!token) {
    return request;
  }

  return request.clone({
    headers: request.headers.set(AUTH_HEADER, 'Bearer ' + token),
  });
}

function refreshAccessToken(http: HttpClient): Observable<any> {
  return http
    .post<any>(
      `${environment.apiUrl('auth')}/auth/refreshToken`,
      {},
      { withCredentials: true }
    )
    .pipe(
      take(1),
      tap((response) => {
        localStorage.setItem('ACCESS_TOKEN', response.access_token);
      })
    );
}