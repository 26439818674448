import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  combineLatest,
  Subject,
  take,
  tap,
} from 'rxjs';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { EventService } from '../../../../core/services/events.service';
import {MatToolbarModule} from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatBadgeModule } from '@angular/material/badge';
import { FacilitiesService } from '../../../../core/services/facilities.service';
import { RequestsService } from '../../../../core/services/utils/requests.service';
import { ISettingsData } from '../../settings/settings.page';
import { IUser } from '../../../../shared/models/be/user';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { NotificationService } from '../../../../core/services/notification.service';
import { IonButton, IonContent } from '@ionic/angular/standalone';
import { SideNavService } from '../../../../core/services/utils/sidenav.service';
import { SharedService } from '../../../../core/services/shared.service';
import { ToastService } from '../../../../core/services/utils/toast.service';

@Component({
  selector: 'settingsMobile',
  templateUrl: './settings.mobile.component.html',
  styleUrls: ['./settings.mobile.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatGridListModule,
    MatIconModule,
    TranslateModule,
    RouterModule,
    MatGridListModule,
    MatToolbarModule,
    MatTabsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatBadgeModule,
    IonContent,
    IonButton
]
})
export class settingsMobilePage implements OnInit {

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public eventService: EventService,
    public facilitiesService: FacilitiesService,
    private requestsService: RequestsService,
    private translate: TranslateService,
    private notificationsService: NotificationService,
    private sideNavService: SideNavService,
    private sharedService: SharedService,
    private toastService: ToastService
  ) {
  }

  ionViewDidEnter() {
  }

  private destroyed$ = new Subject();
  public settingsData: ISettingsData[] = [{
    id: 'building',
    label: 'BUILDING',
    rowSpan: 2,
    inputs: [{
      id: 'building',
      type: 'select',
      length: 2,
      label: 'BUILDING',
      selectedValue: null,
      disableTranslation: true,
      touched: false,
      values: []
    }]
  },{
    id: 'language',
    label: 'LANGUAGE',
    rowSpan: 2,
    inputs: [{
      id: 'language',
      type: 'select',
      length: 2,
      selectedValue: 'it',
      label: 'LANGUAGE',
      disableTranslation: true,
      values: [{id: 'it', label: 'Italiano'}, {id: 'en', label: 'Inglese'}],
      touched: false,
      onSelect: (resourceId: string) => this.translate.use(resourceId)
    }]
  },{
    id: 'notifications',
    label: 'NOTIFICATIONS',
    rowSpan: 2,
    inputs: [{
      id: 'notification',
      type: 'select',
      length: 2,
      selectedValue: 'ring',
      label: 'NOTIFICATIONS',
      disableTranslation: true,
      touched: false,
      values: [{id: 'ring', label: 'Sonoro'}, {id: 'silent', label: 'Silenzioso'}, {id: 'vibrate', label: 'Vibrazione'}],
      onSelect: (resourceId: string) => this.setNotificationPreference(resourceId as 'ring' | 'silent' | 'vibrate')
    }]
  }];

  ngOnInit() {
    combineLatest([
      this.facilitiesService.getBuildings$(),
      this.requestsService.currentUser$
    ]).pipe(
      take(1),
      tap(([buildings, currentUser]) => {
        let buildingSection = buildings && this.settingsData.find((section) => section.id === 'building')
        let languageSection = currentUser && this.settingsData.find((section) => section.id === 'language')
        if (buildingSection) {
          buildingSection.inputs[0].selectedValue = currentUser?.building?.id || null,
          buildingSection.inputs[0].values = buildings.map((building) => ({id: building.id!, label: building.name!})) || [],
          buildingSection.inputs[0].onSelect = (resourceId: string) => {
            this.notificationsService.removeToken$().pipe().subscribe();
            currentUser = {
              ...currentUser as IUser,
              building: {
                id: buildings.find((building) => building.id === resourceId)?.id || '',
                name: buildings.find((building) => building.id === resourceId)?.name || ''
              }
            };
            this.requestsService.saveCurrentUser(currentUser);
            this.notificationsService.postToken$(resourceId).subscribe();
            this.sharedService.startWss(this.toastService);
          }          
        }
        if (languageSection) {
          languageSection.inputs[0].selectedValue = currentUser?.language || 'it';
        }
      })
    ).subscribe();
  }

  setNotificationPreference(mode: 'ring' | 'silent' | 'vibrate') {
    let currentUser = this.requestsService.getCurrentUser();
    currentUser && (currentUser.notificationPreference = mode)
    this.requestsService.saveCurrentUser(currentUser)
  }

  saveData() {
    this.settingsData.forEach((section) => {
      if (section.inputs[0].touched) {
        section.inputs[0].onSelect!(section.inputs[0].selectedValue!)
      }
    });    
    this.router.navigateByUrl("");
    this.sideNavService.isHome = true;
  }

  ngOnDestroy() {
    this.destroyed$.next(1);
    this.destroyed$.complete();
  }
}