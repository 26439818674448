<div class="avatar-container">
  <div class="image-container" *ngIf="file; else placeholder"
  [ngClass]="{'box-shadow-avatar': !!file}"
  [ngStyle]="{backgroundImage: 'url(' + file + ')'}">
  </div>

  <ng-template #placeholder>
      <div class="image-container" [style.background-image]="'url('+defaultAvatar+')'">
      </div>
  </ng-template>

  <label for="avatar-input-file">
      <div class="icon-wrapper">
          <input type="file" id="avatar-input-file" accept="image/*" (change)="onFileChange($event)" />
          <mat-icon class="icon" fontIcon="edit"></mat-icon>
      </div>
  </label>

</div>