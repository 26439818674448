import { createAction, props } from '@ngrx/store';
import { IPatient } from '../../shared/models/be/patients';

export const loadingPatients = createAction(
  '[WITA] Loading Patients'
);
/* export const loadPatientsSuccess = createAction(
  '[WITA] Load Patients Success',
  props<IPatient[]>()
); */
export const loadingPatientsError = createAction(
  '[WITA] Loading Patients Error'
);
export const createPatientsuccess = createAction(
  '[WITA] Create Patient Success',
  props<{ patient: IPatient }>()
);
export const createPatientError = createAction(
  '[WITA] Creating Patient Error'
);
