<div style="display: flex; justify-content: space-between; align-items: center; padding: 10px; background-color: #00AEBE;">
  <mat-icon style="color: white;" (click)="preferencesMode = !preferencesMode">{{preferencesMode ? 'arrow_back' : 'timer'}}</mat-icon>
  <h2 style="margin: 0; color: white; font-size: 20px;">{{preferencesMode ? 'Temporizzazione stanza' : data.title}}</h2>
  <mat-icon [mat-dialog-close]="false" [ngStyle]="{'visibility': preferencesMode ? 'hidden' : null}" style="color: white;">close</mat-icon>
</div>
<p *ngIf="preferencesMode" style="text-align: center; color: #00AEBE; font-size: 20px; margin: 15px 0;">{{data.title}}</p>
<mat-dialog-content class="mat-typography" style="padding: 0 15px;">
  @if (!preferencesMode) {
    <div style="margin-top: 15px; width: 100%;" [style.margin-bottom]="getCurrentAllert() ? 'unset' : '20px'">
      <div colspan="18" rowspan="1" style="background-color: white; border-radius: 20px; padding: 5px; border: 1px solid #e6e6e6;" *ngFor="let patient of data.patients">
        <mat-icon style="margin: 0 5px;">person</mat-icon>
        {{patient.name}}
      </div>
      <streaming style="width: 100%;" [devices]="$any(data.devices)" ></streaming>
      <div *ngIf="getCurrentAllert()" style="display: flex; flex-direction: column; border-radius: 5px; width: 100%; height: 100%; background-color: white; padding: 10px; margin: 20px 0;" [ngStyle]="{'border': '1px solid' + severityToColor[getCurrentAllert().severity]}">
        <div style="display: flex; justify-content: space-between;">
          <p>{{eventTypeToTranslation[getCurrentAllert().eventType] | translate}}</p>
          <p>{{getTimeLeft(getCurrentAllert().timestamp)}} min</p>
        </div>
        <div style="height: 100%; display: flex; justify-content: space-between;">
          <ion-button color="primary" mode="md" style="width: 100; height: 100%;" (click)="manageEvent(getCurrentAllert())" [disabled]="manageRunning">
            {{manageRunning ? 'Gestione in corso' : 'Gestisci allerta'}}<ion-spinner name="lines-small" *ngIf="manageRunning"></ion-spinner>
          </ion-button>
        </div>
      </div>
    </div>
  } @else {
    <timingComponentsGroup (onSubmitData)="submitDevicesSettings($event)" [deviceSettings]="deviceSettings" [isMobile]="isMobile"></timingComponentsGroup>
  }
</mat-dialog-content>
