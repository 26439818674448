<ion-breadcrumbs style="display: flex; justify-content: flex-start;">
  <ion-breadcrumb [style.cursor]="last ? '' : 'pointer'" [routerLink]="breadcrumb.routerLink || null" *ngFor="let breadcrumb of breadcrumbsList; last as last">
    @if (breadcrumb.icon) {
      @if (breadcrumb.isSvgIcon) {
        <mat-icon matListItemIcon slot="start" [svgIcon]="breadcrumb.icon" style="margin-right: 5px;"></mat-icon>
      } @else {
        <mat-icon matListItemIcon slot="start" style="margin-right: 5px;">{{breadcrumb.icon}}</mat-icon>
      }
    }
    {{'BREADCRUMB.'+breadcrumb.label | translate}}{{breadcrumb.breadCrumbSubject ? ' ('+breadcrumb.breadCrumbSubject+')' : ''}}
  </ion-breadcrumb>
</ion-breadcrumbs>
