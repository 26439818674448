import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { environment } from '../../../environments/environment';
import { createCustomerError, createCustomerSuccess, loadCustomersSuccess, loadingCustomers, loadingCustomersError } from "../../state/actions/customers.actions";
import { Observable, catchError, map, of, take, tap } from "rxjs";
import { ICustomer } from "../../shared/models/be/customers";
import { Measurement } from "../../shared/models/be/measurement";
import { IDevice, IDeviceFacilities, IDeviceLocations } from "../../shared/models/be/device";
import { RequestsService } from "./utils/requests.service";
import { LoggerService } from "./utils/logger.service";

@Injectable({
    providedIn: 'any'
  })
export class SensorService {
  
    constructor(
      private _http: HttpClient,
      private requestsService: RequestsService,
      private loggerService: LoggerService
    ) {}

    public getServiceStatus$(): Observable<boolean> {
        return this._http.get<{message: string}>(`${environment.apiUrl('devices')}/devices/ping`).pipe(
          take(1),
          map((val: {message: string}) => !!val.message),
          catchError(err => {
            this.loggerService.error('Error on .../ping')
            return of(false);
          })
        );
    }

    public getDevices$(): Observable<IDevice[]> {
        const currentUser = this.requestsService.getCurrentUser();
        return this._http.get<{devices: IDevice[]}>(
            `${environment.apiUrl('devices')}/iot-devices${currentUser?.customer?.id ? '?customerId='+currentUser?.customer?.id : ''}`
        ).pipe(
            take(1),
            map((data) => data.devices),
            catchError(err => {
                throw 'error in source. Details: ' + err;
            })
        )
    }

    public getDevice$(deviceId: string): Observable<IDevice> {
        return this._http.get<IDevice>(
            `${environment.apiUrl('devices')}/iot-devices/${deviceId}`
        ).pipe(
            take(1),
            catchError(err => {
                /* this.store.dispatch(loadingPatientsError()) */
                throw 'error in source. Details: ' + err;
            })
        )
    }

    public linkDevice$(deviceId: string, deviceFacilities?: IDeviceFacilities & {customer?: {id: string; name: string}}): Observable<IDevice> {
        const currentUser = this.requestsService.getCurrentUser();
        deviceFacilities && (deviceFacilities['customer'] = {
            id: String(currentUser?.customer?.id),
            name: String(currentUser?.customer?.businessName)
        });
        deviceFacilities && (deviceFacilities['organization'] = {
            id: String(currentUser?.organization?.id),
            name: String(currentUser?.organization?.businessName)
        });
        return this._http.put<IDevice>(
            `${environment.apiUrl('devices')}/iot-devices/${deviceId}/facilities`, deviceFacilities || {}
        ).pipe(
            take(1),
            catchError(err => {
                /* this.store.dispatch(loadingPatientsError()) */
                throw 'error in source. Details: ' + err;
            })
        )
    }

    public deleteDevice$(deviceLabel: string) {
      return this._http.delete(
          `${environment.apiUrl('devices')}/iot-devices/${deviceLabel}`
      ).pipe(
          tap(() => {
              //this.store.dispatch(createPatientsuccess({patient}))
          }),
          catchError(err => {
              //this.store.dispatch(createPatientError())
              throw `error in source. Details:  + ${err}`
          })
      );
    }
    
    public disableDevice$(deviceId: string): Observable<{success: boolean; deviceId: string; status: string; label: string}> {
        return this._http.put<{success: boolean; deviceId: string; status: string; label: string}>(
            `${environment.apiUrl('devices')}/iot-devices/${deviceId}/disable`, {}
        ).pipe(
            take(1),
            catchError(err => {
                /* this.store.dispatch(loadingPatientsError()) */
                throw 'error in source. Details: ' + err;
            })
        )
    }

    public enableDevice$(deviceId: string): Observable<{success: boolean; deviceId: string; status: string; label: string}> {
        return this._http.put<{success: boolean; deviceId: string; status: string; label: string}>(
            `${environment.apiUrl('devices')}/iot-devices/${deviceId}/enable`, {}
        ).pipe(
            take(1),
            catchError(err => {
                /* this.store.dispatch(loadingPatientsError()) */
                throw 'error in source. Details: ' + err;
            })
        )
    }
    
    public editDeviceName$(deviceNameData: {oldLabel: string, newLabel: string}): Observable<{success: boolean; deviceId: string; status: string; label: string}> {
        return this._http.put<{success: boolean; deviceId: string; status: string; label: string}>(
            `${environment.apiUrl('devices')}/iot-devices/label`, deviceNameData
        ).pipe(
            take(1),
            catchError(err => {
                /* this.store.dispatch(loadingPatientsError()) */
                throw 'error in source. Details: ' + err;
            })
        )
    }
}