import { IonicColors } from "../../enums/ionicColors.enum";

export interface INotificationData {
    /**
     * Id della notifica in oggetto
     */
    id: string;
    /**
     * Data (orario) in cui si presenta l'allerta 
     */
    timestamp: Date,
    /**
     * Tipo di evento (wss)
     */
    type: "notification",
    /**
     * Label del sensore che ha rilevato l'allerta (LABEL NON ID)
     */
    deviceId: string,
    /**
     * Gravità della allerta
     */
    severity: "low" | "medium" | "high",
    /**
     * Tipo di allerta (caduta, uscita letto, ecc...)
     */
    eventType: EventTypeCodes,
    messageId: string,
    /**
     * Facilities in cui è contenuto il sensore
     */
    facilities: {
        building: {
            id: string,
            name: string
        },
        unit: {
            id: string,
            name: string
        },
        room: {
            id: string,
            name: string
        }
    },
    /**
     * Id dell'edificio in cui è contenuto il sensore
     */
    buildingId: string,
    /**
     * Utente che ha gestito l'allerta
     */
    managedBy: string,
    /**
     * Data e ora in cui è stata gestita la allerta
     */
    managedAt: string
}

export const eventTypeToData = {
    "01": {
        ionicColor: IonicColors.NOTIFICATION_HIGH,
        exaColor: '#EE867D',
        translation: 'GENERAL.EVENTS_LIST.FALL',
        svgIcon: 'fall',
        statusIcon: 'priority_high',
        severity: 'high'
    }, 
    "02": {
        ionicColor: IonicColors.NOTIFICATION_MEDIUM,
        exaColor: '#EEBF7D',
        translation: 'GENERAL.EVENTS_LIST.ROOM_IN',
        svgIcon: 'room_in',
        statusIcon: 'priority_high',
        severity: 'medium'
    },
    "03": {
        ionicColor: IonicColors.NOTIFICATION_MEDIUM,
        exaColor: '#EEBF7D',
        translation: 'GENERAL.EVENTS_LIST.ROOM_OUT',
        svgIcon: 'room_out',
        statusIcon: 'priority_high',
        severity: 'medium'
    },
    "04": {
        ionicColor: IonicColors.NOTIFICATION_LOW,
        exaColor: '#E7DA53',
        translation: 'GENERAL.EVENTS_LIST.BED_IN',
        svgIcon: 'bed_in',
        statusIcon: 'priority_high',
        severity: 'low'
    },
    "05": {
        ionicColor: IonicColors.NOTIFICATION_LOW,
        exaColor: '#E7DA53',
        translation: 'GENERAL.EVENTS_LIST.BED_OUT',
        svgIcon: 'bed_out',
        statusIcon: 'priority_high',
        severity: 'low'
    },
    "06": {
        ionicColor: IonicColors.NOTIFICATION_OFFLINE,
        exaColor: '#E6E6E6',
        translation: 'GENERAL.EVENTS_LIST.DEVICE_OFFLINE',
        svgIcon: 'device_offline',
        statusIcon: 'wifi_off',
        severity: 'offline'
    },
    "07": {
        ionicColor: IonicColors.NOTIFICATION_ONLINE,
        exaColor: '#E6E6E6',
        translation: 'GENERAL.EVENTS_LIST.DEVICE_ONLINE',
        svgIcon: 'device_online',
        statusIcon: 'wifi_off',
        severity: 'online'
    }

}

export enum EventType {
    "01" = "fall", 
    "02" = "room_in",
    "03" = "room_out",
    "04" = "bed_in",
    "05" = "bed_out",
    "06" = "device_offline",
    "07" = "device_online"
}

export type EventTypeCodes = "01" | "02" | "03" | "04" | "05" | "06" | "07";