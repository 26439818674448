<div style="display: flex; justify-content: space-between; align-items: center; padding: 10px;">
  <mat-icon style="visibility: hidden;">close</mat-icon>
  <h2 mat-dialog-title>{{data.title}}</h2>
  <mat-icon [mat-dialog-close]="false" style="cursor: pointer;">close</mat-icon>
</div>

<mat-dialog-content class="mat-typography" style="min-width: 600px;">
  <mat-grid-list cols="6" rowHeight="36px" gutterSize="20" style="margin: 9px;"> 
    @switch (data.mode) {
      @case ('streaming') {
        <mat-grid-tile colspan="6" rowspan="4" style="overflow: visible;">
          @if (data.additionalData.src) {
            <img [src]="data.additionalData.src" style="width: 100%; border-radius: 0 0 5px 5px; border: 1px solid #e6e6e6;">
          } @else {
            <div style="background-color: #616161; color: aliceblue; width: 100%; border-radius: 0 0 5px 5px; border: 1px solid #e6e6e6; height: 176px; display: flex; justify-content: center; align-items: center;">
              SENSORE OFFLINE
            </div>
          }
        </mat-grid-tile>
      }
      @case ('form') {
        <ng-container  [formGroup]="formGroup">
          <mat-grid-tile [colspan]="input.length" rowspan="1" *ngFor="let input of data.formData" style="overflow: visible;">
            <mat-form-field appearance="outline"  [ngSwitch]="input.type" style="width: 100%; height: 100%;">
              <mat-label>{{'FORM.LABELS.'+input.label | translate}}</mat-label>
              <!-- Forzato tipo any a malincuore a causa di errori di compilazione per via del fatto che placeholder è presente solo in ISimpleInput  -->
              <input 
                matInput
                sanitizeInput
                [inputRule]="$any(input).inputRule"
                [placeholder]="$any(input).placeholder"
                [formControlName]="input.id"
                *ngSwitchDefault
              >
              <!-- Forzato tipo any a malincuore a causa di errori di compilazione per via del fatto che values è presente solo in ISelectInput  -->
              <mat-select [formControlName]="input.id" *ngSwitchCase="'select'">
                @for (item of $any(input).values; track item) {
                  <mat-option [value]="item.id">{{'FORM.LABELS.'+item.label | translate}}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </mat-grid-tile>
        </ng-container>
      }
      @case ('editDeviceName') {
        <mat-grid-tile colspan="6" rowspan="2" style="overflow: visible;">
          <div style="display: flex; flex-direction: column; width: 100%;">
          <div class="custom-input">
            <span class="label">MENT-</span>
            <input
              #inputEl
              *ngFor="let input of formArray.controls; let i = index"
              (focus)="handleFocus($event)"
              (keypress)="handleKeyPress($event, i)"
              (input)="handleInput(i)"
              (keydown)="handleKeyDown($event, i)"
              [formControl]="$any(input)"
              type="text"
              inputmode="numeric"
              [class.error]="hasError"
            />
            </div>
            <p style="margin: 15px 0 0 0; color: #ff000091; text-align: center;" *ngIf="hasError">{{'GENERAL.ERRORS.DEVICE_NAME_ALREADY_TAKEN' | translate}}</p> 
          </div>
        </mat-grid-tile>
      }
    }
    <mat-grid-tile colspan="6" rowspan="1"></mat-grid-tile>
    <mat-grid-tile colspan="2" rowspan="1"></mat-grid-tile>
    <mat-grid-tile colspan="2" rowspan="1">
      <ion-button color="primary" mode="md" [mat-dialog-close]="formArray ? formArray.value : formGroup ? formGroup.value : null" [disabled]="saveButtonDisabledStatus">
        {{'GENERAL.BUTTONS.SAVE_AND_CLOSE' | translate}}
      </ion-button>
    </mat-grid-tile>
  </mat-grid-list>
</mat-dialog-content>
