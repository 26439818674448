import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { SensorService } from '../../../core/services/sensors.service';
import { tap } from 'rxjs';
import { IDevice } from '../../models/be/device';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'streaming',
  templateUrl: './streaming.component.html',
  styleUrls: ['./streaming.component.scss'],
  standalone: true,
  imports: [ 
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatGridListModule,
    MatFormFieldModule,
    MatSelectModule,
    TranslateModule,
    ReactiveFormsModule,
    MatInputModule,
  ]
})
export class StreamingComponent implements OnInit {
  /**
   * @description FormGroup che regola tutti i campi della form nel component 
   */
  public formGroup!: FormGroup;
  public formArray!: FormArray;
  public hasError: boolean = false;
  public currentSensorIndex: number = 0;
  public fullScreenMode: boolean = false;
  private restartStreamingInterval = setInterval(() => {
    this.stopStreaming(true);
  }, 10000);

  @Input() devices: (IDevice & {sessionId: string; src: any; ws?: WebSocketSubject<unknown>;})[] = [];
  @ViewChild('streaming') streaming!: any;

  constructor(
    private sensorService: SensorService
  ) { }

  ngOnInit() {
    this.startStreaming();
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.startStreaming();
      } else if (document.visibilityState === 'hidden') {
        this.stopStreaming();
      }
    });
  }

  startStreaming() {
    this.devices.forEach((device) => {
      this.sensorService.getDeviceSessionId$(device.label).pipe(
        tap((data) => {
          device.sessionId = data.sessionId
          device.ws = webSocket(environment.wsUrl+'video/signal/'+device.label);
          device.ws.subscribe({
            next: (msg: any) => {
              switch (msg.type) {
                case 'welcome':
                  device.ws?.next({type:"register_viewer",deviceId: device.label, role:"viewer"});
                  break;
                case 'registration_successful':
                  device.ws?.next({type: 'start_stream', deviceId: device.label, sessionId: device.sessionId});
                  break;   
                case 'video_frame':
                  device.src = "data:image/jpg;base64,"+JSON.parse(msg.data).data
                  break;
                default:
                  break;
              }
              !['video_frame', 'ping'].includes(msg.type) && console.log(msg);
            },
            error: (err: any) => console.log(err),
            complete: () => console.log('complete')
          });
        })
      ).subscribe();
    });
  }

  ngOnDestroy() {
    this.stopStreaming();
    clearInterval(this.restartStreamingInterval);
  }

  stopStreaming(restart: boolean = false) {
    this.devices.forEach((device) => {
      device.ws?.next({type:"stop_stream", deviceId: device.label, sessionId: device.sessionId});
      device.ws?.unsubscribe();
      this.sensorService.stopDeviceStreaming$(device.label, device.sessionId).pipe().subscribe();
    })
    restart && this.startStreaming();
  }
}