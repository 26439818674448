export function  isTimeWithinRange(startTime: string, endTime: string): boolean {
    function convertiOraInMinuti(ora: string) {
        const parti = ora.split(':');
        const ore = parseInt(parti[0], 10);
        const minuti = parseInt(parti[1], 10);
        return ore * 60 + minuti;
    }
    const oraAttuale = new Date();
    const oreAttuali = oraAttuale.getHours();
    const minutiAttuali = oraAttuale.getMinutes();
    const minutiAttualiTotali = oreAttuali * 60 + minutiAttuali;

    const minutiInizio = convertiOraInMinuti(startTime);
    const minutiFine = convertiOraInMinuti(endTime);

    if (minutiInizio <= minutiFine) {
        // Intervallo nello stesso giorno
        return minutiInizio <= minutiAttualiTotali && minutiAttualiTotali <= minutiFine;
    } else {
        // Intervallo su due giorni
        return minutiInizio <= minutiAttualiTotali || minutiAttualiTotali <= minutiFine;
    }
}