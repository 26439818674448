import { Component, Input, OnInit } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { ITableColumns, ITableConfigParams, ITableConfigParamsFilter, ITableData } from '../table.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { concatMap, config, from, tap } from 'rxjs';
import { TableService } from '../table.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IDevice } from '../../../models/be/device';

@Component({
  selector: 'table-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  imports: [
    MatGridListModule,
    IonicModule,
    TranslateModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule
  ],
  standalone: true
})
export class FiltersComponent  implements OnInit {

  @Input() dataSource!: MatTableDataSource<ITableData, MatPaginator>;
  @Input() config!: ITableConfigParams;
  @Input() tableColumns: ITableColumns[] = [];

  constructor(
    private translateService: TranslateService,
    private tableService: TableService
  ) { }

  ngOnInit() {
  }

  /**
   * @description Funzione chiamata alla modifica di un filtro
   * @param filterId Id del filtro in oggetto
   * @param value Valore selezionato
   */
  applyFilter(
    filter: ITableConfigParamsFilter,
    disableOverrideLocalStorage?: boolean
  ) {
    let this_ = this;
    // Recupero il filtro corrente
    let currentJsonFilter: ITableConfigParamsFilter[] = (this.dataSource.filter && JSON.parse(this.dataSource.filter).filters) || [];
    const currentSavedFilter = currentJsonFilter.find((currFilter) => currFilter.id === filter.id);
    const currentSavedFilterIndex = currentJsonFilter.findIndex((currFilter) => currFilter.id === filter.id);


    // Se c'è qualche filtro che prevede il caricamento dinamico dei dati basato sul valore dell'elemento appena selezionato allora provvedo a caricare i dati
    const lowerLevelFilter = this_.config.filters?.find((currFilter) => ((currFilter.dynamicLoadOn === filter.id)))
    // Se il valore è 'Tutti' o non esiste allora lo elimino dai filtri
    if (filter.selectedValue === 'all' || !filter.selectedValue) {

      function checkLowerLevel(filter: ITableConfigParamsFilter) {
        filter.options = [];
        filter.selectedValue = '';
        const currentSavedFilter = currentJsonFilter.findIndex((currFilter) => currFilter.id === filter.id)
        currentSavedFilter && currentJsonFilter.splice(currentSavedFilter, 1);
        const lowerLevelFilter = this_.config.filters?.find((currFilter) => ((currFilter.dynamicLoadOn === filter.id)))
        lowerLevelFilter && checkLowerLevel(lowerLevelFilter);
      }
      
      currentSavedFilter && currentJsonFilter.splice(currentSavedFilterIndex, 1);

      lowerLevelFilter && checkLowerLevel(lowerLevelFilter)        
    } else if (!!lowerLevelFilter?.loader) {
      if (filter.dynamicCustomerAndOrganization) {
        const selectedOrd = (this.config.filters || []).find((currFilter) => currFilter.id === 'organization');
        const orgName = selectedOrd?.selectedValue && selectedOrd.options.find((opt) => opt.id === selectedOrd.selectedValue)?.name;

        const customerId = orgName && (this.dataSource.data.find((resource) => (resource.data as any)['organization'] === orgName)?.data as IDevice).customerId;

        if (filter.id === 'organization') {
          selectedOrd?.selectedValue && customerId && lowerLevelFilter!.loader!(customerId+'', selectedOrd?.selectedValue as string).pipe(
            tap((data) => {
              lowerLevelFilter!.options = [ ...data as {id: string; name: string}[]];
            })
          ).subscribe();
        } else {
          selectedOrd?.selectedValue && customerId && lowerLevelFilter!.loader!(filter.selectedValue as string, customerId+'', selectedOrd?.selectedValue as string).pipe(
            tap((data) => {
              lowerLevelFilter!.options = [{id: 'all', name: this.translateService.instant('TABLE.FILTER.LABEL.ALL')}, ...data as {id: string; name: string}[]];
            })
          ).subscribe();
        }
      } else {
        lowerLevelFilter!.loader!(filter.selectedValue as string).pipe(
          tap((data) => {
            lowerLevelFilter!.options = [{id: 'all', name: this.translateService.instant('TABLE.FILTER.LABEL.ALL')}, ...data as {id: string; name: string}[]];
          })
        ).subscribe();
      }
    } if (currentSavedFilter) {
      currentSavedFilter.selectedValue = filter.selectedValue;
    }

    !currentSavedFilter &&  currentJsonFilter.push(filter);

    !disableOverrideLocalStorage && this.tableService.updateTablePreferences(this.config.id, 'filters', currentJsonFilter)
    this.dataSource.filter = JSON.stringify({ filters: currentJsonFilter || []});
    this.dataSource.paginator?.firstPage();
  }

  clearFilters() {
    (this.config.filters || []).forEach((filter) => {
      filter.dynamicLoadOn && filter.id !== 'organization' && (filter.options = [])
      filter.selectedValue = undefined
    });
    this.dataSource.filter = '';
  }
}
