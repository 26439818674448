import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { environment } from '../../../environments/environment';
import { Observable, catchError, of, take, tap } from "rxjs";
import { createPatientError, createPatientsuccess, loadingPatients, loadingPatientsError } from "../../state/actions/patients.actions";
import { IPatient } from "../../shared/models/be/patients";
import { IMedicalHistory } from "../../shared/models/be/medicalHistory";

@Injectable({
    providedIn: 'any'
  })
export class MedicalHistoryService {
  
    constructor(
      private store: Store,
      private _http: HttpClient
    ) {}

    public getMedicalHistory$(patientId: string) {
        return this._http.get<IMedicalHistory>(
            `${environment.apiUrl('ehr')}/ehr/medicalHistory/${patientId}`
            ).pipe(
            take(1),
            tap((medicalHistory: IMedicalHistory) => {
                //this.store.dispatch(loadCustomersSuccess({customers}))
            }),
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        )
    }

    public createMedicalHistory$(medicalHistory: IMedicalHistory): Observable<any> {
        return this._http.post(
            `${environment.apiUrl('ehr')}/ehr/medicalHistory`,
            medicalHistory
        ).pipe(
            take(1),
            tap(() => {
                //this.store.dispatch(createPatientsuccess({medicalHistory}))
            }),
            catchError(err => {
                this.store.dispatch(createPatientError())
                throw `error in source. Details:  + ${err}`
            })
        );
    }

    public editMedicalHistory$(medicalHistory: IMedicalHistory): Observable<any> {
        return this._http.put(
            `${environment.apiUrl('ehr')}/ehr/medicalHistory`,
            medicalHistory
        ).pipe(
            take(1),
            tap(() => {
                //this.store.dispatch(createPatientsuccess({patient}))
            }),
            catchError(err => {
                this.store.dispatch(createPatientError())
                throw `error in source. Details:  + ${err}`
            })
        );
    }
}