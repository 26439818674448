<!--   
  NOTE:

  Chiedo venia per la forzatura del tipo sugli input (gli any che vedete in giro) ma si è rivelato necessario
  in quanto, senza, provocava errori per via del fatto che i parametri indicati in seguito sono 
  talvolta presenti solo per alcuni DTO (ISimpleInput invece che ICheckboxInput per intendersi) 
-->

<ion-content [fullscreen]="true" class="page-content" color="light">
  <modal-container
      (onSubmit)="onSubmit()"
      (generateData)="generateData()"
      [formStatus]="formStatus"
      [modalHeightInRows]="modalHeight"
      [breadcrumbsList]="breadcrumbsList"
      [showGenerateDataButton]="showGenerateDataButton"
    >
    <mat-grid-list cols="8" rowHeight="36px" gutterSize="20" [ngSwitch]="formData.formType" *ngIf="formGroup">
      <!-- Simple form -->
      <ng-container *ngSwitchCase="'simple'" [formGroup]="formGroup">
        <ng-container *ngFor="let structure of formData.structure, index as structureIndex" [formGroupName]="structure.id">

          <!-- Titolo della macrosezione -->
          <mat-grid-tile colspan="8" rowspan="1">
            <b>{{'FORM.LABELS.'+structure.title | translate}}</b>
          </mat-grid-tile>

          <!-- Riga vuota per dare spazio, a sx della sezione -->
          <mat-grid-tile colspan="1" [rowspan]="structure.heightInRows"></mat-grid-tile>

          <!-- Mostra la sezione con il tipo di visualizzazione indicata -->
          <ng-container *ngFor="let children of structure.children; index as childrenIndex" [ngSwitch]="children.childrenType">
            <!-- Sezione con visualizzazione ad accordion -->
            <ng-container *ngSwitchCase="'accordion'">
              <!-- Se l'accordion è aperto allora gli do altezza 4 altrimenti 1 -->
              <mat-grid-tile colspan="6" [rowspan]="children.rowSpan || 1" style="overflow: visible;">
                <mat-accordion style="height: 100%; width: 100%;">
                  <mat-expansion-panel [expanded]="children.isAccordionOpen" (opened)="onAccordionAction(true, children, structure)" (closed)="onAccordionAction(false, children, structure)" [style.height]="children.isAccordionOpen ? '100%' : ''">
                    <mat-expansion-panel-header>
                      <mat-panel-title style="font-weight: bold;">Stanza {{formGroup.get(structure.id)?.value[childrenIndex][$any(children.inputs)[0].id]  || ""}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-grid-list cols="6" rowHeight="36px" gutterSize="20">
                      <ng-container *ngIf="!children.hideSection" [formGroupName]="childrenIndex">
                        <ng-container *ngFor="let childInput of children.inputs" [ngSwitch]="childInput.type">
                          <!-- Input semplice -->
                          <mat-grid-tile [colspan]="$any(childInput).length" [rowspan]="childInput.id === 'avatar' ? 2 : 1" class="inputGrid" *ngSwitchDefault>
                            <mat-form-field appearance="outline">
                              <mat-label>{{'FORM.LABELS.'+$any(childInput).label | translate}}</mat-label>
                              <input
                                matInput
                                sanitizeInput
                                [inputRule]="$any(childInput).inputRule"
                                [placeholder]="$any(childInput).placeholder"
                                [formControlName]="childInput.id"
                                (input)="children.score && calculateSectionScore(children, formGroup.value?.[structure.id]?.[children.id])"
                                >
                            </mat-form-field>
                          </mat-grid-tile>

                          <ng-container *ngSwitchCase="'inputArray'" [formGroupName]="childInput.id">
                            <!-- Input semplice -->
                            <mat-grid-tile *ngFor="let input of $any(childInput).inputs; index as inputIndex" [colspan]="$any(input).length" [rowspan]="1" class="inputGrid" [formArrayName]="inputIndex">
                                <mat-form-field appearance="outline">
                                  <mat-label>{{'FORM.LABELS.'+$any(input).label | translate}}</mat-label>
                                  <input
                                    matInput
                                    sanitizeInput
                                    [inputRule]="$any(input).inputRule"
                                    [placeholder]="$any(input).placeholder"
                                    [formControlName]="input.id"
                                    >
                                  <button style="background-color: transparent;" matSuffix mat-icon-button aria-label="Remove bed" (click)="removeItem('inputArray', inputIndex, structure, $any(formGroup.get(structure.id)?.get(childrenIndex+'')?.get(childInput.id)), $any(childInput), children)">
                                    <mat-icon>close</mat-icon>
                                  </button>
                                </mat-form-field>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="2" rowspan="1">
                              <ion-button color="primary" (click)="addItem('inputArray', structure, $any(childInput), $any(formGroup.get(structure.id)?.get(childrenIndex+'')?.get(childInput.id)), children)">
                                <ion-icon slot="start" name="add"></ion-icon>
                                {{'GENERAL.BUTTONS.ADD_BED' | translate}}
                              </ion-button>
                            </mat-grid-tile>
                          </ng-container>

                        </ng-container>
                        <!-- Pulsante rimozione stanza -->
                        <mat-grid-tile colspan="6" rowspan="1">
                          <hr style="width: 30%; border: 0; border-top: 2px solid #ccc;">
                          <ion-button color="danger" style="width: 30%;" (click)="removeItem('accordion', childrenIndex, structure)" [disabled]="structure.children.length === 1">
                            <ion-icon slot="start" name="remove"></ion-icon>
                            {{'GENERAL.BUTTONS.REMOVE_ROOM' | translate}}
                          </ion-button>
                          <hr style="width: 30%;  border: 0; border-top: 2px solid #ccc;">
                        </mat-grid-tile>
                        
                      </ng-container>
                    </mat-grid-list>
                  </mat-expansion-panel>
                </mat-accordion>
              </mat-grid-tile>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <ng-container *ngIf="!children.hideSection" [formGroupName]="children.id">
                <!-- Se non presente flag per vedere il pulsante di creazione risorsa allora mostro solo il titolo della sezione ed eventualmente il punteggio della sezione-->
                @if (!children.showCreateNewResource) {
                  <mat-grid-tile colspan="6" rowspan="1">
                    <!-- Titolo della sezione di Inputs -->
                    <b>{{'FORM.LABELS.'+children.title | translate}}</b>
                    <!-- Se abilitato flag di visibilità score sezione allora mostro lo score -->
                    <span style="margin-left: auto; font-weight: bold;" *ngIf="children.score" [class]="children.score.grade || ''">{{children.score.current+'/'+children.score.max}}</span>
                  </mat-grid-tile> 
                } @else {
                  <mat-grid-tile colspan="4" rowspan="1">
                    <!-- Titolo della sezione di Inputs -->
                    <b>{{'FORM.LABELS.'+children.title | translate}}</b>
                  </mat-grid-tile>
                  <mat-grid-tile colspan="2" rowspan="1" style="overflow: visible;">
                    <ion-button *ngIf="children.showCreateNewResource" color="primary" fill="outline" mode="md" (click)="createNewResource()">
                      <ion-icon slot="start" name="add"></ion-icon>
                      Crea nuova risorsa
                    </ion-button>
                  </mat-grid-tile>
                }
                <mat-grid-tile *ngFor="let input of children.inputs; index as inputIndex" [colspan]="$any(input).length" [rowspan]="input.id === 'avatar' ? 2 : 1" class="inputGrid" [ngSwitch]="input.type">
                  <!-- Input semplice -->
                  <ng-container *ngSwitchDefault>
                    <mat-form-field appearance="outline">
                      <mat-label>{{'FORM.LABELS.'+$any(input).label | translate}}</mat-label>
                      <input 
                        matInput
                        sanitizeInput
                        [inputRule]="$any(input).inputRule"
                        [placeholder]="$any(input).placeholder"
                        [formControlName]="input.id"
                        (input)="children.score && calculateSectionScore(children, formGroup.value?.[structure.id]?.[children.id])"
                        >
                    </mat-form-field>
                  </ng-container>
                  <!-- Input data -->
                  <ng-container *ngSwitchCase="'date'">
                    <mat-form-field appearance="outline">
                      <mat-label>{{'FORM.LABELS.'+$any(input).label | translate}}</mat-label>
                      <input 
                        matInput 
                        [matDatepicker]="picker"
                        [placeholder]="$any(input).placeholder"
                        [formControlName]="input.id"
                        >
                      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </ng-container>
                  <!-- Testo -->
                  <ng-container *ngSwitchCase="'simpleText'">
                    <mat-label>
                      {{'FORM.LABELS.'+$any(input).label | translate}}
                      <mat-icon *ngIf="$any(input).infoText" [matTooltip]="'FORM.LABELS.'+$any(input).infoText | translate" style="color: #24a0edb8;">info</mat-icon>
                    </mat-label>
                  </ng-container>
                  <!-- Checkbox -->
                  <ng-container *ngSwitchCase="'checkbox'">
                    @if (!$any(input).adjustSectionVisibility) {
                      <ion-checkbox labelPlacement="end" mode="md" [formControlName]="input.id" (ionChange)="$any(input).completeSection ? autoCompleteSection(structure.id, children.id, $event.detail.checked) : ''">{{'FORM.LABELS.'+$any(input).label | translate}}</ion-checkbox>
                    } @else {
                      <ion-checkbox labelPlacement="end" mode="md" [checked]="isDynamicSectionsExisting" (ionChange)="changeDynamicSectionsVisibility($event.detail.checked)">{{'FORM.LABELS.'+$any(input).label | translate}}</ion-checkbox>
                    }
                  </ng-container>
                  <!-- Select -->
                  <ng-container *ngSwitchCase="'select'">
                    <mat-form-field appearance="outline">
                      <mat-label style="display: flex;">
                        {{'FORM.LABELS.'+$any(input).label | translate}}
                        <mat-spinner class="spinner" diameter="20" *ngIf="$any(input).isLoading"></mat-spinner>
                      </mat-label>
                      <mat-select 
                        [formControlName]="input.id" 
                        (selectionChange)="checkLoader($any(children.inputs), $event.value, inputIndex)" 
                        (selectionChange)="children.score && calculateSectionScore(children, formGroup.value?.[structure.id]?.[children.id])">
                        @for (item of $any(input).values; track item) {
                          <mat-option [value]="item.id" [disabled]="item.disabled">{{$any(input).disableTranslation ? item.label : ('FORM.LABELS.'+item.label | translate)}}</mat-option>
                        }
                      </mat-select>
                      <button 
                        mat-button 
                        matSuffix 
                        *ngIf="$any(input).allowClear && formGroup.controls[structure.id].get(children.id)?.get(input.id)?.value" 
                        mat-icon-button 
                        aria-label="Clear" 
                        style="padding: 10px; background-color: transparent;" 
                        (click)="$event.stopPropagation(); formGroup.controls[structure.id].get(children.id)?.get(input.id)?.reset()"
                        >
                        <mat-icon style="color: rgba(0, 0, 0, 0.54);">close</mat-icon>
                      </button>
                    </mat-form-field>
                  </ng-container>
                  <!-- Autocomplete -->
                  <ng-container *ngSwitchCase="'autocomplete'">
                    <mat-form-field appearance="outline">
                      <mat-label>{{'FORM.LABELS.'+$any(input).label | translate}}</mat-label>
                      <input 
                        matInput
                        type="text"
                        sanitizeInput
                        [inputRule]="$any(input).inputRule"
                        [placeholder]="$any(input).placeholder"
                        [formControlName]="input.id"
                        [matAutocomplete]="auto"
                        [disabled]="!$any(input).suggestedItems?.length"
                        >
                      <mat-icon matSuffix>search</mat-icon>
                      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this, input.id)">
                        @for (option of ($any(input).suggestedItems.length ? $any(input).suggestedItems : []); track option) {
                          <mat-option [value]="option.id">{{option.label}}</mat-option>
                        }
                      </mat-autocomplete>
                    </mat-form-field>
                  </ng-container>
                  <!-- Avatar -->
                  <ng-container *ngSwitchCase="'avatar'">
                    <avatar [formControlName]="input.id" style="margin: auto;"></avatar>
                  </ng-container>
                </mat-grid-tile>
                <!-- Radio - Se abilitato mostra una tabella con i vari radio buttons -->
                <ng-container *ngIf="children.radio">
                  <!-- Lascio 2 colonne libere (larghezza della label dei radio) per permettere l'allineamento del testo degli headers agli Input  -->
                  <mat-grid-tile colspan="2" rowspan="1"></mat-grid-tile>
                  <!-- Per ogni label degli headers creo una tile di una colonna -->
                  <mat-grid-tile colspan="4" rowspan="1" style="text-align: center;">
                    <p *ngFor="let header of children.radio.headers" style="margin: 0; font-weight: bold;" [style.width]="100/children.radio.headers.length+'%'">{{'FORM.LABELS.'+header | translate}}</p>            
                  </mat-grid-tile>  
                  <ng-container *ngFor="let radioElement of children.radio.values">
                      <mat-grid-tile colspan="2" [rowspan]="('FORM.LABELS.'+radioElement.label | translate).length > 60 ? 2 : 1">
                        <mat-label>
                          {{'FORM.LABELS.'+radioElement.label | translate}}
                        </mat-label>
                      </mat-grid-tile>
                      <mat-grid-tile colspan="4" [rowspan]="('FORM.LABELS.'+radioElement.label | translate).length > 60 ? 2 : 1">
                        <mat-radio-group [formControlName]="radioElement.id" style="width: 100%; display: flex; justify-content: space-around;" (change)="children.score && calculateSectionScore(children, formGroup.value?.[structure.id]?.[children.id])">
                          <mat-radio-button *ngFor="let header of children.radio.headers, index as index" [value]="index+1"></mat-radio-button>
                        </mat-radio-group>
                      </mat-grid-tile>
                  </ng-container>
                </ng-container>
                <!-- TextLines - Se presenti righe di testo allora le mostro nel formato specificato dall'oggetto -->
                <ng-container *ngIf="children.textLines">
                  <ng-container *ngFor="let item of children.textLines">
                    <ng-container *ngIf="item.type === 'textValue'">
                      <mat-grid-tile colspan="5" rowspan="1">{{'FORM.TEXT_LINES.'+item.text | translate}}</mat-grid-tile>
                      <mat-grid-tile colspan="1" rowspan="1"><span style="margin-left: auto;">{{item.value}}</span></mat-grid-tile>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>

          <!-- PULSANTI AGGIUNTA E RIMOZIONE EDIFICIO -->
          <ng-container *ngIf="structure.canAddAndRemove">
            <!-- Pulsante rimozione risorsa -->
            <ng-container *ngIf="structure.children[0].childrenType !== 'accordion'">
              <mat-grid-tile colspan="2" rowspan="1">
                <hr style="width: 100%; border: 0; border-top: 2px solid #ccc;">
              </mat-grid-tile>
              <mat-grid-tile colspan="2" rowspan="1">
                <ion-button color="danger" (click)="removeItem('section', structure.id)">
                  <ion-icon slot="start" name="remove"></ion-icon>
                  {{'GENERAL.BUTTONS.REMOVE_'+structure.categoryLabel | translate}}
                </ion-button>
              </mat-grid-tile>
              <mat-grid-tile colspan="2" rowspan="1">
                <hr style="width: 100%;  border: 0; border-top: 2px solid #ccc;">
              </mat-grid-tile>
            </ng-container>
            <!-- Pulsante aggiunta risorsa, lo mostro solo per l'ultima sezione della lista -->
            <ng-container *ngIf="formData.structure.length === (structureIndex+1)">
              <mat-grid-tile colspan="2" rowspan="1">
                <hr style="width: 100%; border: 0; border-top: 2px solid #ccc;">
              </mat-grid-tile>
              <mat-grid-tile colspan="2" rowspan="1">
                <ion-button (click)="addItem('section')">
                  <ion-icon slot="start" name="add"></ion-icon>
                  {{'GENERAL.BUTTONS.ADD_'+structure.categoryLabel | translate}}
                </ion-button>
              </mat-grid-tile>
              <mat-grid-tile colspan="2" rowspan="1">
                <hr style="width: 100%;  border: 0; border-top: 2px solid #ccc;">
              </mat-grid-tile>
            </ng-container>
          </ng-container>

          <!-- PULSANTE AGGIUNTA STANZA -->
          <ng-container *ngIf="structure.dynamicChildren">
            <mat-grid-tile colspan="2" rowspan="1">
              <hr style="width: 100%; border: 0; border-top: 2px solid #ccc;">
            </mat-grid-tile>
            <mat-grid-tile colspan="2" rowspan="1">
              <!-- DISABILITO IL PULSANTE SE NON É STATO ASSEGNATO UN NUMERO ALL'ULTIMA STANZA IN ELENCO -->
              <ion-button (click)="addItem('accordion', structure)" [disabled]="!formGroup.get(structure.id)?.value[structure.children.length-1][structure.children[structure.children.length-1].inputs[0].id]">
                <ion-icon slot="start" name="add"></ion-icon>
                {{'GENERAL.BUTTONS.ADD_'+structure.categoryLabel | translate}}
              </ion-button>
            </mat-grid-tile>
            <mat-grid-tile colspan="2" rowspan="1">
              <hr style="width: 100%;  border: 0; border-top: 2px solid #ccc;">
            </mat-grid-tile>
          </ng-container>

        </ng-container>
      </ng-container>
    </mat-grid-list>
  </modal-container>
</ion-content>
