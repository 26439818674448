import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RequestsService } from '../utils/requests.service';

@Injectable({ providedIn: 'root' })
export class SideBarResolver implements Resolve<any> {
    constructor(
        private requestsService: RequestsService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.requestsService.changeSelectedItem(state.url.split("/wita/")[1]);
        return; // Puoi restituire un valore se necessario per altri componenti
    }
}