<mat-grid-list cols="12" rowHeight="36px" gutterSize="20" style="height: 100%;">
  <mat-grid-tile colspan="4" rowspan="1"></mat-grid-tile>
  <mat-grid-tile colspan="4" rowspan="1" style="overflow: visible;">
    <div style="width: 100%; height: 100%; display: flex; flex-direction: column; padding: 5px;">
      <p style="text-align: center; margin: 3px; font-weight: bold;">Privilegi</p>
      <p style="text-align: center; margin: 3px;">Mario Rossi - OSS</p>
    </div>
  </mat-grid-tile>
  <mat-grid-tile colspan="12" rowspan="6">
    <div class="mat-elevation-z8" style="height: 90%; width: 90%; margin: auto;">
      <form [formGroup]="form">
        <mat-table [dataSource]="dataSource" style="height: 100%; overflow: auto;" matSort class="mat-elevation-z0">
          <ng-container
            *ngFor="let column of tableColumns; let last = last"
            [matColumnDef]="column.id"
          >
            <!-- Generic -->
            <ng-container>
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                <span style="font-weight: bold;">{{ 'TABLE.HEADER.'+column.label | translate }}</span>
              </mat-header-cell>
            </ng-container>
            <!-- VALORE RIGA -->
            <mat-cell *matCellDef="let element;" [ngSwitch]="column.id">
              <ng-container class="generic_text" *ngSwitchCase="'functionality'">
                {{element[column.id] || '-'}}
              </ng-container>
              <ng-container *ngSwitchDefault [formGroup]="$any(form.controls[element.id])">
                <mat-checkbox [id]="column.id" color="primary" [formControlName]="column.id"></mat-checkbox>
              </ng-container>
            </mat-cell>
          </ng-container>
          <!-- Header row -->
          <mat-header-row *matHeaderRowDef="['functionality','canView','canEdit']"></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: ['functionality','canView','canEdit']"
            [class.redrow]="row?.late"
          ></mat-row>
          <!-- Quando non vengono trovati dati mostro questa riga -->
          <tr *matNoDataRow class="no-results-row">
            <td class="mat-cell" colspan="4">
              {{'TABLE.NO_RESULTS' | translate}}
            </td>
          </tr>
        </mat-table>
      </form>
    </div>
  </mat-grid-tile>
  <mat-grid-tile colspan="3" rowspan="1"></mat-grid-tile>
  <mat-grid-tile colspan="3" rowspan="1" style="overflow: visible;">
    <ion-button mode="md" fill="outline" style="width: 100%;">{{'GENERAL.BUTTONS.RESET_DEFAULT' | translate}}</ion-button>
  </mat-grid-tile>
  <mat-grid-tile colspan="3" rowspan="1">
    <ion-button style="width: 100%;" (click)="saveAndClose()" [mat-dialog-close]="true">{{'GENERAL.BUTTONS.SAVE_AND_CLOSE' | translate}}</ion-button>
  </mat-grid-tile>
  <mat-grid-tile colspan="12" rowspan="1"></mat-grid-tile>
</mat-grid-list>