import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { environment } from '../../../environments/environment';
import { loadCustomersSuccess, loadingCustomers, loadingCustomersError } from "../../state/actions/customers.actions";
import { Observable, catchError, of, tap } from "rxjs";
import { ICustomer } from "../../shared/models/be/customers";
import { IEvaluation } from "../../shared/models/be/evaluation";
@Injectable({
    providedIn: 'any'
  })
export class EvaluationService {

    constructor(
      private store: Store,
      private _http: HttpClient
    ) {}

    /**
     * @description Carica i punteggi dei vari Input della scala di valutazione indicata
     */
    public getEvaluationInputScores$(evaluationScale: 'clinical' | 'psychological'): Observable<any> {
      return this._http.get<{customers: ICustomer[]}>(
        `${environment.apiUrl('ehr')}/ehr/evaluation/inputScores/${evaluationScale}`
        ).pipe(
          tap((evaluation) => {
              //this.store.dispatch(loadCustomersSuccess(customers))
          }),
          catchError(err => {
              //this.store.dispatch(loadingCustomersError())
              throw 'error in source. Details: ' + err;
          })
      )
    }

    /**
     * @description Carica i valori dei vari Input compilati per il paziente indicato
     */
    public getEvaluationValues$(patientId: string): Observable<IEvaluation> {
        return this._http.get<IEvaluation>(
          `${environment.apiUrl('ehr')}/ehr/evaluation/${patientId}`
          ).pipe(
            tap((evaluation) => {
                //this.store.dispatch(loadCustomersSuccess(customers))
            }),
            catchError(err => {
                //this.store.dispatch(loadingCustomersError())
                throw 'error in source. Details: ' + err;
            })
        )
      } 

    public sendEvaluation$(evaluation: IEvaluation, patientId: string) {
        return this._http.post(
            `${environment.apiUrl('ehr')}/ehr/evaluation/${patientId}`,
            evaluation
        ).pipe(
            tap(() => {
                //this.store.dispatch(createCustomerSuccess({customer}))
            }),
            catchError(err => {
                //this.store.dispatch(createCustomerError())
                throw `error in source. Details:  + ${err}`
            })
        ).subscribe();
    }
}