import { Component, Input, OnInit } from '@angular/core';
import { IUser, UserRole } from '../../models/be/user';
import { RoleToTranslation } from '../../enums/userRole.enum';
import { SharedModule } from '../../modules/shared.module';

@Component({
  selector: 'user-section-sidenav',
  templateUrl: './user-section-sidenav.component.html',
  styleUrls: ['./user-section-sidenav.component.scss'],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class UserSectionSidenavComponent implements OnInit {
  @Input() userData?: IUser;

  constructor(
  ) { }

  ngOnInit() {
    if (this.userData?.role) {
      this.userData.role = 'PAGES.USERS.ROLES.'+RoleToTranslation[this.userData.role] as UserRole
    } 
  }

}
