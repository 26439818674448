import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { NavigationEnd, NavigationStart, Router, RouterModule } from '@angular/router';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { filter, map, tap } from 'rxjs';
import { AuthService } from '../../../../core/services/auth.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IUser } from '../../../../shared/models/be/user';
import { RequestsService } from '../../../../core/services/utils/requests.service';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SensorTimingDialogComponent } from '../../../../shared/components/sensorTimingDialog/sensor-timing-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SensorService } from '../../../../core/services/sensors.service';
import { FacilitiesService } from '../../../../core/services/facilities.service';
import { IonContent } from '@ionic/angular/standalone';
import { SideNavService } from '../../../../core/services/utils/sidenav.service';
import { RingtoneService } from '../../../../core/services/utils/ringtone.service';
import { NotificationService } from '../../../../core/services/notification.service';

@Component({
  selector: 'app-root-mobile',
  templateUrl: './root.mobile.page.html',
  styleUrls: ['./root.mobile.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatSidenavModule,
    MatGridListModule,
    MatSidenavModule,
    MatListModule,
    MatExpansionModule,
    MatIconModule,
    TranslateModule,
    MatToolbarModule,
    IonContent
  ],
})
export class RootMobilePage implements OnInit {
  @ViewChild('sidenav') sidenav!: MatSidenav;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  public loggedUser?: IUser;
  public buildingName: string = '';
  public menuItems: IMenuItem[] = [];

  constructor(
    private router: Router,
    private authService: AuthService,
    private alertController: AlertController,
    private translateService: TranslateService,
    public dialog: MatDialog,
    private requestsService: RequestsService,
    private sensorService: SensorService,
    private facilitiesService: FacilitiesService,
    public sideNavService: SideNavService,
    private notificationService: NotificationService
  ) {
    this.loggedUser = requestsService.getCurrentUser() || undefined;
    requestsService.currentUser$.pipe(
      tap((currentUser: IUser | null) => {
        this.loggedUser = currentUser || undefined;
        this.buildingName = currentUser?.building?.name || '';
      })
    ).subscribe();
  }

  ngOnInit() {
    this.loggedUser = this.requestsService.getCurrentUser() || undefined;
    this.menuItems = [
      {
        id: 'settings',
        label: 'SETTINGS',
        icon: 'settings',
        routerLink: 'settings'
      },
      {
        id: 'logout',
        label: 'LOGOUT',
        icon: 'logout',
        routerLink: ''
      }
    ];

    if(this.authService.isLoggedIn()){
      // all'apertura dell'app con l'utente già loggato, aggiorna il firebase token per sicurezza nel caso in cui sia cambiato
      //this.authService.updateUserDevice$().pipe(take(1)).subscribe();
      // Se l'utente è già loggato allora aggiorno l'Observable contenente i dati dello user dal momento che all'inizio non sarà mai valorizzato
      this.requestsService.saveCurrentUser(this.requestsService.getCurrentUser());
      if (this.requestsService.getCurrentUser()?.building?.id) {
        this.notificationService.requestPermission();
        this.notificationService.listen();
      }
    }
  }

  goHome() {
    this.router.navigateByUrl("witaMobile/monitoring");
    this.sideNavService.isHome = true;
  }

  logout() {
    this.authService.logout$().subscribe()
  }

  reloadPage() {
    window.location.reload();
  }

  openTimingDialog() {
    const dialogRef = this.dialog.open(SensorTimingDialogComponent, {
      data: {
        /* title: `${room.name} (${unit.name})`,
        patients: room.patients,
        alert: room.alert */
        devicesRequest: this.sensorService.getDevices$(),
        facilitiesService: this.facilitiesService,
        sensorService: this.sensorService
      },
      width: '95vw',
      maxWidth: '95vw',
      minHeight: '75vh'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
    
  }
}

interface IMenuItem {
  /**
   * Identificativo della voce di menu
   */
  id: string;
  /**
   * Label della voce di menu
   */
  label: string;
  /**
   * Icona, N.B. Deve essere di Angular Material l'icona
   */
  icon: string;
  /**
   * Route della pagina in cui si vuole navigare, opzionale nel caso siano presenti figli
   */
  routerLink?: string;
  /**
   * Se true richiede la presenza di una organizzazione, qualora non presente comparirà un Popup
   */
  requireOrganization?: boolean;
  /**
   * Opzionale, se presenti figli si inseriscono qua, il DTO è lo stesso di IMenuItem (ma senza children, non si possono annidare voci di menu più volte)
   */
  children?: Omit<IMenuItem, 'children'>[];
  /**
   * Opzionale, se true non viene mostrata la voce di menu
   */
  hide?: boolean;
}
