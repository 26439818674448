import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';
import { catchError, take, tap } from 'rxjs';
import { ToastService } from '../../../core/services/utils/toast.service';
import { AuthService } from '../../../core/services/auth.service';
import { LoggerService } from '../../../core/services/utils/logger.service';
import { CustomValidator } from '../../../shared/utils/custom-validators';
import { UserCredentials } from '../../../shared/models/user-credentials';
import { SharedModule } from '../../../shared/modules/shared.module';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { IonicColors } from '../../../shared/enums/ionicColors.enum';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatGridListModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    RouterModule
  ]
})
export class LoginPage implements OnInit {
  public showPassword: boolean = false;
  public selectedLang: 'it' | 'en' | 'fr' = 'it';
  public section: SectionType = 'login';
  authForm = new FormGroup<any>({});
  forgotPwForm = new FormGroup<any>({});
  resetPwForm = new FormGroup<any>({});
  resetPwToken?: string;
  constructor(
    private translateService: TranslateService,
    private toastService: ToastService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private loggerService: LoggerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }
  
  isSubmitted: boolean = false;
  public hasForgotPw: boolean = false;

  ngOnInit(): void {
    this.authForm = this.formBuilder.group({
      email: ['', [Validators.required, CustomValidator.emailValidator]],
      password: ['', [Validators.required]]
    });
    this.forgotPwForm = this.formBuilder.group({
      email: ['', [Validators.required, CustomValidator.emailValidator]]
    });
    this.resetPwForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    });
    this.selectedLang = this.translateService.currentLang as 'it' | 'en' | 'fr' || 'it';

    this.resetPwToken = this.route.snapshot.queryParams['token'] || undefined;

    this.activatedRoute.fragment.subscribe((fragment: string | null) => {
      this.section = fragment as SectionType;
    });
  }

  /**
   * @description Funzione invocata al click di Reimposta, dopo aver fatto 'password dimenticata?'
   */
  submitMail() {
    if (this.resetpwFormControls['email']['errors'] || this.resetPwForm.invalid) {
      this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.UNCORRECT_FIELDS_COMPILATION'), IonicColors.RED);
      return;
    }
    const emailObject = {
      email: this.resetPwForm.controls['email'].value,
      type: '10'
    };
    this.authService.sendEmail$(emailObject)
      .pipe(take(1))
      .subscribe({
        complete: () => {
          this.toastService.showToast(this.translateService.instant('GENERAL.SUCCESS.EMAIL_SENT'), IonicColors.GREEN, 6000);
          this.hasForgotPw = false;
        },
        error: () => {
          this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.UNKNOWN_EMAIL'), IonicColors.RED);
        }
      });
  }

  /**
   * @description Funzione invocata al click di Login
   */
  submitCredentials() {
    this.isSubmitted = true;
    if (this.authFormControls['email']['errors'] || this.authFormControls['password']['errors'] || this.authForm.invalid) {
      this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.UNCORRECT_FIELDS_COMPILATION'), IonicColors.RED);
      return;
    }
    let {email,password}: UserCredentials = this.authForm["value"];
    this.authService.login(
      {
        email,
        password,
      },
      () => this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.WRONG_CREDENTIALS'), IonicColors.RED)
    );
  }

  /**
   * @description Funzione invocata al cambiamento della lingua, vengono impostate le traduzioni di conseguenza
   */
  onLanguageSelect(language: 'it' | 'en' | 'fr') {
    this.translateService.use(language);
    this.loggerService.debug(`Selezionata lingua: ${language}`);
  }

  /**
   * @description Funzione invocata al click del pulsante indietro, resetta l'input di inserimento email
   */
  onBack() {
    this.hasForgotPw = false; 
    this.resetpwFormControls['email'].reset();
  }

  resetPw() {
    this.resetPwToken && this.authService.setPassword$({
      token: this.resetPwToken,
      password: this.resetPwForm.value['password']
    }).pipe(
      tap(() => {
        this.toastService.showToast(this.translateService.instant('GENERAL.SUCCESS.OPERATION_SUCCESS'), IonicColors.GREEN)
        this.router.navigateByUrl('/auth/login')
      }), 
      catchError((err) => {
        this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.RESET_PW'), IonicColors.RED)
        throw err;
      })
    ).subscribe();
  }

  sendResetPwMail() {
    this.authService.sendForgotPwMail({
      email: this.forgotPwForm.value['email']
    }).pipe(
      tap(() => {
        this.toastService.showToast(this.translateService.instant('GENERAL.SUCCESS.OPERATION_SUCCESS'), IonicColors.GREEN)
        this.router.navigateByUrl('/auth/login')
      }), 
      catchError((err) => {
        this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.RESET_PW'), IonicColors.RED)
        throw err;
      })
    ).subscribe();
  }

  get authFormControls() {
    return this.authForm.controls
  }

  get resetpwFormControls() {
    return this.resetPwForm.controls
  }
}

type SectionType = 'login' | 'forgot_pw' | 'reset_pw';