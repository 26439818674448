import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import {
  Subject,
  tap,
  take,
  catchError,
  of,
} from 'rxjs';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  IOptionOutput,
  ICtaOutput,
  ITableColumns,
  ITableConfigParams,
  ITableData,
  TableComponent,
  ITableConfigParamsFilter,
} from '../../../shared/components/table/table.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { ToastService } from '../../../core/services/utils/toast.service';
import { IonicColors } from '../../../shared/enums/ionicColors.enum';
import { ResourcesStructure } from '../../../shared/components/form/formResourcesStructure';
import { SensorService } from '../../../core/services/sensors.service';
import { ISimpleInput } from '../../../shared/components/form/form.component';
import { DialogComponent, IDialogData } from '../../../shared/components/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Validators } from '@angular/forms';
import { FacilitiesService } from '../../../core/services/facilities.service';
import moment from 'moment';
import { RequestsService } from '../../../core/services/utils/requests.service';
import { IDevice } from '../../../shared/models/be/device';
import { FiltersList } from '../../../shared/components/table/filtersList';
import { IonContent } from "@ionic/angular/standalone";

@Component({
  selector: 'devices',
  templateUrl: './devices.page.html',
  styleUrls: ['./devices.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatGridListModule,
    MatIconModule,
    TableComponent,
    TranslateModule,
    RouterModule,
    IonContent
  ]
})
export class DevicesPage implements OnInit {
  public tableColumns: ITableColumns[] = [
    /* { label: 'ID', id: 'id', showCol: true }, */
    { label: 'STATUS', id: 'status', showCol: true, cannotHide: true },
    { label: 'REF', id: 'label', showCol: true },
    { label: 'LINK_STATUS', id: 'linked', showCol: true },
    { label: 'ID_RUST', id: 'macAddress', showCol: true },
    { label: 'CREATION_DATE', id: 'createdAt', showCol: false },
    { label: 'ORGANIZATION', id: 'organization', showCol: false },
    { label: 'BUILDING', id: 'building', showCol: false },
    { label: 'UNIT', id: 'unit', showCol: false },
    { label: 'ROOM', id: 'room', showCol: true },
  ];

  public tableData: ITableData[] = [];

  public tableConfigParams: ITableConfigParams = {
    id: "devices",
    referenceLabel: 'DEVICES',
    searchBy: ['label', 'macAddress'],
    hideAddBtn: true,
    filters: [],
    defaultSort: 'label'
  };
  
  /**
   * Definisce l'altezza della form in numero di righe, di default 1, viene poi valorizzato correttamente nel costruttore
   */
  public patientFormRows: number = 1;
  public admissionFormRows: number = 1;
  public medicalHistoryFormRows: number = 1;
  loadingElements: boolean;
  hasError: boolean;
  public isDataLoading: boolean = false;
  private autoReload = setInterval(() => {
    this.loadDevices(false, true);
  }, 15000)

  public wifiCredentialsForm: ISimpleInput[] = [
    {
      id: 'ssid',
      type: 'input',
      length: 6,
      label: 'INPUT_LABELS.SSID',
      validators: [Validators.required]
    },{
      id: 'password',
      type: 'input',
      length: 6,
      label: 'INPUT_LABELS.PASSWORD',
      validators: [Validators.required]
    }
  ];

  public editNameForm: ISimpleInput[] = [
    {
      id: 'firstDigit',
      type: 'input',
      length: 0,
      inputValue: '',
      label: 'INPUT_LABELS.NEW_DEVICE_NAME',
      inputRule: 'number',
      validators: [Validators.required]
    },{
      id: 'secondDigit',
      type: 'input',
      length: 0,
      inputValue: '',
      label: 'INPUT_LABELS.NEW_DEVICE_NAME',
      inputRule: 'number',
      validators: [Validators.required]
    },{
      id: 'thirdDigit',
      type: 'input',
      length: 0,
      inputValue: '',
      label: 'INPUT_LABELS.NEW_DEVICE_NAME',
      inputRule: 'number',
      validators: [Validators.required]
    },{
      id: 'fourthDigit',
      type: 'input',
      length: 0,
      inputValue: '',
      label: 'INPUT_LABELS.NEW_DEVICE_NAME',
      inputRule: 'number',
      validators: [Validators.required]
    }
  ];

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public sensorService: SensorService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private resourceStructure: ResourcesStructure,
    private alertController: AlertController,
    private dialog: MatDialog,
    private facilitiesService: FacilitiesService,
    private requestsService: RequestsService,
    private filtersList: FiltersList,
  ) {
    //this.elements = []
    this.loadingElements = false;
    this.hasError = false;
  }

  ionViewDidEnter() {
    this.loadElements();
  }

  private destroyed$ = new Subject();

  ngOnInit() {
    this.loadFilters();
    this.loadDevices();
  }

  loadFilters() {
    !this.tableConfigParams.filters?.length && (this.tableConfigParams.filters = [
      this.filtersList.getFilterById('status'),
      this.filtersList.getFilterById('facilities'),
      !this.requestsService.hasLinkedOrg() && (this.filtersList.getFilterById('organization')),
      this.filtersList.getFilterById('building'),
      this.filtersList.getFilterById('unit'),
      this.filtersList.getFilterById('room'),
    ].filter((filter) => Boolean(filter)) as ITableConfigParamsFilter[])
  }

  /**
   * @description Funzione che va ad aggiornare i dati della table in pagina
   */
  loadDevices(refreshData: boolean = false, reloadOnlyOnStatusChange: boolean = false) {
    console.log("Loading data...")
    if (refreshData && this.isDataLoading) {
      return;
    }
    this.isDataLoading = !reloadOnlyOnStatusChange && true;
    this.sensorService.getDevices$(undefined, true).pipe(
      take(1),
      tap((devices) => {
        if (reloadOnlyOnStatusChange && this.tableData) {
          const devicesByStatus = this.tableData.reduce((acc, curr) => {
            acc[(curr.data as IDevice).id] = (curr.data as IDevice).status
            return acc;
          }, {} as {[key: string]: string})
          if (!devices.some((device) => devicesByStatus[device.id] !== device.status)) {
            return;
          }
        }
        this.tableData = devices.map((device) => {
          return {
            rowStyle: {
              disabledLine: device.status === 'disabled'
            },
            data: {
              ...device,
              facilities: !!device.facilities?.room,
              room: device.facilities?.room?.name || null,
              building: device.facilities?.building?.name || null,
              unit: device.facilities?.unit?.name || null,
              activeAllerts: null,
              organization: device.facilities?.organization?.name || null,
              createdAt: moment(device.createdAt).format('DD/MM/YYYY'),
              updatedAt: moment(device.updatedAt).format('DD/MM/YYYY'),
              linked: !!device.facilities?.room ? this.translateService.instant('TABLE.FILTER.OPTIONS.LINKED') : this.translateService.instant('TABLE.FILTER.OPTIONS.NOT_LINKED')
            },
            options: [
              ...['online', 'offline'].includes(device.status) ? [{
                action: 'disable',
                icon: 'lock',
                label: 'DISABLE'
              },{
                action: 'linkRoom',
                icon: 'deployed_code_update',
                label: device.facilities?.room  ? 'EDIT_LINKED_ROOM' : 'LINK_ROOM'
              },{
                action: 'unlink',
                icon: 'close',
                label: 'UNLINK_DEVICE'
              }] : [],
              ...device.status === 'online' ? [{
                action: 'changeNetwork',
                icon: 'wifi',
                label: 'CHANGE_NETWORK_CREDENTIALS'
              },{
                action: 'viewStream',
                icon: 'visibility',
                label: 'VIEW_STREAM'
              }] : [],
              ...device.status === 'disabled' ? [{
                action: 'enable',
                icon: 'lock_open',
                label: 'ENABLE'
              },{
                action: 'delete',
                icon: 'delete',
                label: 'DELETE'
              }] : [],

              {
                action: 'edit',
                icon: 'edit',
                label: 'EDIT_SENSOR_NAME',
              }
            ].filter((x) => x),
          } as ITableData;
        });
        if (!this.requestsService.hasLinkedOrg()) {
          const organizationFilter = this.tableConfigParams.filters?.find((filter) => filter.id === 'organization');
          if (organizationFilter) {
            organizationFilter.options = [];
            organizationFilter.options = devices.reduce((acc, curr) => {
              if (curr.facilities?.organization?.id && !acc.find((opt) => opt.id === curr.facilities!.organization!.id)) {
                acc.push({id: curr.facilities.organization.id, name: curr.facilities.organization.name})
              }
              return acc;
            }, [] as {id: string; name: string}[]);
          } 
        }
        this.isDataLoading = false;
        refreshData && this.toastService.showToast(this.translateService.instant('GENERAL.SUCCESS.DATA_REFRESHED'), IonicColors.GREEN);
      }),
      catchError(err => {
        this.isDataLoading = false;
        refreshData && this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.LOADING_DATA'), IonicColors.RED);
        throw err
      })
    ).subscribe()
  }

  /**
   * @description Funzione invocata al click di una opzione della table
   * @param event Viene passato un oggetto contenente l'id delle risorsa in oggetto e l'action invocata
   */
  async onOptionAction(event: IOptionOutput) {
    switch (event.action) {
      case 'linkRoom':
        if (!this.requestsService.hasLinkedOrg()) {
          const customerAlert = await this.alertController.create({
            mode: 'md',
            header: this.translateService.instant('GENERAL.MESSAGES.CAUTION'),
            message: this.translateService.instant('GENERAL.MESSAGES.ORGANIZATION_REQUIRED_ON_DEVICE_LINK_MESSAGE'),
            buttons: [
              {
                text: this.translateService.instant('GENERAL.BUTTONS.SETTINGS'),
                handler: () => {
                  customerAlert.dismiss();
                  this.router.navigateByUrl('wita/settings');
                },
              },
              {
                text: this.translateService.instant('GENERAL.BUTTONS.CANCEL'),
                handler: () => {
                  customerAlert.dismiss();
                },
              }
            ],
          });
          await customerAlert.present();
        } else {
          if (event.resource['room']) {
            this.router.navigate([`/wita/devices/link/${event.resource.id}`], { state: { additionalData: event.resource, breadCrumbSubject: event.resource['label']}});
          } else {
            this.router.navigate([`/wita/devices/link`], { state: { additionalData: event.resource, breadCrumbSubject: event.resource['label']}});
          }
        }
        break;
      case 'unlink':
        this.isDataLoading = true;
        this.sensorService.linkDevice$(event.resource.id).pipe(
          tap(() => {
            this.toastService.showToast(this.translateService.instant('GENERAL.SUCCESS.UNLINKED_DEVICE'), IonicColors.GREEN);
            this.loadDevices(false);
          }),
          catchError((err) => {
            this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.UNLINKED_DEVICE'), IonicColors.RED);
            throw err;
          })
        ).subscribe()
        break;
      case 'viewStream':
        this.dialog.open(DialogComponent, {
          data: {
            title: this.translateService.instant('PAGES.DEVICES.SET_NETWORK_CREDENTIALS'),
            mode: 'streaming',
            additionalData: {
              id: event.resource.id,
              name: event.resource.label
            }
          } as IDialogData
        });
        break;
      case 'disable':
        this.isDataLoading = true;
        this.sensorService.disableDevice$(event.resource.id).pipe(
          tap(() => {
            this.toastService.showToast(this.translateService.instant('GENERAL.SUCCESS.DISABLING_DEVICE'), IonicColors.GREEN);
            this.loadDevices(false);
          }),
          catchError((err) => {
            this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.DISABLING_DEVICE'), IonicColors.RED);
            this.isDataLoading = false;
            throw err;
          })
        ).subscribe();
        break;
      case 'changeNetwork':
        const dialrogRef = this.dialog.open(DialogComponent, {
          data: {
            formData: this.wifiCredentialsForm,
            title: this.translateService.instant('PAGES.DEVICES.SET_NETWORK_CREDENTIALS'),
            mode: 'form'
          } as IDialogData
        });
        dialrogRef.afterClosed().subscribe((data) => {          
          this.isDataLoading = true;
          of(true).pipe(
            tap(() => this.toastService.showToast(this.translateService.instant('GENERAL.SUCCESS.DEVICE_NETWORK_CREDENTIALS'), IonicColors.GREEN)),
            catchError((err) => {
              this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.DEVICE_NETWORK_CREDENTIALS'), IonicColors.RED);
              throw err;
            })
          ).subscribe();
        });
        break;
      case 'enable':
        this.isDataLoading = true;
        this.sensorService.enableDevice$(event.resource.id).pipe(
          tap(() => {
            this.toastService.showToast(this.translateService.instant('GENERAL.SUCCESS.ENABLING_DEVICE'), IonicColors.GREEN);
            this.loadDevices(false);
          }),
          catchError((err) => {
            this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.ENABLING_DEVICE'), IonicColors.RED);
            throw err;
          })
        ).subscribe();
        break;
      case 'delete':
        const hasFacilities: boolean = event.resource.facilities;
        const customerAlert = await this.alertController.create({
          mode: 'md',
          header: this.translateService.instant('GENERAL.MESSAGES.CAUTION'),
          message: hasFacilities ? this.translateService.instant('GENERAL.MESSAGES.FACILITIES_FOUND_ON_DEVICE_DELETE_MESSAGE') : this.translateService.instant('GENERAL.MESSAGES.DELETE_DEVICE_MESSAGE'),
          buttons: [
            {
              text: this.translateService.instant('GENERAL.BUTTONS.OK'),
              handler: () => {
                customerAlert.dismiss();
                if (!hasFacilities) {                  
                  this.isDataLoading = true;
                  this.sensorService.deleteDevice$(event.resource.label).pipe(
                    tap(() => {
                      this.loadDevices()
                    })
                  ).subscribe();
                }
              },
            },
            ...hasFacilities ? [{
              text: this.translateService.instant('GENERAL.BUTTONS.CANCEL'),
              handler: () => {
                customerAlert.dismiss();
              },
            }] : [],
          ],
        });
        await customerAlert.present();
        break;
      case 'edit':
        const currentValue = Array.from(event.resource.label.replace('MENT-', ''));
        this.editNameForm.forEach((input, idx) => {
          input.inputValue = currentValue[idx] as string;
        });
        const dialrogRefEdit = this.dialog.open(DialogComponent, {
          data: {
            formData: this.editNameForm,
            additionalData: {
              valuesToCheck: this.tableData.map((tableData) => (tableData.data as IDevice).label?.replace('MENT-', '')).filter((value) => value != event.resource.label.replace('MENT-', ''))
            },
            title: this.translateService.instant('PAGES.DEVICES.CHANGE_SENSOR_NAME'),
            mode: 'editDeviceName'
          } as IDialogData
        });
        dialrogRefEdit.afterClosed().subscribe((data: number[] | false) => {
          if (data) {
            this.isDataLoading = true;
            this.sensorService.editDeviceName$({
              oldLabel: 'MENT-'+currentValue.join(''),
              newLabel: 'MENT-'+data.join('')
            }).pipe(
              tap(() => {
                this.toastService.showToast(this.translateService.instant('GENERAL.SUCCESS.DEVICE_NETWORK_CREDENTIALS'), IonicColors.GREEN);
                this.loadDevices(false);
              }),
              catchError((err) => {
                this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.DEVICE_NETWORK_CREDENTIALS'), IonicColors.RED);
                throw err;
              })
            ).subscribe();
          } 
        });
        break;
      default:
        this.router.navigate(['/wita/devices']);
        break;
    }
  }

  /**
   * @description Funzione invocata al click di un button (Aggiungi risorsa, refresh dati, ecc...)
   * @param event Viene passato un oggetto contenente il tipo di action invocata
   */
  onCtaAction(event: ICtaOutput) {
    switch (event.action) {
      case 'refresh':
        this.loadDevices(true);
        break;
      case 'add':
        this.router.navigate(['/wita/devices/new']);
        break;
      default:
        break;
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(1);
    this.destroyed$.complete();
    clearInterval(this.autoReload)
  }

  loadElements() {
    //this.monitoringService.loadMonitoringElements()
  }

  handlePressElement(event: any) {
    //let element = this.elements.find(element => element.id === event);
    //this.router.navigate(['/wita/appMonitoring/monitoringRoot/sensors/bed', { id: element?.id }])
  }

  handleSelectElement(event: any) {
    //let element = this.elements.find(element => element.id === event);
    //console.log(element)
    console.log('Element selected');
  }
}
