import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { CustomersPage } from '../customers/customers.page';
import { UsersPage } from '../users/users.page';
import { PatientsPage } from '../patients/patients.page';
import { inject } from '@angular/core';
import { CustomerService } from '../../../core/services/customer.service';
import { FormComponent, IFormResource } from '../../../shared/components/form/form.component';
import { PatientService } from '../../../core/services/patient.service';
import { SensorService } from '../../../core/services/sensors.service';
import { SettingsPage } from '../settings/settings.page';
import { AdmissionService } from '../../../core/services/admission.service';
import { StructurePage } from '../structure/structure.page';
import { FacilitiesService } from '../../../core/services/facilities.service';
import { UserService } from '../../../core/services/user.service';
import { DevicesPage } from '../devices/devices.page';
import { organizationGuard } from '../../../core/guards/organization.guard';
import { SideBarResolver } from '../../../core/services/resolvers/sideBar.resolver';
import { DeveloperSettingsPage } from '../developerSettings/developerSettings.page';
import { MentoragePage } from '../mentorage/mentorage.page';
import { AnalyticsPage } from '../analytics/analytics.page';
import { AlertsHistoryPage } from '../alertsHistory/alertsHistory.page';

export const RootRoutes: Routes = [
  /* Clienti */
  {
    path: "customers",
    children: [
      /* Pagina Clienti */
      {
        path: "",
        component: CustomersPage
      },
      /* Modifica cliente */
      {
        path: 'edit/:resourceId',
        component: FormComponent,
        resolve: {data: (route: ActivatedRouteSnapshot) => ({
          data: inject(CustomerService).getCustomer$(route.params['resourceId']),
          category: 'customer',
          breadcrumbs: [{
            icon: 'group',
            routerLink: '/wita/customers',
            label: 'CUSTOMERS'
          }, {
            label: 'EDIT_CUSTOMER',
            showBreadCrumbSubject: true
          }]
        } as IFormResource)}
      }, 
      /* Nuovo cliente */
      {
        path: 'new',
        component: FormComponent,
        resolve: {data: () => ({
          category: 'customer',
          breadcrumbs: [{
            icon: 'group',
            routerLink: '/wita/customers',
            label: 'CUSTOMERS'
          }, {
            label: 'NEW_CUSTOMER'
          }]
        })}
      },
      /* Nuova organizzazione per cliente */
      {
        path: 'new_org',
        component: FormComponent,
        resolve: {data: () => ({
          category: 'organization',
          breadcrumbs: [{
            icon: 'group',
            routerLink: '/wita/customers',
            label: 'CUSTOMERS'
          }, {
            label: 'NEW_ORGANIZATION'
          }]
        })}
      },
      /* Modifica organizzazione */
      {
        path: 'edit_org/:resourceId/:customerId',
        component: FormComponent,
        resolve: {data: (route: ActivatedRouteSnapshot) => ({
          data: inject(FacilitiesService).getOrganizationById$(route.params['resourceId'], route.params['customerId']),
          category: 'organization',
          breadcrumbs: [{
            icon: 'group',
            routerLink: '/wita/customers',
            label: 'CUSTOMERS'
          }, {
            label: 'EDIT_ORGANIZATION'
          }]
        })}
      },
    ],
    resolve: {
      sideBarResolver: SideBarResolver
    }
  },
  /* Utenti */
  {
    path: "users",
    children: [
      /* Pagina Utenti */
      {
        path: '',
        component: UsersPage,
      },
      /* Nuovo utente */
      {
        path: 'new',
        component: FormComponent,
        resolve: {data: () => ({
          category: 'user',
          breadcrumbs: [{
            icon: 'group',
            routerLink: '/wita/users',
            label: 'USERS'
          }, {
            label: 'NEW_USER'
          }]
        })}
      },
      /* Modifica utente */
      {
        path: 'edit/:resourceId',
        component: FormComponent,
        resolve: {data: (route: ActivatedRouteSnapshot) => ({
          data: inject(UserService).getUser$(route.params['resourceId']),
          category: 'user',
          breadcrumbs: [{
            icon: 'groups',
            routerLink: '/wita/users',
            label: 'USERS'
          }, {
            label: 'EDIT_USER'
          }]
        })}
      }
    ],
    resolve: {
      sideBarResolver: SideBarResolver
    }
  },
  /* Struttura */
  {
    path: "structure",
    children: [
      /* Pagina Struttura */
      {        
        path: "",
        component: StructurePage,
      },
      /* Modifica edificio */
      {
        path: 'edit_building/:buildingId',
        component: FormComponent,
        resolve: {data: (route: ActivatedRouteSnapshot) => ({
          data: inject(FacilitiesService).getBuilding$(route.params['buildingId']),
          category: 'building',
          breadcrumbs: [{
            icon: 'group',
            routerLink: '/wita/structure',
            label: 'STRUCTURE'
          },{
            label: 'EDIT_BUILDING'
          }]
        })}
      },
      /* Nuovo edificio */
      {
        path: 'new_building',
        component: FormComponent,
        resolve: {data: () => ({
          category: 'building',
          breadcrumbs: [{
            icon: 'group',
            routerLink: '/wita/structure',
            label: 'STRUCTURE'
          },{
            label: 'NEW_BUILDING'
          }]
        })}
      },
      /* Nuovo reparto */
      {
        path: 'new_ward',
        component: FormComponent,
        resolve: {data: () => ({
          category: 'structure',
          breadcrumbs: [{
            icon: 'group',
            routerLink: '/wita/structure',
            label: 'STRUCTURE'
          }, {
            label: 'NEW_WARD'
          }]
        })}
      },
      /* Modifica reparto */
      {
        path: 'edit_ward/:unitId',
        component: FormComponent,
        resolve: {data: (route: ActivatedRouteSnapshot) => ({
          data: inject(FacilitiesService).getWardByIdWithRoomsAndBeds$(route.params['unitId']),
          category: 'structure',
          breadcrumbs: [{
            icon: 'group',
            routerLink: '/wita/structure',
            label: 'STRUCTURE'
          },{
            label: 'EDIT_WARD'
          }]
        })}
      }
    ],
    canActivate: [organizationGuard]
  },
  /* Pazienti */
  {
    path: "patients",
    children: [
      /* Pagina Pazienti */
      {
        path: "",
        component: PatientsPage,
      },
      /* Nuovo paziente */
      {
        path: 'new',
        component: FormComponent,
        resolve: {data: () => ({
          category: 'patient',
          breadcrumbs: [{
            icon: 'personal_injury',
            routerLink: '/wita/patients',
            label: 'PATIENTS'
          }, {
            label: 'NEW_PATIENT'
          }]
        })}
      },
      /* Modifica paziente */
      {
        path: 'edit/:resourceId',
        component: FormComponent,
        resolve: {data: (route: ActivatedRouteSnapshot) => ({
          data: inject(PatientService).getPatient$(route.params['resourceId']),
          category: 'patient',
          breadcrumbs: [{
            icon: 'personal_injury',
            routerLink: '/wita/patients',
            label: 'PATIENTS'
          }, {
            label: 'EDIT_PATIENT',
            showBreadCrumbSubject: true
          }]
        })}
      },
      /* Modifica ammissione paziente */
      {
        path: 'admission/:patientId',
        component: FormComponent,
        resolve: {data: (route: ActivatedRouteSnapshot) => ({
          data: inject(AdmissionService).getEncounter$(route.params['patientId']),
          category: 'admission',
          breadcrumbs: [{
            icon: 'personal_injury',
            routerLink: '/wita/patients',
            label: 'PATIENTS'
          }, {
            label: 'ADMISSION',
            showBreadCrumbSubject: true
          }]
        })}
      },
      /* Crea ammissione paziente */
      {
        path: 'admission',
        component: FormComponent,
        resolve: {data: () => ({
          category: 'admission',
          breadcrumbs: [{
            icon: 'personal_injury',
            routerLink: '/wita/patients',
            label: 'PATIENTS'
          }, {
            label: 'ADMISSION',
            showBreadCrumbSubject: true
          }]
        })}
      }
    ],
    canActivate: [organizationGuard],
    
  },
  /* Sensori */
  {
    path: "devices",
    children: [
      /* Pagina sensori */
      {
        path: "",
        component: DevicesPage,
      },
      /* Modifica associazione dispositivo */
      {
        path: 'link/:resourceId',
        component: FormComponent,
        resolve: {data: (route: ActivatedRouteSnapshot) => ({
          data: inject(SensorService).getDevice$(route.params['resourceId']),
          category: 'deviceLink',
          breadcrumbs: [{
            icon: 'videocam',
            routerLink: '/wita/devices',
            label: 'DEVICES'
          }, {
            label: 'EDIT_LINK_DEVICE'
          }]
        })}
      },
      /* Associa dispositivo */
      {
        path: 'link',
        component: FormComponent,
        resolve: {data: () => ({
          category: 'deviceLink',
          breadcrumbs: [{
            icon: 'videocam',
            routerLink: '/wita/devices',
            label: 'DEVICES'
          }, {
            label: 'LINK_DEVICE'
          }]
        })}
      },
    ],
    resolve: {
      sideBarResolver: SideBarResolver
    }
  },
  /* Impostazioni */
  {
    path: 'settings',
    component: SettingsPage,
    resolve: {
      sideBarResolver: SideBarResolver
    }
  },
  /* Impostazioni Sviluppatore */
  {
    path: 'developerSettings',
    component: DeveloperSettingsPage,
    resolve: {
      SideBarResolver: SideBarResolver
    }
  },
  /* Mentorage */
  {
    path: 'mentorage',
    component: MentoragePage,
    resolve: {
      SideBarResolver: SideBarResolver
    },
    canActivate: [organizationGuard]
  },
  /* Statistiche */
  {
    path: 'analytics',
    component: AnalyticsPage,
    resolve: {
      SideBarResolver: SideBarResolver
    },
    canActivate: [organizationGuard]
  },
  /* Lista allerte */
  {
    path: 'alertsHistory',
    component: AlertsHistoryPage,
    resolve: {
      SideBarResolver: SideBarResolver
    },
    canActivate: [organizationGuard]
  },
  {
    path: '',
    redirectTo: 'customers',
    pathMatch: 'full',
  }


  /* ⛔ ROTTE DEPRECATE ⛔ */

  /* Modifica Anamnesi paziente */
  /* {
    path: 'patients/medical_history/:patientId',
    component: FormComponent,
    resolve: {data: (route: ActivatedRouteSnapshot) => ({
      data: inject(MedicalHistoryService).getMedicalHistory$(route.params['patientId']),
      category: 'medical_history',
      breadcrumbs: [{
        icon: 'personal_injury',
        routerLink: '/wita/patients',
        label: 'PATIENTS'
      }, {
        label: 'MEDICAL_HISTORY',
        showBreadCrumbSubject: true
      }]
    })}
  }, */
  /* Anamnesi paziente */
  /* {
    path: 'patients/medical_history',
    component: FormComponent,
    resolve: {data: () => ({
      category: 'medical_history',
      breadcrumbs: [{
        icon: 'personal_injury',
        routerLink: '/wita/patients',
        label: 'PATIENTS'
      }, {
        label: 'MEDICAL_HISTORY',
        showBreadCrumbSubject: true
      }]
    })}
  }, */
  /* Lista attività */
  /* {
    path: 'patients/activities',
    component: ActivitiesPage,
  }, */
  /* Lista allergeni */
  /* {
    path: 'patients/allergens',
    component: AllergensPage,
  }, */
  /* Valutazione paziente */
  /* {
    path: 'patients/evaluation/:patientId',
    component: EvaluationPage,
    resolve: {data: (route: ActivatedRouteSnapshot) => ({
      data: inject(EvaluationService).getEvaluationValues$(route.params['patientId']),
    })}
  }, */
  /* Valutazione clinica paziente */
  /* {
    path: 'patients/evaluation/:patientId/:evaluationScale',
    component: FormComponent,
    resolve: {data: (route: ActivatedRouteSnapshot) => ({
      data: inject(EvaluationService).getEvaluationValues$(route.params['patientId']),
      additionalParams: {
        scoreLoader: inject(EvaluationService).getEvaluationInputScores$(route.params['evaluationScale'])
      },
      category: `${route.params['evaluationScale']}`,
      breadcrumbs: [{
        icon: 'personal_injury',
        routerLink: '/wita/patients',
        label: 'PATIENTS'
      },{
        routerLink: `/wita/patients/evaluation/${route.params['patientId']}`,
        label: 'EVALUATION',
        showBreadCrumbSubject: true
      },{
        label: 'CLINICAL_EVALUATION'
      }]
    })}
  }, */
  /* Valutazione psicologica paziente */
  /* {
    path: 'patients/evaluation/:resourceId/psychological',
    component: FormComponent,
    resolve: {data: (route: ActivatedRouteSnapshot) => ({
      data: inject(CustomerService).getCustomer$(route.params['resourceId']),
      category: 'psychological',
      breadcrumbs: [{
        icon: 'personal_injury',
        routerLink: '/wita/patients',
        label: 'PATIENTS'
      },{
        routerLink: `/wita/patients/evaluation/${route.params['resourceId']}`,
        label: 'EVALUATION',
        showBreadCrumbSubject: true
      },{
        label: 'CLINICAL_EVALUATION'
      }]
    })}
  }, */
  /* Trattamento piaghe paziente */
  /* {
    path: 'patients/bedsore/:resourceId',
    component: BedsorePage,
    resolve: {data: (route: ActivatedRouteSnapshot) => ({
      data: of(null),
    })}
  }, */ 
  /* Eventi intercorrenti paziente */
  /* {
    path: 'patients/intervening_events/:patientId',
    component: InterveningEventsPage,
  }, */
  /* Gestione eventi paziente */
  /* {
    path: 'patients/activity_management/:patientId',
    component: ActivityManagementPage,
  }, */
  /* Dashboard */
  /* {
    path: 'patients/dashboard/:resourceId',
    component: DashboardPage,
    resolve: {data: (route: ActivatedRouteSnapshot) => ({
      data: inject(SensorService).getDevicesData$(route.params['resourceId']),
      category: 'activity',
      breadcrumbs: [{
        icon: 'personal_injury',
        routerLink: '/wita/patients',
        label: 'PATIENTS'
      }, {
        label: 'ACTIVITY_LEVEL',
        showBreadCrumbSubject: true
      }]
    })}
  }, */
  /* Mentorage */
  /* {
    path: 'events',
    component: EventsPage,
  }, */
];
