
<mat-grid-list cols="10" rowHeight="36px" gutterSize="20" style="margin-right: 20px;">
  <mat-grid-tile colspan="10" rowspan="1">
  </mat-grid-tile>
  <mat-grid-tile colspan="1" rowspan="1">
  </mat-grid-tile>
  <!-- BREADCRUMB -->
  <mat-grid-tile [colspan]="showGenerateDataButton ? 6 : 8" rowspan="1" style="display: flex; justify-content: flex-start;">
    <breadcrumbs
      [breadcrumbsList]="breadcrumbsList"
    ></breadcrumbs>
  </mat-grid-tile>
  @if (showGenerateDataButton) {
    <mat-grid-tile colspan="2" rowspan="1" style="display: flex; justify-content: flex-start;">
      <ion-button fill="outline" mode="md" style="width: 100%; height: 100%; text-transform: none;" (click)="emitGenerateData()">
        <mat-icon slot="start" class="material-symbols-outlined">network_intelligence</mat-icon>
        {{'GENERAL.BUTTONS.GENERATE_DATA' | translate}}
      </ion-button>
    </mat-grid-tile>
  }
</mat-grid-list>

<div style="width: 100%;">
  <div style="box-shadow: 0px 0px 20px 0px #80808066; overflow: auto; margin: 20px auto; height: fit-content; width: 80%;" >
    <mat-card style="height: 100%; min-height: 400px;">
      <mat-card-content>
        <ng-content></ng-content>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<mat-grid-list cols="10" rowHeight="36px" gutterSize="20" style="margin-right: 20px;">
  <mat-grid-tile colspan="4" rowspan="1"></mat-grid-tile>
  <!-- Button per l'eventuale salvataggio della form -->
  <mat-grid-tile colspan="2" rowspan="1" *ngIf="!hideSaveButton">
    <!-- <ion-button expand="block" style="width: 100%;" (click)="onSubmit.emit()" [disabled]="!formStatus.canSaveDraft">{{(formStatus.canSaveData ? 'GENERAL.BUTTONS.SAVE' : 'GENERAL.BUTTONS.SAVE_DRAFT') | translate}}</ion-button> -->
    <ion-button expand="block" style="width: 100%;" (click)="onSubmit.emit()" [disabled]="!formStatus.canSaveData">{{'GENERAL.BUTTONS.SAVE' | translate}}</ion-button>
  </mat-grid-tile>
  <mat-grid-tile colspan="10" rowspan="1"></mat-grid-tile>
</mat-grid-list>
