import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AlertController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  formStructureToHeightInRows,
  formToHeightInRows,
} from '../../utils/form.utils';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SanitizeInputDirective } from '../../directives/inputSanitize.directive';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ModalContainerComponent } from '../modal-container/modal-container.component';
import { IBreadcrumbs } from '../breadcrumbs/breadcrumbs.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, catchError, combineLatest, firstValueFrom, isObservable,of, take, tap } from 'rxjs';
import { ResourcesStructure } from './formResourcesStructure';
import { LoggerService } from '../../../core/services/utils/logger.service';
import { IBuilding } from '../../models/be/building';
import { IUnit } from '../../models/be/unit';
import { ICustomer } from '../../models/be/customers';
import { IPatient } from '../../models/be/patients';
import { IAdmission } from '../../models/be/encounter';
import { CustomerService } from '../../../core/services/customer.service';
import { PatientService } from '../../../core/services/patient.service';
import { AdmissionService } from '../../../core/services/admission.service';
import { IEvaluation } from '../../models/be/evaluation';
import { EvaluationService } from '../../../core/services/evaluation.service';
import { FacilitiesService } from '../../../core/services/facilities.service';
import { IOrganization } from '../../models/be/organization';
import { IRoom } from '../../models/be/room';
import { ToastService } from '../../../core/services/utils/toast.service';
import { IonicColors } from '../../enums/ionicColors.enum';
import { IBed } from '../../models/be/bed';
import { UserService } from '../../../core/services/user.service';
import { IUser } from '../../models/be/user';
import { MedicalHistoryService } from '../../../core/services/medicalHistory.service';
import { IMedicalHistory } from '../../models/be/medicalHistory';
import { SensorService } from '../../../core/services/sensors.service';
import { IDeviceFacilities } from '../../models/be/device';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { provideNativeDateAdapter } from '@angular/material/core';
import { IonButton, IonCheckbox, IonContent, IonIcon } from '@ionic/angular/standalone';


/////////////////////////////////////////////////////////////////////////////////////
//                                         _                                       //
//                                  ⛔ATTENZIONE⛔                                //
//                                         _                                       //
//                          Avere cura nel mettere le mani                         //
//                                  in questo codice.                              //
//                                         _                                       //
//                      RICORDARSI DI SPIEGARE LE VARIE FUNZIONI                   //
//                                         _                                       //
//                                       NOTE:                                     //
//   1) Chiedo venia per la porcata che è stata fatta con le sezioni dinamiche,    //
//      sono stato obbligato in quanto una gestione della form con il formArray    //
//      avrebbe comportato l'esistenza di un livello di form in più e purtroppo    //
//      Angular non permette lato html di condizionare l'esistenza della           //
//      direttiva formControlGroup pertanto avrei dovuto ripetere 2 volte una      //
//      stessa parte di codice. Per ulteriori info e chiarimenti chiedere a        //
//      Jacopo Stefanuto, baci e abbracci                                          //
//                                         _                                       //
/////////////////////////////////////////////////////////////////////////////////////


@Component({
  selector: 'generic-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    MatGridListModule,
    MatIconModule,
    MatDividerModule,
    MatCardModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatExpansionModule,
    MatSelectModule,
    MatRadioModule,
    MatTooltipModule,
    SanitizeInputDirective,
    MatAutocompleteModule,
    MatDatepickerModule,
    ModalContainerComponent,
    MatProgressSpinnerModule,
    IonContent,
    IonButton,
    IonIcon,
    IonCheckbox
  ]
})
export class FormComponent implements OnDestroy {
  /**
   * @description Fa da se che è il cuore del componente, la form si basa su questo parametro
   */
  public formData: IFormData;
  /**
   * @description Se sono presenti sezioni che possono essere aggiunte o rimosse allora vedrò questo parametro valorizzato con la struttura base della sezione
   */
  public dynamicSection!: IFormStructure | null;
  /**
   * @description FormGroup che regola tutti i campi della form nel component
   */
  public formGroup!: FormGroup;
  /**
   * @description Oggetto contenente la struttura in formato IFormStructure e FormControl della sezione dinamica, perchè? Perchè se vengono rimosse tutte le sezioni devo essere poi in grado di ricrearle sulla base di qualcosa pertanto salvo la struttura base su un oggetto
   */
  public formGroupStructure!: FormControl;
  /**
   * @description Lista dei Breadcrumbs, dato che si andrà a valorizzare mediante i dati pesacati dalla rotta
   */
  public breadcrumbsList: IBreadcrumbs[];
  /**
   * @description Id della risorsa in oggetto se presente, se non presente si tratta allora della creazione di una risorsa e non della modifica
   */
  private resourceId: string | null = null;
  /**
   * @description Id di una eventuale risorsa secondaria, come per esempio il dottore nel caso del paziente
   */
  private secondaryResourceId: string | null = null;
  /**
   * @description Id del paziente della risorsa in oggetto se presente
   */
  private patientId: string | null = null;
  /**
   * @description Altezza della modale espressa in righe
   */
  public modalHeight: number = 1;
  /**
   * @description Stato dei pulsanti della form
   */
  public formStatus: IFormChangeEmitter = {
    canSaveDraft: false,
    canSaveData: false,
  };
  public showGenerateDataButton: boolean = false;
  public resource: IFormResource;
  public inputsScores: {
    [key:string]: {
      scores: {
          [key: string]: number[]
      },
      thresholds: {
          minimal: number[],
          moderate: number[],
          severe: number[]
      },
      maxScore: number
    }
  } | null = null;
  /**
   * Variabile utilizzata per la lettura dei campi allo stato iniziale, non leggo da FormData poichè soggetto a modifiche
   */
  public readonly structureHolder: IFormData;

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private alertController: AlertController,
    private route: ActivatedRoute,
    private router: Router,
    private loggerService: LoggerService,
    private customerService: CustomerService,
    private userService: UserService,
    private patientService: PatientService,
    private admissionService: AdmissionService,
    private medicalHistoryService: MedicalHistoryService,
    private evaluationService: EvaluationService,
    private facilitiesService: FacilitiesService,
    private sensorService: SensorService,
    private toastService: ToastService,
    private cdRef:ChangeDetectorRef,
  ) {
    /* Creo una nuova istanza di ResourcesStructure, classe che mi servirà poi per andare a popolare la form sulla base della categoria della risorsa in oggetto */
    const currentResourceStructure = new ResourcesStructure();
    // Mergio sulla variabile resource i dati contenuti in 'data', definiti nella root.routes.ts e i dati presi dagli 'state' definiti in fase di navigazione
    this.resource = {
      ...this.route.snapshot.data['data'],
      ...this.router.getCurrentNavigation()?.extras.state
    };
    this.resource.toHideSections && this.checkToHideSections(currentResourceStructure)
    this.patientId = this.route.snapshot.params['patientId'] || null;
    this.formData = currentResourceStructure.formResourcesStructure[this.resource.category].formStructure;
    this.showGenerateDataButton = !!currentResourceStructure.formResourcesStructure[this.resource.category].showGenerateDataButton;
    this.loadFormDataInputsObservables()
    this.structureHolder = new ResourcesStructure().formResourcesStructure[this.resource.category].formStructure
    // Valorizzo la variabile della sezione dinamica (qualora presente)
    this.dynamicSection = currentResourceStructure.formResourcesStructure[this.resource.category].dynamicSection || null;
    // Calcolo l'altezza della modale
    this.modalHeight = formToHeightInRows(this.formData, this.translateService);
    (this.resource.breadcrumbs || []).forEach((breadcrumb) => {
      if (breadcrumb.showBreadCrumbSubject && this.resource.breadCrumbSubject) {
        breadcrumb.breadCrumbSubject = this.resource.breadCrumbSubject
      }
    })
    // Valorizzo i breadcrumbs
    this.breadcrumbsList = this.resource.breadcrumbs;
    this.loadFormValues();
  }

  /**
   * @description Funzione che si occupa di andare a nascondere le sezioni indicate su "toHideSections", SE VALORIZZATO
   * @param resourceStructure Struttura delle Risorse
   */
  checkToHideSections(resourceStructure: ResourcesStructure) {
    Object.keys(this.resource.toHideSections!).forEach((sectionName) => 
      (resourceStructure.formResourcesStructure[sectionName].formStructure.structure[0].children.filter((child) => (this.resource.toHideSections![sectionName] || []).includes(child.id)) || []).forEach((scale) => scale.hideSection = false)
    )
  }

  /**
   * @description Funzione che se presente input 'autocomplete' e suggestedItem di tipo Observable, rimane in ascolto del suggestedItem e tiene in memoria la lista iniziale 
   */
  loadFormDataInputsObservables() {
    this.formData.structure.forEach((structure) => {
      structure.children.filter((child) => !child.hideSection).forEach((child) => {
        child.inputs.forEach((input) => {
          // Se stiamo gestendo un input di tipo 'autocomplete' allora verifico se è necessario andare a popolare la lista di item suggeriti attraverso qualche chiamata
          if (input.type === 'autocomplete' && isObservable(input.suggestedItems)) {
            input.suggestedItems.pipe(
              tap((data: any) => {
                input.suggestedItems = data;
                (this.resource.autocompleteData ||= {})[input.id] = data;
              }),
              catchError((err: any) => {
                input.suggestedItems = [];
                throw err;
              })
            ).subscribe();
          }
        })
      })
    });
  }

  /**
   * @description Se presente chiamata di recupero risorsa dai dati della rotta, faccio la chiamata e valorizzo gli Input con i dati che mi vengono tornati 
   */
  loadFormValues() {
    /* Richiamo i dati degli Input se presenti e i punteggi delle varie scale, sempre se presenti */
    combineLatest([
      this.resource.data?.pipe(catchError(err => {
        this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.LOADING_DATA'), IonicColors.RED)
        return of(null)
      })) || of(null),
      this.resource.additionalParams?.scoreLoader?.pipe(catchError(err => {
        this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.LOADING_DATA'), IonicColors.RED)
        return of(null)
      })) || of(null)
    ]).pipe(
        take(1),
        tap(([inputsValue, inputsScores]) => {
          /* Se presente scoreLoader allora faccio la chiamata e setto il max score dei vari child della struttura */
          if (inputsScores) {
            this.inputsScores = inputsScores;
            /* Ciclo i punteggi delle scale e ne setto il maxScore */
            Object.keys(this.inputsScores!).forEach(key => {
              if (!!this.formData.structure[0].children.find((child) => child.id === key)) {
                this.formData.structure[0].children.find((child) => child.id === key)!.score = {
                  current: 0,
                  max: this.inputsScores?.[key].maxScore || 0,
                  grade: 'none'
                }
              }
            });
          }
          /* Se inputsValue esiste allora valorizzo gli Input con i valori scesi dalla chiamata */
          if (inputsValue) {            
            switch (this.resource.category) {
              case 'clinical':
              case 'psychological':
                /* Se valutazione clinica o psicologica allora vado a valorizzare gli input e i radio con i valori salvati */
                this.formData.structure.forEach((structureSection) => {
                  structureSection.children.map((data) => ({
                    ...data,
                    inputs: data.inputs.map((input: any) => (input.inputValue = inputsValue[data.id]?.values?.[input.id] || null)) as unknown as ISelectInput[],
                    ...(data.radio ? {
                      radio: {
                        ...data.radio,
                        values: data.radio.values.map((radioItem) => radioItem.inputValue = inputsValue[data.id]?.values?.[radioItem.id] || null)
                      }
                    }: {})
                  }));
                });
                /* Poi carico il formGroup */
                this.loadFormGroup();
                /* E vado a settare lo score della sezione, N.B: questa operazione va fatta DOPO che il formGroup è stato caricato, non prima */
                this.formData.structure.forEach((structureSection) => {
                  structureSection.children.forEach((structureSectionChild) => {
                    !structureSectionChild.hideSection && structureSectionChild.score && this.calculateSectionScore(structureSectionChild, this.formGroup.value?.[structureSection.id]?.[structureSectionChild.id]);
                  })
                })
                break;
              case 'structure':
                this.resourceId = inputsValue.ward.id;
                //Valorizzo gli Input del reparto/appartamento
                this.formData.structure.forEach((structureSection) => {
                  if (!structureSection.dynamicChildren) {
                    structureSection.children.map((data) => ({
                      ...data,
                      inputs: data.inputs.forEach((input: any) => (input.inputValue = inputsValue.ward[input.id])) as unknown as ISimpleInput[],
                    }));
                  } else {
                    const roomPlaceHolder = JSON.parse(JSON.stringify(structureSection.children[0]));
                    let compiledRooms: IFormStructureChildren[] = [];
                    (inputsValue.rooms).forEach((room: IRoom) => {
                      // Ci troviamo dinnanzi ad una sfizioseria senza precedenti, il nuovissimo operatore "||=" (logical OR assignment) che permette, se non esiste, di valorizzare ad array vuoto una variabile
                      ((this.resource.preloadedData ||= {})['rooms'] ||= []).push(room.id!)
                      compiledRooms.push({
                        ...roomPlaceHolder,
                        inputs: roomPlaceHolder.inputs.map((input: IInputArray | ISimpleInput) => {
                          if (input.type === 'inputArray') {
                            const bedPlaceHolder = JSON.parse(JSON.stringify(input.inputs[0]));
                            return {
                              ...input,
                              inputs: (room.beds || []).map((bed) => {
                                ((this.resource.preloadedData ||= {})['beds'] ||= []).push(bed.id!);
                                return {
                                  ...bedPlaceHolder,
                                  id: bed.id!,
                                  inputValue: bed.name,
                                  validators: [Validators.required]
                                }
                              })
                            }
                            
                          } else {
                            return {
                              ...input,
                              id: room.id!,
                              validators: [Validators.required],
                              inputValue: room.name
                            }
                          }
                        }),
                        validators: [Validators.required]
                      })
                    })
                    structureSection.children = compiledRooms;
                  }
                });
                this.modalHeight = formToHeightInRows(this.formData, this.translateService);
                this.loadFormGroup();
                break;
              case 'deviceLink':
                this.resourceId = inputsValue.id;
                this.formData.structure.forEach((structureSection) => {
                  structureSection.children.map((data) => ({
                    ...data,
                    inputs: data.inputs.forEach((input: any) => {
                      input.inputValue = inputsValue['facilities'][input.id].id as string || '';
                      // Se l'input è una select con autocompletamento basato su altri campi (loadOn) allora vedo di riempire la lista valori con la relativa chiamata a cui passerò il valore dell'Input a cui si vuol fare riferimento, se valorizzato
                      if (input.type === 'select' && input.loadOn && inputsValue[input.loadOn]) {
                        input.loader(inputsValue[input.loadOn]).pipe(
                          tap((data: IOrganization[]) => {
                            input.values = data;
                          })
                        ).subscribe();
                      }
                    }) as unknown as ISimpleInput[],
                  }));
                });
                this.loadFormGroup();
                break;
              default:
                this.resourceId = inputsValue.id;
                this.formData.structure.forEach((structureSection) => {
                  structureSection.children.map((data) => ({
                    ...data,
                    inputs: data.inputs.forEach((input: any) => {
                      if (structureSection.id === 'doctor') {
                        input.inputValue = inputsValue['doctor']?.[input.id];
                        this.secondaryResourceId = inputsValue['doctor']?.['id']
                      } else {
                        input.inputValue = inputsValue[input.id];
                      }
                      // Se l'input è una select con autocompletamento basato su altri campi (loadOn) allora vedo di riempire la lista valori con la relativa chiamata a cui passerò il valore dell'Input a cui si vuol fare riferimento, se valorizzato
                      if (input.type === 'select' && input.loadOn && inputsValue[input.loadOn]) {
                        input.loader(inputsValue[input.loadOn]).pipe(
                          tap((data: IOrganization[]) => {
                            input.values = data;
                          })
                        ).subscribe();
                      } else if (input.completeSection && this.resource.additionalData) {
                        // Se invece abbiamo un input con autocompletamento allora verifico se i campi corrispondono a quelli relativi alla sezione dell'autocompletamento e setto a "true" se tutti i campi sono uguali
                        if (data.inputs.filter((input) => !(input as ICheckboxInput).completeSection).some((data) => !!this.resource.additionalData?.[data.id])) {
                          input.inputValue = data.inputs
                            .filter((input) => !(input as ICheckboxInput).completeSection)
                            .every((data) => !this.resource.additionalData?.[data.id] || (inputsValue[data.id] == this.resource.additionalData[data.id]))
                        } else {
                          input.inputValue = false;
                        }
                      }
                    }) as unknown as ISimpleInput[],
                  }));
                });
                this.loadFormGroup();
                break;
            }
          } else {
            this.loadFormGroup();
          }
        }),
        catchError((err) => {
          this.loadFormGroup();
          throw err;
        })
      ).subscribe()
  }

  ngAfterViewChecked(){
    this.cdRef.detectChanges();
  }

  /**
   * @description Costruisce il formGroup sulla base della struttura della form
   */
  loadFormGroup() {
    this.loadLoaderValues();
    /* Variabile che se valorizzata va ad eliminare la sezione indicata, viene utilizzata per andare a mostrare o nascondere certe sezioni in funzione dello stato di un input (per esempio nella creazione di una org, tramite una spunta è possibile abilitare o meno la visibilità della form degli edifici) */
    let sectionNameToRemove: string = '';
    /* Creazione della form */
    this.formGroup = this.formBuilder.group(
      /* Per ogni elemento dell'array della struttura */
      this.formData.structure.reduce(
        (structureAccumulator, structureCurrentChild) => {
          /* Funzione per la creazione del fromgroup della struttura */
          const buildStructureGroup = (
            structure: IFormStructure
          ) => {
            /**
             * @description Funzione invocata per la creazione dei formControl degli Inputs
             * @param currentChild Figlio corrente
             */
            const buildInputs = (currentChild: IFormStructureChildren) => ({              
              /* Infine creo i vari formControl sulla base degli input presenti al più basso livello della struttura */
              ...currentChild.inputs.reduce(
                (_accumulator, currentInput) => {
                  /* Se il parametro adjustSectionVisibility è valorizzato allora valorizzo la variabile removeSection con il nome della sezione indicata */
                  (currentInput as ICheckboxInput).adjustSectionVisibility && (sectionNameToRemove = (currentInput as ICheckboxInput).adjustSectionVisibility as string);  
                  /* Se il parametro dynamicInputParams è valorizzato allora procedo a creare un formArray */
                  if (currentInput.type === 'inputArray') {
                    _accumulator[currentInput.id] = this.formBuilder.array(
                      (currentInput as IInputArray).inputs.map((input) => (
                        this.formBuilder.group({[input.id]: [(input as ISimpleInput).inputValue || null,(input as ISimpleInput).validators || []]})
                      ))
                    )
                  } else {
                    if (currentInput.type === 'date') {
                      currentInput.inputValue = new Date(currentInput.inputValue as string)
                    }
                    if (currentInput.type !== 'simpleText' && !(currentInput as ICheckboxInput).adjustSectionVisibility) {
                      _accumulator[currentInput.id] = [
                        {
                          value: (currentInput as ISimpleInput | ICheckboxInput | ISelectInput | IAutoComplete | IAvatarInput).inputValue || null,
                          disabled: !!((currentInput as ISimpleInput).disableOnEdit && this.resourceId)
                        },
                        (currentInput as ISimpleInput).validators || []
                      ];
                    }
                  }                  
                  return _accumulator;
                },
                {} as any
              ),
              /* Se presente radio allora creo anche un formControl per ogni radio censito */
              ...(currentChild.radio &&
                currentChild.radio.values.reduce(
                  (_accumulator, currentInput) => {
                    _accumulator[currentInput.id] = [Number(currentInput.inputValue) || null, currentChild.radio?.validators || []];
                    return _accumulator;
                  },
                  {} as any
                )
              )              
            })
            /* Se abilitato flag di figli dinamici allora creo un formArray */
            if (structureCurrentChild.dynamicChildren) {
              structureAccumulator[structure.id] = this.formBuilder.array(
                /* Per ogni elemento dell'array dei figli della struttura creo un nuovo formGroup */
                structure.children.reduce((accumulator, currentChild) => {
                  if (currentChild.hideSection) {
                    return accumulator;
                  };
                  accumulator.push(this.formBuilder.group(buildInputs(currentChild)));
                  return accumulator;
                }, [] as FormGroup[])
              )
            } else {
              /* Creo un nuovo formGroup */
              structureAccumulator[structure.id] = this.formBuilder.group(
                /* Per ogni elemento dell'array dei figli della struttura creo un nuovo formGroup */
                structure.children.reduce((accumulator, currentChild) => {
                  if (currentChild.hideSection) {
                    return accumulator;
                  }
                  accumulator[currentChild.id] = this.formBuilder.group(buildInputs(currentChild));

                  return accumulator;
                }, {} as any)
              );
            }
          };
          /* Creo il formgroup della struttura */
          buildStructureGroup(structureCurrentChild);
          /* E se presente una sezione dinamica allora ne calcolo l'altezza e ne costruisco il formgroup */
          if (this.dynamicSection) {
            this.dynamicSection.heightInRows = formStructureToHeightInRows(
              this.dynamicSection,
              this.translateService
            );
            !structureAccumulator[this.dynamicSection.id] &&
              buildStructureGroup(this.dynamicSection);
          }
          /* Definisco l'altezza della struttura della form */
          structureCurrentChild.heightInRows = formStructureToHeightInRows(
            structureCurrentChild,
            this.translateService
          );
          /* Se la sezione permette l'aggiunta o di sezioni analoghe o la rimozione della stessa allora salvo lo stato della struttura in quanto in seguito ad eliminazione di tutte le sezioni devo essere in grado di poter recuperare la struttura base nell'eventualità di una riaggiunta della sezione */
          if (
            !this.formGroupStructure &&
            this.dynamicSection &&
            structureAccumulator[this.dynamicSection.id] &&
            this.dynamicSection.canAddAndRemove
          ) {
            this.formGroupStructure = structureAccumulator[this.dynamicSection.id];
          }
          return structureAccumulator;
        },
        {} as any
      )
    );
    /* Se è stata valorizzata la variabile allora rimuovo la sezione indicata dal formGroup e dalla struttura dati */
    if (sectionNameToRemove !== '') {
      this.formGroup.removeControl(sectionNameToRemove);
      this.formData.structure.some(
        (structure) => structure.id === sectionNameToRemove
      ) &&
        this.formData.structure.splice(
          this.formData.structure.findIndex(
            (structure) => structure.id === sectionNameToRemove
          ),
          1
        );
      this.modalHeight = formToHeightInRows(this.formData, this.translateService);
    }

    this.formData.structure.forEach((structure) => {
      const disabledOnChildren = (structure.children.filter((child) => child.disabledOn) || [])
      structure.children.forEach((child) => {
        child.inputs.forEach((input) => {
          switch (input.type) {
            case 'autocomplete':
              // Nel caso di Input autocomplete rimango in ascolto dell'Input e modifico la lista di elementi suggeriti 
              this.formGroup.get(structure.id)?.get(child.id)?.get(input.id)?.valueChanges.pipe(
                tap((value) => {
                  if (typeof value === 'string') {
                    input.suggestedItems = (this.resource.autocompleteData ||= {})[input.id].filter((option) => option.label.toLowerCase().includes(value.toLowerCase()));                
                  }
                })
              ).subscribe()
              break;
            case 'select':
              // Se trovo loadOn valorizzato allora rimango in ascolto dei cambiamenti del valore dell'Input e richiamo il loadOn 
              input.loadOn && this.formGroup.get(structure.id)?.get(child.id)?.get(input.loadOn)?.valueChanges.pipe(
                tap((value) => {
                  typeof value === 'number' && input.loader?.(value).pipe(
                    tap((data) => {
                      input.values = data;
                    })
                  ).subscribe();
                })
              ).subscribe();
              // Se trovo un child il cui stato di disabilitazione dipende dal valore di un Input allora procedo a rimanere in ascolto del cambiamento di Input e in base alle regole definite nel parametro 'disabledOn', abilitare o disabilitare
              if (disabledOnChildren.some((obj) => obj.disabledOn?.inputId === input.id)) {
                const currentObj = disabledOnChildren.find((obj) => obj.disabledOn!.inputId === input.id)
                const manageDisabilitationStatus = (value?: string) => {
                  if (value === currentObj!.disabledOn!.value) {
                    this.formGroup.get(structure.id)?.get(currentObj!.id)?.disable();
                  } else {
                    this.formGroup.get(structure.id)?.get(currentObj!.id)?.enable();
                  }
                }
                manageDisabilitationStatus(this.formGroup.get(structure.id)?.get(child.id)?.get(disabledOnChildren.find((obj) => obj.disabledOn?.inputId ===  input.id)!.disabledOn!.inputId)?.value)
                this.formGroup.get(structure.id)?.get(child.id)?.get(disabledOnChildren.find((obj) => obj.disabledOn?.inputId ===  input.id)!.disabledOn!.inputId)?.valueChanges.pipe(
                  tap((value) => {
                    manageDisabilitationStatus(value)
                  })
                ).subscribe();
              }
              break;
            default:
              break;
          }
        })
      })
    })

    /* A ogni cambiamento della form censisco lo stato del pulsante di salvataggio e dei dati della form  */
    /* NOTA BENE: É un operazione che viene fatta molto frequentemente, LIMITARE la complessità del codice quanto più possibile!! */
    this.formGroup.valueChanges.subscribe((data) => {
      this.formStatus = {
        canSaveDraft: !this.formGroup.valid
          ? this.checkEligibilityToSaveDraft()
          : true,
        canSaveData: this.formGroup.valid,
      };
    });
  }

  /**
   * @description Funzione che se presente input 'select' e 'loader' carica i valori della select a partire dalle chiamate dei loaders
   */
  loadLoaderValues() {
    this.formData.structure.forEach((structureSection) => {
      structureSection.children.filter((child) => !child.hideSection).map((child) => ({
        ...child,
        inputs: child.inputs.forEach((input, inputIndex) => {
          if (input.type === 'select' && !!input.loader) {
            if (!inputIndex) {
              input.isLoading = true;
              input.loader().pipe(
                take(1),
                tap((res) => {
                  input.values = res;
                  input.isLoading = false;
                }),
                catchError((err) => {
                  this.loggerService.error(err);
                  input.isLoading = false;
                  throw err;
                })
              ).subscribe()
            };
            input.inputValue && this.checkLoader(child.inputs as ISelectInput[], input.inputValue, inputIndex)
          };
        }),
      }));
    });
  }

  /**
   * @description Funzione che va a popolare la lista degli Input Select tramite loader
   * @param inputList Lista degli input della sezione
   * @param resourceId Id della risorsa selezionata
   * @param currentInputIndex Indice dell'input in oggetto (rispetto all'inputList)
   */
  checkLoader(inputList: ISelectInput[], resourceId: string, currentInputIndex: number) {
    /**
     * @description Cuore della funzione principale, si occupa di andare a riempire la lista di valori da mostrare nella select
     */
    function loadValues(input: ISelectInput, checkOccupationStatus: boolean = false) {
      input.isLoading = true;
      input?.loader?.(resourceId).pipe(
        take(1),
        tap((data) => {
          input.isLoading = false;
          if (checkOccupationStatus) {
            input!.values = data.map((bed) => ({
              ...bed,
              ...(bed.id === input.inputValue) ? {
                disabled: false
              } : {},
              label: `${bed.label} ${(bed.disabled && (bed.id !== input.inputValue)) ? '(OCCUPATO)' : ''}`
            }));
          } else {
            input!.values = data;
          }
        }),
        catchError((err) => {
          input.isLoading = false;
          throw err;
        })
      ).subscribe();
    }
    switch (inputList[currentInputIndex].id) {
      case 'building':
      case 'buildingId':
        inputList.filter((input: ISelectInput) => !['buildingId', 'building'].includes(input.id)).map((input: ISelectInput) => input.values = []);
        loadValues(inputList[currentInputIndex+1]);
        break;
      case 'unit':
      case 'unitId':
        inputList.filter((input: ISelectInput) => !['buildingId', 'building', 'unit', 'unitId'].includes(input.id)).map((input: ISelectInput) => input.values = []);
        loadValues(inputList[currentInputIndex+1]);
        break;
      case 'roomId':
      case 'room':
        inputList.filter((input: ISelectInput) => input.id === 'bedId').map((input: ISelectInput) => input.values = []);
        inputList[currentInputIndex+1] && loadValues(inputList[currentInputIndex+1], true);
        break;
      default:
        break;
    }
  }

  /**
   * @description Alla distruzione del component resetto la Form
   */
  ngOnDestroy() {
    this.formGroup.reset();
  }

  displayFn(inputId: string, resourceId: string): string {
    return (this.resource.autocompleteData ||= {})?.[inputId]?.find((data) => data.id == resourceId)?.label || '';
  }

  /**
   * @description Funzione che si occupa di effettuare la navigazione alla pagina precedente la Form
   */
  navigateBack() {
    this.resource.breadcrumbs?.length && this.resource.breadcrumbs[this.resource.breadcrumbs.length-2].routerLink && this.router.navigate([this.resource.breadcrumbs[this.resource.breadcrumbs.length-2].routerLink!])
  }

  /**
   * @description Funzione di generazione dati Form
   */
  generateData() {
    this.formData.structure.forEach((section) => {
      section.children?.forEach((child) => {
        child.inputs?.forEach((input) => {
          if ((input as ISimpleInput | ISelectInput).generatedData?.length && !this.formGroup.get(section.id)?.get(child.id)?.get(input.id)?.value) {
            this.formGroup.get(section.id)?.get(child.id)?.get(input.id)?.setValue((input as ISimpleInput | ISelectInput).generatedData![Math.floor(Math.random() * (input as ISimpleInput | ISelectInput).generatedData!.length)])
          }
        })
      })
    })
  }

  /**
   * @description Funzione invocata al salvataggio della form, in base al tipo di risorsa vengono effettuate operazioni diverse
   */
  async onSubmit() {
    /**
     * @description Funzione che torna un oggetto compatibile con il body che si vuole inserire in una chiamata di Post
     * @returns Torna un oggetto in formato chiave valore
     */
    const formDataToRequestBody = (customObject?: { [key: string]: any }): { [key: string]: string | number | null } => {
        let valuesList: { [key: string]: string | number | null } = {};
        let objectToCycle = customObject || this.formGroup.value;
        Object.keys(objectToCycle).forEach(firstLevel => {
            if (objectToCycle[firstLevel] && typeof objectToCycle[firstLevel] === 'object') {
                Object.keys(objectToCycle[firstLevel]).forEach(secondLevel => {
                    if (objectToCycle[firstLevel][secondLevel] && typeof objectToCycle[firstLevel][secondLevel] === 'object' && !(objectToCycle[firstLevel][secondLevel] instanceof Date)) {
                        Object.keys(objectToCycle[firstLevel][secondLevel]).forEach(thirdLevel => {
                            if (objectToCycle[firstLevel][secondLevel][thirdLevel] && (typeof objectToCycle[firstLevel][secondLevel][thirdLevel] !== 'object' || objectToCycle[firstLevel][secondLevel] instanceof Date)) {
                                valuesList[thirdLevel] = objectToCycle[firstLevel][secondLevel][thirdLevel]
                            }
                        })
                    } else {
                      valuesList[secondLevel] = objectToCycle[firstLevel][secondLevel];
                    }
                })
            } else {
              valuesList[firstLevel] = objectToCycle[firstLevel];
            }
        });
        this.patientId && (valuesList['patientId'] = this.patientId);
        !customObject && this.resourceId && (valuesList['id'] = this.resourceId);
        return valuesList;
    }
    let requestCall: Observable<any> = of(null);
    // In base al tipo di risorsa che voglio andare a salvare, valorizzo requestCall con la chiamata di salvataggio o di modifica da fare
    switch (this.resource.category) {
      case 'customer':
        requestCall = (this.resourceId
            ? this.customerService.editCustomer$(formDataToRequestBody() as unknown as ICustomer).pipe(
              tap(() => this.toastService.showToast(this.translateService.instant('GENERAL.SUCCESS.RESOURCE_UPDATED_SUCCESFULLY'), IonicColors.GREEN)),
              catchError((err) => this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.RESOURCE_UPDATE'), IonicColors.RED))
            )
            : this.customerService.createCustomer$(formDataToRequestBody() as unknown as ICustomer).pipe(
              tap(() => this.toastService.showToast(this.translateService.instant('GENERAL.SUCCESS.RESOURCE_CREATED_SUCCESFULLY'), IonicColors.GREEN)),
              catchError((err) => this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.RESOURCE_CREATE'), IonicColors.RED))
            ));
        break;
      case 'patient':
        const data = {
          ...formDataToRequestBody(this.formGroup.value.patient),
          doctor: {
            ...formDataToRequestBody(this.formGroup.value.doctor),
            id: this.secondaryResourceId
          }
        } as unknown as IPatient;
        if (this.resourceId) {
          requestCall = this.patientService.editPatient$({...data, id: this.resourceId});
        } else {
          requestCall = this.patientService.createPatient$(data);
        }
        break;
      case 'admission':
        if (this.resourceId) {
          requestCall = this.admissionService.editEncounter$({
            ...formDataToRequestBody() as unknown as IAdmission,
            patientId: this.resource.additionalData!['id']+'',
          });
        } else {
          requestCall = this.admissionService.createEncounter$({
            ...formDataToRequestBody() as unknown as IAdmission,
            patientId: this.resource.additionalData!['id']+'',
          });
        }
        break;
      case 'medical_history':
        if (this.resourceId) {
          requestCall = this.medicalHistoryService.editMedicalHistory$(formDataToRequestBody() as unknown as IMedicalHistory);
        } else {
          requestCall = this.medicalHistoryService.createMedicalHistory$(formDataToRequestBody() as unknown as IMedicalHistory);
        }
        break;
      case 'clinical':
      case 'psychological':
        requestCall = this.resource.data!.pipe(
          tap((data) => {
            let mappedObject: IEvaluation = {} as IEvaluation;
            Object.keys(this.formGroup.value.prevention).forEach((sectionName: string) => {
              mappedObject[sectionName] = {
                values: this.formGroup.value.prevention[sectionName],
                totalScore: this.formData.structure[0].children.find((child) => child.id === sectionName)?.score?.current+'' || "0",
                resourceId: data[sectionName]?.resourceId || null,
                nextEvaluation: this.formGroup.value.summary.specs.nextEvaluation || ''
              }
            })
            mappedObject!.mattress = this.formGroup.value.summary.specs.matress || '';
            this.evaluationService.sendEvaluation$(mappedObject! as unknown as IEvaluation, this.patientId!);
          })
        );          
        break;
      case 'organization':
        if (this.resourceId) {
          requestCall = this.facilitiesService.putOrganization$({
            ...formDataToRequestBody() as unknown as IOrganization,
            customerId: Number(this.resource.additionalData?.['id'])
          });
        } else {
          requestCall = this.facilitiesService.postOrganization$({
            ...formDataToRequestBody() as unknown as IOrganization,
            customerId: Number(this.resource.additionalData?.['id'])
          });
        }         
        break;
      case 'structure':
        if (this.resourceId) {
          // Se esiste l'id della risorsa allora mando la chiamata di modifica UNIT
          requestCall = this.facilitiesService.putUnit$({
            ...formDataToRequestBody(this.formGroup.value.ward),
            id: this.resourceId,
            buildingId: this.resource.additionalData?.['id'] || ''
          } as IUnit).pipe(
            tap(() => {
              // Una volta modificata la UNIT, per ogni stanza censita...
              (this.formGroup.value.rooms || []).forEach((room: any) => {
                // ...controllo se la stanza in oggetto è presente tra le stanze presenti inizialmente (preloadedData)
                const roomId = Object.keys(room).find((key) => this.resource.preloadedData?.['rooms'].includes(key));
                // Se presente allora procedo alla modifica della stanza
                if (roomId) {
                  this.facilitiesService.putRoom$({
                    id: roomId,
                    name: room[roomId!],
                    status: 'U',
                    unitId: this.resourceId
                  } as IRoom).pipe(
                    tap(() => {
                      //Una volta modificata la stanza effettuo lo stesso controllo della presenza stanze
                      (room.beds || []).forEach((bed: any) => {
                        // Quindi, se trovo letti inclusi tra i letti presenti inizialmente mi limito a modificarli
                        if (this.resource.preloadedData?.['beds'].some((value) => value === Object.keys(bed)[0])) {
                          this.facilitiesService.putBed$({
                            id: Object.keys(bed)[0],
                            name: bed[Object.keys(bed)[0]],
                            status: 'U',
                            roomId: roomId
                          } as IBed).subscribe();
                        } else {
                          // Altrimenti li creo
                          this.facilitiesService.postBed$({
                            name: bed.name,
                            status: 'U',
                            roomId: roomId
                          } as IBed).subscribe();
                        };
                      });
                    })
                  ).subscribe();
                } else {
                  //Altrimenti creo la stanza
                  this.facilitiesService.postRoom$({
                    name: room.name,
                    status: 'U',
                    unitId: this.resourceId
                  } as IRoom).pipe(
                    tap((newRoom) => {
                      // E ci creo pure i letti associati alla stanza
                      room.beds?.forEach((bed: any) => {
                        this.facilitiesService.postBed$({
                          name: bed.name,
                          status: 'U',
                          roomId: newRoom.id
                        } as IBed).subscribe();
                      })
                    })
                  ).subscribe();
                }
              });
              // Infine controllo se dalla form sono sparite Stanze/Letti ed eventualmente li elimino 
              (this.resource.preloadedData?.['rooms'] || []).forEach((roomId: string) => {
                !this.formGroup.value.rooms.some((room: any) => Object.keys(room).some((internalRoomId) => (internalRoomId === roomId))) && this.facilitiesService.deleteRoom$(roomId).subscribe();
              });
              (this.resource.preloadedData?.['beds'] || []).forEach((bedId: string) => {
                !this.formGroup.value.rooms.some((room: any) => room.beds.some((bed: any) => Object.keys(bed)[0] === bedId)) && this.facilitiesService.deleteBed$(bedId).subscribe();
              });
            }),
            catchError((err) => this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.RESOURCE_CREATE'), IonicColors.RED))
          );
        } else {
          requestCall = this.facilitiesService.postUnit$({
            ...formDataToRequestBody(this.formGroup.value.ward),
            buildingId: this.resource.additionalData?.['id'] || ''
          } as IUnit).pipe(
            tap((unit) => {
              this.formGroup.value.rooms.forEach((room: IRoom) => {
                this.facilitiesService.postRoom$({
                  name: room.name,
                  status: 'U',
                  unitId: unit.id
                } as IRoom).pipe(
                  tap((newRoom) => {
                    room.beds?.forEach((bed) => {
                      this.facilitiesService.postBed$({
                        name: bed.name,
                        status: 'U',
                        roomId: newRoom.id
                      } as IBed).subscribe();
                    })
                  })
                ).subscribe();
              });
            }),
            catchError((err) => this.toastService.showToast('', IonicColors.RED))
          );
        }
        break;
      case 'user':
        requestCall = (this.resourceId
          ? this.userService.editUser$(formDataToRequestBody() as unknown as IUser).pipe(
            tap(() => this.toastService.showToast(this.translateService.instant('GENERAL.SUCCESS.RESOURCE_UPDATED_SUCCESFULLY'), IonicColors.GREEN)),
            catchError((err) => this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.RESOURCE_UPDATE'), IonicColors.RED))
          )
          : this.userService.createUser$(formDataToRequestBody() as unknown as IUser).pipe(
            tap(() => this.toastService.showToast(this.translateService.instant('GENERAL.SUCCESS.RESOURCE_CREATED_SUCCESFULLY'), IonicColors.GREEN)),
            catchError((err) => this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.RESOURCE_CREATED'), IonicColors.RED))
          ));
        break;
      case 'building':
        requestCall = (this.resourceId
          ? this.facilitiesService.putBuildings$(formDataToRequestBody() as unknown as IBuilding).pipe(
            tap(() => this.toastService.showToast(this.translateService.instant('GENERAL.SUCCESS.RESOURCE_UPDATED_SUCCESFULLY'), IonicColors.GREEN)),
            catchError((err) => this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.RESOURCE_UPDATE'), IonicColors.RED))
          )
          : this.facilitiesService.postBuildings$(formDataToRequestBody() as unknown as IBuilding).pipe(
            tap(() => this.toastService.showToast(this.translateService.instant('GENERAL.SUCCESS.RESOURCE_CREATED_SUCCESFULLY'), IonicColors.GREEN)),
            catchError((err) => this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.RESOURCE_CREATE'), IonicColors.RED))
          ));
        break;
      case 'deviceLink':
        let payload = {} as IDeviceFacilities; 
        Object.keys(this.formGroup.value['deviceLink']['deviceLink']).forEach((facilityType) => {
          const currentInput = this.formData.structure[0].children[0].inputs.find((input) => input.id === facilityType) as ISelectInput;
          const currentValue = currentInput && currentInput.values.find((value) => value.id === this.formGroup.value['deviceLink']['deviceLink'][facilityType]);
          currentValue && (payload[facilityType as 'room' | 'unit' | 'building'] = {
            id: currentValue.id,
            name: currentValue.label
          })
        });
        requestCall = this.sensorService.linkDevice$(this.resourceId || this.resource.additionalData!['id'] as string, payload).pipe(
          tap(() => this.toastService.showToast(this.translateService.instant('GENERAL.SUCCESS.RESOURCE_LINKED_SUCCESFULLY'), IonicColors.GREEN)),
          catchError((err) => this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.RESOURCE_LINKED'), IonicColors.RED))
        );
        break;
      default:
        break;
    }
    // Nel dubbio resetto pure lo stato di attivazione del pulsante salva
    this.formStatus = {
      canSaveDraft: false,
      canSaveData: false,
    };
    // Sottoscrivo la chiamata salvata e al completamento della chiamata effettuo la navigazione alla pagina precedente
    await firstValueFrom(requestCall.pipe(
      catchError((err) => {
        this.formStatus = {
          canSaveDraft: true,
          canSaveData: true,
        };
        throw err;
      })
    ));
    this.loggerService.info("Request sended successfully, navigating to the previous page...");
    this.navigateBack();
  }

  /**
   * @description Controlla se all'interno della struttura sono presenti requisiti per mostrare il pulsante 'salva bozza' e in caso verifica la validità del campo indicato
   * @returns Torna true se sono censiti campi che devono essere compilati per poter salvare la bozza, altrimenti false
   */
  checkEligibilityToSaveDraft(): boolean {
    /* Può sembrare un operazione ad elevata complessità ma consideriamo che stiamo ciclando una struttura prepopolata (this.data) che non sarà mai troppo pesante */
    return this.formData.structure.some((structureChildren) =>
      structureChildren.children.some(
        (child) =>
          child.requiredToSaveDraft &&
          this.formGroup
            .get(structureChildren.id)
            ?.get(child.id)
            ?.get(child.requiredToSaveDraft)?.valid
      )
    );
  }

  /**
   * @description Funzione invocata al click di una checkbox con parametro 'adjustSectionVisibility' a true
   * @param checkStatus Stato della checkbox
   */
  async changeDynamicSectionsVisibility(checkStatus: boolean) {
    const builtId = `${(this.dynamicSection as IFormStructure).id}_${JSON.stringify(Date.now() + Math.random()).replace(".","")}`
    /* Se la checkbox ha stato true allora aggiungo la sezione dinamica alla form e alla struttura dati */
    if (checkStatus) {
      this.formData.structure.push({
        ...this.dynamicSection as IFormStructure,
        id: builtId
      });
      this.formGroup.addControl(
        builtId,
        this.formGroupStructure
      );
      this.modalHeight = formToHeightInRows(this.formData, this.translateService);
    } else {
      /* Altrimenti mostro un alert che avvisa che l'operazione scatenerà l'eliminazione delle sezioni indicate */
      const alert = await this.alertController.create({
        mode: 'md',
        header: 'Attenzione',
        message:
          'Procedendo verranno eliminati gli edifici, procedere?',
        buttons: [
          {
            text: 'Annulla',
            handler: () => {
              alert.dismiss();
            },
          },
          {
            text: 'Procedi',
            handler: () => {
              Object.keys(this.formGroup.value).filter((param) => param.includes(this.dynamicSection!.id)).forEach((param) => {
                this.formGroup.removeControl(param);
                let sectionIndex = this.formData.structure.findIndex(
                  (element) => element.id === param
                );
                if (sectionIndex > -1) {
                  this.formData.structure.splice(sectionIndex, 1);
                }
              })
              this.modalHeight = formToHeightInRows(this.formData, this.translateService);
              alert.dismiss();
            },
          },
        ],
      });
      await alert.present();
    }
  }


  calculateAccordionRowSpan(accordion: IFormStructureChildren) {    
    return Math.ceil(accordion.inputs.reduce((acc, curr) => {
      if (curr.type === 'inputArray') {
        acc += (curr as IInputArray).inputs.reduce((inputArrayAcc, inputArrayCurr) => {
          inputArrayAcc += inputArrayCurr.length;
          return inputArrayAcc;
        }, 2)
      } else {
        acc += ((curr as ISimpleInput).length || 0)
      }
      return acc;
    }, 18)/6);
  }

  /**
   * @description Una delle chicche della form, l'verload del metodo per l'aggiunta degli elementi, da richiamare per far fronte all'aggiunta di elementi tipo accordion, sezioni, ecc...
   * @param type Definisce il tipo di elemento che si vuole aggiungere, 'section', 'accordion' o 'inputArray'
   */
  addItem(type: 'section'): void;
  addItem(type: 'accordion', structure: IFormStructure): void;
  addItem(type: 'inputArray', structure: IFormStructure, inputArray: IInputArray, formArray: FormArray, children: IFormStructureChildren): void;
  addItem(type: 'section' | 'accordion' | 'inputArray', structure?: IFormStructure, inputArray?: IInputArray, formArray?: FormArray, children?: IFormStructureChildren) {
    switch (type) {
      case 'section':
        const builtId = `${(this.dynamicSection as IFormStructure).id}_${JSON.stringify(Date.now() + Math.random()).replace(".","")}`
        this.formData.structure.push({
          ...(this.dynamicSection as IFormStructure),
          id: builtId,
        });
        /* Aggiungo la sezione al formGroup */
        this.formGroup.addControl(
          builtId,
          this.formGroupStructure
        );
        break;
      case 'accordion':
        (this.formGroup.controls[structure!.id] as FormArray).push(
          this.formBuilder.group({
            name: [null, []],
            beds: this.formBuilder.array([
              this.formBuilder.group({name: [null, []]})
            ])
          })
        )
        const newChild = this.structureHolder.structure.find((str) => str.id === structure?.id)?.children[0];
        newChild && structure!.children.push(JSON.parse(JSON.stringify(newChild)));
        structure!.heightInRows! += 1;
        break;
      case 'inputArray':
        formArray?.push(this.formBuilder.group({name: []}));
        const newInput = (this.structureHolder.structure
        .find((str) => str.id === structure?.id)?.children
        .find((child) => child.id === children!.id)?.inputs
        .find((input) => input.id === inputArray?.id) as IInputArray)
        .inputs[0];
        newInput && inputArray!.inputs.push(JSON.parse(JSON.stringify(newInput)));
        children!.rowSpan = this.calculateAccordionRowSpan(children!);
        break;
      default:
        break;
    }
    structure && (structure.heightInRows = structure.children.reduce((acc, curr) => {
      acc += (!curr.isAccordionOpen ? 1 : this.calculateAccordionRowSpan(curr));
      return acc;
    }, 1));
    this.modalHeight = formToHeightInRows(this.formData, this.translateService);
  };

  removeItem(type: 'section', sectionId: string): void;
  removeItem(type: 'accordion', childrenIndex: number, structure: IFormStructure): void;
  removeItem(type: 'inputArray', inputIndex: number, structure: IFormStructure, formArray: FormArray, inputArray: IInputArray, children: IFormStructureChildren): void;
  removeItem(type: 'section' | 'accordion' | 'inputArray', indexOrId: number | string, structure?: IFormStructure, formArray?: FormArray, inputArray?: IInputArray, children?: IFormStructureChildren) {
    switch (type) {
      case 'section':
        let sectionIndex = this.formData.structure.findIndex(
          (element) => element.id === indexOrId
        );
        if (sectionIndex > -1) {
          this.formData.structure.splice(sectionIndex, 1);
        }
        this.formGroup.removeControl(indexOrId as string);
        break;
      case 'accordion':
        structure!.children.splice(indexOrId as number, 1);
        (this.formGroup.controls[structure!.id] as FormArray).removeAt(indexOrId as number);
        break;
      case 'inputArray':
        formArray!.removeAt(indexOrId as number);
        inputArray!.inputs.splice(indexOrId as number, 1);        
        children!.rowSpan = this.calculateAccordionRowSpan(children!);
        break;
      default:
        break;
    }
    structure && (structure.heightInRows = structure.children.reduce((acc, curr) => {
      acc += (!curr.isAccordionOpen ? 1 : this.calculateAccordionRowSpan(curr));
      return acc;
    }, 1));
    this.modalHeight = formToHeightInRows(this.formData, this.translateService);
  }

  onAccordionAction(accordionStatus: boolean, accordionField: IFormStructureChildren, structure: IFormStructure) {
    accordionField.isAccordionOpen = accordionStatus;
    accordionField.rowSpan = accordionStatus ? this.calculateAccordionRowSpan(accordionField) : 1;
    this.modalHeight = formToHeightInRows(this.formData, this.translateService);
    structure.heightInRows = structure.children.reduce((acc, curr) => {
      acc += (!curr.isAccordionOpen ? 1 : this.calculateAccordionRowSpan(curr));
      return acc;
    }, 1)
  }

  async createNewResource() {
    /* Altrimenti mostro un alert che avvisa che l'operazione scatenerà l'eliminazione delle sezioni indicate */
    const alert = await this.alertController.create({
      mode: 'md',
      header: 'Crea nuova risorsa',
      animated: true,
      buttons: [
        {
          text: 'Salva e chiudi',
          handler: (data) => {
            //this is how you read the data from the template file
            alert.dismiss(true, data.resourceName);
          },
        },
      ],
      inputs: [
        {
          name: 'resourceName',
          placeholder: 'Nome risorsa',
        },
      ],
    });
    await alert.present();
    alert.onDidDismiss().then((res) => {
      /* Se l'utente accetta procedo a rimuovere tutte le sezioni in oggetto */
      if (res.data) {
        console.log('Risorsa creata: ' + res.role);
      }
    });
  }

  /**
   * @description Funzione invocata al cambiamento di una select con score abilitato, calcola e setta lo score della scala in oggetto e ne cambia il colore
   * @param structureChild Sezione su cui si sta lavorando, al cuo interno contiene le informazioni relative allo score e agli Input
   * @param sectionValues Valori dei vari input (da prendere dal formGroup)
   */
  calculateSectionScore(structureChild: IFormStructureChildren, sectionValues: {[key: string]: string | number}) {
    // Resetto lo score
    structureChild.score!.current = 0;
    if (sectionValues) {
      if (structureChild.id === 'ipaq') {
        let metScores: any = {
          vigorousActivities: {
            multiplier: 8,
            days: ["vigorousActivities1","vigorousActivities2","vigorousActivities3","vigorousActivities4","vigorousActivities5","vigorousActivities6","vigorousActivities7","vigorousActivities8"].indexOf(sectionValues['vigorousActivities'] as string),
            minutes: ((sectionValues['vigorousActivitiesHours'] as number) * 60),
            met: 0
          },
          moderateActivities: {
            multiplier: 4,
            days: ["moderateActivities1","moderateActivities2","moderateActivities3","moderateActivities4","moderateActivities5","moderateActivities6","moderateActivities7","moderateActivities8"].indexOf(sectionValues['moderateActivities'] as string),
            minutes: ((sectionValues['moderateActivitiesHours'] as number) * 60),
            met: 0
          },
          walking: {
            multiplier: 3.3,
            days: ["walking1","walking2","walking3","walking4","walking5","walking6","walking7","walking8"].indexOf(sectionValues['walkingActivities'] as string),
            minutes: ((sectionValues['walkingActivitiesHours'] as number) * 60),
            met: 0
          }
        }
        Object.keys(metScores).forEach((activity) => {
          if (metScores[activity].days && metScores[activity].minutes) {
            const exceedMax = ((metScores[activity].days * metScores[activity].minutes) > 1260);
            metScores[activity].met = metScores[activity].multiplier * (exceedMax ? 1260 : (metScores[activity].days * metScores[activity].minutes));
          }
        })
        structureChild.score!.current += metScores.moderateActivities.met + metScores.vigorousActivities.met + metScores.walking.met;
      } else {
        // Ciclo le key dei vari Input
        structureChild.inputs?.forEach((input) => {
          switch (input.type) {
            case 'select':
              // Recupero l'index del valore selezionato per l'Input in oggetto
              const valueIndex = (input as ISelectInput)?.values?.findIndex((value) => value.id === sectionValues[input.id])
              // Se l'index esiste e quindi è di tipo 'number' allora setto il punteggio della scala di valutazione (faccio il check sul tipo e non sull'esistenza perchè il caso in cui l'index è 0)
              if (typeof valueIndex === 'number' && valueIndex !== -1) {
                structureChild.score!.current += this.inputsScores![structureChild.id].scores[input.id][valueIndex] || 0;
              }
              break;
            case 'input':
              structureChild.score!.current += Number(sectionValues[input.id]) || 0;
              break;
            default:
              structureChild.score!.current += 0;
              break;
          } 
        })
      }
      // Ciclo i radio, se presenti
      structureChild.radio?.values?.forEach((radio) => {
        structureChild.score!.current += (Number(sectionValues[radio.id]) || 0);
      }) 
    }
    //Ciclo le 3 soglie della valutazione (minimal, moderate e severe)
    for (let i = 0; i < Object.keys(this.inputsScores![structureChild.id]?.thresholds || {}).length; i++) {
      // Recupero la soglia del ciclo corrente
      const currentThreshold = this.inputsScores![structureChild.id]?.thresholds[Object.keys(this.inputsScores![structureChild.id].thresholds)[i] as unknown as 'minimal' | 'moderate' | 'severe'];
      // Se la soglia ciclata è quella corretta allora cambio il colore dello score quello della rispettiva severity
      if (currentThreshold?.length > 1 && structureChild.score!.current >= currentThreshold[0] && structureChild.score!.current <= currentThreshold[1]) {
        structureChild.score!.grade = Object.keys(this.inputsScores![structureChild.id].thresholds)[i] as unknown as 'minimal' | 'moderate' | 'severe'
      }
    }
  }

  /**
   * @description Funzione invocata al check di una checkbox abilitata all'autocompletamento della sezione, autocompila la sezione conenente la checkbox in oggetto
   * @param sectionId Id della sezione
   * @param childrenId Id della sottosezione (children)
   * @param checkboxStatus Stato della checkbox
   */
  autoCompleteSection(sectionId: string, childrenId: string, checkboxStatus: boolean) {
    // Recupero la sezione della checkbox premuta
    const sectionParams = this.formGroup.get(sectionId)?.get(childrenId);
    // Per ogni parametro della sezione, sovrascrivo con il rispettivo valore trovato negli additionalData o, nel caso la spunta venga tolta, rimuovo i valori dei parametri della sezione
    Object.keys(sectionParams?.value || {}).forEach((field) => {
      if (checkboxStatus) {
        // Se la sezione in oggetto è una sezione dinamica allora l'autocompletamento lo faccio sulla base dell'organizzazione e non del cliente
        if (this.dynamicSection && sectionId.includes(this.dynamicSection.id)) {
          this.formGroup.value['organization']['organization_address'][field] && sectionParams?.get(field)?.setValue(this.formGroup.value['organization']['organization_address'][field])
        } else {
          this.resource.additionalData![field] && sectionParams?.get(field)?.setValue(this.resource.additionalData![field]);
        }
      } else {
        sectionParams?.get(field)?.setValue(null);
      }
    });
  }

  get isDynamicSectionsExisting(): boolean {
    return !!this.dynamicSection && this.formData.structure.some((structure) => structure.id.includes(this.dynamicSection?.id!))
  }
}

/* DTO */

/* Component Related */

export interface IFormResource {
  category: string;
  breadcrumbs: IBreadcrumbs[];
  data?: Observable<any>;
  /**
   * Contiene una lista di id di risorse caricate in fase di modifica per permettere di tener traccia, per esempio, degli edifici in fase di modifica organizzazione
   */
  preloadedData?: {[key: string]: string[]};
  /**
   * Può sembrare inutile ma è essenziale, tiene traccia della lista di dati delle autocomplete allo scopo di usarla per il confronto nel momento in cui si va a scrivere nell'input dell'autocomplete 
   */
  autocompleteData?: {[key: string]: any[]};
  additionalData?: {[key: string]: string | number};
  /**
   * Sezioni da nascondere
   */
  toHideSections?: {[key: string]: string[]};
  additionalParams?: {
    scoreLoader?: Observable<any>;
  };
  /**
   * Valorizzato, mostra la stringa indicata dopo i breadCrumb marcati con il parametro showBreadCrumbSubject
   */
  breadCrumbSubject?: string;
}

/* Main */

export interface IFormData {
  /**
   * Definisce il tipo di form:
   * @param simple Se si vuole la classica form con titolo della modale e le varie sezioni con titolo e inputs
   */
  formType: 'simple';
  /**
   * La struttura della form, ogni elemento dell'array è una SEZIONE (vedi creazione paziente: la sezione paziente e medico curante sono 2 structures)
   */
  structure: IFormStructure[];
}

export interface IFormStructure {
  /**
   * Titolo della modale
   */
  title: string;
  /**
   * Id della modale
   */
  id: string;
  /**
   * Definisce l'altezza della struttura in numero di righe
   */
  heightInRows?: number;
  /**
   * Figli (sezioni) della modale
   */
  children: IFormStructureChildren[];
  /**
   * Opzionale, se abilitato permette l'aggiunta di una sezione analoga (o la rimozione della stessa)
   */
  canAddAndRemove?: boolean;
  /**
   * Label della categoria, utilizzata per andare a scrivere il riferimento alla risorsa indicata nei pulsanti di aggiunta e rimozione sezione
   */
  categoryLabel?: string;
  /**
   * Regola la possibilità di aggiungere o rimuovere children dinamicamente
   */
  dynamicChildren?: boolean;
}

export interface IFormStructureChildren {
  /**
   * Id del figlio, necessario alla creazione della form
   */
  id: string;
  /**
   * Titolo della sezione
   */
  title: string;
  /**
   * Condiziona lo stato disabilitato degli Input della sezione indicata al cambiamento di valore di un Input 
   */
  disabledOn?: {
    /**
     * Id del child contenente l'input da monitorare
     */
    childId: string;
    /**
     * Id dell'Input da monitorare 
     */
    inputId: string;
    /**
     * Valore dell'input per cui si nasconde la sezione
     */
    value: string;
  };
  /**
   * Flag opzionale che se abilitato mostra il punteggio della sezione in oggetto ed esegue di conseguenza le chiamate per il calcolo dello score
   */
  score?: {
    current: number;
    max: number;
    grade: 'minimal' | 'moderate' | 'severe' | 'none';
  };
  /**
   * Opzionale, viene valorizzato con il campo la cui compilazione è richiesta al fine di poter salvare in bozza la form
   */
  requiredToSaveDraft?: string;
  /**
   * Lista degli input
   */
  inputs: Array<
    ISimpleInput | ICheckboxInput | ISelectInput | ISimpleText | IAutoComplete | IAvatarInput | IInputArray
  >;
  /**
   * Se presenta mostra una tabella con una riga dedicata agli headers e tutti i radio-button sotto
   */
  radio?: IRadioSystem;
  /**
   * Opzionale, se abilitato permette la creazione di una nuova risorsa mediante pulsante posto a sinistra del titolo della sezione
   */
  showCreateNewResource?: boolean;
  /**
   * Opzionale, se abilitato non tiene il considerazione la sezione in oggetto nella creazione della form
   */
  hideSection?: boolean;
  /**
   * Opzionale, censisce i campi di testo con a destra un valore (es: Fattore di correzione tempo mobilitazione: -50%)
   */
  textLines?: {
    type: 'textValue',
    text: string,
    value: string
  }[];
  /**
   * Definisce il tipo di visualizzazione del children, di default la visualizzazione classica
   * @param accordion Se accordion allora mostro una visualizzazione ad accordion (mat-expansion-panel)
   */
  childrenType?: 'accordion';
  /**
   * Censisce lo stato dell'Accordion
   */
  isAccordionOpen?: boolean;
  /**
   * Opzionale, Definisce il numero di righe che occupa il Child
   */
  rowSpan?: number;
}

/* Sub */

export interface ISimpleInput {
  /**
   * Id dell'Input, necessario alla creazione della form
   */
  id: string;
  /**
   * Tipo di input:
   * @param input Input semplice, quelli in cui inserisci la mail per intendersi
   */
  type: 'input' | 'date';
  /**
   * Lunghezza dell'input (fa riferimento al sistema di griglia a 8 colonne)
   */
  length: number;
  /**
   * Label dell'Input
   */
  label: string;
  /**
   * Opzionale, permette di disabilitare il campo in fase di modifica risorsa
   */
  disableOnEdit?: boolean;
  /**
   * Lista dei validatori da applicare all'Input
   */
  validators: any[];
  /**
   * Placeholder dell'Input
   */
  placeholder?: string;
  /**
   * Valore dell'Input, se non presente il campo sarà vuoto
   */
  inputValue?: string | Date;
  /**
   * Regola dell'Input
   * @param number Potremmo andare a inserire nell'Input solo numeri
   * @param capitalize Ogni parola inserita nella form avrà la prima lettera maiscola
   */
  inputRule?: IInputRule;
  /**
   * Permetta la disabilitazione del campo sulla base di un altro campo //TODO: Implementare logica
   */
  disableOn?: {
    field: string,
    mustExist: boolean,
    cannotBe: string[]
  }
  /**
   * Regola l'aggiunta e la rimozione della risorsa in oggetto (nel concreto fa comparire una x sull'input che rimuoverà l'input in oggetto e un pulsante aggiungi che aggiunge un nuovo input)
   */
  dynamicInputParams?: {
    containerName: string;
    buttonLabel: string;
  };
  /**
   * Opzionale, permette di indicare dei dati di esempio dai quali verrà pescato un valore random e settato come valore dell'Input (logica inserita per far fronte alla rottura di coglioni di inserire dati manualmente)
   */
  generatedData?: (string | number)[]
}

export interface IInputArray {
  /**
   * Id dell'Input, necessario alla creazione della form
   */
  id: string;
  /**
   * Tipo di input:
   * @param inputArray Array di Input
   */
  type: 'inputArray';
  /**
   * Testo del pulsante che permette l'aggiunta della risorsa in oggetto
   */
  buttonLabel: string;
  /**
   * Array di input, al momento permetto solo la gestione di Input semplici (tipo quello dell'email per intendersi)
   */
  inputs: Array<ISimpleInput>;
}

export interface ISelectInput {
  /**
   * Id dell'Input, necessario alla creazione della form
   */
  id: string;
  /**
   * Tipo di input:
   * @param select Input select, quelli che fanno comparire una lista di opzioni tra cui scegliere
   */
  type: 'select';
  /**
   * Lunghezza dell'input (fa riferimento al sistema di griglia a 8 colonne)
   */
  length: number;
  /**
   * Label dell'Input
   */
  label: string;
  /**
   * Valori da inserire nella lista di valori della select
   */
  values: Array<{
    id: string;
    label: string;
    disable?: boolean;
  }>;
  /**
   * Lista dei validatori da applicare all'Input
   */
  validators?: any[];
  /**
   * Valore dell'Input, se non presente il campo sarà vuoto
   */
  inputValue?: string;
  /**
   * Funzione che invocata permette il caricamento dei valori da mostrare nella select
   */
  loader?: (id?: string | number) => Observable<{id: string, label: string, disabled?: boolean}[]>;
  /**
   * Da valorizzare solo qualora presente il 'loader', permette di ricaricare il loader alla valorizzazione di un altro input
   */
  loadOn?: string;
  /**
   * Se abilitata disabilita il servizio di traduzione a favori di nomi propri da utilizzare nelle select
   */
  disableTranslation?: boolean;
  /**
   * Parametro che valorizzato indica che i valori dell'Input sono in una fase di caricamento
   */
  isLoading?: boolean;
  /**
   * Parametro che regola la possibilità di resettare l'Input
   */
  allowClear?: boolean;
  /**
   * Opzionale, permette di indicare dei dati di esempio dai quali verrà pescato un valore random e settato come valore dell'Input (logica inserita per far fronte alla rottura di coglioni di inserire dati manualmente)
   */
  generatedData?: (string | number)[]
}

interface ICheckboxInput {
  /**
   * Id dell'Input, necessario alla creazione della form
   */
  id: string;
  /**
   * Tipo di input:
   * @param checkbox Input checkbox, quelli quadrati che puoi spuntare
   */
  type: 'checkbox';
  /**
   * Lunghezza dell'input (fa riferimento al sistema di griglia a 8 colonne)
   */
  length: number;
  /**
   * Label dell'Input
   */
  label: string;
  /**
   * Valore dell'Input, se non presente il campo sarà vuoto
   */
  inputValue?: string;
  /**
   * Opzionale, se valorizzato mostra la/e sezione/i indicata/e in funzione del valore dell'Input
   */
  adjustSectionVisibility?: string;
  /**
   * Opzionale, se abilitato completa i dati della sezioni con quelli trovati in 'AdditionalParams'
   */
  completeSection?: boolean;
  /**
   * Opzionale, censisce lo stato di abilitazione del campo
   */
  disabled?: boolean;
}

interface ISimpleText {
  /**
   * Id dell'Input, necessario alla creazione della form
   */
  id: string;
  /**
   * Tipo di input:
   * @param simpleText Testo generico
   */
  type: 'simpleText';
  /**
   * Lunghezza dell'input (fa riferimento al sistema di griglia a 8 colonne)
   */
  length: number;
  /**
   * Label dell'Input
   */
  label: string;
  /**
   * Se valorizzato mostra un pulsante di Info che alla pressione mostra il testo indicato nel parametro
   */
  infoText?: string;
}

interface IAutoComplete {
  /**
   * Id dell'Input, necessario alla creazione della form
   */
  id: string;
  /**
   * Tipo di input:
   * @param autocomplete Input di testo con autocomplete
   */
  type: 'autocomplete';
  /**
   * Lunghezza dell'input (fa riferimento al sistema di griglia a 8 colonne)
   */
  length: number;
  /**
   * Label dell'Input
   */
  label: string;
  /**
   * Lista dei validatori da applicare all'Input
   */
  validators: any[];
  /**
   * Valore dell'Input, se non presente il campo sarà vuoto
   */
  inputValue?: string;
  /**
   * Lista degli elementi suggeriti dall'autcomplete
   */
  suggestedItems: { id: string; label: string }[] | Observable<any>;
}

interface IAvatarInput {
  /**
   * Id dell'Input, necessario alla creazione della form
   */
  id: string;
  /**
   * Tipo di input
   */
  type: 'avatar';
  /**
   * Lunghezza dell'input (fa riferimento al sistema di griglia a 8 colonne)
   */
  length: number;
  /**
   * Label dell'Input
   */
  label?: string;
  /**
   * Valore dell'Input, se non presente il campo sarà vuoto
   */
  inputValue?: string;
}

interface IRadioSystem {
  /**
   * Lista dei testi nella prima riga della tabella radio
   */
  headers: string[];
  /**
   * Ogni value corrisponde ad un radio pertanto avremo l'id che ne permette l'identificazione nel formGroup e la label che altro non è che il testo del radio button
   */
  values: { label: string; id: string, inputValue?: number; }[];
  /**
   * Lista dei validatori da applicare all'Input
   */
  validators?: any[];
}

/* Other */

export interface IFormChangeEmitter {
  /**
   * Censito per sviluppo futuro, consente di salvare la bozza della risorsa in oggetto per finirne il completamento in seguito
   */
  canSaveDraft: boolean;
  /**
   * Regola la possibilità di andare a salvare la form
   */
  canSaveData: boolean;
}

export type IInputRule = 'number' | 'capitalize' | 'upperCase' | 'noSpaces';