import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { Observable, catchError, map, of, take, tap } from "rxjs";
import { IDevice, IDeviceFacilities } from "../../shared/models/be/device";
import { RequestsService } from "./utils/requests.service";
import { LoggerService } from "./utils/logger.service";
import { IStartStreaming, IStreamingConfig } from "../../shared/models/be/streaming";
import { EventType, EventTypeCodes } from "../../shared/models/be/notification";
import { IDevicesSettings } from "../../shared/models/be/devicesSettings";
import { IFcmTopic } from "../../shared/models/be/fcmTopic";

@Injectable({
    providedIn: 'any'
  })
export class SensorService {
  
    constructor(
      private _http: HttpClient,
      private requestsService: RequestsService,
      private loggerService: LoggerService
    ) {}

    public getServiceStatus$(): Observable<boolean> {
        return this._http.get<{message: string}>(`${environment.apiUrl('devices')}/iot-devices/ping`).pipe(
          take(1),
          map((val: {message: string}) => !!val.message),
          catchError(err => {
            this.loggerService.error('Error on .../ping')
            return of(false);
          })
        );
    }

    public getDevices$(customerId?: string, getAll?: boolean): Observable<IDevice[]> {
        const currentUser = this.requestsService.getCurrentUser();
        customerId = (customerId || currentUser?.customer?.id) ? `?customerId=${customerId || currentUser?.customer?.id}` : '';
        return this._http.get<{devices: IDevice[]}>(
            `${environment.apiUrl('devices')}/iot-devices${ getAll ? '' : customerId }`
        ).pipe(
            take(1),
            map((data) => data.devices),
            catchError(err => {
                throw 'error in source. Details: ' + err;
            })
        )
    }

    public getDevicesByRoomId$(roomId: string): Observable<IDevice[]> {
        const currentUser = this.requestsService.getCurrentUser();
        const customerId = currentUser?.customer?.id ? `?customerId=${currentUser?.customer?.id}` : '';
        return this._http.get<{devices: IDevice[]}>(
            `${environment.apiUrl('devices')}/iot-devices${ customerId }`
        ).pipe(
            take(1),
            map((data) => data.devices.filter((dev) => dev.facilities?.room?.id === roomId)),
            catchError(err => {
                throw 'error in source. Details: ' + err;
            })
        )
    }

    public getDevice$(deviceId: string): Observable<IDevice> {
        return this._http.get<IDevice>(
            `${environment.apiUrl('devices')}/iot-devices/${deviceId}`
        ).pipe(
            take(1),
            catchError(err => {
                /* this.store.dispatch(loadingPatientsError()) */
                throw 'error in source. Details: ' + err;
            })
        )
    }

    public linkDevice$(deviceId: string, deviceFacilities?: IDeviceFacilities & {customer?: {id: string; name: string}}): Observable<IDevice> {
        const currentUser = this.requestsService.getCurrentUser();
        deviceFacilities && (deviceFacilities['customer'] = {
            id: String(currentUser?.customer?.id),
            name: String(currentUser?.customer?.businessName)
        });
        deviceFacilities && (deviceFacilities['organization'] = {
            id: String(currentUser?.organization?.id),
            name: String(currentUser?.organization?.businessName)
        });
        return this._http.put<IDevice>(
            `${environment.apiUrl('devices')}/iot-devices/${deviceId}/facilities`, deviceFacilities || {}
        ).pipe(
            take(1),
            catchError(err => {
                /* this.store.dispatch(loadingPatientsError()) */
                throw 'error in source. Details: ' + err;
            })
        )
    }

    public deleteDevice$(deviceLabel: string) {
      return this._http.delete(
          `${environment.apiUrl('devices')}/iot-devices/${deviceLabel}`
      ).pipe(
          tap(() => {
              //this.store.dispatch(createPatientsuccess({patient}))
          }),
          catchError(err => {
              //this.store.dispatch(createPatientError())
              throw `error in source. Details:  + ${err}`
          })
      );
    }
    
    public disableDevice$(deviceId: string): Observable<{success: boolean; deviceId: string; status: string; label: string}> {
        return this._http.put<{success: boolean; deviceId: string; status: string; label: string}>(
            `${environment.apiUrl('devices')}/iot-devices/${deviceId}/disable`, {}
        ).pipe(
            take(1),
            catchError(err => {
                /* this.store.dispatch(loadingPatientsError()) */
                throw 'error in source. Details: ' + err;
            })
        )
    }

    public enableDevice$(deviceId: string): Observable<{success: boolean; deviceId: string; status: string; label: string}> {
        return this._http.put<{success: boolean; deviceId: string; status: string; label: string}>(
            `${environment.apiUrl('devices')}/iot-devices/${deviceId}/enable`, {}
        ).pipe(
            take(1),
            catchError(err => {
                /* this.store.dispatch(loadingPatientsError()) */
                throw 'error in source. Details: ' + err;
            })
        )
    }
    
    public editDeviceName$(deviceNameData: {oldLabel: string, newLabel: string}): Observable<{success: boolean; deviceId: string; status: string; label: string}> {
        return this._http.put<{success: boolean; deviceId: string; status: string; label: string}>(
            `${environment.apiUrl('devices')}/iot-devices/label`, deviceNameData
        ).pipe(
            take(1),
            catchError(err => {
                /* this.store.dispatch(loadingPatientsError()) */
                throw 'error in source. Details: ' + err;
            })
        )
    }

    public sendEvent$(deviceId: string, eventType: EventTypeCodes, buildingId?: string) {
        const currentUser = this.requestsService.getCurrentUser();
        return this._http.post<any>(
            `${environment.apiUrl('devices')}/notifications/building/${buildingId || currentUser?.building?.id}/notify`, {
                title: "Simulazione Notifica",
                body: EventType[eventType],
                eventType,
                data: {
                    deviceId,
                }
            }
        ).pipe(
            take(1),
            catchError(err => {
                throw 'error in source. Details: ' + err;
            })
        )
    }

    
    public getBuildingTopicData$(): Observable<IFcmTopic[]> {
        const currentUser = this.requestsService.getCurrentUser();
        return this._http.get<IFcmTopic[]>(
            `${environment.apiUrl('devices')}/notifications/building/${currentUser?.building?.id}/check`
        ).pipe(
            take(1),
            catchError(err => {
                throw 'error in source. Details: ' + err;
            })
        )
    }

    /**
     * @deprecated Torna i parametri di config della ws ma non ci interessa
     * @param deviceId
     */
    public getDeviceStreamingConfig$(deviceId: string): Observable<IStreamingConfig> {
        return this._http.get<any>(
            `${environment.apiUrl('devices')}/video/${deviceId}/config`
        ).pipe(
            take(1),
            catchError(err => {
                throw 'error in source. Details: ' + err;
            })
        )
    }

    /**
     * @description Dato il deviceId torna il sessionId della sessione di streaming
     * @param deviceId Id (label) del device
     */
    public getDeviceSessionId$(deviceId: string): Observable<any> {
        return this._http.post<any>(
            `${environment.apiUrl('devices')}/video/register-viewer/${deviceId}`, {}
        ).pipe(
            take(1),
            catchError(err => {
                throw 'error in source. Details: ' + err;
            })
        )
    }

    /**
     * @description Chiamata di avvio streaming per il sessionId passato
     * @param deviceId Id (label) del device
     * @param sessionId SessionId dello streaming
     */
    public startDeviceStreaming$(deviceId: string, sessionId: string): Observable<IStartStreaming> {
        return this._http.post<any>(
            `${environment.apiUrl('devices')}/video/stream/${deviceId}/start`, {sessionId}
        ).pipe(
            take(1),
            catchError(err => {
                throw 'error in source. Details: ' + err;
            })
        )
    }

    /**
     * @description Chiamata di stop streaming per il sessionId passato
     * @param deviceId Id (label) del device
     * @param sessionId SessionId dello streaming
     */
    public stopDeviceStreaming$(deviceId: string, sessionId: string): Observable<any> {
        return this._http.post<any>(
            `${environment.apiUrl('devices')}/video/stream/${deviceId}/stop`, {sessionId}
        ).pipe(
            take(1),
            catchError(err => {
                throw 'error in source. Details: ' + err;
            })
        )
    }

    public getDevicesSettings$(resource: 'roomId' | 'unitId', id?: string) {
        const currentUser = this.requestsService.getCurrentUser();
        return this._http.get<IDevicesSettings>(
            `${environment.apiUrl('notifications')}/notifications/settings?buildingId=${currentUser?.building?.id}${'&'+resource+'='+id}`
        ).pipe(
            take(1),
            map((data) => {
                data.scheduleStartTime = data.scheduleStartTime || "00:00";
                data.scheduleEndTime = data.scheduleEndTime || "00:00";
                return data;
            }),
            catchError(err => {
                throw 'error in source. Details: ' + err;
            })
        )
    }

    public editDevicesSettings$(devicesSetting: IDevicesSettings) {
        const currentUser = this.requestsService.getCurrentUser();
        devicesSetting = {
            ...devicesSetting,
            buildingId: currentUser!.building!.id
        }
        return this._http.put<any>(
            `${environment.apiUrl('notifications')}/notifications/settings/room/${devicesSetting.roomId}`, devicesSetting
        ).pipe(
            take(1),
            catchError(err => {
                /* this.store.dispatch(loadingPatientsError()) */
                throw 'error in source. Details: ' + err;
            })
        )
    }
}