export enum IonicColors {
    PRIMARY = 'primary',
    GREEN = 'success',
    YELLOW = 'warning',
    RED = 'danger',
    BLACK = 'dark',
    GREY = 'medium',
    LIGHT_GRAY = 'light',
    NOTIFICATION_OFFLINE = 'medium',//"#000000",
    NOTIFICATION_ONLINE = 'success',//"#000000",
    NOTIFICATION_LOW = 'warning',//"#E7DA53",
    NOTIFICATION_MEDIUM = 'tertiary',//"#E7DA53",
    NOTIFICATION_HIGH = 'danger',//"#EE867D"
}