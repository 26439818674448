// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: IEnvironment = {
  production: false,
  //unsecureApiUrl: "http://192.168.1.109:7001"
  apiUrl: (service: string) => 'https://api-portal-dev.wita.care',
  omdApi: {
    hostname: "https://staging.omnidermal-wv.com",
    port: 80,
    apiRoot: "v1",
    token: "9f918e0dcbe1a1355c54855b631d3451224fccae680687e6ba6a5395587cdb4d",
    omdIntegrationToggle: true
  },
  firebase: {
    apiKey: "AIzaSyDMObXhht67d0j-Cj-8m0Pt5JZqmIiBfKA",
    authDomain: "wita-420w69.firebaseapp.com",
    projectId: "wita-420w69",
    storageBucket: "wita-420w69.firebasestorage.app",
    messagingSenderId: "947047359142",
    appId: "1:947047359142:web:129f92f8929e3c19868a67",
    measurementId: "G-687BE9DDR8",
    vapidKey: "BLhUiNqRJ2SYO9No45k-zEc3zqehpEufST-8Fv4p0N4deY0cFV_NMsAwpU87vqTWW8AVAnKnVW_Jp7xrj85mMGw"
  },
  wsUrl: 'wss://api-portal-dev.wita.care/'
};

export interface IEnvironment {
  production: boolean,
  apiUrl: (service: string) => string,
  omdApi: {
    hostname: string,
    port: number,
    apiRoot: string,
    token: string,
    omdIntegrationToggle: boolean
  },
  firebase: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
    vapidKey: string;
  },
  wsUrl: string
}
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
  