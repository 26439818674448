import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { getMessaging, getToken, MessagePayload, onMessage } from "firebase/messaging";
import { FirebaseApp, initializeApp } from 'firebase/app';
import { ToastService } from './utils/toast.service';
import { catchError, Observable, take } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { RequestsService } from './utils/requests.service';
import { eventTypeToData, INotificationData } from '../../shared/models/be/notification';
import { RingtoneService } from './utils/ringtone.service';
import { SharedService } from './shared.service';
import { LoggerService } from './utils/logger.service';
import { getTimeDifferenceInMs } from '../../shared/utils/transformation.util';
import { IonicColors } from '../../shared/enums/ionicColors.enum';

@Injectable({ providedIn: 'any' })
export class NotificationService {

    message?: MessagePayload;
    app: FirebaseApp;

    constructor(
      private toastService: ToastService,
      private _http: HttpClient,
      private requestsService: RequestsService,
      private ringtoneService: RingtoneService,
      private shareSrv: SharedService,
      private loggerService: LoggerService
    ) {
        this.app = initializeApp(environment.firebase);
    }

    requestPermission() {  
      const messaging = getMessaging();  
      getToken(messaging, { vapidKey: environment.firebase.vapidKey }).then((currentToken) => {
        if (currentToken) {
          const currentUser = this.requestsService.getCurrentUser();
          currentUser!.fcmToken = currentToken;
          this.requestsService.saveCurrentUser(currentUser);
          this.postToken$(currentUser?.building?.id || "", currentToken).subscribe();
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        this.toastService.showToast("Autorizzare consenso notifiche!", IonicColors.RED)
      });
    }

    listen() {
      const messaging = getMessaging();
      onMessage(messaging, (payload) => {
        if (payload.data?.['type'] !== 'ping') {
          if (payload.data?.['facilities'] ) {
            payload.data['facilities'] = JSON.parse(payload.data['facilities'])
          }
          console.log('Notifications received: ', payload);
          this.loggerService.info(`Notifications received: ${payload}`)
          const notificationData = {
            ...payload.data,
            severity: eventTypeToData[(payload.data as unknown as INotificationData).eventType]?.severity,
            timestamp: new Date(payload.data!['timestamp'])
          } as INotificationData;
          this.toastService.showToast(payload.notification?.title || "Notifica di test", eventTypeToData[notificationData.eventType].ionicColor);
          this.shareSrv.addAlert(notificationData);
          this.loggerService.info(`Notification receive time: ${getTimeDifferenceInMs(payload.data?.['timestamp'] || new Date())}`)
          this.ringtoneService.playRingtone();          
        }
      });
    }

    public postToken$(buildingId: string, token?: string): Observable<any> {
      token = token || this.requestsService.getCurrentUser()?.fcmToken || "";
      return this._http.post(
          `${environment.apiUrl('notifications')}/notifications/register-token`,
          {
            token,
            buildingId
          }
      ).pipe(
          take(1),
          catchError(err => {
              throw `error in source. Details:  + ${err}`
          })
      );
    }

    public removeToken$(buildingId?: string, token?: string): Observable<any> {
      token = token || this.requestsService.getCurrentUser()?.fcmToken || "";
      buildingId = buildingId || this.requestsService.getCurrentUser()?.building?.id || "";
      return this._http.post(
          `${environment.apiUrl('notifications')}/notifications/unregister-token`,
          {
            token,
            buildingId
          }
      ).pipe(
          take(1),
          catchError(err => {
              throw `error in source. Details:  + ${err}`
          })
      );
    }
}