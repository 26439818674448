import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { FormArray, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { TimeRangeSelector } from '../time-range-selector/time-range-selector.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { IonButton, IonLabel, IonSegment, IonSegmentButton, IonToolbar } from '@ionic/angular/standalone';
import { IDevicesSettings } from '../../models/be/devicesSettings';

@Component({
  selector: 'timingComponentsGroup',
  templateUrl: './timingComponentsGroup.component.html',
  styleUrls: ['./timingComponentsGroup.component.scss'],
  standalone: true,
  imports: [ 
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatGridListModule,
    MatFormFieldModule,
    MatSelectModule,
    TranslateModule,
    ReactiveFormsModule,
    MatInputModule,
    TimeRangeSelector,
    MatButtonToggleModule,
    MatSliderModule,
    IonButton,
    IonSegment,
    IonLabel,
    IonSegmentButton,
    IonToolbar,
    FormsModule
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic'
      }
    }
  ]
})
export class TimingComponentsGroup implements OnInit {
  
  /**
   * @description FormGroup che regola tutti i campi della form nel component 
   */
  public formGroup!: FormGroup;
  public formArray!: FormArray;
  public hasError: boolean = false;
  public isHandleTouched: boolean = false;
  public hoursList: string[] = this.generaOrari();
  @Output() onSubmitData = new EventEmitter();
  @Input() deviceSettings!: IDevicesSettings;
  @Input() isMobile: boolean = true;
  
  @ViewChild(TimeRangeSelector) child!: TimeRangeSelector;
  constructor(
  ) {
  }
  
  ngOnInit() {
  }

  refreshCirclePosition() {
    setTimeout(() => {
      this.child.refreshCirclePosition();
    }, 500);
  }

  handleTouched(isHandleTouched: boolean) {
    this.isHandleTouched = isHandleTouched;
  }

  onScroll() {
    this.child.refreshCirclePosition();
  }

  generaOrari(): string[] {
    const orari: string[] = [];
    for (let ora = 0; ora < 24; ora++) {
      for (let minuti = 0; minuti < 60; minuti += 30) {
        const oraStringa = ora.toString().padStart(2, '0');
        const minutiStringa = minuti.toString().padStart(2, '0');
        orari.push(`${oraStringa}:${minutiStringa}`);
      }
    }
    return orari;
  }

  /**
   * TEMPORANEO
   * @deprecated
   */
  formatLabel(value: number): string {
    return `${Math.floor(value/2).toString().padStart(2, '0')}:${value % 2 === 0 ? '00' : '30'}`;
  }

  submitData() {
    this.onSubmitData.emit(this.deviceSettings)
  }

}
