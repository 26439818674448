import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { environment } from '../../../environments/environment';
import { Observable, catchError, combineLatest, finalize, forkJoin, from, map, mergeAll, mergeMap, of, switchMap, take, tap, toArray } from "rxjs";
import { createPatientError, createPatientsuccess, loadingPatients, loadingPatientsError } from "../../state/actions/patients.actions";
import { IBuilding } from "../../shared/models/be/building";
import { IUnit } from "../../shared/models/be/unit";
import { IRoom } from "../../shared/models/be/room";
import { IBed } from "../../shared/models/be/bed";
import { IOrganization } from "../../shared/models/be/organization";
import { RequestsService } from "./utils/requests.service";
import { LoggerService } from "./utils/logger.service";

@Injectable({
    providedIn: 'any'
  })
export class FacilitiesService {
  
    constructor(
      private store: Store,
      private _http: HttpClient,
      private requestsService: RequestsService,
      private loggerService: LoggerService
    ) {}

    /* BUNDLE */

    /**
     * @description Funzione che dato l'id del reparto/appartamento in oggetto torna il reparto e le unità ad esso correlate
     * @param unitId Id del reparto/appartamento
     * @param organizationId Id dell'organizzazione
     * @param customerId Id del cliente, necessario al fine di poter effettuare le chiamate
     * @returns Reparto/appartamento e le unità ad esso correlate
     */
    public getWardByIdWithRoomsAndBeds$(unitId: string): Observable<{ward: IUnit, rooms: IRoom[]}> {
        return combineLatest([
            this.getUnitById$(unitId),
            this.getRooms$(unitId).pipe(
                take(1),
                mergeAll(),
                mergeMap((room) => this.getBeds$(room.id!).pipe(
                    take(1),
                    map((beds) => ({
                        ...room,
                        beds
                    })),
                    tap((data) => {
                        room.beds = data.beds;
                    })
                )),
                toArray(),
                map((data) => ({rooms: data}))
            )
        ]).pipe(
            take(1),
            map(([ward, rooms]) => ({
                ward,
                rooms: rooms.rooms
            })),           
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        )
    }

    public getServiceStatus$(): Observable<boolean> {
        return this._http.get<{message: string}>(`${environment.apiUrl('facilities')}/facilities/ping`).pipe(
          take(1),
          map((val: {message: string}) => !!val.message),
          catchError(err => {
            this.loggerService.error('Error on .../ping')
            return of(false);
          })
        );
      }

    /*  ORGANIZATION  */

    public getOrganization$(customerId?: string) {
        const currentUser = this.requestsService.getCurrentUser();
        return this._http.get<{organizations: IOrganization[]}>(
            `${environment.apiUrl('facilities')}/facilities/organizations?customerId=${customerId || currentUser?.customer?.id}`
        ).pipe(
            take(1),
            map(data => data.organizations),
            tap(() => {
                //this.store.dispatch(loadCustomersSuccess({customers}))
            }),
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        )
    }

    public getOrganizationById$(organizationId?: string, customerId?: string) {
        return this._http.get<IOrganization>(
            `${environment.apiUrl('facilities')}/facilities/organizations/${organizationId}?customerId=${customerId}`
        ).pipe(
            take(1),
            tap(() => {
                //this.store.dispatch(loadCustomersSuccess({customers}))
            }),
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        )
    }

    public postOrganization$(organization: IOrganization) {
        return this._http.post<{ id: string }>(
            `${environment.apiUrl('facilities')}/facilities/organizations?customerId=${organization.customerId}`,
            organization
        ).pipe(
            take(1),
            /* map(data => data), */
            tap(() => {
                //this.store.dispatch(loadCustomersSuccess({customers}))
            }),
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        );
    }

    public putOrganization$(organization: IOrganization) {
        return this._http.put(
            `${environment.apiUrl('facilities')}/facilities/organizations/${organization.id}?customerId=${organization.customerId}`,
            organization
        ).pipe(
            take(1),
            tap((data) => {
                if (organization.id == this.requestsService.getCurrentUser()?.organization?.id) {
                    this.requestsService.saveCurrentUser({
                            ...this.requestsService.getCurrentUser()!,
                            organization: {
                                id: organization.id,
                                businessName: organization.name
                            }
                        }
                    )
                }
                //this.store.dispatch(loadCustomersSuccess({customers}))
            }),
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        );
    }

    public deleteOrganizations$(orgIds: string[], customerId: string) {
        return this._http.delete(
            `${environment.apiUrl('facilities')}/facilities/organizations?customerId=${customerId}`,
            {
                body: {
                    customerId,
                    orgIds
                }
            }
        ).pipe(
            take(1),
            map(data => data),
            tap(() => {
                //this.store.dispatch(loadCustomersSuccess({customers}))
            }),
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        );
    }

    /*  BUILDING  */

    /**
     * @description Recupero le facilities di tipo "Edificio"
     * @param retrieveFullStructure Opzionale, se true mi torna TUTTA la alberature dell'edificio, quindi avrò pure reparti stanze e letti
     * @param customerId Opzionale, se valorizzato lo imposta nei queryParams altrimenti ci mette quello trovato nel localStorage (se presente)
     * @param orgId Opzionale, se valorizzato lo imposta nei queryParams altrimenti ci mette quello trovato nel localStorage (se presente)
     */
    public getBuildings$(customerId?: string, orgId?: string, retrieveFullStructure: boolean = false) {
        const currentUser = this.requestsService.getCurrentUser();
        return this._http.get<{buildings: IBuilding[]}>(
            `${environment.apiUrl('facilities')}/facilities/buildings?customerId=${customerId || currentUser?.customer?.id}&organizationId=${orgId || currentUser?.organization?.id}&fullStructure=${retrieveFullStructure}`
        ).pipe(
            take(1),
            map(data => data.buildings),
            tap((buildings) => {
                //this.store.dispatch(loadCustomersSuccess({customers}))
            }),
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        )
    }

    public getBuilding$(buildingId: string, retrieveFullStructure: boolean = false) {
        const currentUser = this.requestsService.getCurrentUser();
        return this._http.get<IBuilding>(
            `${environment.apiUrl('facilities')}/facilities/buildings/${buildingId}?customerId=${currentUser?.customer?.id}&organizationId=${currentUser?.organization?.id}&fullStructure=${retrieveFullStructure}`
        ).pipe(
            take(1),
            tap((building) => {
                //this.store.dispatch(loadCustomersSuccess({customers}))
            }),
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        )
    }

    public postBuildings$(building: IBuilding) {
        const currentUser = this.requestsService.getCurrentUser();
        return this._http.post<{buildings: IBuilding}>(
            `${environment.apiUrl('facilities')}/facilities/buildings`,
            {
                ...building,
                customerId: currentUser?.customer?.id,
                organizationId: currentUser?.organization?.id,
            }
        ).pipe(
            take(1),
            /* map(data => data.buildings), */
            tap(() => {
                //this.store.dispatch(loadCustomersSuccess({customers}))
            }),
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        )
    }

    public putBuildings$(building: IBuilding) {
        const currentUser = this.requestsService.getCurrentUser();
        return this._http.put(
            `${environment.apiUrl('facilities')}/facilities/buildings/${building.id}`,
            {
                ...building,
                customerId: currentUser?.customer?.id,
                organizationId: currentUser?.organization?.id
            }
        ).pipe(
            take(1),
            tap(() => {
                //this.store.dispatch(loadCustomersSuccess({customers}))
            }),
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        );
    }

    public deleteBuilding$(buildingId: string, customerId?: string, organizationId?: string) {
        const currentUser = this.requestsService.getCurrentUser();
        return this._http.delete(
            `${environment.apiUrl('facilities')}/facilities/buildings/${buildingId}`,
            {
                body: {
                    customerId: customerId || currentUser?.customer?.id,
                    organizationId: organizationId || currentUser?.organization?.id
                }
            }
        ).pipe(
            take(1),
            tap(() => {
                //this.store.dispatch(loadCustomersSuccess({customers}))
            }),
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        );
    }

    /* UNITS */

    public getUnits$(buildingId?: string, customerId?: string, orgId?: string) {
        const currentUser = this.requestsService.getCurrentUser();
        return this._http.get<{units: IUnit[]}>(
            `${environment.apiUrl('facilities')}/facilities/units?customerId=${customerId || currentUser?.customer?.id}&organizationId=${orgId || currentUser?.organization?.id}&buildingId=${buildingId|| currentUser?.building?.id}`
        ).pipe(
            take(1),
            map(data => data.units),
            tap((units) => {
                //this.store.dispatch(loadCustomersSuccess({customers}))
            }),
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        )
    }

    public getUnitById$(unitId: string) {
        const currentUser = this.requestsService.getCurrentUser();
        return this._http.get<IUnit>(
            `${environment.apiUrl('facilities')}/facilities/units/${unitId}?customerId=${currentUser?.customer?.id}&organizationId=${currentUser?.organization?.id}`
        ).pipe(
            take(1),
            tap((units) => {
                //this.store.dispatch(loadCustomersSuccess({customers}))
            }),
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        )
    }

    public postUnit$(unit: IUnit) {
        const currentUser = this.requestsService.getCurrentUser();
        return this._http.post<IUnit>(
            `${environment.apiUrl('facilities')}/facilities/units`,
            {
                ...unit,
                customerId: currentUser?.customer?.id,
                organizationId: currentUser?.organization?.id
            }
        ).pipe(
            take(1),
            tap(() => {
                //this.store.dispatch(loadCustomersSuccess({customers}))
            }),
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        )
    }

    public putUnit$(unit: IUnit) {
        const currentUser = this.requestsService.getCurrentUser();
        return this._http.put<IUnit>(
            `${environment.apiUrl('facilities')}/facilities/units/${unit.id}`,
            {
                ...unit,
                customerId: currentUser?.customer?.id,
                organizationId: currentUser?.organization?.id
            }
        ).pipe(
            take(1),
            tap(() => {
                //this.store.dispatch(loadCustomersSuccess({customers}))
            }),
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        )
    }
    
    public deleteUnit$(unitId: string) {
        const currentUser = this.requestsService.getCurrentUser();
        return this._http.delete(
            `${environment.apiUrl('facilities')}/facilities/units/${unitId}`,
            {
                body: {
                    customerId: currentUser?.customer?.id,
                    organizationId: currentUser?.organization?.id
                }
            }
        ).pipe(
            take(1),
            tap(() => {
                //this.store.dispatch(loadCustomersSuccess({customers}))
            }),
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        );
    }
    
    /* ROOMS */

    public getRooms$(unitId: string, customerId?: string, orgId?: string) {
        const currentUser = this.requestsService.getCurrentUser();
        return this._http.get<{rooms: IRoom[]}>(
            `${environment.apiUrl('facilities')}/facilities/rooms?unitId=${unitId}&customerId=${customerId || currentUser?.customer?.id}&organizationId=${orgId || currentUser?.organization?.id}`
        ).pipe(
            take(1),
            map(data => data.rooms),
            tap((units) => {
                //this.store.dispatch(loadCustomersSuccess({customers}))
            }),
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        )
    }

    public postRoom$(room: IRoom) {
        const currentUser = this.requestsService.getCurrentUser();
        return this._http.post<IRoom>(
            `${environment.apiUrl('facilities')}/facilities/rooms`,
            {
                ...room,
                customerId: currentUser?.customer?.id,
                organizationId: currentUser?.organization?.id
            }
        ).pipe(
            take(1),
            tap(() => {
                //this.store.dispatch(loadCustomersSuccess({customers}))
            }),
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        )
    }

    public putRoom$(room: IRoom) {
        const currentUser = this.requestsService.getCurrentUser();
        return this._http.put<IRoom>(
            `${environment.apiUrl('facilities')}/facilities/rooms/${room.id}`,
            {
                ...room,
                customerId: currentUser?.customer?.id,
                organizationId: currentUser?.organization?.id
            }
        ).pipe(
            take(1),
            tap(() => {
                //this.store.dispatch(loadCustomersSuccess({customers}))
            }),
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        )
    }

    public deleteRoom$(roomId: string) {
        const currentUser = this.requestsService.getCurrentUser();
        return this._http.delete(
            `${environment.apiUrl('facilities')}/facilities/rooms/${roomId}`,
            {
                body: {
                    customerId: currentUser?.customer?.id,
                    organizationId: currentUser?.organization?.id
                }
            }
        ).pipe(
            take(1),
            tap(() => {
                //this.store.dispatch(loadCustomersSuccess({customers}))
            }),
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        );
    }

    /* BEDS */

    public getBeds$(roomId: string): Observable<IBed[]> {        
        const currentUser = this.requestsService.getCurrentUser();
        return this._http.get<{beds: IBed[]}>(
            `${environment.apiUrl('facilities')}/facilities/beds?roomId=${roomId}&customerId=${currentUser?.customer?.id}&organizationId=${currentUser?.organization?.id}`
        ).pipe(
            take(1),
            map(data => data.beds),
            tap((units) => {
                //this.store.dispatch(loadCustomersSuccess({customers}))
            }),
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        )
    }

    public postBed$(bed: IBed) {
        const currentUser = this.requestsService.getCurrentUser();
        return this._http.post<IBed>(
            `${environment.apiUrl('facilities')}/facilities/beds`,
            {
                ...bed,
                customerId: currentUser?.customer?.id,
                organizationId: currentUser?.organization?.id
            }
        ).pipe(
            take(1),
            tap(() => {
                //this.store.dispatch(loadCustomersSuccess({customers}))
            }),
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        )
    }

    public putBed$(bed: IBed) {
        const currentUser = this.requestsService.getCurrentUser();
        return this._http.put<IBed>(
            `${environment.apiUrl('facilities')}/facilities/beds/${bed.id}`,
            {
                ...bed,
                customerId: currentUser?.customer?.id,
                organizationId: currentUser?.organization?.id
            }
        ).pipe(
            take(1),
            tap(() => {
                //this.store.dispatch(loadCustomersSuccess({customers}))
            }),
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        )
    }

    public deleteBed$(bedId: string) {
        const currentUser = this.requestsService.getCurrentUser();
        return this._http.delete(
            `${environment.apiUrl('facilities')}/facilities/beds/${bedId}`,
            {
                body: {
                    customerId: currentUser?.customer?.id,
                    organizationId: currentUser?.organization?.id
                }
            }
        ).pipe(
            take(1),
            tap(() => {
                //this.store.dispatch(loadCustomersSuccess({customers}))
            }),
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        );
    }
}