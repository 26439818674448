import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { IUnit } from '../../models/be/unit';
import { catchError, combineLatest, Observable, of, switchMap, tap } from 'rxjs';
import { RequestsService } from '../../../core/services/utils/requests.service';
import { FacilitiesService } from '../../../core/services/facilities.service';
import { TimeRangeSelector } from '../time-range-selector/time-range-selector.component';
import { IDevice } from '../../models/be/device';
import { TimingComponentsGroup } from '../timingComponentsGroup/timingComponentsGroup.component';
import { SensorService } from '../../../core/services/sensors.service';
import { IDevicesSettings } from '../../models/be/devicesSettings';
import { ToastService } from '../../../core/services/utils/toast.service';
import { IonicColors } from '../../enums/ionicColors.enum';

@Component({
  selector: 'app-dialog',
  templateUrl: './sensor-timing-dialog.component.html',
  styleUrls: ['./sensor-timing-dialog.component.scss'],
  standalone: true,
  providers: [],
  imports: [ 
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatGridListModule,
    MatFormFieldModule,
    MatSelectModule,
    TranslateModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatInputModule,
    TimingComponentsGroup
  ]
})
export class SensorTimingDialogComponent implements OnInit {
  
  /**
   * @description FormGroup che regola tutti i campi della form nel component 
   */
  public formGroup!: FormGroup;
  public formArray!: FormArray;
  public hasError: boolean = false;
  public unitsTree: (IUnit & {isOpen: boolean, devicesSettings?: IDevicesSettings})[] = [];
  public isHandleTouched: boolean = false;
  @ViewChild(TimeRangeSelector) child!: TimeRangeSelector;

  constructor(
    private requestsService: RequestsService,
    private sensorService: SensorService,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: {
      /* title: string,
      patients: {id: string, name: string}[],
      alert?: IAlert */
      devicesRequest: Observable<IDevice[]>,
      facilitiesService: FacilitiesService,
      sensorService: SensorService
    },
  ) { 
    combineLatest([
      this.requestsService.currentUser$,
      data.devicesRequest
    ]).pipe(
      tap(([user, devices]) => {
        user?.building?.id && data.facilitiesService.getBuilding$(user?.building?.id, true).pipe(
          tap((building) => {
            this.unitsTree = (building.units || []).reduce((acc, curr) => {
              let currentUnitDevices = devices.filter((device) => device.facilities?.unit?.id === curr.id);
              if (currentUnitDevices.length) {
                this.data.sensorService.getDevicesSettings$('unitId', curr.id).pipe(
                  tap((devicesSettings) => {                    
                    acc.push({
                      ...curr,
                      isOpen: currentUnitDevices.length === 1,
                      devicesSettings,
                      rooms: curr.rooms?.filter((room) => currentUnitDevices.map((device) => device.facilities?.room?.id).includes(room.id)).map((room) => {
                        let currRoomDevices = devices.filter((device) => device.facilities?.room?.id === room.id);
                        return {
                          ...room,
                          devices: currRoomDevices,
                        }
                      })
                    })
                  })
                ).subscribe();
              };
              return acc;
            }, [] as (IUnit & {isOpen: boolean, devicesSettings?: IDevicesSettings})[]);
          })
        ).subscribe();
      })
    ).subscribe();
  }

  ngOnInit() {
    
  }

  refreshCirclePosition() {
    setTimeout(() => {
      this.child?.refreshCirclePosition();
    }, 500);
  }

  handleTouched(isHandleTouched: boolean) {
    this.isHandleTouched = isHandleTouched;
  }

  onScroll() {
    this.child.refreshCirclePosition();
  }


  submitDevicesSettings(devicesSettings: IDevicesSettings, unit: IUnit & { isOpen: boolean; devicesSettings?: IDevicesSettings; }) {
    devicesSettings = {
      ...devicesSettings, 
      unitId: unit.id
    }    
    this.data.sensorService.editDevicesSettings$(devicesSettings).pipe(
      tap(() => {
        this.toastService.showToast("Temporizzazione salvata con successo");
        unit.isOpen = !unit.isOpen;
      }),
      catchError((err) => {
        this.toastService.showToast("Errore durante il salvataggio della temporizzazione", IonicColors.NOTIFICATION_HIGH);
        throw err;
      })
    ).subscribe();
  }
}
