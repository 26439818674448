import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { environment } from '../../../environments/environment';
import { createCustomerError, createCustomerSuccess, loadCustomersSuccess, loadingCustomers, loadingCustomersError } from "../../state/actions/customers.actions";
import { Observable, catchError, map, of, take, tap } from "rxjs";
import { ICustomer } from "../../shared/models/be/customers";
import { IPatient } from "../../shared/models/be/patients";
import { LoggerService } from "./utils/logger.service";
import { FhirInstance } from "../../shared/models/beGeneral";

@Injectable({
    providedIn: 'any'
  })
export class CustomerService {
  
    constructor(
      private store: Store,
      private _http: HttpClient,
      private loggerService: LoggerService
    ) {}

    public getServiceStatus$(): Observable<boolean> {
      return this._http.get<{message: string}>(`${environment.apiUrl('customers')}/customers/ping`).pipe(
        take(1),
        map((val: {message: string}) => !!val.message),
        catchError(err => {
          this.loggerService.error('Error on .../ping')
          return of(false);
        })
      );
    }

    public forceCustomerFhirInstance$(data: { customerId: string; instanceName: string}) {
      return this._http.put<{message: string}>(`${environment.apiUrl('customers')}/fhir-instances/checkSpecificInstance?token=jFarRgRCmppxzko5tDwpTimLsEYN4a3ZTFnncL46yz2ButZGxFi7JKinndGkYJTL`, data).pipe(
        take(1),
        catchError(err => {
          this.loggerService.error('Error on .../ping')
          return of(false);
        })
      );
    }

    public getFhirInstances$() {
      return this._http.get<{instances: FhirInstance[]}>(`${environment.apiUrl('customers')}/fhir-instances`).pipe(
        take(1),
        map(data => data.instances),
        catchError(err => {
          this.loggerService.error('Error on .../ping')
          return of([]);
        })
      );
    }

    /**
     * @description Carica la lista dei clienti nello store e li ritorna al subscribe
     */
    public loadCustomers$(page: number = 0, itemsForPage: number = 10): Observable<ICustomer[]> {
      return this._http.get<{customers: ICustomer[]}>(
        `${environment.apiUrl('customers')}/customers?page=${page}&pageSize=${itemsForPage}` // TODO: Implementare paginazione lato BE
        ).pipe(
          take(1),
          map((data) => data.customers),
          tap((customers) => {
              //this.store.dispatch(loadCustomersSuccess(customers))
          }),
          catchError(err => {
              this.store.dispatch(loadingCustomersError())
              this.loggerService.error('error in source. Details: ' + err);
              throw [];
          })
      )
    }

    public createCustomer$(customer: ICustomer): Observable<any> {
      return this._http.post(
        `${environment.apiUrl('customers')}/customers`,
        customer
      ).pipe(
          take(1),
          tap(() => {
              this.store.dispatch(createCustomerSuccess({customer}))
          }),
          catchError(err => {
              this.store.dispatch(createCustomerError())
              throw `error in source. Details:  + ${err}`
          })
      );
    }
    
    public editCustomer$(customer: ICustomer) {
      return this._http.put(
        `${environment.apiUrl('customers')}/customers/${customer.id}`,
        customer
      ).pipe(
          take(1),
          tap(() => {
              //this.store.dispatch(createCustomerSuccess({customer}))
          }),
          catchError(err => {
              //this.store.dispatch(createCustomerError())
              throw `error in source. Details:  + ${err}`
          })
      );
    }

    public deleteCustomer$(customerId: string) {
      return this._http.delete(
        `${environment.apiUrl('customers')}/customers/${customerId}`
      ).pipe(
          take(1),
          tap(() => {
              //this.store.dispatch(createCustomerSuccess({customer}))
          }),
          catchError(err => {
              //this.store.dispatch(createCustomerError())
              throw `error in source. Details:  + ${err}`
          })
      );
    }

    public getCustomer$(customerId: string) {
      return this._http.get<ICustomer>(
        `${environment.apiUrl('customers')}/customers/${customerId}`
        ).pipe(
          take(1),
          tap((customer) => {
              //this.store.dispatch(loadCustomersSuccess(customer))
          }),
          catchError(err => {
              this.store.dispatch(loadingCustomersError())
              throw 'error in source. Details: ' + err;
          })
      )
    }
}