
<mat-toolbar style="width: 100%; background-color: #00AEBE; display: flex; justify-content: space-between;">
  <button mat-icon-button class="example-icon" aria-label="menu button" style="background-color: transparent; color: aliceblue;" (click)="sideNavService.isHome ? drawer.toggle() : goHome()">
    <mat-icon>{{sideNavService.isHome ? 'menu' : 'arrow_back'}}</mat-icon>
  </button>
  <span style="color: aliceblue; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 80%; margin: auto; text-align: center;">{{buildingName}}</span>
  <!-- <button mat-icon-button class="example-icon favorite-icon" aria-label="settings" style="background-color: transparent; color: aliceblue;" [ngStyle]="{'visibility': sideNavService.isHome ? null : 'hidden'}" (click)="openTimingDialog()">
    <mat-icon>update</mat-icon>
  </button> -->
  <button mat-icon-button class="example-icon favorite-icon" aria-label="refresh" style="background-color: transparent; color: aliceblue;" (click)="reloadPage()">
    <mat-icon>refresh</mat-icon>
  </button>
</mat-toolbar>

<mat-drawer-container class="example-container" hasBackdrop="true">
  <mat-drawer #drawer mode="over">
      <!-- MENU ITEM LIST -->
      <mat-nav-list style="padding: 0;">
        <ng-container *ngFor="let item of menuItems">
          <a [routerLink]="item.routerLink || undefined" (click)="sideNavService.isHome = false; item.id === 'logout' ? logout() : null; drawer.toggle()" style="color: inherit; text-decoration: none;">
            <mat-list-item>
              <mat-icon matListItemIcon>{{item.icon}}</mat-icon>
              <div matListItemTitle style="color: #616161;">{{'SIDENAV.ITEMS.'+item.label | translate}}</div>
            </mat-list-item>
          </a>
          <!-- DIVIDER -->
          <mat-divider></mat-divider>
        </ng-container>
      </mat-nav-list>
      <p style="color: gray; text-align: center;">Versione: 0.77</p>
  </mat-drawer>
  <mat-drawer-content>
    <ion-content [fullscreen]="true" [scrollY]="false">
      <router-outlet></router-outlet>
    </ion-content>
  </mat-drawer-content>
</mat-drawer-container>