import { Component, Inject, OnInit } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { ITableColumns } from '../../../../shared/components/table/table.component';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-privileges-table',
  templateUrl: './privileges-table.component.html',
  styleUrls: ['./privileges-table.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    MatGridListModule,
    MatTableModule,
    TranslateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDialogModule,
    MatCheckboxModule
  ]
})
export class PrivilegesTableComponent  implements OnInit {
  public dataSource = new MatTableDataSource<any>([]);
  public form!: FormGroup;
  public tableColumns: ITableColumns[] = [
    { label: 'FUNCTIONALITY', id: 'functionality', showCol: true },
    { label: 'VIEW', id: 'canView', showCol: true },
    { label: 'EDIT', id: 'canEdit', showCol: true },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {tableData: {
      id: string;
      functionality: string,
      canView: boolean,
      disabled: boolean,
      canEdit: boolean
    }[]},
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group(
      this.data.tableData.reduce((accumulator, currentValue) => {
        accumulator[currentValue.id] = this.formBuilder.group({
          canView: [currentValue.canView, []],
          canEdit: [currentValue.canEdit, []],
        })
        return accumulator;
      }, {} as any)
    )
    this.dataSource = new MatTableDataSource<any>(this.data.tableData);
  }

  saveAndClose() {

  }
}
