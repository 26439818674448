import { Injectable } from "@angular/core";
import { ITableColumns, ITableConfigParams, ITableConfigParamsFilter, ITablePreferences } from "./table.component";
import { FiltersList } from "./filtersList";

@Injectable({
    providedIn: 'any'
  })
export class TableService {
  
    constructor(
      private filtersList: FiltersList
    ) {}


  /**
   * @description Aggiorna le preferenze della tabella nel localStorage
   */
  updateTablePreferences(pageId: string, dataType: 'columnsVisibility', item: ITableColumns, tableColumns: ITableColumns[], value: boolean): void;
  updateTablePreferences(pageId: string, dataType: 'filters', item: ITableConfigParamsFilter[] | {id: string, value?: string}[]): void;
  updateTablePreferences(pageId: string, dataType: 'search', item: string): void;
  updateTablePreferences(pageId: string, dataType: 'columnsVisibility' | 'filters' | 'search', item: ITableColumns | ITableConfigParamsFilter[] | {id: string, value?: string}[] | string, tableColumns?: ITableColumns[], value?: boolean): void {
    let currentTablePreferences: ITablePreferences = {};
    if (localStorage.getItem('TABLE_PREFERENCES')) {
      currentTablePreferences = JSON.parse(
        localStorage.getItem('TABLE_PREFERENCES')!
      );
    }
    if (dataType === 'columnsVisibility' && tableColumns) {
      tableColumns.find((column) => column.id === (item as ITableColumns).id) && (tableColumns.find((column) => column.id === (item as ITableColumns).id)!.showCol = value!);
      currentTablePreferences[pageId] = {
        ...(currentTablePreferences[pageId] || {}),
        columnsVisibility: tableColumns,
      };
    } else if (dataType === 'filters') {
      //Se trovo qualche filtro che non presenta il formato filtri standard allora provvedo a caricarli
      if ((item as (ITableConfigParamsFilter | {id: string, value?: string})[]).some((filter) => !(filter as ITableConfigParamsFilter).type)) {
        item = (item as {id: string, value?: string}[]).map((rawFilter) => ({...this.filtersList.getFilterById(rawFilter.id), selectedValue: rawFilter.value || undefined})) as ITableConfigParamsFilter[]
      }
      currentTablePreferences[pageId] = {
        ...(currentTablePreferences[pageId] || {}),
        filters: item as ITableConfigParamsFilter[],
      };
    } else if (dataType === 'search') {
      if (!item || item === '') {
        delete currentTablePreferences[pageId].search;
      } else {
        currentTablePreferences[pageId] = {
          ...(currentTablePreferences[pageId] || {}),
          search: item as string,
        };
      }
      
    }
    localStorage.setItem(
      'TABLE_PREFERENCES',
      JSON.stringify(currentTablePreferences)
    );
  }

}