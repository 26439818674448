<ion-content [fullscreen]="true" class="page-content" color="light">
  <modal-container
      [modalHeightInRows]="tableRowSpan"
      [breadcrumbsList]="breadcrumbsList"
      [hideSaveButton]="true"
    >
      <ion-toolbar mode="ios" style="position: sticky; top: 0; z-index: 1; background-color: #f4f5f8;">
        <ion-segment value="all" [value]="currentSegment">
          <ion-segment-button value="structure" content-id="structure">
            <ion-label>Struttura</ion-label>
          </ion-segment-button>
          <ion-segment-button value="allerts" content-id="allerts">
            @if (sharedSrv.alertsList.length) {
              <div [matBadge]="sharedSrv.alertsList.length" matBadgeSize="small" matBadgeOverlap="false" class="alertsBadge">
                Allerte
              </div>
            } @else {
              <ion-label>
                Allerte
              </ion-label>
            }
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
      <ion-segment-view style="width: 100%;">
        <!-- Sezione Struttura -->
        <ion-segment-content id="structure">
          @if (sharedSrv.unitsTree.length) {
            <mat-expansion-panel *ngFor="let unit of sharedSrv.unitsTree" [expanded]="sharedSrv.unitsTree.length === 1" (opened)="unit.isOpen = true" (closed)="unit.isOpen = false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{unit.name}}
                  <div style="display: flex;">
                    <div *ngFor="let icon of getUnitAlerts(unit)" [ngStyle]="{'background-color': icon.color}" [class.blinkingImg]="!unit.isOpen" style="margin: 5px;">
                      <mat-icon>{{icon.icon}}</mat-icon>
                    </div>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-grid-list cols="8" rowHeight="36" gutterSize="10" style="margin: 5px;">
                <ng-container *ngFor="let room of unit.rooms; let i = index">
                  <mat-grid-tile 
                    colspan="2"
                    rowspan="2"
                    style="border-radius: 10px; background-color: #FFFFFF"
                    [ngStyle]="{
                      'border': (room.alert || room.offlineMode === 'full') ? '1px solid'+eventTypeToData[room.alert ? room.alert.eventType : '06'].exaColor : '1px solid #00AEBE'
                    }" 
                    (click)="openModal(room, unit)">
                    <mat-grid-list cols="10" rowHeight="6" gutterSize="3" style="width: 100%; margin: 5px;">
                      <mat-grid-tile colspan="10" rowspan="1"></mat-grid-tile>
                      <mat-grid-tile colspan="2" rowspan="4" style="overflow: visible;">
                        <mat-icon class="material-symbols-rounded badge" [style.color]="room.offlineMode === 'full' ? '#dddcdc' : 'rgba(0, 0, 0, 0.6)'" [matBadge]="room.patients?.length" matBadgeSize="small">person</mat-icon>
                      </mat-grid-tile>
                      <mat-grid-tile colspan="4" rowspan="4">
                      </mat-grid-tile>
                      <mat-grid-tile colspan="4" rowspan="5" style="overflow: visible;">
                        @if (room.alert) {
                          <mat-icon [svgIcon]="eventTypeToData[room.alert.eventType].svgIcon" style="height: 55px; width: 55px; font-size: 55px; margin-left: auto;" class="blinkingImg"></mat-icon>
                        } @else if (room.offlineMode) {
                          <mat-icon
                            style="margin-left: auto;"
                            [svgIcon]="eventTypeToData['06'].svgIcon"
                            [ngStyle]="{
                              'height': room.offlineMode === 'full' ? '55px' : '25px',
                              'width': room.offlineMode === 'full' ? '55px' : '25px',
                              'font-size': room.offlineMode === 'full' ? '55px' : '25px',
                              'position': room.offlineMode === 'full' ? 'unset' : 'absolute',
                              'right': room.offlineMode === 'full' ? 'unset' : '0',
                              'top': room.offlineMode === 'full' ? 'unset' : '0'
                              }">
                          </mat-icon>
                        } @else if (room.isNotificationSilenced) {
                          <mat-icon style="height: 55px; width: 55px; font-size: 55px; color: #616161;">notifications_off</mat-icon>
                        }
                      </mat-grid-tile>
                      <mat-grid-tile [colspan]="room.alert ? 6 : 10" rowspan="2" style="font-size: 18px; overflow: visible;">
                        <span style="position: absolute; top: -7px;" [style.color]="room.offlineMode === 'full' ? '#dddcdc' : 'rgba(0, 0, 0, 0.6)'">
                          {{room.name}}
                        </span>
                      </mat-grid-tile>
                      @if (room.alert) {
                        <mat-grid-tile colspan="10" rowspan="1" style="font-size: 10px; overflow: visible;">
                          <span style="color: #616161;">
                            {{eventTypeToData[room.alert.eventType].translation | translate}} | {{getTimeLeft(room.alert.timestamp)}} min
                          </span>
                        </mat-grid-tile>
                      } @else if (room.offlineMode === 'full') {
                        <mat-grid-tile colspan="10" rowspan="1" style="font-size: 10px; overflow: visible;">
                          <span style="color: #dddcdc;">
                            {{'GENERAL.EVENTS_LIST.DEVICE_OFFLINE' | translate}}
                          </span>
                        </mat-grid-tile>
                      }
                      <mat-grid-tile colspan="10" rowspan="1"></mat-grid-tile>
                    </mat-grid-list>
                  </mat-grid-tile>
                </ng-container>
              </mat-grid-list>
            </mat-expansion-panel>
          } @else {
            <div>
              <h1>Nessun sensore rilevato nell'edificio corrente</h1>
            </div>
          }
        </ion-segment-content>
        <!-- Sezione allerte -->
        <ion-segment-content id="allerts">
          @if (sharedSrv.alertsList.length) {
            <mat-grid-list cols="9" rowHeight="25" gutterSize="10" style="margin: 15px;">
              <mat-grid-tile *ngFor="let alert of sharedSrv.alertsList let i = index" colspan="9" rowspan="2" [ngStyle]="{'border': '1px solid'+eventTypeToData[alert.eventType].exaColor}" style="border-radius: 10px;" (click)="openModalFromAlert(alert)">
                <span style="padding: 5px;">
                  <mat-icon [svgIcon]="eventTypeToData[alert.eventType].svgIcon" style="height: 50px; width: 50px; font-size: 50px;"></mat-icon>
                </span>
                <div style="display: flex; flex-direction: column; width: 100%; height: 100%; justify-content: space-evenly;">
                  <div style="height: 100%; display: flex; align-items: flex-end; height: fit-content;">{{alert.facilities.room.name}}</div>
                  <div style="height: 100%; display: flex; align-items: flex-start; height: fit-content; font-size: 12px;">{{eventTypeToData[alert.eventType].translation| translate}} | {{getTimeLeft(alert.timestamp)}} min</div>
                </div>
                <span style="padding: 5px;">
                  <mat-icon class="material-symbols-rounded" class="material-symbols-outlined" style="height: 35px; width: 35px; font-size: 35px; color: #616161;">videocam</mat-icon>
                </span>
              </mat-grid-tile>
            </mat-grid-list>
            <ion-button expand="block" (click)="manageAll()" style="margin: 15px;" [disabled]="manageAllRunning">{{manageAllRunning ? 'Gestione in corso' : 'Gestisci tutte'}}<ion-spinner name="lines-small" *ngIf="manageAllRunning"></ion-spinner></ion-button>
            <div style="height: 122px; width: 100%;"></div>
          } @else {
            <div>
              <h1>Nessuna allerta rilevata</h1>
            </div>
          }
        </ion-segment-content>
      </ion-segment-view>
  </modal-container>
</ion-content>