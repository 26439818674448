import { ChangeDetectorRef, Component, inject, input } from '@angular/core';
import { AlertController, IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { Store, select } from '@ngrx/store';
import {
  of,
  Subject,
  tap,
  take,
  catchError,
  mergeMap,
  toArray,
  map,
  mergeAll,
  combineLatest,
} from 'rxjs';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  IOptionOutput,
  ICtaOutput,
  ITableColumns,
  ITableConfigParams,
  ITableData,
  ITableDataOptions,
  TableComponent,
} from '../../../shared/components/table/table.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { CustomerService } from '../../../core/services/customer.service';
import { ToastService } from '../../../core/services/utils/toast.service';
import { addressFieldsToCompactAddress } from '../../../shared/utils/remap.utils';
import { FacilitiesService } from '../../../core/services/facilities.service';
import { IonicColors } from '../../../shared/enums/ionicColors.enum';
import { PageEvent } from '@angular/material/paginator';
import { RequestsService } from '../../../core/services/utils/requests.service';

@Component({
  selector: 'customers',
  templateUrl: 'customers.page.html',
  styleUrls: ['customers.page.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    MatGridListModule,
    MatIconModule,
    TableComponent,
    TranslateModule,
    RouterModule,
  ],
})
export class CustomersPage {

  public tableColumns: ITableColumns[] = [
    /* { label: 'ID', id: 'id', showCol: true }, */
    { label: 'BUSINESS_NAME', id: 'businessName', showCol: true, cannotHide: true },
    { label: 'VAT', id: 'vatNumber', showCol: true },
    { label: 'TAX_CODE', id: 'taxCode', showCol: false },
    { label: 'ADDRESS', id: 'fullStreet', showCol: true },
    { label: 'REFERENT', id: 'representative', showCol: true },
    { label: 'ORGANIZATIONS', id: 'organizations', showCol: true },
    { label: 'PEC', id: 'pec', showCol: false },
    { label: 'RECIPIENT_CODE', id: 'recipientCode', showCol: false },
    { label: 'STATUS', id: 'status', showCol: false },
  ];

  public tableData: ITableData[] = [];


  public tableConfigParams: ITableConfigParams = {
    id: 'customers',
    referenceLabel: 'CUSTOMERS',
    contactInfo: {
      title: 'businessName',
      subtitles: [{
        value: 'representative'
      },{
        value: 'email'
      },{
        value: 'telephone',
        prefix: 'Tel:'
      },{
        value: 'phoneNumber',
        prefix: 'Mob:'
      },{
        value: 'fax'
      }],
    },
    searchBy: ['businessName']
  };

  //private monitoringService: MonitoringService = inject(MonitoringService)

  private store = inject(Store);
  private router = inject(Router);
  public isDataLoading: boolean = false;
  loadingElements: boolean;
  hasError: boolean;

  constructor(
    public route: ActivatedRoute,
    private customersService: CustomerService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private toastService: ToastService,
    private translateService: TranslateService,
    private customerService: CustomerService,
    private alertController: AlertController,
    private facilitiesService: FacilitiesService,
    private requestsService: RequestsService
  ) {
    this.loadingElements = false;
    this.hasError = false;
  }

  ionViewDidEnter() {
    this.loadElements();
  }

  private destroyed$ = new Subject();

  ngOnInit() {
    if  (this.requestsService.getCurrentUser()?.role !== 'superuser') {
      this.tableColumns = this.tableColumns.filter((column) => column.id !== 'id')
    }
  }

  paginatorChange(paginatorData: PageEvent) {
    //console.log(paginatorData);
  }

  ngAfterViewInit() {
    this.loadCustomersData();
  }

  /**
   * @description Funzione che va ad aggiornare i dati della table in pagina
   */
  loadCustomersData(showToast: boolean = false, page:number = 0, itemsForPage: number = 10) {
    this.isDataLoading = true;
    let preValorizedTable: ITableData[] = [];
    combineLatest([
      this.customersService.loadCustomers$(page, itemsForPage),
      this.customerService.getFhirInstances$()
    ]).pipe(
      take(1),
      tap(([customersData, fhirInstances]) => {
        preValorizedTable = customersData?.map((item) => {
          return {
            data: {
              ...item,
              fullStreet: addressFieldsToCompactAddress(item),
              status: fhirInstances.find((instance) => instance.customerId === item.id)?.status || '',
            },
            options: [
              {
                action: 'add_org',
                icon: 'add',
                label: 'ADD_ORG',
              }
            ].filter((x) => x) as ITableDataOptions[],
          };
        });
      }),
      map(([customersData, fhirInstances]) => customersData),
      mergeAll(),
      mergeMap((customer) =>
        this.facilitiesService.getOrganization$(customer.id+"").pipe(
          take(1),
          tap((data) => {
            const currentItem = preValorizedTable!.find((tableData: any) => tableData.data['id'] === customer.id);
            if (data.length && currentItem) {
              ((currentItem.data as {[key: string]: string})['organizations'] = data.map(x => x.name).join('<br><br>'));
              (currentItem?.options as any[]).push({
                action: 'edit_org',
                icon: 'edit',
                label: 'EDIT_ORG',
                children: data.map((org) => ({
                  action: 'edit_org',
                  label: org.name,
                  disableTranslation: true,
                  additionalParams: {
                    orgId: org.id
                  }
                }))
              });
              (currentItem?.options as any[]).push({
                action: 'edit_customer',
                icon: 'edit',
                label: 'EDIT_CUSTOMER',
              });
              (currentItem?.options as any[]).push({
                action: 'delete_org',
                icon: 'delete',
                label: 'DELETE_ORG',
                children: data.map((org) => ({
                  action: 'delete_org',
                  label: org.name,
                  disableTranslation: true,
                  additionalParams: {
                    orgId: org.id
                  }
                }))
              });
            };
            currentItem && (currentItem?.options as any[]).push({
              action: 'delete',
              icon: 'delete',
              label: 'DELETE_CUSTOMER',
              disabled: (currentItem?.data as {[key: string]: string})['status'] === 'running',
              additionalParams: {
                hasMultipleOrgs: !!data.length
              }
            });
          }),
          catchError((err) => {
            return of([]);
          })
        )
      ),
      toArray(),
      tap(() => {
        showToast && this.toastService.showToast(this.translateService.instant('GENERAL.SUCCESS.DATA_REFRESHED'), IonicColors.GREEN);
        this.isDataLoading = false;
        this.tableData = preValorizedTable;
      }),
      catchError(err => {
        this.isDataLoading = false;
        showToast && this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.LOADING_DATA'), IonicColors.RED);
        throw err;
      })
    ).subscribe()
  }

  /**
   * @description Funzione invocata al click di una opzione della table
   * @param event Viene passato un oggetto contenente l'id delle risorsa in oggetto e l'action invocata
   */
  async onOptionAction(event: IOptionOutput) {
    switch (event.action) {
      case 'add_org':
        this.router.navigate(['wita/customers/new_org'], { state: { additionalData: event.resource}});
        break;
      case 'edit_customer':
        this.router.navigate([`wita/customers/edit/${event.resource.id}`], { state: { breadCrumbSubject: event.resource['businessName']}});
        break;
      case 'delete':
        const hasMultipleOrgs: boolean = !!(event.additionalParams && event.additionalParams['hasMultipleOrgs'])
        const customerAlert = await this.alertController.create({
          mode: 'md',
          header: this.translateService.instant('GENERAL.MESSAGES.CAUTION'),
          message: this.translateService.instant(hasMultipleOrgs ? 'GENERAL.MESSAGES.DELETE_CUSTOMER_MESSAGE_ALERT' : 'GENERAL.MESSAGES.DELETE_CUSTOMER_MESSAGE'),
          buttons: [
            !hasMultipleOrgs && {
              text: this.translateService.instant('GENERAL.BUTTONS.CANCEL'),
              handler: () => {
                customerAlert.dismiss();
              },
            },
            {
              text: this.translateService.instant(hasMultipleOrgs ? 'GENERAL.BUTTONS.GOT_IT' : 'GENERAL.BUTTONS.OK'),
              handler: () => {
                hasMultipleOrgs 
                  ? customerAlert.dismiss()
                  : this.customerService.deleteCustomer$(event.resource.id).pipe(
                    tap(() => {
                      this.toastService.showToast(this.translateService.instant('GENERAL.SUCCESS.RESOURCE_DELETED_SUCCESFULLY'), IonicColors.GREEN)
                      this.loadCustomersData();
                    }),
                    catchError((err) => this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.RESOURCE_DELETE'), IonicColors.RED))
                  ).subscribe();
              },
            },
          ].filter(x => x) as any,
        });
        await customerAlert.present();
        break;
      case 'edit_org':
        this.router.navigate([`wita/customers/edit_org/${event.additionalParams?.['orgId']}/${event.resource.id}`], { state: { additionalData: event.resource}});
        break;
      case 'delete_org':
        const organizationAlert = await this.alertController.create({
          mode: 'md',
          header: this.translateService.instant('GENERAL.MESSAGES.CAUTION'),
          message: this.translateService.instant('GENERAL.MESSAGES.DELETE_ORGANIZATION_MESSAGE'),
          buttons: [
            {
              text: this.translateService.instant('GENERAL.BUTTONS.OK'),
              handler: () => {
                organizationAlert.dismiss();
                this.facilitiesService.deleteOrganizations$([event.additionalParams?.['orgId']! as string], event.resource.id).pipe(
                  tap(() => this.toastService.showToast(this.translateService.instant('GENERAL.SUCCESS.RESOURCE_DELETED_SUCCESFULLY'), IonicColors.GREEN)),
                  catchError((err) => this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.RESOURCE_DELETE'), IonicColors.RED))
                ).subscribe();
                this.loadCustomersData();
              },
            },
            {
              text: this.translateService.instant('GENERAL.BUTTONS.CANCEL'),
              handler: () => {
                organizationAlert.dismiss();
              },
            },
          ],
        });
        await organizationAlert.present();
        break;
      default:
        break;
    }
  }

  /**
   * @description Funzione invocata al click di un button (Aggiungi risorsa, refresh dati, ecc...)
   * @param event Viene passato un oggetto contenente il tipo di action invocata
   */
  onCtaAction(event: ICtaOutput) {
    switch (event.action) {
      case 'add':
        this.router.navigate(['wita/customers/new']);
        break;
      case 'refresh':
        this.loadCustomersData(true);
        break;
      default:
        break;
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(1);
    this.destroyed$.complete();
  }

  loadElements() {
    //this.monitoringService.loadMonitoringElements()
  }

  handlePressElement(event: any) {
    //let element = this.elements.find(element => element.id === event);
    //this.router.navigate(['/wita/appMonitoring/monitoringRoot/sensors/bed', { id: element?.id }])
  }

  handleSelectElement(event: any) {
    //let element = this.elements.find(element => element.id === event);
    console.log('Element selected');
  }
  
  ngAfterViewChecked(): void {
    /* Necessario per andare a rilevare i cambiamenti e per far si che non vengano sparati errori in console */
    this.changeDetectorRef.detectChanges();
  }
}
