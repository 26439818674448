<ion-content [fullscreen]="true" class="page-content" color="light">
  <generic-table
    style="width: 100%; height: 100%"
    [tableColumns]="tableColumns"
    [data]="tableData"
    [isLoading]="isDataLoading"
    [config]="tableConfigParams"
    (onOptionAction)="onOptionAction($event)"
    (onCtaAction)="onCtaAction($event)"
  ></generic-table>
</ion-content>
