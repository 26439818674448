import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, catchError, Observable, map, take, of } from 'rxjs';
import { Store } from '@ngrx/store';

import { LoggerService } from './utils/logger.service';
import { loadUserSuccess, loadingUser, loadingUserError } from '../../state/actions/user.actions';
import { environment } from '../../../environments/environment';
import { IUser } from '../../shared/models/be/user';
import { RequestsService } from './utils/requests.service';
//import { DeviceSpecs, LocalUserDevices, UserDevices } from 'src/app/shared/models/user-devices';

@Injectable({
  providedIn: 'any'
})
export class UserService {

  constructor(
    private loggerService: LoggerService,
    private _http: HttpClient,
    private store: Store,
    private requestsService: RequestsService
  ) { }

  public getServiceStatus$(): Observable<boolean> {
    return this._http.get<{message: string}>(`${environment.apiUrl('users')}/users/ping`).pipe(
      take(1),
      map((val: {message: string}) => !!val.message),
      catchError(err => {
        this.loggerService.error('Error on .../ping')
        return of(false);
      })
    );
  }

  public loadUserInfos() {
    this.store.dispatch(loadingUser());
    return this._http.get(`${environment.apiUrl('users')}/user-info/details`).pipe(
      take(1),
      tap((val: any) => {
        this.store.dispatch(loadUserSuccess({user: val}))
      }),
      catchError(err => {
        this.store.dispatch(loadingUserError())
        this.loggerService.error('Error on .../user-info/details')
        throw 'error in source. Details: ' + err;
      })
    ).subscribe();
  }

  public loadUsers$(): Observable<{users: IUser[]}> {

    const currentUser = this.requestsService.getCurrentUser();
    const byOrgQueryParams = ['localadmin'].includes(currentUser?.role || "") ? `&customerId=${currentUser?.customer?.id}&organizationId=${currentUser?.organization?.id}` : '';
    this.store.dispatch(loadingUser());
    return this._http.get<{users: IUser[]}>(`${environment.apiUrl('users')}/users?page=1&pageSize=30${byOrgQueryParams}`).pipe(
      take(1),
      tap(() => {
        //this.store.dispatch(loadUserSuccess({user: val}))
      }),
      catchError(err => {
        //this.store.dispatch(loadingUserError())
        this.loggerService.error('Error on .../user-info/details')
        throw 'error in source. Details: ' + err;
      })
    );
  }

  public getUser$(userId: string): Observable<IUser> {
    this.store.dispatch(loadingUser());
    return this._http.get<IUser>(`${environment.apiUrl('users')}/users/${userId}`).pipe(
      take(1),
      tap(() => {
        //this.store.dispatch(loadUserSuccess({user: val}))
      }),
      catchError(err => {
        //this.store.dispatch(loadingUserError())
        this.loggerService.error('Error on .../user-info/details')
        throw 'error in source. Details: ' + err;
      })
    );
  }

  public createUser$(user: IUser) {
    return this._http.post(
        `${environment.apiUrl('users')}/users`,
        user
    ).pipe(
        take(1),
        tap(() => {
            //this.store.dispatch(createPatientsuccess({patient}))
        }),
        catchError(err => {
            //this.store.dispatch(createPatientError())
            throw `error in source. Details:  + ${err}`
        })
    );
  }

  public editUser$(user: IUser) {
    return this._http.put(
        `${environment.apiUrl('users')}/users/${user.id}`,
        user
    ).pipe(
        take(1),
        tap(() => {
            //this.store.dispatch(createPatientsuccess({patient}))
        }),
        catchError(err => {
            //this.store.dispatch(createPatientError())
            throw `error in source. Details:  + ${err}`
        })
    );
  }

  public deleteUser$(userId: string) {
    return this._http.delete(
        `${environment.apiUrl('users')}/users/${userId}`
    ).pipe(
        take(1),
        tap(() => {
            //this.store.dispatch(createPatientsuccess({patient}))
        }),
        catchError(err => {
            //this.store.dispatch(createPatientError())
            throw `error in source. Details:  + ${err}`
        })
    );
  }

  /**
   * @returns Torna i devices collegati allo user corrente in formato LocalUserDevices
   */
  /* getUserDevices$(): Observable<Array<LocalUserDevices>> {
    return this._http.get<Array<UserDevices>>(`${environment.apiUrl('users')}/user-info/user-devices`).pipe(
      map((response) => (response || []).map((device) => {
        const deviceInfo: DeviceSpecs = JSON.parse(device.deviceName) || {}
        return {
          lastTimeOnline: new Date(device.lastUpdateDate).toLocaleDateString(),
          os: ['windows','android'].includes(deviceInfo.os.toLowerCase()) ? deviceInfo.os.toLowerCase() : 'generic',
          id: device.id,
          deviceId: device.deviceId,
          token: device.deviceToken
        } as LocalUserDevices
      }))
    );
  } */

  public editUserInfo$(body: {[key: string]: string | boolean}) {
    return this._http.patch(`${environment.apiUrl('users')}/user-info/details`, body).pipe(
      take(1),
      /* 
      TODO: Implementare all'integrazione di una response lato BE @Edwin 
      tap((val: any) => {
        this.store.dispatch(loadUserSuccess({user: val}));
      }), */
      catchError(err => {
        this.store.dispatch(loadingUserError());
        this.loggerService.error('Error on .../user-info/details');
        throw 'error in source. Details: ' + err;
      })
    )
  }
}
