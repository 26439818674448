<mat-grid-list cols="40" rowHeight="36px" gutterSize="20">
  <mat-grid-tile colspan="5" rowspan="1" *ngFor="let filter of config.filters" style="overflow: visible;">
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="grid-list_header-container_filters" style="width: 100%;">
      <mat-label>{{'TABLE.FILTER.LABEL.'+filter.label | translate}}</mat-label>
      <mat-select
        [(value)]="filter.selectedValue"
        [disabled]="!$any(filter).options.length"
        [multiple]="filter.type === 'multiSelect'"
        (selectionChange)="applyFilter(filter)"
        >
          @for (option of $any(filter).options; track option) {
            <mat-option 
              [value]="option.id"
              >
              <div class="floatingText">
                <span>{{option.translateName ? ('TABLE.FILTER.OPTIONS.'+option.name | translate) : option.name}}</span>
              </div>
            </mat-option>
          }
      </mat-select>
    </mat-form-field>
  </mat-grid-tile>
  <mat-grid-tile colspan="5" rowspan="1" *ngIf="config.filters?.length" style="overflow: visible;">
    <ion-button fill="outline" mode="md" style="text-transform: none; height: 100%; width: 100%;" (click)="clearFilters()">
      <mat-icon slot="start" style="margin: 0 5px 0 0;" class="material-symbols-outlined">cancel</mat-icon>
      {{'TABLE.BUTTONS.CANCEL_FILTERS' | translate}}
    </ion-button>
  </mat-grid-tile>
</mat-grid-list>