import { Component, ElementRef, OnChanges, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  catchError,
  combineLatest,
  concatMap,
  finalize,
  from,
  map,
  of,
  Subject,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { EventService } from '../../../../core/services/events.service';
import {MatToolbarModule} from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatBadgeModule } from '@angular/material/badge';
import { FacilitiesService } from '../../../../core/services/facilities.service';
import { RequestsService } from '../../../../core/services/utils/requests.service';
import { SensorService } from '../../../../core/services/sensors.service';
import { IUnit } from '../../../../shared/models/be/unit';
import { IRoom } from '../../../../shared/models/be/room';
import { MatDialog } from '@angular/material/dialog';
import { RoomDetailsDialogComponent } from '../../../../shared/components/roomDetailsDialog/room-details-dialog.component';
import { IDevice } from '../../../../shared/models/be/device';
import { AlertsService } from '../../../../core/services/alerts.service';
import { IonContent, IonLabel, IonSegment, IonSegmentButton, IonSegmentContent, IonSegmentView, IonToolbar, IonButton, AlertController, IonSpinner } from '@ionic/angular/standalone';
import { eventTypeToData, INotificationData } from '../../../../shared/models/be/notification';
import { getTimeLeft } from '../../../../shared/utils/transformation.util';
import { IPatient } from '../../../../shared/models/be/patients';
import { RingtoneService } from '../../../../core/services/utils/ringtone.service';
import { SharedService } from '../../../../core/services/shared.service';
import { isTimeWithinRange } from '../../../../shared/utils/check.utils';
import { LoggerService } from '../../../../core/services/utils/logger.service';

@Component({
  selector: 'monitoringMobile',
  templateUrl: './monitoring.mobile.component.html',
  styleUrls: ['./monitoring.mobile.component.scss'],
  standalone: true,
  imports: [ 
    IonButton, 
    CommonModule,
    MatGridListModule,
    MatIconModule,
    TranslateModule,
    RouterModule,
    MatGridListModule,
    MatToolbarModule,
    MatTabsModule,
    MatExpansionModule,
    MatBadgeModule,
    IonContent,
    IonToolbar,
    IonSegment,
    IonLabel,
    IonSegmentButton,
    IonSegmentView,
    IonSegmentContent,
    IonSpinner
]
})
export class monitoringMobilePage implements OnInit {

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public eventService: EventService,
    public facilitiesService: FacilitiesService,
    private requestsService: RequestsService,
    public dialog: MatDialog,
    private sensorService: SensorService,
    private alertService: AlertsService,
    private alertController: AlertController,
    private ringtoneService: RingtoneService,
    public sharedSrv: SharedService,
    private loggerService: LoggerService
  ) {
  }

  ionViewDidEnter() {
  }
  private destroy$ = new Subject<void>();
  public installPrompt?: any;
  public devicesList: IDevice[] = [];
  public manageAllRunning: boolean = false;
  public currentSegment: 'structure' | 'allerts' = 'structure';
  public eventTypeToData = eventTypeToData;
  public isLoading: boolean = false;
  @ViewChildren('text') textElements!: QueryList<ElementRef<HTMLDivElement>>;
  getTimeLeft(data: Date | string) {
    return getTimeLeft(data)
  }

  ngOnInit() {
    combineLatest([
      this.requestsService.currentUser$,
      this.sensorService.getDevices$(),
      this.alertService.getActiveAlertsList$()
    ]).pipe(
      take(1),
      tap(([user, devices, notificationsList]) => {
        this.sharedSrv.alertsList = this.sharedSrv.sortBySeverity(notificationsList);
        this.devicesList = devices;
        if (user?.building?.id) {
          this.facilitiesService.getBuilding$(user?.building?.id, true).pipe(
            tap((building) => {
              this.sharedSrv.unitsTree = (building.units || []).reduce((acc, curr) => {
                let currentUnitDevices = devices.filter((device) => device.facilities?.unit?.id === curr.id);
                currentUnitDevices.length && acc.push({
                  ...curr,
                  isOpen: false,
                  rooms: curr.rooms?.filter((room) => currentUnitDevices.map((device) => device.facilities?.room?.id).includes(room.id)).map((room) => {
                    let currRoomDevices = devices.filter((device) => device.facilities?.room?.id === room.id);
                    const currAllerts = this.sharedSrv.sortBySeverity(this.sharedSrv.alertsList.filter((alert) => currRoomDevices.some((roomDevice) => roomDevice.label === alert.deviceId)));
                    return {
                      ...room,
                      devices: currRoomDevices,
                      offlineMode: currRoomDevices.some((device) => device.status === 'offline') ? (currRoomDevices.every((device) => device.status === 'offline') ? 'full' : 'partial') : undefined,
                      alert: currAllerts[0] || undefined
                    }
                  })
                })
                return acc;
              }, [] as (IUnit & {isOpen: boolean})[]);
              this.sharedSrv.unitsTree.length === 1 && (this.sharedSrv.unitsTree[0].isOpen = true)
            }),
            map((building) => {
              this.sharedSrv.unitsTree.forEach((unit) => {
                unit.rooms?.forEach((room) => {                
                  this.sensorService.getDevicesSettings$('roomId', room.id).pipe(
                    tap((data) => {
                      room.isNotificationSilenced = data.notificationMode === 'disabled' || (data.notificationMode === 'scheduled' && !isTimeWithinRange(data.scheduleStartTime!, data.scheduleEndTime!))
                    })
                  ).subscribe();
                })
              });
            })
          ).subscribe();
        } else {
          this.loggerService.error("Nessun edificio trovato per l'utente corrente")
        }
        
      })
    ).subscribe();

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.alertService.getActiveAlertsList$().pipe(
          tap((alerts) => {
            this.sharedSrv.alertsList = this.sharedSrv.sortBySeverity(alerts);
            this.sharedSrv.unitsTree.forEach((unit) => {
              unit.rooms?.forEach((room) => {
                const currRoomAlerts = this.sharedSrv.alertsList.filter((alert) => room.devices?.some((device) => device.label === alert.deviceId))
                room.alert = currRoomAlerts.length ? this.sharedSrv.sortBySeverity(currRoomAlerts)[0] : undefined;
              })
            })
          })
        ).subscribe();
      }
    });
  }

  getUnitAlerts(unit: IUnit) {
    return unit.rooms?.reduce((acc, curr) => {
      if (curr.alert && !acc.some((item) => item.severity === curr.alert?.severity)) {
        curr.alert.severity !== 'low' && acc.push({
          icon: eventTypeToData[curr.alert.eventType].statusIcon,
          color: eventTypeToData[curr.alert.eventType].exaColor,
          severity: curr.alert.severity
        })
      }
      return acc;
    }, [] as {icon: string; color: string; severity: 'medium' | 'high'}[])
  }

  openModal(room : IRoom) {
    this.reorderDevicesOnModal(room.devices || [], room.alert);
    this.dialog.open(RoomDetailsDialogComponent, {
      data: {
        title: `${room.name}`,
        roomId: room.id,
        patients: room.patients,
        devices: room.devices,
        alertService: this.alertService,
        sensorService: this.sensorService
      },
      width: '95vw',
      maxWidth: '95vw'
    });
  }

  openModalFromAlert(alert: INotificationData) {
    let patients: IPatient[] = [];
    let devices: IDevice[] = [];
    this.sharedSrv.unitsTree.forEach((unit) => {
      (alert?.facilities.unit.id === unit.id) && unit.rooms?.forEach((room) => {
        if ((alert?.facilities.room.id === room.id) && room.devices?.some((device) => device.label === alert?.deviceId)) {
          patients = room.patients || [];
          devices = room.devices?.filter((device) => device.label === alert?.deviceId);
        }
      })
    });
    this.reorderDevicesOnModal(devices, alert);
    this.dialog.open(RoomDetailsDialogComponent, {
      data: {
        title: `${alert.facilities.room.name}`,
        roomId: alert.facilities.room.id,
        patients,
        devices,
        alert,
        alertService: this.alertService,
        sensorService: this.sensorService
      },
      width: '95vw',
      maxWidth: '95vw'
    });
    //this.onModalClosed(dialogRef);
  }

  reorderDevicesOnModal(devices: IDevice[], alert?: INotificationData) {
    if (alert) {
      devices = devices.sort((a, b) => {
        if (a.label === alert.deviceId) {
          return -1; // a viene prima
        } else if (b.label === alert.deviceId) {
          return 1; // b viene prima
        } else {
          return 0; // Nessun cambiamento di ordine
        }
      });
    } else if (devices.some((device) => device.status === "offline") && devices.some((device) => device.status === "online")) {
      devices = devices.sort((a, b) => {
        if (a.status === 'online') {
          return -1; // a viene prima
        } else if (b.status === 'online') {
          return 1; // b viene prima
        } else {
          return 0; // Nessun cambiamento di ordine
        }
      });
    }
  }

  async manageAll() {
    const alert = await this.alertController.create({
      header: 'ATTENZIONE',
      message: 'Gestire tutte le allerte?',
      mode: 'ios',
      buttons: [{
        text: 'Chiudi',
        role: 'close',
        handler: () => {},
      },
      {
        text: 'Gestisci tutte',
        role: 'manage',
        handler: () => {
          this.manageAllRunning = true;
          this.ringtoneService.stopRingtone();
          from(this.sharedSrv.alertsList).pipe(
            concatMap((alertEl) =>
              this.alertService.manageAlert$(alertEl.id).pipe(
                tap(() => {
                  this.sharedSrv.removeAlert(alertEl);
                }),
                catchError((err) => {
                  (err.error.error === "15") && this.sharedSrv.removeAlert(alertEl);
                  return of(null);
                })
              )
            ),
            finalize(() => {
              this.manageAllRunning = false;
            })
          ).subscribe();
        },
      }],
    });
    await alert.present();
  }

  ngAfterViewInit(): void {
    const processElements = (list: QueryList<ElementRef>) => {
      if (this.sharedSrv.unitsTree.reduce((acc, curr) => {acc += curr.rooms?.length || 0; return acc}, 0) === list.length) {
        this.sharedSrv.unitsTree.forEach((unit) => {
          unit.rooms?.forEach((room) => {
            const roomTextElement = list.find((element) => element.nativeElement.textContent.trim() === room.name.trim());
            if (roomTextElement) {
              room.isTextTooLong = roomTextElement.nativeElement.scrollWidth > roomTextElement.nativeElement.clientWidth 
            }
          })
        })
      }
    };
    this.textElements.changes.pipe(
      takeUntil(this.destroy$)
    ).subscribe((list: QueryList<ElementRef>) => {
      processElements(list);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    console.log('Sottoscrizioni cancellate.');
  }

}
