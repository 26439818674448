import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { Routes } from '@angular/router';
import { RootRoutes } from './pages/core/root/root.routes';
import { provideState } from '@ngrx/store';
import * as fromUserReducer from './state/reducers/user.reducer';
import { LoginPage } from './pages/auth/login/login.page';
import { authGuard } from './core/guards/auth.guard';
import { RootPage } from './pages/core/root/root.page';
import { authInterceptor } from './shared/interceptors/auth.interceptor';

export const routes: Routes = [
    {
      path: 'auth',
      providers: [
        provideHttpClient(withInterceptors([authInterceptor])),
        provideState(fromUserReducer.USER_FEATURE_KEY, fromUserReducer.userReducer),
      ],
      children: [
        {
          path: 'login',
          component: LoginPage
        },
        {
          path: 'reset-password',
          component: LoginPage
        },
        {
          path: '',
          redirectTo: 'login',
          pathMatch: 'full',
        },
      ],
    },
    {
      path: "wita",
      component: RootPage,
      children: RootRoutes,
      canActivate: [authGuard],
      providers: [
        provideHttpClient(withInterceptors([authInterceptor])),
        provideState(fromUserReducer.USER_FEATURE_KEY, fromUserReducer.userReducer)
      ]
    },
    {
      path: '',
      redirectTo: 'wita',
      pathMatch: 'full',
    }
];
