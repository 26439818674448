import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { INotificationData } from "../../shared/models/be/notification";
import { IUnit } from "../../shared/models/be/unit";
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import { environment } from "../../../environments/environment";
import { IUser } from "../../shared/models/be/user";
import { ToastService } from "./utils/toast.service";
import { IonicColors } from "../../shared/enums/ionicColors.enum";
import { LoggerService } from "./utils/logger.service";

/////////////////////////////////////////////////////////
//                                                     //
//                   ATTENZIONE                        //
//    Servizio utilizzato con providedIn: 'root'       //
//    Questa configurazione indica ad Angular che      //
//  il servizio deve essere fornito a livello della    //
//   root dell'applicazione. Ciò significa che verrà   //
//   creata una singola istanza del servizio che sarà  //
//  condivisa da tutti i componenti e gli altri        //
//              servizi che lo richiedono              // 
//                                                     //
//          NON RICHIAMARE HTTPCLIENT,                 //
//        NON PASSEREBBE PER L'INTERCEPTOR             //
//                                                     //
/////////////////////////////////////////////////////////


@Injectable({ providedIn: 'root' })
export class SharedService {

    public alertsList: INotificationData[] = [];
    public unitsTree: (IUnit & {isOpen: boolean})[] = [];
    private webSocketInstance?: WebSocketSubject<unknown>;

    constructor(
        private loggerService: LoggerService
    ) {
    }

    addAlert(alert: INotificationData) {        
        this.unitsTree.forEach((unit) => {
            (alert?.facilities.unit.id === unit.id) && unit.rooms?.forEach((room) => {
                if ((alert?.facilities.room.id === room.id) && room.devices?.some((device) => device.label === alert?.deviceId)) {
                    if (room.alert) {
                        room.alert = this.sortBySeverity([room.alert, alert])[0];
                    } else {
                        room.alert = alert;
                    }
                }
            })
        });
        this.alertsList = this.sortBySeverity([...this.alertsList, alert]);
    }

    removeAlert(alert: INotificationData) {
        this.alertsList = this.alertsList.filter((currAlert) => currAlert.id !== alert.id);
        this.unitsTree.forEach((unit) => {
            unit.rooms?.forEach((room) => {
                const currRoomAlerts = this.alertsList.filter((alertEl) => alertEl.id !== alert.id && room.devices?.some((device) => device.label === alertEl.deviceId))
                room.alert = currRoomAlerts.length ? this.sortBySeverity(currRoomAlerts)[0] : undefined;
            })
        });
    }

    public sortBySeverity(alertsList: INotificationData[]) {
      return alertsList.sort((a, b) => {
        const severityOrder = ['high', 'medium', 'low'];
        const severityA = severityOrder.indexOf(a.severity);
        const severityB = severityOrder.indexOf(b.severity);
        if (severityA !== severityB) {
          return severityA - severityB; // Ordina per gravità
        } else {
          return b.timestamp.getTime() - a.timestamp.getTime(); // Ordina per timestamp (più recente prima)
        }
      });
    }

    
    startWss(toastService: ToastService) {
        if (typeof localStorage.getItem('USER') === 'string') {
            (JSON.parse(localStorage.getItem('USER')!) as IUser)?.building?.id && (this.webSocketInstance = webSocket(environment.wsUrl+'notifications/ws/'+(JSON.parse(localStorage.getItem('USER')!) as IUser)?.building?.id))
        }
        this.webSocketInstance?.subscribe({
            next: (msg: any) => {
                switch (msg.type) {
                    case 'notification_managed':
                        this.removeAlert({
                            id: msg.notificationId
                        } as INotificationData);
                        toastService.showToast("Allerta Gestita", IonicColors.GREEN, 5000)
                        //toastService.showToast("Allerta Gestita (CADUTA - Stanza 101 - Unità 1) da Nome Utente", IonicColors.GREEN, 5000)
                    break;
                    case 'ping':
                        // Respond to server pings with a pong
                        this.webSocketInstance?.next({
                            type: 'pong',
                            timestamp: new Date().toISOString()
                        });
                        break;
                    default:
                    break;
                }
            },
            error: (err: any) => {
                this.loggerService.error("ERRORE - WSS NOTIFICHE");
                this.webSocketInstance?.unsubscribe();
                this.startWss(toastService);
            },
            complete: () => {
                this.loggerService.error("CONNESSIONE PERSA - WSS NOTIFICHE");
                this.webSocketInstance?.unsubscribe();
                document.visibilityState === 'visible' && this.startWss(toastService);
            },
        });
    }

    stopWss() {
        this.webSocketInstance?.unsubscribe();
    }
}