import { TranslateService } from "@ngx-translate/core";
import { IFormData, IFormStructure, IFormStructureChildren } from "../components/form/form.component";

/**
 * @description Funziona che data una form calcola il numero di righe che occupa la form (definizione dell'altezza della form)
 * @param form form in formato IFormData
 * @returns torna l'altezza della form in numero di righe 
 */
export function formToHeightInRows(form: IFormData, translateService?: TranslateService): number {
    /* Ciclo la struttura della form */
    return form.structure.reduce((accumulator, currentValue) => {
        /* Aggiungo una riga relativa al titolo della struttura in oggetto */
        accumulator += 1;
        /* Aggiungo tante righe quante le sottosezioni della struttura in quanto ognuna ha un titolo (scarto quelle con flag hideSection a true e gli accordion chiaramente) */
        accumulator += currentValue.children.filter((child) => !child.hideSection && (child.childrenType !== 'accordion')).length;

        /* Se si tratta di una sezione con almeno un accordion allora predispongo una riga per il pulsante di aggiunta accordion */
        accumulator += currentValue.children.some((child) => child.childrenType === 'accordion') ? 1 : 0;
        /* Vado a calcolare le righe occupate dagli Input delle varie sottosezioni */
        accumulator += currentValue.children.reduce((childrenAccumulator, childrenValue) => {
            childrenAccumulator += childrenRowSpanCalculator(childrenValue, translateService)
            /* Se presente sistema a children dinamici con accordion aperto allora aggiungo 2 righe */
            childrenAccumulator += (currentValue.dynamicChildren && childrenValue.isAccordionOpen) ? 2 : 0;
            return childrenAccumulator;
        }, 0);
        /* Se presente pulsante per aggiunta/rimozione sezione allora aggiungo una riga */
        accumulator += (currentValue.canAddAndRemove ? 2 : 0)
        return accumulator;
      }, 0)+1;
      /* NOTA BENE: Alla fine aggiungo 1 per dare margine sotto la form ; ) */
}

export function formStructureToHeightInRows(formStructure: IFormStructure, translateService?: TranslateService): number {
    return formStructure.children.reduce((childrenAccumulator, childrenValue) => {
        /* Aggiungo una riga relativa al titolo della sezione in oggetto se il child non è un accordion */
        childrenValue.childrenType !== 'accordion' && !childrenValue.hideSection && (childrenAccumulator += 1);
        childrenAccumulator += childrenRowSpanCalculator(childrenValue, translateService)
        return childrenAccumulator;
    }, 0) 
    /* Se presente pulsante per aggiunta/rimozione sezione allora aggiungo una riga */
    + (formStructure.canAddAndRemove ? 2 : 0)
    /* Se si tratta di una sezione con almeno un accordion allora predispongo una riga per il pulsante di aggiunta accordion */
    + (formStructure.children.some((child) => child.childrenType === 'accordion') ? 1 : 0);
}

function childrenRowSpanCalculator(childrenValue: IFormStructureChildren, translateService?: TranslateService) {
    return (
        /* Sommo le lunghezze degli Input delle sottosezioni e divido per 6 arrotondando per eccesso (divido per 6 in quanto una riga è composta da 6 colonne) */
        Math.ceil(
            childrenValue.hideSection ? 0 : (childrenValue.inputs.reduce((inputAccumulator, inputValue) => {
                if (inputValue.type === 'inputArray') {
                    if (childrenValue.isAccordionOpen) {
                        inputValue.inputs.forEach((input) => {
                            inputAccumulator += input.length;
                        });
                        inputAccumulator += 8; //2 per pulsante aggiunta (letto per esempio) e 6 per pulsante rimozione (stanza per esempio)
                    }                    
                } else {
                    inputAccumulator += inputValue.length;
                }
                return inputAccumulator;
            }, 0)/6)
        )
        + 
        ((childrenValue.radio && translateService) 
            ? (
                /* Se presente radio group e translateService allora aggiungo una riga per ogni radio */
                childrenValue.radio.values.length 
                /* + 1 per l'intestazione */
                + 1
                /* Poi faccio un check della lunghezza del testo del radio, per ogni radio che supera i 60 caratteri aggiungo una riga in più */
                + childrenValue.radio.values.reduce((radioAccumulator, radioValue) => { 
                    radioAccumulator += translateService.instant('FORM.LABELS.'+radioValue.label).length > 60 ? 1 : 0;
                    return radioAccumulator;
                }, 0))
            : 0
        )            
        +
        /* Se è presente un Input 'avatar' allora aggiungo una riga (per far così un Input alto 2 righe) */
        ((childrenValue.inputs || []).some((input) => input.id === 'avatar') ? 1 : 0)
        +
        /* Se presenti righe di testo, aggiungo una riga per ognuna di esse */
        (childrenValue.textLines ? (childrenValue.textLines || []).length + 1 : 0)
    );
}