import { CommonModule, Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { IFormChangeEmitter } from '../form/form.component';
import { Router, RouterModule } from '@angular/router';
import { BreadcrumbsComponent, IBreadcrumbs } from '../breadcrumbs/breadcrumbs.component';
import { IonButton } from '@ionic/angular/standalone';

@Component({
  selector: 'modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatGridListModule,
    MatIconModule,
    MatDividerModule,
    MatCardModule,
    RouterModule,
    BreadcrumbsComponent,
    MatIconModule,
    IonButton
  ],
})
export class ModalContainerComponent  implements OnInit {
  @Input() modalHeightInRows: number = 1;
  @Input() hideSaveButton: boolean = false;
  @Input() breadcrumbsList: IBreadcrumbs[] = [];
  @Input() showGenerateDataButton: boolean = false;
  @Output() generateData = new EventEmitter();
  @Input() formStatus: IFormChangeEmitter = {
    canSaveDraft: false,
    canSaveData: false,
  };
  @Output() onSubmit = new EventEmitter();

  constructor(
    public location: Location,
    private router: Router
  ) { }

  submitData() {
  }

  emitGenerateData() {
    this.generateData.emit(true);
  }

  ngOnInit() {
  }

}
