import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { StreamingComponent } from '../streaming/streaming.component';
import { TimingComponentsGroup } from '../timingComponentsGroup/timingComponentsGroup.component';
import { IDevice } from '../../models/be/device';
import { INotificationData } from '../../models/be/notification';
import { getTimeLeft } from '../../utils/transformation.util';
import { RingtoneService } from '../../../core/services/utils/ringtone.service';
import { IonButton, IonSpinner } from "@ionic/angular/standalone";
import { catchError, concatMap, finalize, from, of, skip, tap } from 'rxjs';
import { SharedService } from '../../../core/services/shared.service';
import { AlertsService } from '../../../core/services/alerts.service';
import { SensorService } from '../../../core/services/sensors.service';
import { IDevicesSettings } from '../../models/be/devicesSettings';
import { ToastService } from '../../../core/services/utils/toast.service';
import { IonicColors } from '../../enums/ionicColors.enum';
import { HardwareService } from '../../../core/services/hardware.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './room-details-dialog.component.html',
  styleUrls: ['./room-details-dialog.component.scss'],
  standalone: true,
  imports: [IonSpinner, 
    IonButton,  
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatGridListModule,
    MatFormFieldModule,
    MatSelectModule,
    TranslateModule,
    ReactiveFormsModule,
    MatInputModule,
    TimingComponentsGroup,
    StreamingComponent
  ],
  providers: [AlertsService]
})
export class RoomDetailsDialogComponent implements OnInit {
  
  /**
   * @description FormGroup che regola tutti i campi della form nel component 
   */
  public formGroup!: FormGroup;
  public formArray!: FormArray;
  public hasError: boolean = false;
  public preferencesMode: boolean = false; 
  public manageRunning: boolean = false; 
  public eventTypeToTranslation = {
    "01": 'GENERAL.EVENTS_LIST.FALL', 
    "02": 'GENERAL.EVENTS_LIST.ROOM_IN',
    "03": 'GENERAL.EVENTS_LIST.ROOM_OUT',
    "04": 'GENERAL.EVENTS_LIST.BED_IN',
    "05": 'GENERAL.EVENTS_LIST.BED_OUT',
    "06": 'GENERAL.EVENTS_LIST.DEVICE_OFFLINE',
    "07": 'GENERAL.EVENTS_LIST.DEVICE_ONLINE'
  }
  public severityToColor = {
    "low": "#000000",
    "medium": "#E7DA53",
    "high": "#EE867D"
  }

  public deviceSettings: IDevicesSettings = {    
    buildingId: "",
    token: "",
    notificationMode: "always_active",
    scheduleStartTime: null, //Formato "06:00"
    scheduleEndTime: null, //Formato "14:00"
    bedExitDelay: 0
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      patients: {id: string, name: string}[],
      roomId: string,
      devices: IDevice[],
      alert?: INotificationData,
      alertService: AlertsService,
      sensorService: SensorService
    },
    private ringtoneService: RingtoneService,
    public dialogRef: MatDialogRef<RoomDetailsDialogComponent>,
    private sharedSrv: SharedService,
    private toastService: ToastService,
    private hardwareSrv: HardwareService
  ) { }

  public isMobile: boolean = this.hardwareSrv.isMobile();

  getTimeLeft(data: Date | string) {
    return getTimeLeft(data)
  }

  ngOnInit() {
    this.data.sensorService.getDevicesSettings$('roomId', this.data.roomId).pipe(
      tap((data) => {
        this.deviceSettings = data;
      })
    ).subscribe();
  }

  manageEvent(alert: INotificationData) {
    this.manageRunning = true;
    //if (this.data.alert?.type === 'notification') {
      this.ringtoneService.stopRingtone();
    //}
    from(this.sharedSrv.alertsList.filter((currAlert) => currAlert.deviceId === alert.deviceId)).pipe(
      concatMap((alertEl) =>
        this.data.alertService.manageAlert$(alertEl.id).pipe(
          tap(() => {
            this.sharedSrv.removeAlert(alertEl);
          }),
          catchError((err) => {            
            (err.error.error === "15") && this.sharedSrv.removeAlert(alertEl);
            return of(null);
          })
        )
      ),
      finalize(() => {
        this.data.alert = undefined;
        this.manageRunning = false;
      })
    ).subscribe();
  }

  getCurrentAllert() {
    return this.data.alert || this.sharedSrv.sortBySeverity(this.sharedSrv.alertsList.filter((alert) => this.data.devices.some((dev) => alert.deviceId === dev.label)))[0] || undefined;
  }

  submitDevicesSettings(devicesSettings: IDevicesSettings) {
    devicesSettings = {
      ...devicesSettings, 
      roomId: this.data.roomId
    }
    this.data.sensorService.editDevicesSettings$(devicesSettings).pipe(
      tap(() => {
        this.toastService.showToast("Temporizzazione salvata con successo");
        this.preferencesMode = !this.preferencesMode;
      }),
      catchError((err) => {
        this.toastService.showToast("Errore durante il salvataggio della temporizzazione", IonicColors.NOTIFICATION_HIGH);
        throw err;
      })
    ).subscribe();
  }
}
