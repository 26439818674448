import { createAction, props } from '@ngrx/store';
import { ICustomer } from '../../shared/models/be/customers';

export const loadingCustomers = createAction(
  '[WITA] Loading Customers'
);
export const loadCustomersSuccess = createAction(
  '[WITA] Load Customers Success',
  props<{ customers: ICustomer[] }>()
);
export const loadingCustomersError = createAction(
  '[WITA] Loading Customers Error'
);
export const createCustomerSuccess = createAction(
  '[WITA] Create Customer Success',
  props<{ customer: ICustomer }>()
);
export const createCustomerError = createAction(
  '[WITA] Creating Customer Error'
);
