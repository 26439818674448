import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { AlertController, IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import {
  Subject,
  tap,
  take,
  catchError,
  combineLatest,
  map,
} from 'rxjs';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  IOptionOutput,
  ICtaOutput,
  ITableColumns,
  ITableConfigParams,
  ITableData,
  TableComponent,
  ITableExpandableItemValue,
  ITableExpandableItem,
} from '../../../shared/components/table/table.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { CustomerService } from '../../../core/services/customer.service';
import { ToastService } from '../../../core/services/utils/toast.service';
import { addressFieldsToCompactAddress } from '../../../shared/utils/remap.utils';
import { FacilitiesService } from '../../../core/services/facilities.service';
import { IonicColors } from '../../../shared/enums/ionicColors.enum';
import { IOrganization } from '../../../shared/models/be/organization';
import { RequestsService } from '../../../core/services/utils/requests.service';
import { PatientService } from '../../../core/services/patient.service';
import { SensorService } from '../../../core/services/sensors.service';
import { IRoom } from '../../../shared/models/be/room';
import { IUnit } from '../../../shared/models/be/unit';
import { IPatient } from '../../../shared/models/be/patients';

@Component({
  selector: 'structure',
  templateUrl: 'structure.page.html',
  styleUrls: ['structure.page.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    MatGridListModule,
    MatIconModule,
    TableComponent,
    TranslateModule,
    RouterModule,
  ],
})
export class StructurePage {
  public tableColumns: ITableColumns[] = [
    /* { label: 'ID', id: 'id', showCol: true}, */
    { label: 'BUILDING_NAME', id: 'name', showCol: true, cannotHide: true },
    { label: 'WARDS', id: 'units', showCol: true, cannotHide: true },
    { label: 'ROOMS', id: 'rooms', showCol: true, cannotHide: true },
    { label: 'BEDS', id: 'beds', showCol: true, cannotHide: true, colWidth: '70px' },
    { label: 'ADMITTED_PATIENT', id: 'admittedPatient', showCol: true },
  ];

  public tableData: ITableData[] = [];

  public tableConfigParams: ITableConfigParams = {
    id: 'structure',
    referenceLabel: 'STRUCTURE',
    contactInfo: {
      title: 'buildingName',
      subtitles: [
        {
          value: 'fullStreet',
        },
        {
          value: 'email',
        },
        {
          value: 'telephone',
        },
        {
          value: 'phoneNumber',
        },
        {
          value: 'fax',
        },
      ],
    },
    searchBy: ['name']
  };

  //private monitoringService: MonitoringService = inject(MonitoringService)

  private store = inject(Store);
  private router = inject(Router);
  //elements: MonitoringTile[]
  public isDataLoading: boolean = false;
  loadingElements: boolean;
  hasError: boolean;
  private currentOrg?: IOrganization;

  constructor(
    public route: ActivatedRoute,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private toastService: ToastService,
    private translateService: TranslateService,
    private customerService: CustomerService,
    private alertController: AlertController,
    private facilitiesService: FacilitiesService,
    private patientService: PatientService,
    private requestsService: RequestsService,
    private deviceService: SensorService
  ) {
    //this.elements = []
    this.loadingElements = false;
    this.hasError = false;
  }

  ionViewDidEnter() {
    this.loadElements();
  }

  private destroyed$ = new Subject();

  ngOnInit() {
    const currentUser = this.requestsService.getCurrentUser();
    this.facilitiesService
      .getOrganizationById$(
        currentUser?.organization?.id,
        currentUser?.customer?.id + ''
      )
      .pipe(
        tap((org) => {
          this.currentOrg = org;
        })
      )
      .subscribe();
    if (
      !['admin', 'superuser'].includes(
        this.requestsService.getCurrentUser()?.role || ''
      )
    ) {
      this.tableColumns = this.tableColumns.filter(
        (column) => column.id !== 'id'
      );
    }
  }

  ngAfterViewInit() {
    this.loadStructureData();
  }

  /**
   * @description Funzione che va ad aggiornare i dati della table in pagina
   */
  loadStructureData(showToast: boolean = false) {
    this.isDataLoading = true;
    combineLatest([
      this.facilitiesService.getBuildings$(undefined, undefined, true),
      this.patientService.getPatients$(),
      this.deviceService.getDevices$()
    ])
      .pipe(
        take(1),
        tap(([buildingsData, patientsData, devicesData]) => {
          this.tableData = buildingsData?.map((building) => {            
            return {
              data: {
                ...building,
                fullStreet: addressFieldsToCompactAddress(building),
                buildingName: building.name,
                name: {
                  data: [building],
                  values: [
                    {
                      value: building.name,
                      isExpanded: false,
                      fullData: building,
                      redirectData: {
                        destination: '/wita/patients',
                        destinationId: 'patients',
                        params: {
                          filters: [
                            {id: 'building', value: building?.id}
                          ]
                        },
                      },
                    },
                  ],
                  expandableData: {
                    showExpandButton: true,
                    expandTarget: 'all',
                    canCollapse: false,
                  },
                  redirectTo: '/wita/patients',
                } as ITableExpandableItem,
                units: {
                  values: (building.units || []).map((unit) => ({
                    value: unit.name + ` (${this.translateService.instant('TABLE.UNIT_TYPE.'+unit.unitType.toUpperCase())})`,
                    isExpanded: false,
                    emptyRows: unit.rooms?.reduce((acc, room) => {
                      acc += room.beds?.length || 0;
                      return acc;
                    }, 0),
                    fullData: unit,
                    redirectData: {
                      destination: '/wita/patients',
                      destinationId: 'patients',
                      params: {
                        filters: [
                          {id: 'building', value: building?.id},
                          {id: 'unit', value: unit?.id}
                        ]
                      }
                    }
                  })),
                  expandableData: {
                    showExpandButton: true,
                    expandTarget: 'single',
                    canCollapse: false,
                  },
                  redirectTo: '/wita/patients'
                } as ITableExpandableItem,
                rooms: {
                  complexValues: (building.units || []).reduce((acc, currUnit) => {
                    acc.push(
                      (currUnit.rooms || []).filter((room) => room.unitId === currUnit.id).map((room) => ({
                          value: room.name,
                          emptyRows: (room.beds?.length ? room.beds?.length-1 : 0),
                          icons: [{
                            name: 'videocam',
                            showBadge: true,
                            badgeValue: devicesData.filter((device) => device.facilities?.room?.id === room.id).length,
                            redirectData: {
                              destination: '/wita/devices',
                              destinationId: 'devices',
                              params: {
                                filters: [
                                  {id: 'building', value: building?.id},
                                  {id: 'unit', value: currUnit?.id},
                                  {id: 'room', value: room?.id }
                                ]
                              }
                            }
                          }],
                          redirectData: {
                            destination: '/wita/patients',
                            destinationId: 'patients',
                            params: {
                              filters: [
                                {id: 'building', value: building?.id},
                                {id: 'unit', value: currUnit?.id },
                                {id: 'room', value: room?.id }
                              ]
                            }
                          }
                      }))
                    )
                    return acc;
                  }, [] as ITableExpandableItemValue[][]),
                  expandableData: {
                    collapsedValue: (building.units || []).reduce((currAcc, currUnit) => {
                      const currDevicesNumber = currUnit.rooms?.reduce((subAcc, subCurr) => subAcc += devicesData.filter((device) => device.facilities?.room?.id === subCurr.id).length,0);
                      currAcc.push({
                        value: currUnit.rooms?.length || 0,
                        icons: [{
                          name: 'videocam',
                          showBadge: true,
                          badgeValue: currDevicesNumber,
                          badgeNumberFrom: 'devices',
                          redirectTo: '/wita/devices',
                          redirectData: {
                            destination: '/wita/devices',
                            destinationId: 'devices',
                            params: {
                              filters: [
                                {id: 'building', value: building?.id},
                                {id: 'unit', value: currUnit?.id},
                              ]
                            }
                          }
                        }]
                      });
                      return currAcc;
                    }, [] as any),
                    canCollapse: true,
                    icons: [{
                      name: 'videocam',
                      showBadge: true,
                      badgeNumberFrom: 'devices',
                      redirectTo: '/wita/devices'
                    }],
                  },
                } as ITableExpandableItem,
                beds: {
                  complexValues: (building.units || []).reduce((acc, currUnit) => {
                    acc.push(
                      (currUnit.rooms || []).filter((room) => room.unitId === currUnit.id).reduce((accBeds, currRoom) => {
                        if (currRoom.beds?.length) {
                          currRoom.beds?.forEach((bed) => {
                            accBeds.push({value: bed.name})
                          })
                        } else {
                          accBeds.push({value: '-'})
                        }
                        return accBeds;
                      }, [] as any)
                    )
                    return acc;
                  }, [] as ITableExpandableItemValue[][]),
                  expandableData: {
                    collapsedValue: (building.units || []).map((unit) => ({value: 
                      unit.rooms?.reduce((currAcc, curCurr) => currAcc += curCurr.beds?.length || 0, 0)+''
                    })),
                    canCollapse: true,
                  }
                } as ITableExpandableItem,
                admittedPatient: {
                  complexValues: (building.units || []).reduce((acc, currUnit) => {
                    acc.push(
                      (currUnit.rooms || []).filter((room) => room.unitId === currUnit.id).reduce((accBeds, currRoom) => {
                          currRoom.beds?.forEach((bed) => {
                            let currentPatient: IPatient | undefined = patientsData.find((patient) => patient.facilities?.bed?.id === bed?.id);
                            const value = currentPatient ? `${currentPatient.name} ${currentPatient.surname} (${currentPatient.taxCode})` : ''
                            accBeds.push({
                              value,
                              redirectData: {
                                destination: '/wita/patients',
                                destinationId: 'patients',
                                params: {
                                  filters: [
                                    {id: 'building', value: building?.id},
                                    {id: 'unit', value: currUnit?.id},
                                    {id: 'room', value: currRoom?.id}
                                  ],
                                  search: `${currentPatient?.name} ${currentPatient?.surname}`
                                }
                              }
                            })
                          })
                          return accBeds;
                      }, [] as any)
                    )
                    return acc;
                  }, [] as ITableExpandableItemValue[][]),
                  expandableData: {
                    canCollapse: true,
                  },
                  redirectTo: '/wita/patients',
                } as ITableExpandableItem
              },
              options: [
                {
                  icon: 'home',
                  label: 'MANAGE_UNITS',
                  children: [
                    {
                      action: 'add_ward',
                      icon: 'add',
                      label: 'ADD_WARD',
                    },
                    ...((building.units || []).length
                      ? [
                          {
                            icon: 'edit',
                            label: 'EDIT',
                            children: (building.units || []).map((unit) => ({
                              action: 'edit_ward',
                              label: unit.name,
                              disableTranslation: true,
                              additionalParams: {
                                unitId: unit.id,
                              },
                            })),
                          },
                          {
                            icon: 'delete',
                            label: 'DELETE',
                            children: (building.units || []).map((unit) => ({
                              action: 'delete_ward',
                              label: unit.name,
                              disableTranslation: true,
                              additionalParams: {
                                unitId: unit.id,
                              },
                            })),
                          },
                        ]
                      : []),
                  ],
                },
                {
                  action: 'edit_building',
                  icon: 'edit',
                  label: 'EDIT_BUILDING',
                },
                {
                  action: 'delete_building',
                  icon: 'delete',
                  label: 'DELETE_BUILDING',
                },
              ].filter((x) => x),
            } as ITableData;
          });
          showToast &&
            this.toastService.showToast(
              this.translateService.instant('GENERAL.SUCCESS.DATA_REFRESHED'),
              IonicColors.GREEN
            );
          this.isDataLoading = false;
        }),
        catchError((err) => {
          this.isDataLoading = false;
          showToast &&
            this.toastService.showToast(
              this.translateService.instant('GENERAL.ERRORS.LOADING_DATA'),
              IonicColors.RED
            );
          throw err;
        })
      )
      .subscribe();
  }

  /**
   * @description Funzione invocata al click di una opzione della table
   * @param event Viene passato un oggetto contenente l'id delle risorsa in oggetto e l'action invocata
   */
  async onOptionAction(event: IOptionOutput) {
    switch (event.action) {
      case 'edit_building':
        this.router.navigate(
          [`wita/structure/edit_building/${event.resource.id}`],
          { state: { additionalData: this.currentOrg } }
        );
        break;
      case 'delete_building':
        const deleteBuildingAlert = await this.alertController.create({
          mode: 'md',
          header: this.translateService.instant('GENERAL.MESSAGES.CAUTION'),
          message: this.translateService.instant(
            'GENERAL.MESSAGES.DELETE_WARD_MESSAGE'
          ),
          buttons: [
            {
              text: this.translateService.instant('GENERAL.BUTTONS.OK'),
              handler: () => {
                deleteBuildingAlert.dismiss();
                this.facilitiesService
                  .deleteBuilding$(event.resource.id)
                  .pipe(
                    tap(() => {
                      this.toastService.showToast(
                        'Risorsa eliminata con successo',
                        IonicColors.GREEN
                      );
                      this.loadStructureData();
                    }),
                    catchError((err) =>
                      this.toastService.showToast(
                        "Errore durante l'eliminazione della risorsa",
                        IonicColors.RED
                      )
                    )
                  )
                  .subscribe();
              },
            },
            {
              text: this.translateService.instant('GENERAL.BUTTONS.CANCEL'),
              handler: () => {
                deleteBuildingAlert.dismiss();
              },
            },
          ],
        });
        await deleteBuildingAlert.present();
        break;
      case 'add_ward':
        this.router.navigate(['wita/structure/new_ward'], {
          state: { additionalData: event.resource },
        });
        break;
      case 'edit_ward':
        this.router.navigate(
          [`wita/structure/edit_ward/${event.additionalParams?.['unitId']}`],
          { state: { additionalData: event.resource } }
        );
        break;
      case 'delete_ward':
        const deleteWardAlert = await this.alertController.create({
          mode: 'md',
          header: this.translateService.instant('GENERAL.MESSAGES.CAUTION'),
          message: this.translateService.instant(
            'GENERAL.MESSAGES.DELETE_WARD_MESSAGE'
          ),
          buttons: [
            {
              text: this.translateService.instant('GENERAL.BUTTONS.OK'),
              handler: () => {
                deleteWardAlert.dismiss();
                this.facilitiesService
                  .deleteUnit$(event.additionalParams?.['unitId']! as string)
                  .pipe(
                    tap(() => {
                      this.toastService.showToast(
                        'Risorsa eliminata con successo',
                        IonicColors.GREEN
                      );
                      this.loadStructureData();
                    }),
                    catchError((err) =>
                      this.toastService.showToast(
                        "Errore durante l'eliminazione della risorsa",
                        IonicColors.RED
                      )
                    )
                  )
                  .subscribe();
              },
            },
            {
              text: this.translateService.instant('GENERAL.BUTTONS.CANCEL'),
              handler: () => {
                deleteWardAlert.dismiss();
              },
            },
          ],
        });
        await deleteWardAlert.present();
        break;
      default:
        break;
    }
  }

  /**
   * @description Funzione invocata al click di un button (Aggiungi risorsa, refresh dati, ecc...)
   * @param event Viene passato un oggetto contenente il tipo di action invocata
   */
  onCtaAction(event: ICtaOutput) {
    switch (event.action) {
      case 'add':
        this.router.navigate(['wita/structure/new_building'], {
          state: { additionalData: this.currentOrg },
        });
        break;
      case 'refresh':
        this.loadStructureData(true);
        break;
      default:
        break;
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(1);
    this.destroyed$.complete();
  }

  loadElements() {
    //this.monitoringService.loadMonitoringElements()
  }

  handlePressElement(event: any) {
    //let element = this.elements.find(element => element.id === event);
    //this.router.navigate(['/wita/appMonitoring/monitoringRoot/sensors/bed', { id: element?.id }])
  }

  handleSelectElement(event: any) {
    //let element = this.elements.find(element => element.id === event);
    console.log('Element selected');
  }

  ngAfterViewChecked(): void {
    /* Necessario per andare a rilevare i cambiamenti e per far si che non vengano sparati errori in console */
    this.changeDetectorRef.detectChanges();
  }
}
