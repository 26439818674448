import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, map, merge, Observable, of, Subscription } from 'rxjs';
import { IUser } from '../../../shared/models/be/user';
import { ToastService } from './toast.service';
import { LoggerService } from './logger.service';
import { IonicColors } from '../../../shared/enums/ionicColors.enum';

@Injectable({
  providedIn: 'root'
})
export class RequestsService {

  constructor(
    private toastService: ToastService,
    private loggerService: LoggerService
  ) { }

  private currentUser = new BehaviorSubject<IUser | null>(null);
  private selectedSidebarItem = new BehaviorSubject<string | null>(null);
  public currentUser$ = this.currentUser.asObservable();
  public selectedSidebarItem$ = this.selectedSidebarItem.asObservable();
  public networkStatus?: boolean = undefined;
  public networkStatus$: Subscription = Subscription.EMPTY;

  getCurrentUser(): IUser | null {
    if (typeof localStorage.getItem('USER') === 'string') {
      return JSON.parse(localStorage.getItem('USER')!) as IUser
    }
    return null;
  }

  hasLinkedOrg(): boolean {
    if (typeof localStorage.getItem('USER') === 'string') {
      return !!(JSON.parse(localStorage.getItem('USER')!) as IUser)?.organization?.id
    }
    return false;
  }

  saveCurrentUser(data: IUser | null) {
    data && localStorage.setItem('USER', JSON.stringify(data));
    this.currentUser.next(data);
  }

  /**
   * @description Da la possibilità di cambiare la voce di menu (sidebar) selezionata
   * @param selectedItem L'id della voce di menu da selezionare, ATTENZIONE, l'id DEVE coincidere con uno degli id presenti nella lista di oggetti menu di root.page.ts
   */
  changeSelectedItem(selectedItem: string) {
    this.selectedSidebarItem.next(selectedItem);
  }

  checkNetworkStatus() {
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(isOnline => {
        if (this.networkStatus === undefined) {
          this.networkStatus = isOnline;
          return;
        } else {
          this.toastService.showToast(`${isOnline ? 'Smartphone di nuovo ONLINE' : 'ATTENZIONE! Smartphone OFFLINE'}`, isOnline ? IonicColors.GREEN : IonicColors.GREY);
          this.loggerService.info(`SMARTPHONE ${isOnline ? 'ONLINE' : 'OFFLINE'}`)
        }
      });
  }
}