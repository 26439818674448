import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IUser } from '../../../shared/models/be/user';

@Injectable({
  providedIn: 'root'
})
export class RequestsService {

  constructor(
  ) { }

  private currentUser = new BehaviorSubject<IUser | null>(null);
  private selectedSidebarItem = new BehaviorSubject<string | null>(null);
  public currentUser$ = this.currentUser.asObservable();
  public selectedSidebarItem$ = this.selectedSidebarItem.asObservable();

  getCurrentUser(): IUser | null {
    if (typeof localStorage.getItem('USER') === 'string') {
      return JSON.parse(localStorage.getItem('USER')!) as IUser
    }
    return null;
  }

  hasLinkedOrg(): boolean {
    if (typeof localStorage.getItem('USER') === 'string') {
      return !!(JSON.parse(localStorage.getItem('USER')!) as IUser)?.organization?.id
    }
    return false;
  }

  saveCurrentUser(data: IUser | null) {
    this.currentUser.next(data);
  }

  /**
   * @description Da la possibilità di cambiare la voce di menu (sidebar) selezionata
   * @param selectedItem L'id della voce di menu da selezionare, ATTENZIONE, l'id DEVE coincidere con uno degli id presenti nella lista di oggetti menu di root.page.ts
   */
  changeSelectedItem(selectedItem: string) {
    this.selectedSidebarItem.next(selectedItem);
  }
}