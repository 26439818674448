import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { IonBreadcrumb, IonBreadcrumbs } from '@ionic/angular/standalone';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, MatIconModule, RouterModule, IonBreadcrumb, IonBreadcrumbs]
})
export class BreadcrumbsComponent  implements OnInit {
  @Input() breadcrumbsList: IBreadcrumbs[] = []

  constructor() { }

  ngOnInit() {}

}

export interface IBreadcrumbs {
  icon?: string;
  routerLink?: string;
  label: string;
  showBreadCrumbSubject?: boolean;
  isSvgIcon?: boolean;
  breadCrumbSubject?: string;
}
