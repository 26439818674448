import { Directive, HostListener, Input } from "@angular/core";
import { NgControl } from "@angular/forms";
import { IInputRule } from "../components/form/form.component";

export function sanitizeValue(val: string, inputRule: IInputRule): string {
    switch (inputRule) {
        case 'number':
            val = val.replace(/\D/g,'');
            break;
        case 'upperCase':
            val = val.toUpperCase();
            break;
        case 'capitalize':
            val = val.replace(/(^\w|\s\w)(\S*)/g, (_,m1,m2) => m1.toUpperCase()+m2.toLowerCase());
            break;
        default:
            break;
    }
    return val
}


@Directive({
    selector: '[sanitizeInput]',
    standalone: true
})
export class SanitizeInputDirective {
    constructor(private control: NgControl) {}
    @Input('inputRule') inputRule?: IInputRule;  
    @HostListener('input', ['$event'])
    public onInput(): void {
        this.inputRule && this.control!.control!.setValue(sanitizeValue(this.control.value, this.inputRule));        
    }
}