<ion-content [fullscreen]="true">
  <section class="container">
    <!-- IMMAGINE SFONDO - META' SX -->
    <div class="container_left">
    </div>
    <!-- FORM -META' DX -->
    <ng-container [ngSwitch]="section">
      <!-- SEZIONE LOGIN -->
      <mat-grid-list *ngSwitchDefault cols="6" rowHeight="36" gutterSize="20" class="container_right">
        <mat-grid-tile colspan="1" rowspan="5"></mat-grid-tile>
        <!-- TITOLO -->
        <mat-grid-tile colspan="4" rowspan="1">
          <h2 class="container_right_title">{{'PAGES.LOGIN.TITLE' | translate}}</h2>
        </mat-grid-tile>
        <!-- FORM -->
        <form [formGroup]="authForm">
            <!-- EMAIL -->
            <mat-grid-tile colspan="4" rowspan="1" class="container_right_input">
              <mat-form-field appearance="outline">
                <mat-label>{{'PAGES.LOGIN.FORM.EMAIL.LABEL' | translate}}</mat-label>
                <input 
                  matInput
                  [placeholder]="'PAGES.LOGIN.FORM.EMAIL.PLACEHOLDER' | translate"
                  formControlName="email"
                  >
              </mat-form-field>
            </mat-grid-tile>
            <!-- PASSWORD -->
            <mat-grid-tile colspan="4" rowspan="1" class="container_right_input">              
              <mat-form-field appearance="outline">
                <mat-label>{{'PAGES.LOGIN.FORM.PASSWORD.LABEL' | translate}}</mat-label>
                <input 
                  matInput
                  [placeholder]="'PAGES.LOGIN.FORM.PASSWORD.PLACEHOLDER' | translate"
                  [type]="showPassword ? 'text' : 'password'"
                  formControlName="password">
                  <mat-icon 
                    matSuffix
                    *ngIf="authFormControls['password']['value'] !== ''" 
                    (click)="showPassword = !showPassword">{{showPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
              </mat-form-field>
            </mat-grid-tile>
        </form>
        <!-- LOGIN -->
        <mat-grid-tile colspan="4" rowspan="1">
          <ion-button expand="block" class="container_right_button" (click)="submitCredentials()">{{'PAGES.LOGIN.LOGIN' | translate}}</ion-button>
        </mat-grid-tile>
        <!-- FOOTER -->
        <mat-grid-tile colspan="4" rowspan="1">
          <div class="container_right_footer_login">
            <ion-checkbox labelPlacement="end" mode="md">{{'PAGES.LOGIN.REMEMBER_ME' | translate}}</ion-checkbox>
            <a routerLink="/auth/login" fragment="forgot_pw">{{'PAGES.LOGIN.FORGOT_PASSWORD.QUESTION' | translate}}</a>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
      <!-- SEZIONE PASSWORD DIMENTICATA -->
      <mat-grid-list *ngSwitchCase="'forgot_pw'" cols="6" rowHeight="36" gutterSize="20" class="container_right">
        <mat-grid-tile colspan="1" rowspan="5"></mat-grid-tile>
        <!-- TITOLO -->
        <mat-grid-tile colspan="4" rowspan="1">
          <h2 class="container_right_title">{{'PAGES.LOGIN.FORGOT_PASSWORD.TITLE' | translate}}</h2>
        </mat-grid-tile>
        <!-- FORM -->
        <form [formGroup]="forgotPwForm">
            <mat-grid-tile colspan="4" rowspan="1" class="container_right_input">
              {{'PAGES.LOGIN.FORGOT_PASSWORD.SUBTITLE' | translate}}
            </mat-grid-tile>
            <mat-grid-tile colspan="4" rowspan="1" class="container_right_input">
              <mat-form-field appearance="outline">
                <mat-label>{{'PAGES.LOGIN.FORM.EMAIL.LABEL' | translate}}</mat-label>
                <input 
                  matInput
                  [placeholder]="'PAGES.LOGIN.FORM.EMAIL.PLACEHOLDER' | translate"
                  formControlName="email"
                  >
              </mat-form-field>
            </mat-grid-tile>
        </form>
        <!-- CTA -->
        <mat-grid-tile colspan="4" rowspan="1">
          <ion-button expand="block" class="container_right_button" (click)="sendResetPwMail()">{{'PAGES.LOGIN.SEND_MAIL' | translate}}</ion-button>
        </mat-grid-tile>
        <mat-grid-tile colspan="4" rowspan="1">
          <div class="container_right_footer_return-to-login" routerLink="/auth/login">
            <p [innerHTML]="'PAGES.LOGIN.RETURN_TO_LOGIN' | translate"></p>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
      <!-- SEZIONE RESET PASSWORD -->
      <mat-grid-list *ngSwitchCase="'reset_pw'" cols="6" rowHeight="36" gutterSize="20" class="container_right">
        <mat-grid-tile colspan="1" rowspan="5"></mat-grid-tile>
        <mat-grid-tile colspan="4" rowspan="1">
          <h2 class="container_right_title">{{'PAGES.LOGIN.RESET_PASSWORD.TITLE' | translate}}</h2>
        </mat-grid-tile>
        <form [formGroup]="resetPwForm">            
            <mat-grid-tile colspan="4" rowspan="1" class="container_right_input">              
              <mat-form-field appearance="outline">
                <mat-label>{{'PAGES.LOGIN.FORM.PASSWORD.LABEL' | translate}}</mat-label>
                <input 
                  matInput
                  [placeholder]="'PAGES.LOGIN.FORM.PASSWORD.PLACEHOLDER' | translate"
                  [type]="showPassword ? 'text' : 'password'"
                  formControlName="password">
                  <mat-icon 
                    matSuffix
                    *ngIf="resetpwFormControls['password']['value'] !== ''" 
                    (click)="showPassword = !showPassword">{{showPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile colspan="4" rowspan="1" class="container_right_input">              
              <mat-form-field appearance="outline">
                <mat-label>{{'PAGES.LOGIN.FORM.CONFIRM_PASSWORD.LABEL' | translate}}</mat-label>
                <input 
                  matInput
                  [placeholder]="'PAGES.LOGIN.FORM.CONFIRM_PASSWORD.PLACEHOLDER' | translate"
                  [type]="showPassword ? 'text' : 'password'"
                  formControlName="confirmPassword">
                  <mat-icon 
                    matSuffix
                    *ngIf="resetpwFormControls['confirmPassword']['value'] !== ''" 
                    (click)="showPassword = !showPassword">{{showPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
              </mat-form-field>
            </mat-grid-tile>
        </form>
        <mat-grid-tile colspan="4" rowspan="1">
          <ion-button expand="block" class="container_right_button" (click)="resetPw()">{{'PAGES.LOGIN.RESET_PASSWORD.BUTTON' | translate}}</ion-button>
        </mat-grid-tile>
        <mat-grid-tile colspan="4" rowspan="1">
          <div class="container_right_footer_return-to-login" routerLink="/auth/login">
            <p [innerHTML]="'PAGES.LOGIN.RETURN_TO_LOGIN' | translate"></p>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </ng-container>
  </section>
</ion-content>
