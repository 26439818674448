import { Component, EnvironmentInjector, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './core/services/auth.service';
import { PopoverController } from '@ionic/angular';
import { SwUpdate } from '@angular/service-worker';
import { RequestsService } from './core/services/utils/requests.service';
import { environment } from '../environments/environment';
import { NotificationService } from './core/services/notification.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  public environmentInjector = inject(EnvironmentInjector);
  //public navigationService = inject(NavigationService)
  public popoverController = inject(PopoverController)
  public authService = inject(AuthService);
  public isPwa: boolean = false;
  public isMobile: boolean = false;

  deviceOffline: boolean

  constructor(
    private translateService: TranslateService,
    private swUpdate: SwUpdate,
    private requestsService: RequestsService,
    private notificationService: NotificationService
  ) {

    this.deviceOffline = false;

    this.isPwa = !!this.getPWADisplayMode();
    this.isMobile = this.isMobileCheck();
    console.log(`PWA: ${this.isPwa} ${this.isPwa ? '- '+this.getPWADisplayMode() : ''}`);
    console.log(`MOBILE: ${this.isMobile}`);
    notificationService.requestPermission();
    notificationService.listen();

    /* Network.addListener('networkStatusChange', status => {
      console.log('Network status changed', status);
      if (status.connected === false) {
        this.deviceOffline = true
      } else {
        this.deviceOffline = false
      }
    }); */
    
    this.initializeLanguage(navigator.language);
    if(this.authService.isLoggedIn()){
    // all'apertura dell'app con l'utente già loggato, aggiorna il firebase token per sicurezza nel caso in cui sia cambiato
      //this.authService.updateUserDevice$().pipe(take(1)).subscribe(); 
    }
    environment.production && this.swUpdate.checkForUpdate().then((foundNewVersion) => {
      if (foundNewVersion) {
        confirm(this.translateService.instant('GENERAL.PWA.NEW_VERSION_AVAILABLE')) && window.location.reload();
      }
    });
  }

  /**
   * Funzione che verifica che la webApp stia girando in PWA
   * @returns Se sta girando in PWA torna la modalità di visualizzazione della PWA, altrimenti false
   */
  getPWADisplayMode(): string | boolean {
    if (document.referrer.startsWith('android-app://'))
      return 'twa';
    if (window.matchMedia('(display-mode: standalone)').matches)
      return 'standalone';
    if (window.matchMedia('(display-mode: minimal-ui)').matches)
      return 'minimal-ui';
    if (window.matchMedia('(display-mode: fullscreen)').matches)
      return 'fullscreen';
    if (window.matchMedia('(display-mode: window-controls-overlay)').matches)
      return 'window-controls-overlay';  
    return false;
  }

  isMobileCheck() {
    if ("maxTouchPoints" in navigator) {
      return navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
      return (navigator as any).msMaxTouchPoints > 0;
    } else {
        var mQ = matchMedia("(pointer:coarse)");
        if (mQ && mQ.media === "(pointer:coarse)") {
          return !!mQ.matches;
        } else if ('orientation' in window) {
          return true; // deprecated, but good fallback
        } else {
            // Only as a last resort, fall back to user agent sniffing
            var UA = (navigator as any).userAgent;
            return (
                /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
            );
        }
    }
  }

  /**
   * @description Imposto le traduzioni sulla base della lingua dello user o, se non esistente, del browser, di default metto 'it'
   * @param browserLanguage 
   */
  initializeLanguage(browserLanguage: string) {
    if (['it', 'en', 'fr'].includes(this.requestsService.getCurrentUser()?.language || "")) {
      this.translateService.use(this.requestsService.getCurrentUser()!.language);
    } else {
        if (browserLanguage.includes('en-')) {
          this.translateService.use('en');
        } else if (browserLanguage.includes('fr-')) {
          this.translateService.use('fr');
        } else {
          this.translateService.use('it');
        }
    }
  }
}
