import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injectable,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  MatPaginator,
  MatPaginatorIntl,
  MatPaginatorModule,
  PageEvent,
} from '@angular/material/paginator';
import {
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, Subject, take } from 'rxjs';
import { MatSort, MatSortable, MatSortModule } from '@angular/material/sort';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterModule } from '@angular/router';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { IUnit } from '../../models/be/unit';
import { IRoom } from '../../models/be/room';
import { IBed } from '../../models/be/bed';
import { TypeofPipe } from '../../pipes/typeOf.pipe';
import { IBuilding } from '../../models/be/building';
import { IPatient } from '../../models/be/patients';
import { RequestsService } from '../../../core/services/utils/requests.service';
import { MatBadgeModule } from '@angular/material/badge';
import { IDevice } from '../../models/be/device';
import { FiltersComponent } from './filters/filters.component';
import { FilterInitializer } from './initializers/filterInitializer';
import { SortInitializer } from './initializers/sortInitializer';
import { TableService } from './table.service';
import { FacilitiesService } from '../../../core/services/facilities.service';
import { IonBreadcrumb, IonBreadcrumbs, IonButton, IonSearchbar, IonCheckbox } from '@ionic/angular/standalone';

@Injectable()
export class MatCustomPaginator implements MatPaginatorIntl {
  constructor(private translateService: TranslateService) {}
  changes = new Subject<void>();

  firstPageLabel = this.translateService.instant('TABLE.PAGINATOR.FIRST_PAGE');
  itemsPerPageLabel = this.translateService.instant(
    'TABLE.PAGINATOR.ITEMS_PER_PAGE'
  );
  lastPageLabel = this.translateService.instant('TABLE.PAGINATOR.LAST_PAGE');
  nextPageLabel = this.translateService.instant('TABLE.PAGINATOR.NEXT_PAGE');
  previousPageLabel = this.translateService.instant(
    'TABLE.PAGINATOR.PREVIOUS_PAGE'
  );

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return this.translateService.instant('TABLE.PAGINATOR.PAGE_OF', {
        currentPage: 1,
        totalPages: 1,
      });
    }
    const amountPages = Math.ceil(length / pageSize);
    return this.translateService.instant('TABLE.PAGINATOR.PAGE_OF', {
      currentPage: page + 1,
      totalPages: amountPages,
    });
  }
}

@Component({
  selector: 'generic-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  standalone: true,
  imports: [IonCheckbox, 
    CommonModule,
    TranslateModule,
    MatGridListModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatTableModule,
    MatDividerModule,
    MatPaginatorModule,
    MatSortModule,
    RouterModule,
    MatCheckboxModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    TypeofPipe,
    MatBadgeModule,
    FiltersComponent,
    IonButton,
    IonSearchbar,
    IonBreadcrumb,
    IonBreadcrumbs,
    FormsModule
  ],
  providers: [
    { 
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TableComponent,
    }
  ]
})
export class TableComponent {
  @Input() tableColumns: Array<ITableColumns> = [];
  @Input() data: Array<ITableData> = [];
  @Input() isLoading: boolean = false;
  @Input() config!: ITableConfigParams;

  @Output() onPaginatorChange = new EventEmitter<PageEvent>();
  @Output() onOptionAction = new EventEmitter<IOptionOutput>();
  @Output() onCtaAction = new EventEmitter<ICtaOutput>();
  @Output() onCheckboxSelection = new EventEmitter<ITableData[]>();
  @Output() onReloadTableData = new EventEmitter<string>();

  public dataSource?: MatTableDataSource<ITableData, MatPaginator>;
  public searchBarValue: string = '';
  
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort = new MatSort;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private requestsService: RequestsService,
    private filterInitializer: FilterInitializer,
    private sortInitializer: SortInitializer,
    public tableService: TableService,
    private facilitiesService: FacilitiesService,
    public router: Router
  ) {}

  ngAfterViewInit() {
    this.loadTable();
    // Inizializzo la logica di Sort dei dati
    this.config.defaultSort && this.sort.sort(({ id: this.config.defaultSort || '', start: 'asc'}) as MatSortable);
  }

  loadTable() {
    // Se presenti 'opzioni' e non presente oggetto 'opzioni' su tableColumns allora pusho su tableColumns l'oggetto 'opzioni' con i rispettivi campi
    this.data.some((item) => item.options?.length) && !this.tableColumns.some((col) => col.id === 'options') &&
      this.tableColumns.push({
        id: 'options',
        label: '',
        showCol: true,
        colWidth: '136px',
      });
    // Inizializzo la Table
    this.dataSource = new MatTableDataSource<ITableData>(this.data);
    // Inizializzo il Paginator della Table
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortData = this.sortInitializer.sortLogic();
    // Se l'utente cambia il sorting degli elmenti, lo rimando alla prima pagina della table.
    this.dataSource.sort?.sortChange.pipe(take(1)).subscribe(() => (this.dataSource!.paginator!.pageIndex = 0));


    // Inizializzo la logica di Filtraggio dei dati
    this.dataSource.filterPredicate = this.filterInitializer.filterLogic();

    this.filterInitializer.initializeFiltersLoader(this.config, this.dataSource);
    
    if (localStorage.getItem('TABLE_PREFERENCES')) {
      const tablePreferences: ITablePreferences = JSON.parse(
          localStorage.getItem('TABLE_PREFERENCES')!
      );
      this.tableColumns = tablePreferences[this.config.id]?.columnsVisibility || this.tableColumns;
      this.searchBarValue = tablePreferences[this.config.id]?.search || '';
    }

    this.searchBarValue && this.applySearchFilter(
      this.config.searchBy,
      this.searchBarValue
    );
  }

  paginatorChange(paginatorData: PageEvent) {
    this.onPaginatorChange.emit(paginatorData);
  }

  ngOnChanges(changes: any) {
    changes.data?.currentValue && this.loadTable();
  }

  /**
   * 
   * @param tableFields Indica i campi da andare a ricercare
   * @param value 
   */
  applySearchFilter(tableFields: string[], value: string | null) {
    if (this.dataSource) {
      // Recupero il filtro corrente
      let currentJsonFilter: ITableGeneralConfigParams = (this.dataSource.filter && JSON.parse(this.dataSource.filter)) || {};

      if (value && value !== '') {
        currentJsonFilter.searchParams = {
          tableFields,
          value
        }
      } else {
        currentJsonFilter.searchParams = undefined;
      }
      this.tableService.updateTablePreferences(this.config.id, 'search', value || '')
      this.dataSource.filter = JSON.stringify(currentJsonFilter || {});
      this.dataSource.paginator?.firstPage();      
    }
  }

  /**
   * @description Funzione invocata al click di una opzione della table
   * @param event Viene passato un oggetto contenente l'id delle risorsa in oggetto e l'action invocata
   */
  emitOptionAction(
    action: string,
    resource: any,
    additionalParams?: { [key: string]: string }
  ) {
    this.onOptionAction.emit({
      resource,
      action,
      additionalParams,
    });
  }

  /**
   * @description Funzione invocata al click di un button (Aggiungi risorsa, refresh dati, ecc...), manda l'evento al parent
   * @param event Viene passata l'action invocata
   */
  emitCtaAction(action: CtaActionType) {
    this.onCtaAction.emit({
      action,
    });
  }

  /**
   * @description Funzione che si occupa di gestire l'espansione o il restringimento delle sezioni 'espandibili' della tabella
   * @param expandableItem Oggetto contenente i dati della risorsa espandibile in oggetto (data, expandableData, values, ecc...)
   * @param expandableItemId Id della risorsa espandibile in oggetto (fa riferimento all'id di uno degli oggetti presenti sull'array 'data' di 'expandableItem')
   * @param buildingId Id dell'edificio di riferimento
   */
  expandRows(units: ITableExpandableItemValue[], expansionStatus: boolean) {
    units.forEach((unit) => {
      unit.isExpanded = expansionStatus;
    })
  }

  calculateMinHeight(units: ITableExpandableItem): number {
    return units.values!.reduce((acc, currValue) => {
      acc += 49*(currValue.isExpanded ? (currValue.emptyRows || 1) : 1)
      return acc;
    }, 0)
  }

  ngAfterViewChecked(): void {
    /* Necessario per andare a rilevare i cambiamenti e per far si che non vengano sparati errori in console (in verità ne viene sparato uno nello specifico che riguarda il cambiamento delle headers della table, prima senza 'options' e poi con, NOTA BENE: NON É UN VERO ERRORE) */
    this.changeDetectorRef.detectChanges();
  }

  get columnsLabels() {
    return this.tableColumns
      .filter((col) => col.showCol)
      .map((col) => col.label);
  }

  get columnsIds() {
    return this.tableColumns.filter((col) => col.showCol).map((col) => col.id);
  }

  get columnsWithoutOptions() {
    return this.tableColumns.filter((col) => col.id !== 'options' && !col.cannotHide);
  }


  /////////////////////////////////////////
  //                                     //
  //          CODICE DEPRECATO           //
  //      FRUTTO DI VECCHI SVILUPPI      //
  //                                     //
  /////////////////////////////////////////

  /**
   * Utilizzato per gestire lo stato dei checkboxes qualora essi siano abilitati
   */
  selection = new SelectionModel<any>(true, []);

  /**
   * @description Funzione che controlla se sono stati selezionati tutti i checkbox
   * @deprecated DA RIVEDERE LOGICA, POTREBBE ANCHE ANDARE BENE COM'É MA É DA VERIFICARE
   */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource?.data.length;
    return numSelected === numRows;
  }

  /**
   * @description Seleziona tutte le checkbox se non sono tutte selezionate, altrimenti le deseleziona tutte
   * @deprecated DA RIVEDERE LOGICA, POTREBBE ANCHE ANDARE BENE COM'É MA É DA VERIFICARE
   */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource!.data);
  }

  reloadTableData(value: string) {
    this.onReloadTableData.emit(value);
  }

  /**
   * @description Funzione invocata al cambio di stato delle checkbox, manda i campi selezionati al padre
   */
  onCheckboxStatusChange() {
    this.onCheckboxSelection.emit(this.selection.selected);
  }

  /////////////////////////////////////////
  //                                     //
  //        FINE CODICE DEPRECATO        //
  //      FRUTTO DI VECCHI SVILUPPI      //
  //                                     //
  /////////////////////////////////////////


}


  /////////////////////////////////////////
  //                                     //
  //              INTERFACCE             //
  //                                     //
  /////////////////////////////////////////

export interface ITableColumns {
  id: string;
  label: string;
  showCol: boolean;
  /**
   * @description Opzionale, permette di settare una larghezza custom alla colonna in oggetto
   */
  colWidth?: string;
  /**
   * @description Opzionale, consente di nascondere la voce dalla lista di colonne "nascondibili" allo scopo di non nascondere alcune colonne fondamentali per la pagina (status per i device, patient per i pazienti, ecc...)
   */
  cannotHide?: boolean;
}

export interface ITableDataValue {
  value: string | number | boolean;
  type: ITableDataTypes;
  showBadge?: boolean;
}

export type ITableDataTypes = 'text' | 'icon' | 'image';

export interface ITableConfigParams {
  /**
   * Identificativo della tabella, obbligatorio
   */
  id: string;
  /**
   * Label di riferimento per le scritte di del component
   */
  referenceLabel?: string;
  /**
   * Definisce le informazioni da inserire all'apertura della menu al click della card affianco ai 3 puntini (se abilitata)
   */
  contactInfo?: {
    /**
     *
     */
    title: string;
    subtitles: { value: string; prefix?: string }[];
  };
  showOnlyTable?: boolean;
  /**
   * Se definito imposta l'altezza della table sulla base del numero di righe indicato
   */
  tableHeightInRows?: number;
  /**
   * Permette di nascondere il Paginator
   */
  hidePaginator?: boolean;
  /**
   * Permette di nascondere il pulsante di aggiunta risorsa
   */
  hideAddBtn?: boolean;
  /**
   * Permette di inserire dei filtri nella pas
   */
  filters?: ITableConfigParamsFilter[];
  /**
   * Indica l'id della colonna da prendere come riferimento per la ricerca testuale (searchBar)
   */
  searchBy: string[];
  /**
   * Permette di settare un parametro default di sort per i dati in tabella
   */
  defaultSort?: string;
  /**
   * Opzionale, permette di nascondere la searchbar
   */
  hideSearchBar?: boolean;
}

export interface ITableConfigParamsFilter {
  /**
   * Tipo di filtro
   * @param select Input di Select semplice
   * @param multiSelect Input di Select a  selezione multipla
   */
  type: 'select' | 'multiSelect',
  /**
   * Label del filtro
   */
  label: string;
  /**
   * Id del filtro
   */
  id: string;
  /**
   * Nome del parametro della tabella per cui eseguire il filtraggio
   */
  tableParamName: string;
  /**
   * Parametro che se valorizzato "true", al "rimuovi tutti i filtri" di default setta tutte le checkbox del multiselect a true
   */
  allCheckedByDefault?: boolean;
  /**
   * Parametro che se valorizzato "true" non va ad eliminare le options dell'input in oggetto al "rimuovi tutti i filtri"
   */
  dontResetOnClear?: boolean,
  /**
   * Opzioni della Select
   */
  options: {
    id: string;
    name: string;
    /**
     * Opzionale, se 'true' traduco il nome con la translate Pipe
     */
    translateName?: boolean;
    /**
     * Opzionale, se valorizzato rappresenta il valore dell'opzione in oggetto
     */
    value?: boolean;
  }[];
  /**
   * Contiene il valore dell'Input
   */
  selectedValue?: string | {id: string, groupId: string}[] | string[];
  /**
   * Valore di default, se non valorizzato è 'all' o undefined
   */
  defaultValue?: string;
  /**
   * @description Permette il caricamento di risorse tramite chiamata
   * @param id Id della risorsa necessaria per effettuare la chiamata
   * @returns Ritorna un array di oggetti con id e nome della risorsa
   */
  loader?: (...ids: string[]) => Observable<{id: string, name: string}[]>;
  /**
   * Opzionale, fa riferimento al fatto che il caricamento delle opzioni della risorsa in oggetto dipendono dalla selezione dell'input con l'id indicato
   */
  dynamicLoadOn?: string;
  /**
   * Opzionale, se true recupera il customerId e l'organizationId dalla tabella e non dal LocalStorage
   */
  dynamicCustomerAndOrganization?: boolean;
  /**
   * Opzionale, se true considera i campi options con controlli di presenza campo e non di confronto stringhe
   */
  checkExistance?: boolean;
  /**
   * Opzionale, consente di ricaricare i dati della table, passando a monte il parametro selected value del input in oggetto
   */
  reloadTableData?: boolean
}

export interface ITableGeneralConfigParams {
  filters?: ITableConfigParamsFilter[],
  searchParams?: {
    value: string;
    tableFields: string[];
  }
}

export interface ITableData {
  rowStyle?: {
    disabledLine?: boolean;
    bgColor?: string;
  },
  data: ITableDataData | IPatient | IDevice;
  options?: Array<ITableDataOptions>;
}

export interface ITableDataData {
  [key: string]:
      string
      | string[]
      | number
      | boolean
      | null
      | ITableExpandableItem;
}

export interface ITableDataOptions {
  action: string;
  icon: string;
  label: string;
  disabled?: boolean;
  additionalParams?: { [key: string]: string | number | string[] };
  disableTranslation?: boolean;
  children?: Omit<ITableDataOptions, 'children'>;
}

export interface ITableExpandableItem {
  /**
   * Definisce i valori presenti nella cella della tabella
   */
  values?: ITableExpandableItemValue[];
  complexValues?: ITableExpandableItemValue[][];
  /**
   * Definisce le specifiche dell'espansione
   */
  expandableData: {
    /**
     * Opzionale, permette di scegliere se far mostrare o meno la freccetta di espansione dato
     */
    showExpandButton?: boolean;
    /**
     * Opzionale, consente di definire se si sta andando ad espandere TUTTI i dati (all) o solo alcuni (single)
     */
    expandTarget?: 'all' | 'single';
    /**
     * Opzionale, permette la visualizzazione di Icone a dx del testo, viene consentito altresì l'inserimento di badge sull'icona
     */
    icons?: {
      /**
       * Nome icona
       */
      name: string,
      /**
       * Regola la visibilità o meno del badge
       */
      showBadge?: boolean,
      /**
       * Indica da dove prendere le informazioni di count per il badge
       */
      badgeNumberFrom?: string,
      /**
       * Opzionale, se valorizzato permette il reinderizzamento ad altre pagine
       */
      redirectTo?: string
    }[];
    canCollapse?: boolean;
  };
  /**
   * Opzionale, se valorizzato permette il redirect alla pagina indicata al click dell'elemento
   */
  redirectTo?: string;
}

export interface ITableExpandableItemValue {
  value: string | number;
  emptyRows?: number;
  redirectData?: {
    destination: string;
    destinationId: string;
    params?: ITablePrevaluedParams;
  };
  fullData?: IBuilding | IUnit | IRoom | IBed | IPatient;
  isExpanded?: boolean;
  icons?: {
    name: string;
    showBadge?: boolean;
    badgeValue: number;
    redirectData?: {
      destination: string;
      destinationId: string;
      params?: ITablePrevaluedParams;
    };
  }[];
}

/**
 * Va a definire i valori per la prevalorizzazione dei campi di ricerca/filtri nella table
 */
export interface ITablePrevaluedParams {
  search?: string;
  //filters?: ITableConfigParamsFilter[];
  filters?: {id: string, value?: string}[];
}

export interface IOptionOutput {
  resource: any;
  action: string;
  additionalParams?: { [key: string]: string | string[] };
}

export interface ICtaOutput {
  action: CtaActionType;
}

export interface ITablePreferences {
  [key: string]: {
    columnsVisibility: ITableColumns[];
    filters: {id: string, value?: string}[];
    search?: string;
  };
}

type CtaActionType = 'add' | 'refresh';
