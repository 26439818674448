export enum CustomRegExp {
    BUSINESS_NAME = "^([a-zA-Z0-9\\s\\-\\.&\\(\\)]+)(?:\\s+([a-zA-Z0-9\\s\\-\\.&\\(\\)]+))*$",
    VAT = "^[0-9]{11}$",
    //CF = "^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$",
    TAX_CODE = "^[A-Z]{6}[0-9]{2}[A-Z]{1}[0-9]{2}[A-Z]{1}[0-9]{3}[A-Z]{1}$",
    PEC = "^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$",
    RECIPIENT_CODE = "^[A-Z0-9]{7}$",
    CIVIC = "^([0-9]{1,5}|[a-zA-Z]{1,3})(\/[0-9]{1,4})?$",
    MUNICIPALITY = "^[A-Za-zÀ-ÖÈèçñáéíóúüÁÉÍÓÚÜñ]+(?:\\s+|-)?(?:[A-Za-zÀ-ÖÈèçñáéíóúüÁÉÍÓÚÜñ]+(?:\s+|-)?)+$",
    CAP = "^[0-9]{5}$",
    PROVINCE = "^[a-zA-Z\s]+$",
    COUNTRY = "^[a-zA-Z\s]+$",
    EMAIL = "^((?:(?:[a-zA-Z0-9_\\-\\.]+)@(?:(?:\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(?:(?:[a-zA-Z0-9\\-]+\\.)+))(?:[a-zA-Z]{2,10}|[0-9]{1,3})(?:\\]?))*)$",
    PHONE_NUMBER = "^([+]?[s0-9]+)?(d{3}|[(]?[0-9]+[)])?([-]?[s]?[0-9])+$",
    MOBILE_PHONE_NUMBER = "^([0-9]{10}|[0-9]{12})$",
    FAX = "^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]\\d{3}[\\s.-]\\d{4}$",

    CANNOT_START_WITH_ZERO = "^[1-9][0-9]*$",
    UPPERCASE = "[A-Z]",
    LOWERCASE = "[a-z]",
    NUMBER = "\d",
    HAS_8_TO_40_CHARS = "^[a-zA-Z0-9]{8,40}$",
    FIRST_PART_BASE_64 = "^data:image\/[a-z]+;base64,"
}