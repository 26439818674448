<ion-content [fullscreen]="true" class="page-content" color="light">
  <generic-table
    style="width: 100%; height: 100%"
    [tableColumns]="tableColumns"
    [data]="tableData"
    [config]="tableConfigParams"
    [isLoading]="isDataLoading"
    (onOptionAction)="onOptionAction($event)"
    (onCtaAction)="onCtaAction($event)"
  ></generic-table>
  <!-- <modal-container
      [modalHeightInRows]="tableRowSpan"
      [breadcrumbsList]="breadcrumbsList"
      [hideSaveButton]="true"
    > -->
    <!-- <div style="width: 100%; margin: 15px;">
      <mat-grid-list cols="10" rowHeight="66px" gutterSize="20">
        <mat-grid-tile colspan="2" rowspan="1" *ngFor="let input of inputsList" style="overflow: visible;">
          <mat-form-field appearance="outline" subscriptSizing="dynamic" class="grid-list_header-container_filters" style="width: 100%;">
            <mat-label>{{input.label}}</mat-label>
            <mat-select
              [(value)]="input.selectedValue"
              (selectionChange)="applyValue(input, $event)"
              >
                @for (option of $any(input).options; track option) {
                  <mat-option 
                    [value]="option.id"
                    >
                    <div class="floatingText">
                      <span>{{option.label}}</span>
                    </div>
                  </mat-option>
                }
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
    <div *ngSwitch="inputsList[1].selectedValue">
      <ng-content *ngSwitchCase="'chart'">
        <canvas #chartCanvas style="margin: 20px; height: 250px;">{{ room.chartData }}</canvas>
      </ng-content>
      <ng-content *ngSwitchCase="'table'">

      </ng-content>
    </div> -->
    <!-- 
    <mat-expansion-panel *ngFor="let room of data" [expanded]="data.length === 1" style="margin: 15px;">
      <mat-expansion-panel-header>
        <mat-panel-title style="margin: 0 20px;">
          {{room.name}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <canvas #chartCanvas style="margin: 20px; height: 250px;">{{ room.chartData }}</canvas>
    </mat-expansion-panel> -->
  <!-- </modal-container> -->
</ion-content>