import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ModalContainerComponent } from '../../../shared/components/modal-container/modal-container.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IBreadcrumbs } from '../../../shared/components/breadcrumbs/breadcrumbs.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerService } from '../../../core/services/customer.service';
import { FacilitiesService } from '../../../core/services/facilities.service';
import { combineLatest, of, tap } from 'rxjs';
import { IFormChangeEmitter } from '../../../shared/components/form/form.component';
import { RequestsService } from '../../../core/services/utils/requests.service';
import { ToastService } from '../../../core/services/utils/toast.service';
import { IonicColors } from '../../../shared/enums/ionicColors.enum';
import { IUser } from '../../../shared/models/be/user';
import { NotificationService } from '../../../core/services/notification.service';
import { IonButton, IonContent } from "@ionic/angular/standalone";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.page.html',
  styleUrls: ['./settings.page.scss'],
  standalone: true,
  imports: [IonContent, 
    CommonModule,
    ModalContainerComponent,
    MatGridListModule,
    MatFormFieldModule,
    TranslateModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    IonButton,
    IonContent
  ]
})
export class SettingsPage  implements OnInit {

  public currentLanguage: string = 'it';

  public formStatus: IFormChangeEmitter = {
    canSaveDraft: true,
    canSaveData: true,
  }

  public breadcrumbsList: IBreadcrumbs[] = [{
    icon: 'settings',
    routerLink: '',
    label: 'SETTINGS'
  }]

  public settingsData: ISettingsData[] = [];

  constructor(
    private translate: TranslateService,
    private customerService: CustomerService,
    private facilitiesService: FacilitiesService,
    private requestsService: RequestsService,
    private toastService: ToastService,
    private notificationService: NotificationService
  ) { }

  private currentUser = this.requestsService.getCurrentUser();

  ngOnInit() {
    this.currentLanguage = this.translate.currentLang;
    this.settingsData.push({
      id: 'language',
      label: 'LANGUAGE',
      rowSpan: 2,
      inputs: [{
        id: 'language',
        type: 'select',
        length: 2,
        label: 'LANGUAGE',
        selectedValue: this.translate.currentLang,
        values: [{
          id: 'it',
          label: 'ITALIAN'
        },{
          id: 'en',
          label: 'ENGLISH'
        }],
        onSelect: (resourceId: string) => this.changeLanguage(resourceId)
      }]
    })
    if (this.currentUser?.role === 'superuser') {
      combineLatest([
        this.customerService.loadCustomers$(),
        this.currentUser?.customer?.id ? this.facilitiesService.getOrganization$() : of([]),
        this.currentUser?.organization?.id ? this.facilitiesService.getBuildings$() : of([])
      ]).pipe(
        tap(([customersData, organizations, buildings]) => {
          this.settingsData.push({
            id: 'organization',
            label: 'ORGANIZATION',
            rowSpan: 2,
            inputs: [{
              id: 'customer',
              type: 'select',
              length: 2,
              label: 'CUSTOMER',
              selectedValue: this.currentUser?.customer?.id+"" || null,
              disableTranslation: true,
              values: customersData.map((customer) => ({
                id: customer.id+"",
                label: customer.businessName
              })),
              onSelect: (resourceId: string) => this.loadOrganizations(resourceId)
            },{
              id: 'organization',
              type: 'select',
              length: 2,
              selectedValue: this.currentUser?.organization?.id || (organizations.length ? organizations[0].id : null),
              label: 'ORGANIZATION',
              disableTranslation: true,
              values: (organizations || []).map((org) => ({
                id: org.id,
                label: org.name
              })),
              onSelect: (resourceId: string) => this.loadBuildings(resourceId)
            },{
              id: 'building',
              type: 'select',
              length: 2,
              selectedValue: this.currentUser?.building?.id || (buildings.length === 1 ? buildings[0].id : null),
              label: 'BUILDING',
              disableTranslation: true,
              values: (buildings || []).map((building) => ({
                id: building.id!,
                label: building.name!
              }))
            }]
          })
        })
      ).subscribe();
    } else {
      this.facilitiesService.getBuildings$(this.currentUser!.organization!.id+"").pipe(
        tap((buildings) => {
          this.settingsData.push({
            id: 'building',
            label: 'BUILDING_SET',
            rowSpan: 2,
            inputs: [{
              id: 'building',
              type: 'select',
              length: 2,
              selectedValue: this.currentUser?.building?.id || (buildings.length === 1 ? buildings[0].id : null),
              label: 'BUILDING',
              disableTranslation: true,
              values: (buildings || []).map((building) => ({
                id: building.id!,
                label: building.name!
              }))
            }]
          })
        })
      ).subscribe();
    }

  }

  changeLanguage(lang: string) {
    this.translate.use(lang);
  }

  loadOrganizations(customerId: string) {
    this.facilitiesService.getOrganization$(customerId).pipe(
      tap((data) => {
        const orgSection = this.settingsData.find((setting) => setting.id === 'organization')?.inputs.find((input) => input.id === 'organization');
        const buildingSection = this.settingsData.find((setting) => setting.id === 'organization')?.inputs.find((input) => input.id === 'building');
        if (orgSection) {
          orgSection.values = data.map((org) => ({
            id: org.id,
            label: org.name
          }));
          orgSection.selectedValue = orgSection.values.length === 1 ? orgSection.values[0].id : (orgSection.selectedValue || undefined)
          orgSection.selectedValue && this.loadBuildings(orgSection.selectedValue)
        }
        if (buildingSection) {
          buildingSection.values = [];
        }
      })
    ).subscribe();
  }

  loadBuildings(organizationId: string) {
    const selectedCustomer = this.settingsData.find((setting) => setting.id === 'organization')!.inputs.find((input) => input.id === 'customer')!.selectedValue;
    selectedCustomer && this.facilitiesService.getBuildings$(selectedCustomer, organizationId).pipe(
      tap((data) => {
        const buildingSection = this.settingsData.find((setting) => setting.id === 'organization')?.inputs.find((input) => input.id === 'building');
        if (buildingSection) {
          buildingSection.values = data.map((building) => ({
            id: building.id!,
            label: building.name!
          }))
          buildingSection.selectedValue = buildingSection.values.length === 1 ? buildingSection.values[0].id : (buildingSection.selectedValue || undefined)
        }
      })
    ).subscribe();
  }

  submitData() {
    const currentUser = !!localStorage.getItem('USER') && JSON.parse(localStorage.getItem('USER')!)
    this.settingsData.forEach((setting) => {
      switch (setting.id) {
        case 'organization':
          let orgObject: {[key: string]: {id: string | number, businessName: string}} = {};
          setting.inputs.forEach((input) => {
            orgObject[input.id] = {
              id: input.values.find((value) => value.id === input.selectedValue)?.id || "",
              businessName: input.values.find((value) => value.id === input.selectedValue)?.label || ""
            }
          })
          if (currentUser) {
            (currentUser as IUser).customer = orgObject['customer'] as { id: number; businessName: string; };
            (currentUser as IUser).organization = orgObject['organization'] as { id: string; businessName: string; };
            (currentUser as IUser).building = {id: orgObject['building'].id, name: orgObject['building'].businessName} as { id: string; name: string; };
            this.requestsService.saveCurrentUser(currentUser);
          }
          break;
        case 'building':
          if (currentUser) {
            (currentUser as IUser).building = {
              id: setting.inputs[0].id,
              name: setting.inputs[0].label
            };
            this.notificationService.removeToken$().pipe().subscribe();
            this.requestsService.saveCurrentUser(currentUser);
            this.notificationService.postToken$(setting.inputs[0].id).subscribe();
          }
          break;
        default:
          break;
      }
    });
    this.notificationService.requestPermission();
    this.notificationService.listen();
    this.toastService.showToast(this.translate.instant('GENERAL.SUCCESS.CHANGES_SUCCESSFULLY'), IonicColors.GREEN)
  }

  get tableRowSpan(): number {
    return this.settingsData.reduce((prev, curr) => prev + curr.rowSpan,0)+1
  }

}

export interface ISettingsData {
  id: string,
  label: string,
  rowSpan: number,
  inputs: {
    id: string,
    type: 'select' | 'status' | 'button',
    length: number,
    label: string,
    selectedValue?: string | null,
    disableTranslation?: boolean,
    touched?: boolean,
    values: {
      id: string,
      label: string,
      status?: boolean
    }[],
    onSelect?: (resourceId: string) => void;
  }[]
}
