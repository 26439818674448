import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { ModalContainerComponent } from '../../../shared/components/modal-container/modal-container.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IBreadcrumbs } from '../../../shared/components/breadcrumbs/breadcrumbs.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerService } from '../../../core/services/customer.service';
import { FacilitiesService } from '../../../core/services/facilities.service';
import { catchError, combineLatest, filter, map, mergeAll, mergeMap, of, switchMap, take, tap, toArray } from 'rxjs';
import { IFormChangeEmitter } from '../../../shared/components/form/form.component';
import { RequestsService } from '../../../core/services/utils/requests.service';
import { UserService } from '../../../core/services/user.service';
import { SensorService } from '../../../core/services/sensors.service';
import { PatientService } from '../../../core/services/patient.service';
import { AuthService } from '../../../core/services/auth.service';
import { ToastService } from '../../../core/services/utils/toast.service';
import { IonicColors } from '../../../shared/enums/ionicColors.enum';
import { NotificationService } from '../../../core/services/notification.service';
import { IBuilding } from '../../../shared/models/be/building';
import { IonButton, IonContent } from "@ionic/angular/standalone";
import { EventTypeCodes } from '../../../shared/models/be/notification';
import { IUser } from '../../../shared/models/be/user';
import { IFcmTopic } from '../../../shared/models/be/fcmTopic';
import { IDevice } from '../../../shared/models/be/device';
import { isTimeWithinRange } from '../../../shared/utils/check.utils';

@Component({
  selector: 'app-settings',
  templateUrl: './developerSettings.page.html',
  styleUrls: ['./developerSettings.page.scss'],
  standalone: true,
  imports: [IonContent, 
    CommonModule,
    ModalContainerComponent,
    MatGridListModule,
    MatFormFieldModule,
    TranslateModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    IonContent,
    IonButton
  ]
})
export class DeveloperSettingsPage implements OnInit {

  public currentLanguage: string = 'it';

  public formStatus: IFormChangeEmitter = {
    canSaveDraft: true,
    canSaveData: true,
  }

  public breadcrumbsList: IBreadcrumbs[] = [{
    icon: 'settings',
    routerLink: '',
    label: 'DEVELOPER_SETTINGS'
  }]

  public settingsData: ISettingsData[] = [];

  constructor(
    private translate: TranslateService,
    private customerService: CustomerService,
    private facilitiesService: FacilitiesService,
    private requestsService: RequestsService,
    private userService: UserService,
    private sensorService: SensorService,
    private patientService: PatientService,
    private authService: AuthService,
    private toastService: ToastService,
    private alertController: AlertController,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.loadDeveloperSettings();
  }

  loadDeveloperSettings() {
    combineLatest([
      combineLatest([
        this.userService.getServiceStatus$(),
        this.facilitiesService.getServiceStatus$(),
        this.sensorService.getServiceStatus$(),
        this.patientService.getServiceStatus$(),
        this.authService.getServiceStatus$(),
        this.customerService.getServiceStatus$()
      ]),
      combineLatest([
        this.sensorService.getBuildingTopicData$(),
        this.sensorService.getDevices$().pipe(
          map((devices) => devices.filter((device) => device.facilities?.building?.id === this.requestsService.getCurrentUser()!.building!.id!)),
          mergeAll(),
          mergeMap((device) => this.sensorService.getDevicesSettings$("roomId", device.facilities!.room!.id).pipe(
              take(1),
              map((settings) => ({
                  ...device,
                  disabled: settings.notificationMode === 'disabled' || (settings.notificationMode === 'scheduled' && !isTimeWithinRange(settings.scheduleStartTime!, settings.scheduleEndTime!))
              }))
          )),
          toArray(),
        )
      ])
    ])
    .pipe(
      take(1),
      map(([cl1, cl2]) => [...cl1, ...cl2]),
      tap(([userStatus, facilitiesStatus, sensorStatus, ehrStatus, authService, customerStatus, buildingTopic, devices]) => {
        this.settingsData.push({
          id: 'servicesStatus',
          label: 'SERVICES_STATUS',
          rowSpan: 7,
          inputs: [{
            id: 'servicesStatus',
            type: 'status',
            rowspan: 6,
            length: 6,
            label: 'SERVICES_STATUS',
            values: [{
              id: 'user',
              label: 'USER',
              status: userStatus as boolean
            },{
              id: 'facilities',
              label: 'FACILITIES',
              status: facilitiesStatus as boolean
            },{
              id: 'sensor',
              label: 'SENSOR',
              status: sensorStatus as boolean
            },{
              id: 'ehr',
              label: 'EHR',
              status: ehrStatus as boolean
            },{
              id: 'auth',
              label: 'AUTH',
              status: authService as boolean
            },{
              id: 'customer',
              label: 'CUSTOMER',
              status: customerStatus as boolean
            }]
          }]
        })
        this.settingsData.push({
          id: 'notifications',
          label: 'NOTIFICATION_SIMULATION',
          rowSpan: 1,
          inputs:[
            {
              id: 'device',
              type: 'select',
              label: 'DEVICE',
              length: 2,
              disableTranslation: true,
              values: (devices as IDevice[]).map((device) => ({
                id: device.label,
                label: `${device.label} (${device.facilities?.room?.name}) ${(device as IDevice & {disabled: boolean}).disabled ? '- TEMPORIZZATO' : ''}`,
                disabled: (device as IDevice & {disabled: boolean}).disabled
              }))
            },{
              id: 'eventType',
              type: 'select',
              length: 2,
              label: 'EVENT_TYPE',
              disableTranslation: true,
              values: [{
                id: '01',
                label: 'Caduta'
              },{
                id: '02',
                label: 'Entrata stanza'
              },{
                id: '03',
                label: 'Uscita stanza'
              },{
                id: '04',
                label: 'Entrata letto'
              },{
                id: '05',
                label: 'Uscita letto'
              },{
                id: '06',
                label: 'Device offline'
              },{
                id: '07',
                label: 'Device online'
              }
            ]
            },{
              id: 'sendNotification',
              type: 'button',
              length: 2,
              label: 'SEND_NOTIFICATION',
              disableTranslation: true,
              onSelect: () => this.sendNotification()
            }
          ]
        })
        
        this.settingsData.push({
          id: 'token',
          label: 'FCM_TOKEN_INFO',
          rowSpan: 3,
          inputs:[
            {
              id: 'fcmToken',
              type: 'text',
              label: 'Token FCM',
              length: 6,
              rowspan: 2,
              disableTranslation: true,
              value: this.requestsService.getCurrentUser()?.fcmToken || "-"
            },{
              id: 'tokenInTopic',
              type: 'text',
              length: 6,
              label: 'Presenza Token in topic',
              disableTranslation: true,
              value: (buildingTopic as IFcmTopic[]).some((value) => value.token === this.requestsService.getCurrentUser()?.fcmToken && value.valid) ? "Token correttamente registrato" : "Errore nella registrazione al topic",
              onSelect: () => this.sendNotification()
            }
          ]
        })
      })
    ).subscribe();
  }

  printTopic() {
    const buildingId = this.requestsService.getCurrentUser()?.building?.id;
    if (buildingId) {
      this.sensorService.getBuildingTopicData$().pipe(
        tap(async (data) => {
          this.toastService.showToast("Dati Topic recuperati con successo");
          const alert = await this.alertController.create({
            header: 'Building Topic',
            message: `<pre>${JSON.stringify(data, null, 2)}</pre>`,
            buttons: ['Chiudi'],
            mode: 'md',
            cssClass: 'alert',
          });
          await alert.present();
        })
      ).subscribe();      
    } else {
      this.toastService.showToast("Selezionare edificio!", IonicColors.RED)
    }
  }

  sendNotification() {
    const developerSettings = this.settingsData.find((setting) => setting.id === 'notifications');
    const buildingId = this.requestsService.getCurrentUser()?.building?.id;
    const eventType = developerSettings?.inputs.find((input) => input.id === 'eventType')?.selectedValue as EventTypeCodes;
    const deviceId = developerSettings?.inputs.find((input) => input.id === 'device')?.selectedValue;
    if (buildingId && eventType && deviceId) {
      this.sensorService.sendEvent$(deviceId, eventType, buildingId || undefined).pipe(
        tap(() => this.toastService.showToast("Notifica inviata correttamente")),
        catchError((err) => {
          this.toastService.showToast("Errore nell'invio della notifica", IonicColors.RED)
          throw err;
        })
      ).subscribe();
    } else {
      this.toastService.showToast("Errore nella compilazione della form, ricontrollare!", IonicColors.RED)      
    }
  }

  get tableRowSpan(): number {
    return this.settingsData.reduce((prev, curr) => prev + curr.rowSpan,0)+2
  }

}

export interface ISettingsData {
  id: string,
  label: string,
  rowSpan: number,
  inputs: {
    id: string,
    type: 'select' | 'status' | 'button' | 'text',
    rowspan?: number;
    length: number,
    label: string,
    fill?: string,
    selectedValue?: string | null,
    disableTranslation?: boolean,
    touched?: boolean,
    values?: {
      id: string,
      label: string,
      status?: boolean
    }[],
    value?: string;
    onSelect?: (resourceId: string) => void;
  }[]
}
