<mat-sidenav-container class="example-container">
  <mat-grid-list cols="12" rowHeight="100%" gutterSize="20">
    <!-- SIDENAV TILE -->
    <mat-grid-tile [colspan]="isExpanded ? 2 : 1" rowspan="1">
      <mat-sidenav #sidenav class="example-sidenav" style="width: 100%;" mode="side" opened="true" (mouseenter)="mouseenter()" (mouseleave)="mouseleave()">
        <mat-grid-list cols="1" rowHeight="43px" gutterSize="20" class="userMatGridList">
          <mat-grid-tile colspan="1" rowspan="3">
            <!-- USER CARD -->
            <user-section-sidenav style="height: 100%; width: 100%;" [userData]="loggedUser"></user-section-sidenav>
          </mat-grid-tile>
        </mat-grid-list>
        
        <mat-divider></mat-divider>  
        <!-- MENU ITEM LIST -->
        <mat-nav-list style="padding: 0;">
          <ng-container *ngFor="let item of menuItems">
            <!-- CASE 1: NO CHILDREN -->
            <a *ngIf="!item.children" [routerLink]="item.routerLink || undefined" (click)="item.id === 'logout' ? logout() : null" style="color: inherit; text-decoration: none;">
              <mat-list-item [style.background-color]="selectedItem === item.id ? '#96bafc87' : ''">
                <mat-icon matListItemIcon>{{item.icon}}</mat-icon>
                <div matListItemTitle *ngIf="isExpanded">{{'SIDENAV.ITEMS.'+item.label | translate}}</div>
              </mat-list-item>
            </a>
            
            <!-- CASE 2: CHILDREN (SHOW EXP PAN) -->
            <!-- <mat-expansion-panel [class.mat-elevation-z0]="true" dense *ngIf="item.children">
              <mat-expansion-panel-header>
                <mat-icon matListItemIcon style="color: #00000061;">{{item.icon}}</mat-icon>
                <span matListItemTitle>{{'SIDENAV.ITEMS.'+item.label | translate}}</span>
              </mat-expansion-panel-header>
              <mat-nav-list dense>
                <mat-list-item *ngFor="let child of item.children" (click)="navigateToPage(child)">
                  <mat-icon matListItemIcon>{{child.icon}}</mat-icon>
                  <div matListItemTitle>{{'SIDENAV.ITEMS.'+child.label | translate}}</div>
                </mat-list-item>
              </mat-nav-list>
            </mat-expansion-panel> -->
            <!-- DIVIDER -->
            <mat-divider></mat-divider>
          </ng-container>
        </mat-nav-list>
      </mat-sidenav>
    </mat-grid-tile>
    <!-- CONTENT -->
    <mat-grid-tile [colspan]="isExpanded ? 10 : 11" rowspan="1">
      <ion-content [fullscreen]="true" [scrollY]="false">
        <router-outlet></router-outlet>
      </ion-content>
    </mat-grid-tile>
  </mat-grid-list>
</mat-sidenav-container>