import { Injectable } from "@angular/core";
import { ITableData, ITableDataData } from "../table.component";
import { MatSort } from "@angular/material/sort";

@Injectable({
    providedIn: 'any'
  })
export class SortInitializer {
  
  constructor(
  ) {

  }

  /**
   * @description Funzione che sovrascrive la logica del sorting di Material Datatable per far fronte alle mie esigenze
   */
  sortLogic() {
    let sortFunction = function (tableData: ITableData[], sort: MatSort) {
      const active = sort.active;
      const direction = sort.direction;
      if (!active || direction == '') { return tableData; }

      return tableData.sort((a: ITableData, b: ITableData) => {
        a.data = a.data as ITableDataData;
        b.data = b.data as ITableDataData;
        //Sorting logic here
        // If both a and b exist (truthy), then compare the two. Otherwise, check if
        // one value exists while the other doesn't. In this case, existing value should come last.
        // This avoids inconsistent results when comparing values to undefined/null.
        // If neither value exists, return 0 (equal).
        let comparatorResult = 0;
        if (a.data[active] != null && b != null) {
          // Check if one value is greater than the other; if equal, comparatorResult should remain 0.
          if (a.data[active]! > b.data[active]!) {
            comparatorResult = 1;
          } else if (a.data[active]! < b.data[active]!) {
            comparatorResult = -1;
          }
        } else if (a.data[active] != null) {
          comparatorResult = 1;
        } else if (b.data[active] != null) {
          comparatorResult = -1;
        }
        return comparatorResult * (direction == 'asc' ? 1 : -1);
      });
    };

    return sortFunction
  }

}