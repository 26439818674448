import {inject} from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { HardwareService } from '../services/hardware.service';

/**
 * @description Guard che controlla se il dispositivo è MOBILE+PWA o DESKTOP
 * @returns 
 */
export const isDesktopOrMobilePwaGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean => {
    const router = inject(Router);
    const hardwareService = inject(HardwareService);
    if (!hardwareService.isMobile() || (hardwareService.isMobile() && hardwareService.isPwa()) ) {
        return true;
    } else {
        router.navigateByUrl('/pwaInstallation');
        return false;
    }
}

export function deviceGuard(
    allowedDevice: 'pwaMobileOrDesktop' | 'mobile' | 'mobilePwa' | 'desktop',
    fallBackDestination: string
  ): CanActivateFn {
    return () => {
        const router = inject(Router);
        const hardwareService = inject(HardwareService);
        switch (allowedDevice) {
            case 'pwaMobileOrDesktop':
                if (!hardwareService.isMobile() || (hardwareService.isMobile() && hardwareService.isPwa()) ) {
                    return true;
                };
                break;
            case 'mobile':
                if (hardwareService.isMobile()) {
                    return true;
                };
                break;
            case 'mobilePwa':
                if (hardwareService.isMobile() && hardwareService.isPwa()) {
                    return true;
                };
                break;
            case 'desktop':
                if (!hardwareService.isMobile()) {
                    return true;
                };
                break;        
            default:
                break;
        }        
        router.navigateByUrl(fallBackDestination)
        return false;
    };
  }
  