import { createReducer, on } from '@ngrx/store';

import * as CustomersActions from '../actions/customers.actions';
import { ICustomer } from '../../shared/models/be/customers';

export const CUSTOMERS_FEATURE_KEY = 'customers';

export const initialCustomersState: {data: ICustomer[], isLoading: boolean, hasError: boolean} = {data: [], isLoading: false, hasError: false};

export const customersReducer = createReducer(
  initialCustomersState,
  on(CustomersActions.loadingCustomers, state => {
    return ({
      ...state,
      data: [],
      isLoading: true,
      hasError: false
    })
  }),
  on(CustomersActions.loadCustomersSuccess, (state, action) => {
    return({
      ...state,
      data: action.customers,
      isLoading: false,
      hasError: false
    })
  }),
  on(CustomersActions.loadingCustomersError, state => {
    return ({
      data: [],
      isLoading: false,
      hasError: true
    })
  }),
  on(CustomersActions.createCustomerSuccess, (state, action) => {
    return ({
      data: [...state.data, action.customer],
      isLoading: false,
      hasError: false
    })
  }),
  on(CustomersActions.createCustomerError, state => {
    return ({
      ...state,
      isLoading: false,
      hasError: true
    })
  }),
);
