import { Component, ElementRef, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { FormComponent, ISelectInput, ISimpleInput } from '../form/form.component';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SanitizeInputDirective } from '../../directives/inputSanitize.directive';
import { MatInputModule } from '@angular/material/input';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  standalone: true,
  imports: [ 
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatGridListModule,
    MatFormFieldModule,
    MatSelectModule,
    TranslateModule,
    ReactiveFormsModule,
    SanitizeInputDirective,
    MatInputModule,
    IonicModule
  ]
})
export class DialogComponent implements OnInit {
  
  /**
   * @description FormGroup che regola tutti i campi della form nel component 
   */
  public formGroup!: FormGroup;
  public formArray!: FormArray;
  public hasError: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      formData: (ISimpleInput | ISelectInput)[], 
      title: string, 
      isDeviceLabelForm?: boolean, 
      additionalData: {
        valuesToCheck: string[]
      }
    },
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    if (this.data.isDeviceLabelForm) {
      this.formArray = this.formBuilder.array(
        this.data.formData.map((input) => (new FormControl(input.inputValue, input.validators)))
      )
    } else {
      this.formGroup = this.formBuilder.group(
        this.data.formData.reduce((_accumulator, currentInput) => {
          _accumulator[currentInput.id] = [currentInput.inputValue || null, (currentInput as ISimpleInput).validators || []]
          return _accumulator
        }, {} as any)
      )
    }    
  }

  @ViewChildren('inputEl') inputEls!: QueryList<ElementRef<HTMLInputElement>>;
  
  handleKeyPress(e: KeyboardEvent, idx: number) {
    const isDigit = /\d/.test(e.key);  
    if (isDigit && idx + 1 < 4) {
      this.inputEls.get(idx+1)?.nativeElement.focus()
    }  
    if (isDigit && this.formArray.controls[idx].value) {
      // If user deselects an input which already has a value
      // we want to clear it so that it doesn't have more than 1 digit
      this.formArray.controls[idx].setValue('');
    }  
    return isDigit;
  }

  handleInput(idx: number) {
    if (this.inputEls.get(idx)?.nativeElement.value) {
      this.inputEls.get(idx+1)?.nativeElement.focus()
    }
    if (this.formArray.valid) {
      const currentValue = this.inputEls.map((input) => input.nativeElement.value).join().replaceAll(',', '')
      this.hasError = this.data.additionalData.valuesToCheck.some((value) => value === currentValue);
    } else {
      this.hasError = false;
    }
  }
  
  handleFocus(e: FocusEvent) {
    // Select previously entered value to replace with a new input
    (e.target as HTMLInputElement).select();
  }

  handleKeyDown(e: KeyboardEvent, idx: number) {
    if (e.key === 'ArrowLeft') {
      if (idx > 0) {
        this.inputEls.get(idx-1)?.nativeElement.focus()
      }
    } else if (e.key === 'ArrowRight') {
      if (idx < 4) {
        this.inputEls.get(idx+1)?.nativeElement.focus()
      }
    }
  }

  get saveButtonDisabledStatus(): boolean {
    return (this.formGroup && !this.formGroup.valid) || (this.formArray && (!this.formArray.valid || this.hasError))
  }
}
