<mat-grid-list cols="20" rowHeight="36px" gutterSize="20" class="grid-list" [ngClass]="{'grid-list_add-margin-right' : !config.showOnlyTable}">
  <ng-container *ngIf="!config.showOnlyTable">
    <!-- Empty Row -->
    <mat-grid-tile colspan="20" rowspan="1">
    </mat-grid-tile>
    <!-- BREADCRUMB -->
    <mat-grid-tile colspan="16" rowspan="1" class="grid-list_header-container_breadcrumb">
      <ion-breadcrumbs>
        <ion-breadcrumb>
          <mat-icon matListItemIcon slot="start">group</mat-icon>
          {{'SIDENAV.ITEMS.'+config.referenceLabel | translate}}
        </ion-breadcrumb>
      </ion-breadcrumbs>
    </mat-grid-tile>
    @if (!config.hideAddBtn) {
      <!-- BTN AGGIUNTA -->
      <mat-grid-tile colspan="4" rowspan="1" *ngIf="!config.hideAddBtn">
        <ion-button mode="md" (click)="emitCtaAction('add')">
          <mat-icon slot="start">add</mat-icon>
          {{'TABLE.ADD_BTN.'+config.referenceLabel | translate}}
        </ion-button>
      </mat-grid-tile>
    } @else {
      <!-- Empty Row -->
      <mat-grid-tile colspan="4" rowspan="1">
      </mat-grid-tile>
    }    
    <!-- SEARCHBAR -->
    <mat-grid-tile colspan="5" rowspan="1" *ngIf="!config.hideSearchBar">
      <ion-searchbar 
        animated="true"
        [placeholder]="'TABLE.SEARCHBAR.'+config.referenceLabel | translate"
        mode="md"
        (keyup)="applySearchFilter(config.searchBy, $any($event.target).value || null)"
        (ionClear)="applySearchFilter(config.searchBy, null)"
        [ngModel]="searchBarValue"
      ></ion-searchbar>
    </mat-grid-tile>
    <!-- BTN AGGIORNA DATI -->
    <mat-grid-tile colspan="2" rowspan="1">
      <ion-button fill="outline" mode="md" (click)="emitCtaAction('refresh')">
        <mat-icon slot="start">refresh</mat-icon>
        {{'GENERAL.BUTTONS.UPDATE' | translate}}
      </ion-button>
    </mat-grid-tile>
    <!-- EMPTY COL -->
    <mat-grid-tile [colspan]="11" rowspan="1">
    </mat-grid-tile>
    <mat-grid-tile colspan="5" rowspan="1" *ngIf="config.hideSearchBar"></mat-grid-tile>
    <!-- SHOW BTN -->
    <mat-grid-tile colspan="2" rowspan="1">
      <ion-button fill="outline" mode="md" [matMenuTriggerFor]="columnsList"><mat-icon slot="start">tune</mat-icon>{{'TABLE.BUTTONS.SHOW' | translate}}</ion-button>
      <!-- Menu mostrante una lista di tutte colonne con la rispettiva checkbox che ne regola la visibilità -->
      <mat-menu #columnsList xPosition="before">
        <!-- Per ogni colonna che non è "options" genero una checkbox -->
        <ng-container *ngFor="let column of columnsWithoutOptions; last as last">
          <!-- Bindo la checkbox all'array di colonne - N.B. l'evento al click serve per non chiudere il menu (popup) al click di una checkbox (comportamento base del mat-menu) -->
          <ion-checkbox labelPlacement="end" (click)="$event.stopPropagation();" (ionChange)="tableService.updateTablePreferences(config.id, 'columnsVisibility', column, tableColumns, $event.detail.checked)" [(ngModel)]="column.showCol" mode="md" class="grid-list_header-container_show-btn">
            {{ 'TABLE.HEADER.'+column.label| translate }}
          </ion-checkbox>
          <!-- Mostro la linea di divisione tra le colonne per tutti gli elementi tranne l'ultimo -->
          <mat-divider *ngIf="!last"></mat-divider>
        </ng-container>
      </mat-menu>
    </mat-grid-tile>
    <!-- FILTERS -->
    <mat-grid-tile colspan="20" rowspan="1" *ngIf="dataSource && config.filters" style="overflow: visible;">
      <table-filters
        style="width: 100%;"
        [dataSource]="dataSource"
        [config]="config"
        [tableColumns]="tableColumns"
        (reloadTableData)="reloadTableData($event)"
      ></table-filters>
    </mat-grid-tile>
  </ng-container>
  <mat-grid-tile colspan="20" [rowspan]="config.tableHeightInRows || 12" *ngIf="dataSource">
    <div class="mat-elevation-z8" class="grid-list_table-tile">
      <!-- Spinner nel caso di caricamento dati nella table -->
      <div class="grid-list_table-tile_spinner-container" *ngIf="isLoading" >
          <mat-progress-spinner 
          color="primary" 
          mode="indeterminate">
        </mat-progress-spinner>
      </div>
      <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z0 grid-list_table-tile_table">
        <ng-container
            *ngFor="let column of tableColumns; let last = last; let i = index"
            [matColumnDef]="column.id"
          >
            <!-- HEADER ROW -->
            <ng-container [ngSwitch]="column.id">
              <!-- Generic -->
              <ng-container *ngSwitchDefault>
                <mat-header-cell *matHeaderCellDef [mat-sort-header]="column.id" [ngStyle]="{'width': column.colWidth || '', 'flex': column.colWidth ? 'none' : ''}">
                  <span style="font-weight: bold; text-align: start;">{{ 'TABLE.HEADER.'+column.label | translate }}</span>
                </mat-header-cell>
              </ng-container>
              <!-- Options -->
              <ng-container *ngSwitchCase="'options'">
                <mat-header-cell *matHeaderCellDef style="flex: none; width: 136px">
                </mat-header-cell>
              </ng-container>
              <!-- Checkbox -->
              <ng-container *ngSwitchCase="'checkbox'">
                <mat-header-cell *matHeaderCellDef>
                  <mat-checkbox 
                    (change)="$event ? toggleAllRows() : null"
                    (change)="onCheckboxStatusChange()"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                  </mat-checkbox>
                </mat-header-cell>
              </ng-container>
            </ng-container>
            <!-- VALORE RIGA -->
            <mat-cell 
              *matCellDef="let tableData;"
              [ngSwitch]="column.id"
              [ngStyle]="{'width': column.colWidth || '', 'flex': column.colWidth ? 'none' : ''}"
              #tableCellRow>
              <!-- Generic -->
              <ng-container class="generic_text" *ngSwitchDefault>
                <!-- Se si tratta di un oggetto espandibile -->
                @if ((tableData.data[column.id] | typeof) === 'object' && tableData.data[column.id]?.expandableData) {
                  <div style="display: flex; flex-direction: column; padding: 9px 0; height: 100%;">
                    <!-- Se si tratta di un oggetto con valori complessi (quindi o stanze o letti) -->
                    @if (tableData.data[column.id].complexValues) {
                      <!-- Per ogni unità -->
                      @for (unit of tableData.data['units'].values; track unit; let unitIndex = $index) {
                        <!-- Verifico se è espansa -->
                        @if (unit.isExpanded) {
                          <!-- Se è espansa allora ciclo i dati da mostrare per index in modo da puntare solo i dati appartenenti alla unità in oggetto -->
                          @for (currentValue of tableData.data[column.id].complexValues[unitIndex]; track currentValue; let currentValueIndex = $index) {
                            <div style="display: flex; flex-direction: column;">
                              <div style="display: flex;">
                                @if (currentValue.value) {
                                  <p 
                                    style="height: 100%; padding: 5px 0; margin: 0; white-space: nowrap; text-overflow: ellipsis;" 
                                    [ngStyle]="{'cursor': currentValue.redirectData ? 'pointer' : '', 'color': currentValue.redirectData ? '#4588fff0' : ''}"
                                    (click)="tableService.updateTablePreferences(currentValue.redirectData?.destinationId, 'filters', currentValue.redirectData?.params?.filters || []); router.navigateByUrl(currentValue.redirectData?.destination)">
                                      {{currentValue.value || '-'}}
                                  </p><!-- P height = 20px -->
                                  @if (currentValue.icons) {
                                    @for (icon of currentValue.icons; track $index) {
                                      @if (icon.redirectData && icon.badgeValue) {
                                        <mat-icon
                                          aria-hidden="false"
                                          [matBadge]="icon.showBadge ? icon.badgeValue : null"
                                          matBadgeSize="small"
                                          class="material-symbols-outlined"
                                          style="margin: 3px; color: #545554; cursor: pointer;"
                                          (click)="tableService.updateTablePreferences(icon.redirectData?.destinationId, 'filters', icon.redirectData?.params?.filters || []); router.navigateByUrl(icon.redirectData?.destination)"
                                          >
                                            {{icon.name}}
                                        </mat-icon>
                                      }
                                    }
                                  }
                                } @else {
                                  <div style="height: 30px; min-height: 30px;"></div>
                                }
                              </div>
                              @if (currentValue.emptyRows) {
                                <div style="height: 30px; min-height: 30px;" *ngFor="let _ of [].constructor(currentValue.emptyRows)"></div>
                              }
                            </div>
                          }
                        } @else {
                          <div style="display: flex; height: 100%;">
                            <p style="height: 100%; margin: 0; display: flex; align-items: center;">{{(tableData.data[column.id].expandableData?.collapsedValue || [])[unitIndex]?.value || ''}}</p>
                            @if ((tableData.data[column.id].expandableData?.collapsedValue || [])[unitIndex]?.icons) {
                              @for (icon of (tableData.data[column.id].expandableData?.collapsedValue || [])[unitIndex]?.icons; track $index) {
                                @if (icon.redirectData && icon.badgeValue) {
                                  <mat-icon
                                    aria-hidden="false"
                                    [matBadge]="icon.showBadge ? icon.badgeValue : null"
                                    matBadgeSize="small"
                                    class="material-symbols-outlined"
                                    style="margin: 3px; color: #545554; cursor: pointer;"
                                    (click)="tableService.updateTablePreferences(icon.redirectData?.destinationId, 'filters', icon.redirectData?.params?.filters || []); router.navigateByUrl(icon.redirectData?.destination)"
                                    >
                                      {{icon.name}}
                                  </mat-icon>
                                }
                              }
                            }
                          </div>
                        }
                      }
                    } @else {
                      @for (currentValue of tableData.data[column.id].values; track $index) {
                        <div style="display: flex;" [style.height]="!currentValue.isExpanded ? '100%' : ''">
                          <!-- Button per "aprire" la Riga -->
                          <button
                            mat-icon-button
                            matSuffix
                            class="group_actions"
                            style="display: flex; height: 100%; align-items: center; justify-content: center; width: 30px; height: 30px; padding: 0;"
                            *ngIf="tableData.data[column.id]?.expandableData?.showExpandButton && currentValue.value"
                            (click)="
                              currentValue.isExpanded = !currentValue.isExpanded;                              
                              column.id === 'name' && expandRows(tableData.data['units'].values, currentValue.isExpanded);
                              tableData['isTableExpanded'] = !tableData['isTableExpanded'];
                            "
                          >
                            <!-- Freccetta espansione riga -->
                            <mat-icon slot="start" style="height: 24px; width: 24px;;">{{currentValue.isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
                          </button>
                          @if (currentValue.value) {
                            <p 
                              style="height: 100%; padding: 5px 0; margin: 0; white-space: nowrap; text-overflow: ellipsis; width: 100%;" 
                              [ngStyle]="{'cursor': currentValue.redirectData ? 'pointer' : '', 'color': currentValue.redirectData ? '#4588fff0' : ''}"
                              (click)="tableService.updateTablePreferences(currentValue.redirectData?.destinationId, 'filters', currentValue.redirectData?.params?.filters || []); router.navigateByUrl(currentValue.redirectData?.destination)">
                                {{currentValue.value || '-'}}
                            </p><!-- P height = 20px -->
                          } 
                        </div>
                        @if (currentValue.isExpanded && currentValue.emptyRows) {
                          <div style="height: 30px; min-height: 30px;" *ngFor="let _ of [].constructor(currentValue.emptyRows > 0 ? currentValue.emptyRows-1 : 0)"></div>
                        }
                      }
                    }
                  </div>
                } @else {
                  <!-- Pezza momentanea, TODO: SISTEMARE -->
                  @if (column.id === 'patientStatus') {
                    <span style="height: 100%; padding: 10px 0; display: flex; align-items: center;" [ngStyle]="{color: tableData.rowStyle?.disabledLine ? 'gray' : ''}" [innerHTML]="'GENERAL.STATUS.'+tableData.data[column.id].toUpperCase() | translate"></span>
                  } @else {
                    <span style="height: 100%; padding: 10px 0; display: flex; align-items: center;" [ngStyle]="{color: tableData.rowStyle?.disabledLine ? 'gray' : ''}" [innerHTML]="tableData.data[column.id] || '-'"></span>
                  }
                }
              </ng-container>
              <!-- Status -->
              <ng-container *ngSwitchCase="'status'">
                <span class="dot" [class]="'dot_'+tableData.data[column.id]"></span>
              </ng-container>
              <!-- Options -->
              <ng-container *ngSwitchCase="'options'">
                <div style="display: flex; flex-wrap: nowrap; flex-direction: row;">
                  <!-- E se abilitata la visualizzazione della contactInfo (nei configParams) mostro anche la card -->
                  <ng-container *ngIf="config.contactInfo">
                      <button
                        mat-icon-button
                        matSuffix
                        class="group_actions"
                        [matMenuTriggerFor]="contactsMenu"
                      >
                        <mat-icon slot="start">credit_card</mat-icon>
                      </button>
                      <!-- E mostro le informazioni definite nei configParams -->
                      <mat-menu #contactsMenu xPosition="before">
                        <div style="display: flex; justify-content: center; padding: 5px;align-items: flex-start; flex-direction: column; margin-right: 20px;">
                          <p style="margin: 2px; font-weight: bold;">{{tableData.data[config.contactInfo.title] || ''}}</p>
                          <p style="margin: 0 0 0 2px; font-size: 11pt;" *ngFor="let subtitle of config.contactInfo.subtitles">{{tableData.data[subtitle.value] ? ((subtitle.prefix || '')+tableData.data[subtitle.value]) : ''}}</p>
                        </div>
                      </mat-menu>
                  </ng-container>
                  <button
                    mat-icon-button
                    matSuffix
                    class="group_actions"
                    [matMenuTriggerFor]="optionsMenu"
                  >
                    <mat-icon slot="start">more_vert</mat-icon>
                  </button>
                </div>
                <!-- Contiene tutte le opzioni che si vedono nel momento in cui si premono i 3 puntini -->
                <mat-menu #optionsMenu xPosition="before">
                  <!-- Per ogni elemento presente nel parametro 'options' setto un button con le caratteristiche specificate nell'oggetto -->
                  <ng-container *ngFor="let opt of tableData.options; last as last">
                    @if (!opt.children) {
                      <button
                        mat-menu-item
                        style="display: flex; align-items: center;"
                        (click)="emitOptionAction(opt.action, tableData.data, opt.additionalParams)"
                        [disabled]="opt.disabled"
                      >
                        <mat-icon>
                          <span class="material-symbols-outlined">{{
                            opt.icon
                          }}</span>
                        </mat-icon>
                        <span class="menu-item-owner capitalize">{{opt.disableTranslation ? opt.label : ('TABLE.OPTIONS.'+opt.label | translate)}}</span>
                      </button>
                    } @else {
                      <button
                        mat-menu-item
                        style="display: flex; align-items: center;"
                        [disabled]="opt.disabled"
                        [matMenuTriggerFor]="firstMenuLevel"
                      >
                        <mat-icon *ngIf="opt.icon">
                          <span class="material-symbols-outlined">{{
                            opt.icon
                          }}</span>
                        </mat-icon>
                        <span class="menu-item-owner capitalize">{{opt.disableTranslation ? opt.label : ('TABLE.OPTIONS.'+opt.label | translate)}}</span>
                      </button>
                      <mat-menu #firstMenuLevel="matMenu">
                        <ng-container *ngFor="let child of opt.children">
                          @if(!child.children) {
                            <button
                              mat-menu-item
                              style="display: flex; align-items: center;"
                              (click)="emitOptionAction(child.action, tableData.data, child.additionalParams)"
                              [disabled]="child.disabled"
                            >
                              <mat-icon *ngIf="child.icon">
                                <span class="material-symbols-outlined">{{
                                  child.icon
                                }}</span>
                              </mat-icon>
                              <span class="menu-item-owner capitalize">{{child.disableTranslation ? child.label : ('TABLE.OPTIONS.'+child.label | translate)}}</span>
                            </button>
                          } @else {
                            <button
                              mat-menu-item
                              style="display: flex; align-items: center;"
                              [disabled]="child.disabled"
                              [matMenuTriggerFor]="secondMenuLevel"
                            >
                              <mat-icon *ngIf="child.icon">
                                <span class="material-symbols-outlined">{{
                                  child.icon
                                }}</span>
                              </mat-icon>
                              <span class="menu-item-owner capitalize">{{child.disableTranslation ? child.label : ('TABLE.OPTIONS.'+child.label | translate)}}</span>
                            </button>
                            <mat-menu #secondMenuLevel="matMenu" xPosition="before">
                              <ng-container *ngFor="let secondChild of child.children">
                                <button
                                  mat-menu-item
                                  style="display: flex; align-items: center;"
                                  (click)="emitOptionAction(secondChild.action, tableData.data, secondChild.additionalParams)"
                                  [disabled]="secondChild.disabled"
                                >
                                  <mat-icon *ngIf="secondChild.icon">
                                    <span class="material-symbols-outlined">{{
                                      secondChild.icon
                                    }}</span>
                                  </mat-icon>
                                  <span class="menu-item-owner capitalize">{{secondChild.disableTranslation ? secondChild.label : ('TABLE.OPTIONS.'+secondChild.label | translate)}}</span>
                                </button>
                              </ng-container>
                            </mat-menu>
                          }
                          
                        </ng-container>
                        
                      </mat-menu>
                    }
                    <!-- Mostro la linea di divisione tra le options per tutti gli elementi delle options tranne l'ultimo -->
                    <mat-divider *ngIf="!last"></mat-divider>
                  </ng-container>
                </mat-menu>
              </ng-container>
              <!-- Checkbox -->
              <ng-container *ngSwitchCase="'checkbox'">
                <mat-checkbox (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(tableData) : null"
                  (change)="onCheckboxStatusChange()"
                  [checked]="selection.isSelected(tableData)">
              </mat-checkbox>
              </ng-container>
            </mat-cell>
        </ng-container>
        <!-- Header row -->
        <mat-header-row *matHeaderRowDef="columnsIds; sticky: true"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: columnsIds"
          [ngStyle]="{'background-color': row.rowStyle?.bgColor || 'unset'}"
        ></mat-row>
        <!-- Quando non vengono trovati dati mostro questa riga -->
        <tr *matNoDataRow class="no-results-row">
          <td class="mat-cell" colspan="4">
            {{'TABLE.NO_RESULTS' | translate}}
          </td>
        </tr>
      </mat-table>
      <mat-paginator
        #paginator
        [length]="data.length"
        [pageSize]="10"
        [pageSizeOptions]="[10, 15, 25, 30, 50, 100]"
        [showFirstLastButtons]="true"
        *ngIf="!config.hidePaginator"
        (page)="paginatorChange($event)"
      ></mat-paginator>
    </div>
  </mat-grid-tile>
</mat-grid-list>