<div style="display: flex; justify-content: space-between; align-items: center; padding: 10px; background-color: #00AEBE;">
  <mat-icon style="color: white; visibility: hidden;">settings</mat-icon>
  <h2 style="color: white; margin: 0; font-size: 20px;">Temporizzazione unità</h2>
  <mat-icon [mat-dialog-close]="false" style="color: white;">close</mat-icon>
</div>

<mat-dialog-content class="mat-typography" style="padding: 15px;" (scroll)="onScroll()" [ngStyle]="{'overflow': isHandleTouched ? 'hidden' : ''}">
    <mat-expansion-panel *ngFor="let unit of unitsTree" (opened)="refreshCirclePosition(); unit.isOpen = true" [expanded]="unit.isOpen" (closed)="unit.isOpen = false">
      <mat-expansion-panel-header style="background-color: #80808030; height: 48px;">
        <mat-panel-title style="margin-left: 15px; font-size: 18px; color: #616161;"> {{unit.name}}</mat-panel-title>
      </mat-expansion-panel-header>
      <timingComponentsGroup *ngIf="unit.devicesSettings" (onSubmitData)="submitDevicesSettings($event, unit)" [deviceSettings]="unit.devicesSettings"></timingComponentsGroup>
    </mat-expansion-panel>
</mat-dialog-content>
