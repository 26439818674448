import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { getMessaging, getToken, MessagePayload, onMessage } from "firebase/messaging";
import { onBackgroundMessage } from "firebase/messaging/sw";
import { FirebaseApp, initializeApp } from 'firebase/app';

@Injectable({ providedIn: 'root' })
export class NotificationService {

    message?: MessagePayload;

    app: FirebaseApp;
    
    constructor() {
        this.app = initializeApp(environment.firebase);
    }

    requestPermission() {
  
      const messaging = getMessaging();
  
      getToken(messaging, { vapidKey: environment.firebase.vapidKey }).then((currentToken) => {
        if (currentToken) {
          console.log("Token")
          console.log(currentToken);
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  
    }

    listen() {
      const messaging = getMessaging();
      onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
        /* let audio = new Audio();
        audio.src = "../../../assets/sounds/ringtone1.mp3";
        audio.load();
        audio.play();
        this.message=payload; */
      });
    }
}