import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AlertController, IonicModule } from '@ionic/angular';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { Router, RouterModule } from '@angular/router';
import { UserSectionSidenavComponent } from '../../../shared/components/user-section-sidenav/user-section-sidenav.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { tap } from 'rxjs';
import { AuthService } from '../../../core/services/auth.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IUser } from '../../../shared/models/be/user';
import { RequestsService } from '../../../core/services/utils/requests.service';

@Component({
  selector: 'app-root',
  templateUrl: './root.page.html',
  styleUrls: ['./root.page.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    RouterModule,
    UserSectionSidenavComponent,
    MatSidenavModule,
    MatGridListModule,
    MatSidenavModule,
    MatListModule,
    MatExpansionModule,
    MatIconModule,
    TranslateModule
  ],
})
export class RootPage implements OnInit {
  @ViewChild('sidenav') sidenav!: MatSidenav;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  public selectedItem: string = '';
  public loggedUser?: IUser;

  public menuItems: IMenuItem[] = [];

  constructor(
    private router: Router,
    private authService: AuthService,
    private alertController: AlertController,
    private translateService: TranslateService,
    private requestsService: RequestsService
  ) {
    this.loggedUser = requestsService.getCurrentUser() || undefined;
    requestsService.currentUser$.pipe(
      tap((currentUser: IUser | null) => {
        this.loggedUser = currentUser || undefined;
      })
    ).subscribe();
    requestsService.selectedSidebarItem$.pipe(
      tap((selectedItem) => {
        this.selectedItem = selectedItem || this.selectedItem;
      })
    ).subscribe();
  }

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  ngOnInit() {
    this.loggedUser = this.requestsService.getCurrentUser() || undefined;
    this.menuItems = [
      {
        id: 'customers',
        label: 'CUSTOMERS',
        icon: 'group',
        routerLink: '/wita/customers',
        hide: !['admin', 'superuser'].includes(this.loggedUser?.role || "")
      },
      {
        id: 'users',
        label: 'USERS',
        icon: 'groups',
        routerLink: '/wita/users',
        hide: !['admin', 'superuser', 'localadmin'].includes(this.loggedUser?.role || "")
      },
      {
        id: 'structure',
        label: 'STRUCTURE',
        icon: 'domain',
        routerLink: '/wita/structure',
      },
      {
        id: 'patients',
        label: 'PATIENTS_LIST',
        icon: 'personal_injury',
        routerLink: '/wita/patients',
      },
      {
        id: 'devices',
        label: 'SENSORS_LIST',
        icon: 'videocam',
        routerLink: '/wita/devices',
        hide: !['admin', 'superuser'].includes(this.loggedUser?.role || "")
      },
      {
        id: 'settings',
        label: 'SETTINGS',
        icon: 'settings',
        routerLink: '/wita/settings'
      },
      {
        id: 'logout',
        label: 'LOGOUT',
        icon: 'logout',
        routerLink: ''
      },
      /* {
        id: 'allergensList',
        label: 'ALLERGENS',
        icon: 'no_food',
        routerLink: '/wita/patients/allergens',
        requireOrganization: true
      }, */
      /* {
        id: 'mentorage',
        label: 'MENTORAGE',
        icon: 'groups',
        routerLink: '/wita/events',
        requireOrganization: true
      }, */
    ].filter((item) => !item.hide);
    if (!this.selectedItem && this.menuItems.some((item) => this.router.url.includes(item.routerLink!))) {
      this.selectedItem = this.menuItems.find((item) => this.router.url.includes(item.routerLink!))!.id;
    }    
  }

  logout() {
    this.authService.logout$().subscribe()
  }
}

interface IMenuItem {
  /**
   * Identificativo della voce di menu
   */
  id: string;
  /**
   * Label della voce di menu
   */
  label: string;
  /**
   * Icona, N.B. Deve essere di Angular Material l'icona
   */
  icon: string;
  /**
   * Route della pagina in cui si vuole navigare, opzionale nel caso siano presenti figli
   */
  routerLink?: string;
  /**
   * Se true richiede la presenza di una organizzazione, qualora non presente comparirà un Popup
   */
  requireOrganization?: boolean;
  /**
   * Opzionale, se presenti figli si inseriscono qua, il DTO è lo stesso di IMenuItem (ma senza children, non si possono annidare voci di menu più volte)
   */
  children?: Omit<IMenuItem, 'children'>[];
  /**
   * Opzionale, se true non viene mostrata la voce di menu
   */
  hide?: boolean;
}
